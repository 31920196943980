import React, { useState, useEffect } from "react";
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { var_api } from "../constant";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import Pagination from 'react-js-pagination';

const StockTake = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [getstocktake, setstocktake] = useState([]);
    const shopId = localStorage.getItem('shopId');
    const userID = localStorage.getItem("userId");
    const [modalShow, setModalShow] = useState(false);
    const [referenceNumber, setReferenceNumber] = useState('');
    const [remarks, setRemarks] = useState('');
    const [stocktakeDate, setStockTakeDate] = useState('');
    const [stockendDate, setStockEndDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15; // Number of items per page
    const [totalItems, setTotalItems] = useState(0);
    const [startIndex, setStartIndex] = useState(0);

    const handleShow = () => setModalShow(true);
    const handleClose = () => setModalShow(false);

    useEffect(() => {
        fetchstocktake();
    }, [currentPage]);

    const handleButtonClick = () => {
        history.push('/home')
    }

    const handleButtonClickView = () => {
        history.push('/stock-take-details');
    };

    const fetchstocktake = async () => {
      const startIndexCalc = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndexCalc + itemsPerPage;

        setIsLoading(true);
        try {
            const response = await axios.get(`${var_api}/api/stock-take/get/${shopId}`);
            const poDetails = response.data; // Adjust based on your API response structure
            const total = poDetails.length; // Adjust based on your API response structure

            setstocktake(poDetails.slice(startIndexCalc, endIndex));
            setTotalItems(total); // Update total items for pagination
            setStartIndex(startIndexCalc); // Update total items for pagination
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching stock takes:', error);
            setIsLoading(false);
        }
    };

    const Submit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${var_api}/api/stock-take/post`, {
                reference_no: referenceNumber,
                stock_take_date: formatDate(stocktakeDate),
                stock_end: formatDate(stockendDate),
                created_by: parseInt(userID),
                updated_by: parseInt(userID),
                status: 0,
                remarks: remarks,
                shop_id: parseInt(shopId),
            });

            if (response.status === 200) {
                toast("Stock take created successfully");
                setModalShow(false);
                fetchstocktake();
            }
        } catch (error) {
            toast("Error creating stock take");
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    function formatDate(date) {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = getstocktake.filter(item =>
        item.st_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.reference_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.remarks.toLowerCase().includes(searchTerm.toLowerCase()) ||
        formatDate(item.created_at).includes(searchTerm) ||
        formatDate(item.updated_at).includes(searchTerm)
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            {isLoading && <Loader />}
            <Navbar expand="sm" className="bg-body-tertiary bg-success p-0" style={{ padding: "0px" }}>
                <Container className="p-0">
                    <Navbar.Brand className="p-0 text-light fw-bolder">STOCK TAKE</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <button className="btn btn-outline-success" onClick={handleButtonClick} style={{ backgroundColor: "white", color: "green" }}>
                            <i className="bi bi-house"></i> Go to Home
                        </button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <br />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <button
                    className="btn btn-primary"
                    onClick={handleShow}
                    style={{
                        borderRadius: '20px',
                        padding: '10px 20px'
                    }}
                >
                    Add Stock Take
                </button>
                <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    id="example-search-input"
                    style={{ width: "230px", borderRadius: "20px", border: "1px solid black", float: "right" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <br />
            <br />
            <div>
                <Table responsive="sm" hover>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>ST Number</th>
                            <th>Reference No</th>
                            <th>Remarks</th>
                            <th>Stock Take Date</th>
                            <th>Stock End</th>
                            <th>Status</th>
                            <th>Created At</th>
                            <th>Update At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.length > 0 ? (
                            filteredData.map((data, index) => (
                                <tr key={index}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>{data.st_no}</td>
                                    <td>{data.reference_no}</td>
                                    <td>{data.remarks}</td>
                                    <td>{data.stock_take_date}</td>
                                    <td>{data.stock_end}</td>
                                    <td>{data.status === 0 ? (
                                        <span className="badge bg-primary" style={{fontSize:'14px'}}>New</span>
                                    ) : (
                                        <span className="badge bg-success" style={{fontSize:'14px'}}>Posted</span>
                                    )}
                                    </td>
                                    <td>{formatDate(data.created_at)}</td>
                                    <td>{formatDate(data.updated_at)}</td>
                                    <td>
                                        <Link to={{ pathname: `/stock-take-details`, 
                                          search: `?id=${encodeURIComponent(data.id)}&takedate=${encodeURIComponent(data.stock_take_date)}&enddate=${encodeURIComponent(data.stock_end)}&refno=${encodeURIComponent(data.reference_no)}` }}>
                                            <button className="btn btn-primary">
                                              <i style={{ color: "white", fontSize: "20px" }} className="bi bi-eye-fill"></i>
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <br />
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-end"
                    activeClass="active-pagination"
                />
            </div>
            <Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Stock Take</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="referenceNumber">
                            <Form.Label>Reference Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter reference number"
                                value={referenceNumber}
                                onChange={(e) => setReferenceNumber(e.target.value)}
                            />
                        </Form.Group>
                        <br />
                        <Form.Group controlId="remarks">
                            <Form.Label>Remarks</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter remarks"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </Form.Group>
                        <br />
                        <Row>
                            <Col>
                                <Form.Group controlId="stocktakedate">
                                    <Form.Label>Stock Take Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="dd/MM/yyyy"
                                        value={stocktakeDate}
                                        onChange={(e) => setStockTakeDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="stockenddate">
                                    <Form.Label>Stock End Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="dd/MM/yyyy"
                                        value={stockendDate}
                                        onChange={(e) => setStockEndDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={Submit}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StockTake;
