import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from '../constant';
import "./paymode_master.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function PaymodeMaster(){
    const history = useHistory();
    const [categories, setcategories] = useState([]);
    const shopName= localStorage.getItem('shopName');
    const [selectedCurrency,setSelectedCurrency] = useState(null);
    const [selectedCurrencyDe,setSelectedCurrencyDe] = useState("");
    const [selectedPlan,setSelectedPlan] = useState("");
    const [actualPrice,setActualPrice] = useState(null);
    const [discount,setDiscount] = useState(0);
    const [duration,setDuration] = useState(null);
    const [defaultPrcie,setDefaultPrcie] = useState(null);
    const [final_price,setFinal_price] = useState(null);
    const [total_price,setTotal_price] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [ loading, setLoading ] = useState(false);
    const [targetData, setTargetData] = useState([]);
    const options = [
        { id: 1, name: 'Monthly' },
        { id: 2, name: 'Yearly' },
        { id: 2, name: 'SpecialPayment-Month' },
        { id: 2, name: 'SpecialPayment-Year' }
        // Add more options as needed
      ];
      
      const [show, setShow] = useState(false);
      const [PlanD, setPlanD] = useState({});
      const [switches, setSwitches] = useState([]);


      const [paymode_name, setPaymode_name] = useState("");
      const [paymode_desc, setPaymode_desc] = useState("");
      const [modes, setModes] = useState([]);
      const [isEdit , setIsEdit] = useState(false);
      const [updatedId,setUpdatedId] = useState(null);
      const [updatedPaymodeName, setUpdatedPaymodeName] = useState("");
      const [updatedDescription, setUpdatedDescription] = useState("");
    

    //redirect to home page
    const handleHome = () =>{
      history.push('/AdminDashboard')
    }

  
    const fetchCate = async () => {
     try {
      const response = await axios.get(`${var_api}/api/currency-master/get-currency` );
      const use = response.data

    console.log("categories",use)
    setcategories(use);
    }catch (error) {
      console.log('Error fetching data:', error);
      
      }
    };

    const fetchModes = async () => {
      setLoading(true);
        try {
         const response = await axios.get(`${var_api}/api/pay-mode/get-all-mode` );
         const use = response.data
   
       console.log("categories",use)
       // Create an array to track the state of each switch based on location_status
    const initialSwitches = use.map((location) => location.is_active === 1);
    console.log("initialSwitches",initialSwitches)
    setSwitches(initialSwitches);
       setModes(use);
       setLoading(false);
       }catch (error) {
         console.log('Error fetching data:', error);
         
         }
       };

    
    useEffect(() => {
        fetchCate();
        fetchModes();
      }, []);
  
   
//get the selected currency
   const onChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    setSelectedCurrency(selectedOption.value)
    const optionId = selectedOption.id
    setSelectedCurrencyDe(optionId);
    console.log("se",selectedOption);
    console.log("se1",selectedIndex);
    }

    //get the selected plan
   const onChangeHandlerPlan = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    setSelectedPlan(selectedOption.value)
    }

    const handleDiscount = (e) => {
        const disc = e.target.value;
        setDiscount(disc)
        const final_price = actualPrice - (actualPrice * (disc / 100));
        setFinal_price(final_price);
    }


    const handleActual = (e) => {
      const actu = e.target.value;
      setActualPrice(actu);
      if(discount!=null){
      const final_price = actu - (actu * (discount / 100));
      setFinal_price(final_price);
      }
  }

    const handleDefault = (e) => {
        const dePrice = parseInt(e.target.value);
        setDefaultPrcie(dePrice)

        const total_price = parseInt(final_price) + dePrice;
        setTotal_price(total_price);
    }


    
   
   //post the form data
    const Submit = async (e) => {
      e.preventDefault();


    
      try {
        const response = await axios.post(`${var_api}/api/pay-mode/post`, {
            pay_mode_name: paymode_name, 
            description: paymode_desc, 
            is_active: 1
        });
       
    
        if (response.status === 200) {
          Swal.fire({
            icon:"success",
            text:`${paymode_name} mode has been created`,
            confirmButtonColor:"#007500"
          })
         setPaymode_desc("");
         setPaymode_name("");
          fetchModes();

      }
      } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
        if (error.response && error.response.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Duplicate Entry",
            text: "This entry already exists. Please try again with a different one.",
            confirmButtonColor: "#ff0000"
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred. Please try again later.",
            confirmButtonColor: "#ff0000"
          });
        }
      }
    };

    //open modal
    const handleOpenUpdateModal = (data) => {
      setIsEdit(true);
      setUpdatedDescription(data.description);
      setUpdatedPaymodeName(data.pay_mode_name);
      setUpdatedId(data.id);
    }

    

    //update function
    const handleUpdateSubPlan = async(event) => {
      event.preventDefault(); // Prevent default form submission behavior


      const requestData = {
       pay_mode_name : updatedPaymodeName,
       description: updatedDescription
      };
     
      // const fiPr = formData.actual_price - (formData.actual_price * (formData.discount / 100));

      // // Calculate total price by adding fiPr and default_price
      // const totalPrice = fiPr + formData.default_price;
      
      // console.log(totalPrice);
        
      console.log("re",requestData)
       try {
        // Make a PUT request to update the UOM
        const response = await axios.put(`${var_api}/api/pay-mode/put/${updatedId}`, requestData);
        
        if (response.status === 200) {
          fetchModes();
          setIsEdit(false);
        // Category updated successfully
          Swal.fire({
            icon: 'success',
            text: 'Mode Details updated successfully',
            confirmButtonColor: '#007500',
          });
        } else {
           Swal.fire({
            icon: 'error',
            text: 'Failed to update Mode Details',
            confirmButtonColor: '#007500',
          });
        }
      } catch (error) {
        console.error('Error updating Mode Details:', error);
        Swal.fire('Error', 'Failed to update Mode Details. Please try again.', 'error');
      }
        
    } 

    //delect method
    const handleDelete = () => {
      console.log("iiiii",PlanD)
      axios
      .delete(`${var_api}/api/subscription-plan/plan-delete/${PlanD.id}`)
      .then((response) => {
        if (response.status === 200) {
          fetchModes();
          setShow(false);
          Swal.fire("success"," Plan Removed Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Plan not found');
          Swal.fire("error","Plan not found!","error")
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Plan!","error")
        // Handle the error as needed
      });
    }

     // open modal delete method for remove UOM
   const handleDeletePlan = (id,name) => {
    setShow(true);
    setPlanD({id:id,plan_name:name})
     console.log("iiiii",id,name,PlanD)
    }



     //toggle switch for is_active updation
     const handleToggle = async (index,id) => {
      const newSwitches = [...switches];
      console.log('newSwitches', newSwitches);
      console.log('newSwitches', !newSwitches[index]);
      newSwitches[index] = !newSwitches[index];
      console.log('newSwitches', newSwitches[index]);
     
      setSwitches(newSwitches);
      console.log('newSwitches', newSwitches);
      //  Extract the location's ID (you need to replace 'id' with the actual ID property in your data)
      const locationId = modes[index]?.id;
console.log('location Id', locationId);

// Make an API request to update the location_status
try {
  const response = await axios.put(`${var_api}/api/pay-mode/put/${id}`, { is_active: newSwitches[index] ? 1 : 0 });
  if (response.status === 200) {
    console.log('Location status updated successfully');
    fetchModes();
  }
} catch (error) {
  console.log('Error updating location status:', error);
  // Revert the switch state in case of an error
  newSwitches[index] = !newSwitches[index];
  setSwitches(newSwitches);
}
    };





    return(
        <>
        <nav className="navbar navbar-expand navbar-light py-2 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none"}} >
          <h5 className="hefop" style={{textAlign:"center",color:"white"}}><i class="bi bi-arrow-left-circle" onClick={handleHome} style={{fontSize:"21px"}}></i>{' '}PAYMODE MASTER</h5>
        </nav>
        <div id='subbackdp'>
          <br/>
          <br/>
          <div className='row'>
            <div className='col'>
            <div id='fiboxsubdp'>
            <form onSubmit={Submit}>
                <h5 style={{textAlign:"center",margin:"5px",fontFamily:"serif"}}>Create Paymode</h5>
                <br/>

                <div className='row' style={{margin:"0px 20px 0px 20px"}}>
                    <div className='col'>
                    <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Paymode Name</label>
                    <input  className="form-inputsud" type='text' name='paymodename'  
                       required
                       value={paymode_name}
                       onChange={(e) => setPaymode_name(e.target.value)}
                       placeholder='Enter paymode name'
                    />
                    </div>
                </div>

                <br/>
                <div className='row' style={{margin:"0px 20px 0px 20px"}}>
                    <div className='col'>
                        <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Description</label>
                        <input  className="form-inputsud" type='text' name='description' 
                          required
                          value={paymode_desc}
                          onChange={(e) => setPaymode_desc(e.target.value)}
                          placeholder='Enter description'
                        />
                    </div>
                    
                </div>
                <br/>
                <div className='row' style={{margin:"0px 20px 0px 20px"}}>
                   
                    <div className='col'>
                    <button className='btn btn-outline-success' id='dasubscrip'>Submit</button>
                    </div>

                </div>

             
         
           
            </form>
          </div>
            </div>

          
            <div id='taboxsubp'>
          <table class="table table-success table-striped table-hover" >
            {/* <caption className="hefo" style={{captionSide:"top",textAlign:"center",fontSize:"25px",color:"black",fontWeight:"bolder"}}>SUMMARY for
            {' '}
            <input type='date' name='date' value={selectedDate} onChange={handleDateChange} style={{border:"none",borderBottom:"2px solid green"}} placeholder='choose date' />
            </caption> */}
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Paymode Name</th>
      <th scope="col">Description</th>
      <th scope="col">Edit</th>
      <th scope="col">Active Status</th>
    </tr>
  </thead>
  {loading ? (
        <div className="loader-containeru">
      	  <div className="spinner"></div>
        </div>
  ) : (
  <tbody>
   { Array.isArray(modes) && modes.map((plan, index) => (
 
    <tr key={plan.id}>
      <th scope="row">{index + 1}</th>
      <td>{plan.pay_mode_name}</td>
      <td>{plan.description}</td>
      <td>
      <i class="bi bi-pencil-square" onClick={()=>handleOpenUpdateModal(plan)}   style={{fontSize:"21px",color:"rgb(9, 97, 9)"}}></i>
      {/* <i class="bi bi-trash" onClick={()=>handleDeletePlan(plan.sub_id,plan.subscription_plan_name)} style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i> */}
      </td>
      <td>
        
      <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id={`flexSwitchCheckChecked_${index}`} checked={switches[index]}  onChange={() => handleToggle(index,plan.id)}/>
</div>
      </td>
    </tr>
   ))}

   </tbody>
  )}
</table>
          </div>

          </div>
          <br/>
          <br/>
         


          <Modal
        show={isEdit}
        onHide={()=>setIsEdit(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Subscription Plan </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleUpdateSubPlan}>
        <div className='row' style={{margin:"0px 20px 0px 20px"}}>
                    <div className='col'>
                    <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Paymode Name:</label>
                    <input  className="form-inputsud" type='text' name='paymodename'  
                       required
                       value={updatedPaymodeName}
                       onChange={(e) => setUpdatedPaymodeName(e.target.value)}
                       placeholder='Enter paymode name'
                       style={{border:"1px solid green",float:"right"}}
                    />
                    </div>
                    <br/>
                    <br/>
                    <div className='col'>
                        <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Description: </label>
                        <input  className="form-inputsud" type='text' name='description' 
                          required
                          value={updatedDescription}
                          onChange={(e) => setUpdatedDescription(e.target.value)}
                          placeholder='Enter description'
                          style={{border:"1px solid green",float:"right"}}
                        />
                    </div>
                </div>


         <br/>
          <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
          </form>
          <button className="btn btn-danger" onClick={()=>setIsEdit(false)} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
        
      </Modal>

      <Modal
        show={show}
        onHide={()=>setShow(false)}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM !</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete Plan Name 
          <span style={{color:"red",textTransform:"uppercase"}}> {PlanD.plan_name}</span> ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={()=>setShow(false)}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>
          
        </div>
        </>
    )
}
{/* <i class="fa fa-flag" aria-hidden="true"></i> */}

export default PaymodeMaster;