import React, {useEffect,useState} from 'react';
import { db } from '././../../firebase.js';
import { collection, onSnapshot, query, getDocs, doc,
  getDoc,
  getFirestore, } from "firebase/firestore";
import jsPDF from "jspdf";
import './downloadpdf.css';
import { useHistory  } from 'react-router-dom';
import useDrivePicker from "react-google-drive-picker";
import Swal from 'sweetalert2';


function DownloadPdf(){
    const [databaseData, setDatabaseData] = useState([]);
    const [databaseFoodData, setDatabaseFoodData] = useState([]);
    const [dbOrders,setdbOrders] = useState([]);
    const history = useHistory();
    const [openPicker,authResponse] = useDrivePicker();
    const [ authorizationCode,setAuthorizationCode ] = useState('');
    const [ accessToken, setAccessToken ] = useState('');
    

    const clientID ='375222810283-5nufm1jpommuo7seb2qt6dfi4q3frfun.apps.googleusercontent.com';
    const clientSecret = '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';
    const redirectURI = 'https://api.spbiller.com/download';
    

    // const findAll = async () => {
    //     try {
    //       const shopname= localStorage.getItem('shopName');
    //       // console.log("cartegory name is:",shopname);
    //       const colRef = collection(db,`shops/${shopname}/categories`);
    //       const q = query(colRef);
    //       const unsub = onSnapshot(q, (snapshot) => {
    //         const categoriesName = snapshot.docs.map((doc) => ({ id:doc.id, data: doc.data() }))
    //         setDatabaseData(categoriesName);
    //     })
    // } catch (error) {
    //       console.log("Fetching category error:", error);
    //     }
    //   }

      const findAllOrder = async () => {
        try {
          const shopname= localStorage.getItem('shopName');
          // console.log("cartegory name is:",shopname);
          const colRef1 = collection(db,`shops/${shopname}/orders`);
          const unsub1 = onSnapshot(colRef1, (querySnapshot) => {
            const ordersName = querySnapshot.docs.map((doc) => ({ id:doc.id, data: doc.data() }))
            // Sort the orders array based on the order_no field
        ordersName.sort((a, b) => a.data.order_no - b.data.order_no);
    
            setdbOrders(ordersName);
        })
    } catch (error) {
          console.log("Fetching category error:", error);
        }
      }

      console.log("orders:",dbOrders)
      
      useEffect(() => {
        // findAll();
        findAllOrder()
        
      }, []);


    const downloadDataAsFile = async () => {
        // Create a new instance of jsPDF
          let doc = new jsPDF('l', 'mm', [1500, 1400]);
          
    // Get the HTML element with the ID 'divID'
        let pdfjs = document.querySelector('#divID');

        // Generate the PDF document from the HTML content
        doc.html(pdfjs, {
          callback: async function(doc) {
            // Save the generated PDF as a Blob
            const pdfBlob = doc.output('blob');

        // Upload the PDF Blob to Google Drive
        await uploadToGoogleDrive(pdfBlob,'myShopData.pdf');
        

      //   // Save the generated PDF as a file
      // doc.save("newpdf.pdf");
      
    },
     x: 12,
     y: 12
    });
        };


        const AuthGoogleDrive = () =>{
        const authParams = {
            response_type: 'code',
            client_id: clientID,
            redirect_uri: redirectURI,
            scope: 'https://www.googleapis.com/auth/drive',
          };
        
          const authURL = `https://accounts.google.com/o/oauth2/auth?${new URLSearchParams(authParams)}`;
           // Redirect the user to the Google OAuth consent page
           window.location.href = authURL;

        }


        useEffect(() => {

          // Check if the URL contains the authorization code
  const urlParams = new URLSearchParams(window.location.search);
  const receivedAuthorizationCode = urlParams.get('code');

  if (receivedAuthorizationCode) {
    // Set the received authorization code to the state
    setAuthorizationCode(receivedAuthorizationCode);
    
          if (authorizationCode !== '') {
            const tokenParams = {
              code: authorizationCode,
              client_id: clientID,
              client_secret: clientSecret,
              redirect_uri: redirectURI,
              grant_type: 'authorization_code',
            };
      
            fetch('https://accounts.google.com/o/oauth2/token', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: new URLSearchParams(tokenParams),
            })
              .then((response) => response.json())
              .then((data) => {
                setAccessToken(data.access_token)
                localStorage.setItem('accessToken',data.access_token)
                setTimeout( function() { downloadDataAsFile(); }, 5000);
                
              })
              .catch((error) => {
                console.error('Error exchanging authorization code for access token:', error);
              });
          }
        }
        }, [authorizationCode]);
  
        

                

        // const uploadGoogleDrive = () => {
          
        //   openPicker({
        //     clientId:"375222810283-q7fsfgm26fifcgu1418grtqn70nc8mhf.apps.googleusercontent.com",
        //     developerKey:"AIzaSyDBAvw0bLUozMrDzr2ei9XmqAJo_5IqRNk",
        //     viewId:"DOCS",
        //     showUploadView: true,
        //     showUploadFolders: true,
        //     supportDrives: true,
        //     multiselect: true,

        //     callbackFunction: (data) => {
        //       if (data.action === "cancel") {
        //         console.log("user clicked cancel/close button");
        //       }
        //       console.log(data);
        //     },
        //   });
        // };




        const uploadToGoogleDrive = async (pdfBlob, fileName) => {
          const access_token=localStorage.getItem('accessToken')
          
          const createURL = 'https://www.googleapis.com/drive/v3/files';
          const metadata = {
            name: fileName, // Set the desired name for the file
            mimeType: 'application/pdf', // Specify the MIME type
          };
          const headers = {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          };
        
          const response = await fetch(createURL, {
            method: 'POST',
            headers,
            body: JSON.stringify(metadata),
          });
        
          if (response.ok) {
            const responseData = await response.json();
            const fileId = responseData.id;
        
            // Now upload the file content to the created file
            const uploadURL = `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=media`;
        
            const uploadResponse = await fetch(uploadURL, {
              method: 'PATCH',
              headers: {
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/pdf',
              },
              body: pdfBlob,
            });
        
            if (uploadResponse.ok) {
              Swal.fire({
                icon:"success",
                text:"successfully file uploaded to google drive",
                confirmButtonColor:"#007500"
              })
            } else {
              console.error('Error uploading file content to Google Drive:', uploadResponse.status);
            }
          } else {
            console.error('Error creating file in Google Drive:', response.status);
          
             // Check if the error is due to an expired token
    // if (response.status === 401) {
    //   const tokenRefreshed = await refreshToken();
    //   if (tokenRefreshed) {
    //     // Retry the file creation after token refresh
    //     await uploadToGoogleDrive(pdfBlob, fileName);
    //   }else {
    //     console.error('Token refresh failed. Unable to retry file upload.');
    //     // Handle the failure to refresh the token
    //   }
    // }
          }
        };


        // const refreshToken = async () => {    // Function to refresh the access token
        //   try {
        //     await gapi.auth2.getAuthInstance().currentUser.get().reloadAuthResponse();
        //     return true; // Token refresh successful
        //   } catch (error) {
        //     console.error('Error refreshing token:', error);
        //     return false; // Token refresh failed
        //   }
        // };


        

       
   const handleFoodMaster = (id) => {
        const shopName = localStorage.getItem('shopName');
        const colRef = collection(db, `shops/${shopName}/categories/${id}/foodMaster`);
        const q = query(colRef);
          const unsub = onSnapshot(q, (snapshot) => {
            const foodNames = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
            setDatabaseFoodData((prevData) => ({
                ...prevData,
                [id]: foodNames,
              }));
          });
        
      };

     const handleGoHome= ()=>{
        history.push('/home')
      }


    return(
        <>
        <br/>
        <button className='btn btn-md' style={{border:"1px solid black",height:"53px",marginLeft:"10px"}} onClick={handleGoHome}><i class="bi bi-house" style={{border:"none"}}> Go to Home </i></button> {' '} 
         <button className='btn btn-md' style={{border:"1px solid black"}} onClick={AuthGoogleDrive}>
         <img src='https://play-lh.googleusercontent.com/t-juVwXA8lDAk8uQ2L6d6K83jpgQoqmK1icB_l9yvhIAQ2QT_1XbRwg5IpY08906qEw' alt='logo' style={{width:"40px",height:"40px"}} />
          upload pdf to google drive</button>
        
        <div id="divID">
            <div style={{margin:"50px",padding:"10px"}}>
                <h1 id='backde'>BACKUP DATA</h1>
                <h2>Categories</h2>
                {databaseData.map((category,index)=>(
                <ul key={category.id} >
                    <li style={{backgroundColor:"white"}}>{category.data.category_name}
                    <br/>
                    <img src={category.data.image} alt={category.data.category_name} onClick={() => handleFoodMaster(category.id)}  height="150px" width="170px" />
                    <div>
                    {databaseFoodData[category.id] && (
                   
                   <div className="food-items-container">
                             {databaseFoodData[category.id].map((food) =>(
                           <div key={food.id} className="food-item" style={{ backgroundColor: 'white' }}>
                            <img src={food.data.image} alt={food.data.food_name} height="100px" width="130px" /><br/>
                            <div className="food-details">
                            <h5>{food.data.food_name}</h5>
                            <h5>{food.data.currency} . {food.data.price}</h5>
                            <h6>Item Code:{food.data.item_code}</h6>
                            <h6>Text:{food.data.text}</h6>
                            </div>
      </div>
    ))}
  </div>
                    )}
                    </div>
                    </li><br/>
                </ul>
                ))}
<br/>
                <h1>Orders</h1>
                <table className='order'>
                    <thead>
                        <th className='hesty'>Name</th>
                        <th className='hesty'>Phone</th>
                        <th className='hesty'>Order No</th>
                        <th className='hesty'>Items</th>
                        <th className='hesty'>Total</th>
                        <th className='hesty'>Items no</th>
                    </thead>
                    {dbOrders.map((order,index)=>(
                    <tbody>
                        <tr key={order.id} >
                            <td className='dasty'>{order.data.customer_name}</td>
                            <td className='dasty'>{order.data.customer_phone}</td>
                            <td className='dasty'>{order.data.order_no}</td>
                            <td className='dasty'>
                                <table style={{margin:"10px"}}>
                                    <thead>
                                        <th className='hesty'>item name</th>
                                        <th className='hesty'>quantity</th>
                                        <th className='hesty'>price</th>
                                    </thead>
                                    {Object.values(order.data).map((fieldValue) => {
                                      if (Array.isArray(fieldValue)) {
                                        return fieldValue.map((item, index) => (
                                        <tbody>
                                          <tr key={item.id}>
                                            <td className='dasty'>{item.food_name}</td>
                                            <td className='dasty'>{item.quantity}</td>
                                            <td className='dasty'>{item.unit_price}</td>
                                          </tr>
                                        </tbody>
                                      ));
                                    }
                                    return null;
                                    })}
                                </table>
                            </td>
                            <td className='dasty'>{order.data.total}</td>
                            <td className='dasty'>{order.data.total_items}</td>
                        </tr>
                    </tbody>
                                        
                ))}
                </table>
            </div>
        </div>

       
        </>
    )
}

export default DownloadPdf;