import React, {  useState,useEffect }  from 'react';
import { useHistory } from 'react-router-dom';
import splash from "../../images/splash/shop_logo.jpeg";
import swal from 'sweetalert';
import { var_api } from '../constant';
import axios from 'axios';



function Splash(){
    const history = useHistory();
  


    // useEffect(() => {
    //   const id = localStorage.getItem('userId')
    //   if(id != null) {
    //     console.log("id here",id)
    //     fetch(`${var_api}/api/sub/check_coupon/${id}`)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       setCouponStatus(data.coupon_status);
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching coupon status:', error);
    //       // Handle the error here or display an error message
    //     });
        
    //   } else {
    //     history.push('/signin')
    //   }
    // }, [])

    useEffect( () => {

      const fetchData = async () => {
      // // Process the coupon status after fetching
      // if (couponStatus === 'Did not subscribed yet') {
      //   // Redirect to the subscription page
      //   history.push('/sub');
      // } else if (couponStatus === 'Subscription not yet valid') {
      //   // Show SweetAlert for not valid yet
      //   swal('Subscription Not Valid Yet', 'Please wait until the subscription becomes valid.', 'warning');
      //   history.push('/sub');
      // } else if (couponStatus === 'Subscription has expired') {
      //   // Show SweetAlert for expired coupon
      //   swal('Coupon Expired', 'The subscription has expired. Please subscribe again.', 'error');
      //   history.push('/sub');
      // } else if (couponStatus === 'Subscribed and valid') {


        const id = localStorage.getItem('userId')
        console.log("id",id)
        if (id === null){
          history.push('/signin')
        }else{

        try {
          const response = await axios.get(`${var_api}/api/cash-subscription-details/get-plan-status/${id}` );
          const use = response.data;
         
          if (use.payment_status && use.message && use.payment_status === "false" && use.message === "No active subscription found"){
            swal('Did not subscribed yet', 'Please Buy a subscription.', 'warning');
            history.push('/sub');
            console.log("1",id)
          }else if (use.active_status === 1 && use.payment_status === "true" && use.expire_Date > 0 && use.manual_payment_verification === 1 && use.message === "Your subscription is Valid"){
            console.log("2",id)
             // Redirect to the shop page
        fetch(`${var_api}/api/shop/api/check_shop/${id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.exists) {
           
            const role = localStorage.getItem("e_role");
            if( role !== null){
            if(role === "Chef" || role === "Captain" || role === "Waiter"){
            console.log("role",role);
              history.push('/tableOrders')
            }
          }else{
              console.log("home role",role);
              history.push('/home')
            }
          } else {
            // If exist:false, redirect to the shop page
            history.push('/shop')
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });

          }
          else if(use.active_status === 0 && use.payment_status === "false" && use.expire_Date <= 0 && use.message === "Your  subscription has expired. Please subscribe again"){
            swal('Your Subscription Expired', 'The subscription has expired. Please subscribe again.', 'error');
            history.push('/sub');
            console.log("3",id)
          }else if(use.active_status === 1 && use.payment_status === "true" && use.expire_Date > 0  && use.manual_payment_verification === 0){
            swal('Your Subscription Rejected', `${use.message}`, 'error');
            // Redirect to the shop page
        fetch(`${var_api}/api/shop/api/check_shop/${id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.exists) {
           
            const role = localStorage.getItem("e_role");
            if( role !== null){
            if(role === "Chef" || role === "Captain" || role === "Waiter"){
            console.log("role",role);
              history.push('/tableOrders')
            }
          }else{
              console.log("home role",role);
              history.push('/home')
            }
          } else {
            // If exist:false, redirect to the shop page
            history.push('/shop')
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
            console.log("4",id)
          }
          else {
                history.push('/signin')
                console.log("5",id)
              }
      
       
        }catch (error) {
          console.log('Error fetching data:', error);
          
          }
        }

        }
       
        fetchData();
      
    }, []);

    return(
        <>
        <div  style={style.logo} >
        <img src={splash} alt="splash" style={{width:"150px",borderRadius:"50px"}} />
        </div>
        </>
    )
}

export default Splash;

const style={
    logo: {
     
       display: "flex",
       justifyContent: "center",
       alignItems: "center",
       marginTop:"250px"
    }
}