import React from "react";
import { withRouter } from 'react-router-dom';

const AddCart = (props) => {
    const { location } = props;
    const foodItems = location.state ? location.state.foodItems : [];
    console.log(foodItems);
    return(
        <>
        <div className="container">

        </div>
        </>
    )
}

export default withRouter(AddCart);