import React,{useState, useEffect} from 'react';
import { Table, Row, Col, Card} from 'react-bootstrap';
import '../kitchen/kitchen2.css';
import { collection, onSnapshot, where, query, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase.js';
import axios from 'axios';
import { var_api } from '../constant';


const DChildCard2 = () => {
  
  const [ onthewayOrders, setOnthewayOrders ] = useState([]);
  
//fetch data when parcel status is 1
  useEffect(() => {

    const fetchData = async () => {
      // const shopId=localStorage.getItem('shopId');
      // try {
       
      //   const response = await axios.get(`${var_api}/api/food/api/foodparcel/${shopId}/2`);
      //   const responseData = response.data;
        
      //   if (Array.isArray(responseData)) {
      //     setOnthewayOrders(responseData);
      //   } else {
      //     // Handle the case when responseData is not an array
      //     console.error('API response is not an array:', responseData);
      //     setOnthewayOrders([responseData]); // Set users to an empty array or handle the error appropriately
      //   }
      // } catch (error) {
      //   console.log('Error fetching data:',error);
      // }
    };

    fetchData();
    const shopName = localStorage.getItem('shopName');
    const ordersRef = collection(db, `shops/${shopName}/orders`);
    // Query to get orders with process_code equal to 3
    const ordersquery = query(ordersRef, where('process_code', '==', 5));
    const unsub = onSnapshot(ordersquery, snapshot => {
        setOnthewayOrders(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
    })

    return () => {
        unsub()
    }
   }, [])


  //  const handleOnthewayButtonClick = (orderId) => {
  //   const shopName = localStorage.getItem('shopName');
  //   const orderRef = doc(db, `shops/${shopName}/orders`, orderId);
  
  //   // Update the process_code field to 4
  //   updateDoc(orderRef, {
  //     process_code: 6
  //   })
  //   .then(() => {
  //     console.log('process_code updated successfully');
  //   })
  //   .catch(error => {
  //     console.error('Error updating process_code:', error);
  //   });
  // };


  return (
    <div style={{margin:"10px"}}>
     
     <Row>
        {onthewayOrders.map((order) => (
          <Col key={order.id}>
            <Card className="cssa" border="success" style={{borderRadius:"20px",width:"300px",marginBottom:"0px",marginTop:"22px"}}> 
            <Card.Header className="bird" style={{fontWeight:"bold",padding:"7px",textTransform:"capitalize"}}>{order.data.customer_name} <span style={{fontSize:"10px"}}>{order.data.customer_phone}</span></Card.Header> 
          
            <Card.Body className="p-2">
            {Object.values(order.data).map((fieldValue,index) => {
            if (Array.isArray(fieldValue)) {
            return (
           
               <div>
                 <Card.Title style={{fontSize:"13px"}}>order no .{order.data.order_no}</Card.Title>
               <div  className="duck" style={{overflowY:"scroll"}}>
                
               <Table striped="columns" className="scrollable-table" bg="warning"  >
                
            
                <thead>
                <th style={styles.tableHeader}>Item Name</th>
                <th style={styles.tableHeader}>Quantity</th>
                <th style={styles.tableHeader}>Price(INR)</th>
              </thead>
              {fieldValue.map((item, index) => (
                  
                    <tr key={index}>
                    <td style={styles.tableCell}>{item.food_name}</td>
                    <td style={styles.tableCell}>{item.quantity}</td>
                    <td style={styles.tableCell}>{item.unit_price}</td>
                    </tr>
                     ))}
    </Table>
               
               </div>
               </div>
                  )}
                })}
            
               
               {/* <button type="button" class="btn btn-outline-success" onClick={()=>handleOnthewayButtonClick(order.id)}  style={{marginTop:"10px"}}>Parcel Sent to customer</button> */}
              </Card.Body>
          
            </Card>
          </Col>
        ))}
      </Row>
   </div>
 );
};


const styles = {
  table: {
    width: '50%',
    borderCollapse: 'collapse',
    margin: 'auto',
    overflow:"scroll",
    
  },
  tableHeader: {
    border:'1px solid black',
    padding: '2px',
    textAlign: 'center',
    color:"black",
  },
  tableCell: {
    border: '1px solid black',
    padding: '2px',
    color:"black",
  },
 
}

export default DChildCard2;
