import React, { Component } from "react";
import './home.css';
// import { db } from '././../../firebase.js';
// import { collection, onSnapshot, query, addDoc, getDocs, serverTimestamp, updateDoc, doc } from "firebase/firestore";
// import breakfast from "../../fakeData/breakfast";
// import burger from '../../fakeData/burger';
// import dinner from '../../fakeData/dinner';
// import  drinks from '../../fakeData/drinks';
// import icecream from '../../fakeData/icecream';
// import lunch from '../../fakeData/lunch';
// import pizza from '../../fakeData/pizza';
// import sandwich from '../../fakeData/sandwich';
// import shawarma from '../../fakeData/shawarma';
import { TabContent, TabPane, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
// import { OrderContext } from "../kitchen/orderContext";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import {Card} from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactToPrint from 'react-to-print';
import Bill from "./sbBill.js";
import { var_api } from "../constant";
import SearchableDropdown from "../creditCustomers/search";
import ReactDOMServer from 'react-dom/server';
import { data } from "jquery";
import Select from 'react-select';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Display } from "react-bootstrap-icons";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import basic from '../../images/sb-images/basci.jpeg';
import delux from '../../images/sb-images/delux.jpeg';
import pamper from '../../images/sb-images/pampering.jpeg';
import skin1 from '../../images/sb-images/skin1.jpeg';
import skin2 from '../../images/sb-images/skin2.jpeg';
import skin3 from '../../images/sb-images/skin3.jpeg';
import room1 from '../../images/sb-images/room1.jpeg';
import room2 from '../../images/sb-images/room2.jpeg';
import chair1 from '../../images/sb-images/chair1.webp';
import chair2 from '../../images/sb-images/chair2.webp';
import chair3 from '../../images/sb-images/chair3.webp';

toast.configure()


// Import a custom CSS file for styling
// const Foods = () => {
//   const [foods, setFoods] = useState([]);
//   const [selectedFoodType, setSelectedFoodType] = useState("lunch");
//   const [selectedFastFoodType, setSelectedFastFoodType] = useState("pizza");

//   useEffect(() => {
//     setFoods(allFoods);
//   }, []);

//   const selectedFoods = foods.filter(
//     (food) => food.category === selectedFoodType
//   );
//   const selectedFastFoods = foods.filter(
//     (food) => food.category === selectedFastFoodType
//   );


  class SbHOme extends React.Component {


    constructor(props) {
      
      super(props);
      this.routeChange = this.routeChange.bind(this);
      this.routeChance=this.routeChance.bind(this);
      this.routeDay=this.routeDay.bind(this);
      this.routeDaily=this.routeDaily.bind(this);
      this.routeTable =  this.routeTable.bind(this);
      this.routeCredit =  this.routeCredit.bind(this);
      this.routeSupplier = this.routeSupplier.bind(this);
      this.routeUom = this.routeUom.bind(this);
      this.toggle = this.toggle.bind(this);
      this.toggleNav = this.toggleNav.bind(this);
      this.toggleChange = this.toggleChange.bind(this);
      this.handleOnclick = this.handleOnclick.bind(this);
      this.routeReport = this.routeReport.bind(this);
      this.routeContract = this.routeContract.bind(this);
      this.routeGroupOrders =  this.routeGroupOrders.bind(this);
      this.routeEmployee = this.routeEmployee.bind(this);
      this.routeRawMaterial = this.routeRawMaterial.bind(this);
      this.routeTablemaster = this.routeTablemaster.bind(this);
      this.routeOutstandingpayment = this.routeOutstandingpayment.bind(this);
      this.routeItemSummary = this.routeItemSummary.bind(this);
      this.routeOrderhistory= this.routeOrderhistory.bind(this);

      
      this.routeSbReports =  this.routeSbReports.bind(this);
      this.routeAttendance=this.routeAttendance.bind(this);



      this.handleModLogout = this.handleModLogout.bind(this);
      this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
      this.routeSettlements = this.routeSettlements.bind(this);
      this.routeParcelStatus = this.routeParcelStatus.bind(this);
      this.routenNewSettlements = this.routenNewSettlements.bind(this);
      this.handleSwitchToggle = this.handleSwitchToggle.bind(this);
      this.handleInstandFoodChange = this.handleInstandFoodChange.bind(this);
      this.handleInstandQuantityChange = this.handleInstandQuantityChange.bind(this);
      this.handleInstandPriceChange =  this.handleInstandPriceChange.bind(this);
      this.routeSubKitchenmaster =  this.routeSubKitchenmaster.bind(this);
      this.routeSubChance = this.routeSubChance.bind(this);
      
      this.state = {
        dropdownOpen: false
      };
      this.state = {
        shadropdownOpen: false
      };
      const shopNameFromLocalStorage = localStorage.getItem('shop_name');
      this.state = {
        activeTab: '1'
      };
      
      // Get today's date
      const today = new Date();

     // Format the date as dd-mm-yyyy
      const formattedDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
      this.state = {
        isChecked:true,
        activeTab: 2,
        selectedList: 'Breakfast',
        customers:[], // Set the default selected list to 'Breakfast'
        orderNo: 100,
        showTable: false,
        shopName: '',
        users:[],
        categories: [],
        foods: [],
        cartItems: [],
        orders: [],
        mobileInput:[],
        searchQuery:'',
        checkedCategories:false,
        loading:false,
        selectedCreditCustomer: null,
        creditName:'',
        creditMobile:'',
        selectedData: '',
        firstCategoryId:0,
        isPrinted:false,
        ItemCodeOptions:[],
        selectedItemCode:null,
        show:false,
        orderHistoryItems:[],
        selectedOrderLabel: { value: '0', label: 'All' },
        selectedPayMode: null,
        editpaymode:null,
        updateRemark:" ",
        labelCodeOptions: [
            { value: '0', label: 'All' },
            { value: '1', label: 'Inprogress'},
            { value: '2', label: 'Upcoming'}
            // { value: '1', label: 'Normal Order' },
            // { value: '2', label: 'Credit Order' },
            // { value: '3', label: 'Group Location Order' },
            // { value: '4', label: 'Normal Table Order' },
            // { value: '5', label: 'Credit Table Order' },
        ], 
        payModeOptions: [
          
      ], 
      payModes:[],
        startDate: formattedDate,
        endDate: formattedDate,
        isOpenSelectDate:false,
        orderSummary:{},
        isOpenOrderSummary:false,
        order_no:null,
        isLogin:false,
        iscredit:false,
        orderLoading: false,
        amountDetail:{},
        isNormalModal: false,
        isNormalCreditModal:false,
        isCreditModal:false,
        isParcelChecked: false,
        orderResponse:{},
        isOrderConfirmation:false,
        imageStatus: false,
        isPayModeEdit:false,
        editPaymodeOrder:null,
        isPayModeEditLoading:false, 
        selectedAmounts: [],
        isAmountSetByCardClick: false, // Initially set to false 
        enteredPaymodes:null,
        isOpenAddInstant: false,
        instantFoodName:null,
        instantQuantity:null,
        instantPrice:null,
        editSelectedAmounts:[],
        isMastersMenuOpen: false,
        isRoom:true,
      };

      
      // this.contextType = OrderContext; 
    }
  
//  handleCheckboxChange =()=>{
//    this.setState((prevState)=>({
//      isChecked:!prevState.isChecked,
//    }));
//  };
    // handleGenerateNo = async () => {
    //   const { orderNo } = this.state;
  
    //   // Increment the order number by 1
    //   const newOrderNo = orderNo + 1;
  
    //   // Get the reference to the specific document in Firestore
    //   const orderNoRef = doc(db, 'jaya', 'twenty');
  
    //   try {
    //     // Update the 'order_no' field with the new order number
    //     await updateDoc(orderNoRef, { order_no: newOrderNo });
  
    //     // Set the new order number in the state
    //     this.setState({
    //       orderNo: newOrderNo,
    //       showTable: true,
    //     });
    //   } catch (error) {
    //     console.error('Error updating order number:', error);
    //   }
    // };

   
    // handleGenerateNo = async () => {

    //   try {
    //     const { orderNo, customers } = this.state;
  
    //   // Increment the order number by 1
    //    const newOrderNo = orderNo + 1;
  
    //     // Assuming the new order data is fetched from Firestore and stored in newOrderData
    //     // Replace the following line with your actual data retrieval from Firestore
       

    //     this.setState({
    //             orderNo: newOrderNo,
    //             showTable: true,
    //           });

    //   localStorage.setItem('customers', JSON.stringify(customers));
    //   console.log(customers)
  
    //   } catch (error) {
    //     console.error('Error generating order:', error);
    //   }
    // };


    // componentDidUpdate(prevProps) {
    //   const { customers } = this.props;
  
    //   // Calculate the total amount and total number of items
    //   let totalAmount = 0;
    //   let totalItems = 0;
  
    //   customers.forEach((customer) => {
    //     Object.values(customer.data).forEach((fieldValue) => {
    //       if (Array.isArray(fieldValue)) {
    //         fieldValue.forEach((item) => {
    //           totalAmount += item.price * item.quantity;
    //           totalItems += 1;
    //         });
    //       }
    //     });
    //   });
  
    //   // Update the 'total' and 'item_no' fields in Firestore
    //   const customerRef = doc(db, 'jaya', 'twenty');
    //   updateDoc(customerRef, { total: totalAmount, item_no: totalItems });
    // }
    
    // componentDidMount() {
    //   // Fetch data from Firestore and set it to the 'movies' state
    //   const colRef = collection(db, 'jaya');
    //   const q = query(colRef);
    //   this.unsubscribe = onSnapshot(q, (snapshot) => {
    //     const customers = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
    //     this.setState({ 
    //       customers
    //      });
    //     });

    //   this.fetchData()
    // }

    // componentWillUnmount() {
    //   // Unsubscribe from the snapshot when the component is about to unmount
    //   if (this.unsubscribe) {
    //     this.unsubscribe();
    //   }
    // }


    componentDidMount() {

      const userId = localStorage.getItem('userId');
      console.log("userId",userId);
      console.log("Before checking userID",localStorage.getItem('shopName'));
      console.log("Before checking userID",localStorage.getItem('shopId'));
      // this.getShopName(userId);
      
      if (userId) {
       
        const shopName = localStorage.getItem('shopName');
        const shopID = localStorage.getItem('shopId');
        this.fetchData();
        console.log(" chencking",localStorage.getItem('shopType'))
        // Retrieve the shop name from localStorage
        if (shopName && shopID ) {
          if(localStorage.getItem('shopType') == 2){
            this.props.history.push('/sb-home');
            console.log("shop chencking",localStorage.getItem('shopType'))
          }

          this.setState({ shopName }, () => {
            // this.fetchCategoriesAndFirstCategoryFoodItems();
          });
          console.log("after checking shop_name",localStorage.getItem('shopName'));
          console.log("after checking shop_name",localStorage.getItem('shopId'));
          this.handleListClick(); //this reason
          this.fetchCreditUser();
          this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
          this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
          this.getExitingOrderNo();
          this.fetchItemCode();

          // localStorage.removeItem('shopName');
        } else {
          // If shop name is not available in localStorage, fetch it from the server
          this.getShopName(userId);
          if (localStorage.getItem('image_status') === null || '0') {
            localStorage.setItem('image_status', '1');
            console.log("b imagestatus",localStorage.getItem('image_status'));
      
          }
          if(localStorage.getItem('shopType') == 2){
            this.props.history.push('/sb-home');
            console.log("no shop chencking",localStorage.getItem('shopType'))
          }
          
         
        }
      } else {
        console.error('User ID not found in local storage.');
        this.props.history.push("/signin");
        // Handle the case where the user ID is not available in local storage
      }
      // Get the user_id from local storage
      // const userId = localStorage.getItem('userId');
      // if (userId) {
      //   // Fetch the shop name for the provided user ID
      //   this.getShopName(userId);
      // } else {
      //   console.error('User ID not found in local storage.');
      //   // Handle the case where the user ID is not available in local storage
      // }

     
      // this.getShopName(userId);
      // this.fetchCategories();
      // this.handleListClick(this.state.firstCategoryId)
      // this.handleListClick(); //this reason
      // this.fetchOrderHistory();
     
      
     
      // this.handleListClick(this.defaultCategoryId)


      // Simulate a 3-second delay before checking categories (replace with your data fetching logic)
    setTimeout(() => {
      this.fetchData();
      // this.fetchCategories();
  this.setState({ checkedCategories: true})
    }, 11000); // Delay for 3 seconds

    this.handleListPayModes();
   
    this.setState({imageStatus: localStorage.getItem('image_status') === '1' ? true : false})
    console.log("imagestatus",localStorage.getItem('image_status'),this.state.imageStatus);

    }

    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    }
 
    

    // fetchCategoriesAndFirstCategoryFoodItems() {
    //   const { shopName } = this.state;
    //   const categoriesRef = collection(db, `shops/${shopName}/categories`);
    
    //   getDocs(categoriesRef)
    //     .then((querySnapshot) => {
    //       const categories = querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
    //       console.log('Categories:', categories);
    
    //       if (categories.length > 0) {
    //         const firstCategoryId = categories[0].id;
    //         console.log(firstCategoryId )
    //         // this.handleListClick(firstCategoryId);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching categories:', error);
    //     });
    // }

   
  //show the shop name in center
    getShopName(userId) {
      axios.get(`${var_api}/api/shop/shopData/${userId}`)
        .then((response) => {
          const data = response.data;
          this.setState({ shopName:data.shopName });
          localStorage.setItem('shopName', data.shopName);
          localStorage.setItem('shopId', data.shopID);
          localStorage.setItem('shopType',data.shop_type);
          if(data.shop_type == 2){
            this.props.history.push('/sb-home');
          }
          this.handleListClick();
          this.fetchCreditUser();
          this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
          this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
          this.getExitingOrderNo();
        })
        .catch((error) => {
          console.error('Error fetching shop name:', error);
          // Handle the error if needed
        });
}

    
fetchCategories = async () => {
          
         const shopId = localStorage.getItem('shopId')
         try {
          const response = await axios.get(`${var_api}/api/categories/api/categories/${shopId}` );
          const data = response.data;
          if (data.length > 0) {
            // If there are categories in the response, set the ID of the first category in the state
            this.setState({ categories: data, firstCategoryId: data[0].id });
            // this.handleListClick(this.state.firstCategoryId)
          } else {
            // Handle the case where there are no categories
            console.log('No categories found.');
          }
        }catch (error) {
          console.log('Error fetching data:', error);
          
          }
        };


        //Fetch the order history
        fetchAllOrdersHistory = async (order_label) => {
          const shopId = localStorage.getItem('shopId')
          this.setState({orderLoading: true});
          try {
           const response = await axios.get(`${var_api}/api/food/normaldate/related-data/${shopId}/${order_label}/${this.state.startDate}/${this.state.endDate}` );
           const data = response.data;
           if (data.length > 0) {
             // If there are categories in the response, set the ID of the first category in the state
             this.setState({ orderHistoryItems: data });
             this.setState({orderLoading: false});
             // this.handleListClick(this.state.firstCategoryId)
           } else {
             // Handle the case where there are no categories
             console.log('No categories found.');
             this.setState({ orderHistoryItems: [] });
             this.setState({orderLoading: false});
           }
         }catch (error) {
           console.log('Error fetching data:', error);
           
           }
         };

        
      // // console.log("shop category:",shopName)
      // const colRef = collection(db, `shops/${shopName}/categories`);
      // const q = query(colRef);
      // const unsub = onSnapshot(q, (snapshot) => {
      //   // if (snapshot.empty) {
      //   //   // No categories available, show an alert
      //   //   Swal.fire({
      //   //     icon:"error",
      //   //     title:"Oops...",
      //   //     text:"No Categories Available",
      //   //     confirmButtonColor:"#007500"
      //   //   })
      //   //   this.setState({ categories: [] });
      //   //   this.setState({ foods: [] });
      //   // } else {
      //   const categoryNames = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
      //   this.setState({ categories: categoryNames });
        
      //   });
      



    // fetchOrderHistory = () => {
    //   const shopName = localStorage.getItem('shopName');
    //   console.log("shop category:", shopName);
    //   const ordersRef = collection(db, `shops/${shopName}/orders`);
    
    //   const unsubscribe = onSnapshot(ordersRef, (querySnapshot) => {
    //     const orders = querySnapshot.docs.map((doc) => ({
    //       id: doc.id, // Get the document ID
    //       ...doc.data(), // Include the document data
    //     }));
    
    //     // Sort the orders array based on the order_no field
    //     orders.sort((a, b) => b.order_no - a.order_no);
    
    //     this.setState({ orders:orders });
    //     this.fetchData();
    //   }, (error) => {
    //     console.error('Error fetching orders:', error);
    //   });
    
    //   // Store the unsubscribe function in a class property
    //   this.unsubscribe = unsubscribe;
    // }


    //Fetch the order history
    fetchAllOrdersSummary = async (order_label) => {
      const shopId = localStorage.getItem('shopId')
      try {
       const response = await axios.get(`${var_api}/api/food/summary/related-data/${shopId}/${order_label}/${this.state.startDate}/${this.state.endDate}` );
       const data = response.data;
       console.log("oooo", response.data);
       
         // If there are categories in the response, set the ID of the first category in the state
         this.setState({ orderSummary: data });
         console.log("oooo", response);
       
     }catch (error) {
       console.log('Error fetching data:', error);
       this.setState({ orderSummary: [] });
       }
     };


     //list the pay modes
     handleListPayModes =async() => {
      try {
        const response = await axios.get(`${var_api}/api/pay-mode/get-active-mode` );
        const data = response.data
        const options = data.map(modes => ({
          value: modes.pay_mode_name,
          label:  modes.pay_mode_name
        }));
        this.setState({payModeOptions: options, payModes:data})
        const newSelectedAmounts = Array(this.state.payModes.length).fill(0);
        this.setState({ selectedAmounts: newSelectedAmounts});
       console.log("payyyyy",data);
      }catch (error) {
        console.log('Error fetching data:', error);
        
        }
      }


   
    handleListClick =async() => {
      const id = localStorage.getItem('shopId')
      this.setState({ loading : true });
      try {
        const response = await axios.get(`${var_api}/api/categories/cate/subfood/${id}` );
        const data = response.data
        this.setState({ foods: data.categories});
        this.setState({ loading : false });
        // this.fetchOrderHistory();
      }catch (error) {
        console.log('Error fetching data:', error);
        
        }
//       this.setState({ loading : true });
// const shopName = localStorage.getItem('shopName');
//       console.log("shop category:",shopName)
//       const colRef = collection(db, `shops/${shopName}/categories/${id}/foodMaster`);
//       const q = query(colRef);
//         const unsub = onSnapshot(q, (snapshot) => {
//           const foodNames = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
//           this.setState({ foods: foodNames, defaultCategoryId: id });
//           this.setState({ loading : false });
//         });
      };


  
    toggle(tab) {
     
      const { activeTab } = this.state;
      // this.fetchCategories();
  
      if (activeTab === tab) {
    
        // Close the active tab if it's clicked again
        this.setState({ activeTab: null });
      } else {
      
        this.setState({ activeTab: tab });
        
      }
    }

    // routeChange() {
    //   const { shopName } = this.state;
    //   let path = `/cate/${encodeURIComponent(shopName)}`;
    //   this.props.history.push(path);
    // }

    routeChange() {
      let path = `/cate`;
      this.props.history.push(path);
    }

    routeChance() {
      let path = `/kitchen`;
      this.props.history.push(path);
    }


    routeSubChance() {
      let path = `/sub-kitchen`;
      this.props.history.push(path);
    }

    routeAttendance() {
      let path = `/sb-attendance`;
      this.props.history.push(path);
    }

    routeDay() {
      let path = `/deliver`;
      this.props.history.push(path);
    }

    routeDaily() {
      let path = `/daily`;
      this.props.history.push(path);
    }

    routeTable() {
      let path = `/tableOrders`;
      this.props.history.push(path);
    }

    routeCredit() {
      let path = `/creditUser`;
      this.props.history.push(path);
    }

    routeContract() {
      let path = `/contract`;
      this.props.history.push(path);
    }

    routeReport() {
      let path = `/report`;
      this.props.history.push(path);
    }

    routeUom() {
      let path = `/uom-master`;
      this.props.history.push(path);
    }

    routeGroupOrders() {
      let path = `/groupOrders`;
      this.props.history.push(path);
    }


    routeSupplier() {
      let path = `/supplier-master`;
      this.props.history.push(path);
    }

    routeEmployee() {
      let path = `/employee-master`;
      this.props.history.push(path);
    }

    routeRawMaterial() {
      let path = `/RawMaterial-master`;
      this.props.history.push(path);
    }

    routeTablemaster() {
      let path = `/table-master`;
      this.props.history.push(path);
    }

    routeSubKitchenmaster() {
      let path = `/sub-kitchen-master`;
      this.props.history.push(path);
    }


    routeSbReports() {
      let path =`/sb-report`;
      this.props.history.push(path);
    }

     routeOutstandingpayment() {
        let path = `/Outstandingpayment`;
        this.props.history.push(path);
    }


    routeItemSummary() {
      let path = `/item-summary`;
      this.props.history.push(path);
  }

  routeOrderhistory() {
    let path = `/order-history`;
    this.props.history.push(path);
}

routeSettlements() {
  let path = `/expense-log`;
  this.props.history.push(path);
}

routenNewSettlements() {
  let path = `/new-settlement`;
  this.props.history.push(path);
}

routeParcelStatus() {
  let path = `/parcel-kitchen`;
  this.props.history.push(path);
}


    toggleNav() {
      
      this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen

      }));
     
    }

      toggleChange() {
        this.setState(prevState => ({
          shadropdownOpen: !prevState.shadropdownOpen
        }));

    }


    toggleMastersMenu = () => {
      this.setState(prevState => ({
        isMastersMenuOpen: !prevState.isMastersMenuOpen,
        dropdownOpen: !prevState.dropdownOpen
      }));
    }
  

    // handleAddToCart = (foodItem) => {
    //   // Add logic to handle adding the selected food item to the cart
    //   // For demonstration, let's just redirect to the cart page
  
    //   // Redirect to the cart page
    //   this.props.history.push({
    //     pathname: '/cart',
    //     state: { foodItems: [foodItem] },
    //   });
    // };


    
    handleNameSelection = (name) => {
      if(name.shop_type === 2){
        this.setState({
          shopName: name.shop_name,
        });
        localStorage.removeItem('shopName')
        const shopName=localStorage.setItem('shopName',name.shop_name)
        localStorage.setItem('shopId',name.id)
        // this.fetchCategories();
        this.handleListClick();
        this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
        this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
        this.getExitingOrderNo();
        this.setState({foods:[]})
        this.setState({orders:[]})
        console.log("type",name.shop_type)
        localStorage.setItem('shopType',name.shop_type);

      }else{
        localStorage.removeItem('shopName')
        const shopName=localStorage.setItem('shopName',name.shop_name)
        localStorage.setItem('shopId',name.id)
        this.props.history.push('/home');
        console.log("type",name.shop_type)
        localStorage.setItem('shopType',name.shop_type);
        
      }
     
    };

    fetchData = async () => {
      try {
         // const response = await axios.get('https://api.spbiller.com/api/shop/data');
         const create = localStorage.getItem('userId')
         const response = await axios.get(`${var_api}/api/shop/api/shops/${create}`);
        const user =response.data;

         if (Array.isArray(user)) {
          this.setState({ 
            users: user
           });
 
          //  localStorage.setItem('shopId',user.id);
          //  console.log("shop shop id",localStorage.getItem('shopId',user.id));
        } else {
          // Handle the case when responseData is not an array
          console.error('API response is not an array:', user);
          this.setState({ 
            users: [user]
           });; // Set users to an empty array or handle the error appropriately
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleOnclick(){
      this.setState({show:true})
      
    }


    handleCloseConfirmation(){
      this.setState({show: false})
    }


     //function for logout
  handleModLogout(){
   const logout= localStorage.removeItem('userId');
   const shop= localStorage.removeItem('shopId');
      const place = localStorage.getItem('userId');
      console.log("id",place)
      if(place==null){
        this.props.history.push('/signin')
      }
      localStorage.removeItem('shopName');
      localStorage.removeItem('shopType');
      }
    

    //fetch all the item code
    fetchItemCode= async()=> {
      const shopId = localStorage.getItem('shopId')
        try {
          const response = await axios.get(`${var_api}/api/foodMaster/api/allFood/${shopId}`);
          const data = response.data.result;
    
          // Transform the data from the API into the format expected by react-select
          const formattedOptions = data.map(item => ({
            label: item.item_code, // Change this to the actual property name from your API response
          }));
    
          this.setState({ItemCodeOptions:formattedOptions});
        } catch (error) {
          console.log('Error fetching data:', error);
          
        }
}

   //fetch the customer balance
   fetchCustomerBalance= async(id)=> {
    const shopId = localStorage.getItem('shopId')
      try {
        const response = await axios.get(`${var_api}/api/payment/balancedata/${id}/${shopId}`);
        const data = response.data;
       console.log("de",data)
        this.setState({amountDetail:data});
      } catch (error) {
        console.log('Error fetching data:', error);
        
      }
}



  //set the selected item code
   handleCodeSelectChange = (data) => {
    this.fetchItemCode();
 
    this.setState({selectedItemCode:data}, () => {
      console.log('selected item code:', this.state.selectedItemCode);
    });
   }


    handleSearchInput = (e) => {
     this.setState({
      searchQuery : e.target.value,
     });
    };


    //fetch the item details filter by item code
    handleAddByItemCode = async () => {
      const { selectedItemCode } = this.state
      if(selectedItemCode && selectedItemCode.label){
        const item_code = selectedItemCode.label
      const shopId = localStorage.getItem('shopId')
      try {
        const response = await axios.get(`${var_api}/api/foodMaster/api/filterItem/${shopId}/${item_code}`);
        const data = response.data.result;

        const food = data[0];
       // Add the selected food to the cart
        this.addToCart(food);
        console.log("selected food details",food)
  
       
      } catch (error) {
        console.log('Error fetching data:', error);
        
      }
        
      }else{
        Swal.fire({
          text:"Select Item Code Before Add",
          confirmButtonColor: '#007500',
        })
      }
      
    }


    incrementQuantity = (itemId) => {
      const { cartItems } = this.state;
      console.log("itemid", itemId)
      const updatedCartItems = cartItems.map((item) => {
        if (item.id === itemId) {
          console.log("q",item.quantity, parseInt(item.quantity)+1)
          return { ...item, quantity: parseInt(item.quantity) + 1 };
        }
        return item;
      });
      this.setState({ cartItems: updatedCartItems });
    };
    
    decrementQuantity = (itemId) => {
      const { cartItems } = this.state;
      const updatedCartItems = cartItems.map((item) => {
        if (item.id === itemId && item.quantity > 0) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });
      this.setState({ cartItems: updatedCartItems });
    };
    


    addToCart = () => {
      const { isNormalCreditModal, isNormalModal } = this.state;
  // const existingItemIndex = cartItems.findIndex((item) => item.food.id === food.id);

  // if (existingItemIndex !== -1) {
  //   const updatedCartItems = [...cartItems];
  //   updatedCartItems[existingItemIndex].quantity += 1;
    this.setState({ isNormalCreditModal: true, isNormalModal:false });
  // } else {
  //   const cartItemId = Date.now();
  //   this.setState((prevState) => ({
  //     cartItems: [...prevState.cartItems, {  id: cartItemId,food: food, quantity: 1 }],
  //   }));
  // }
  // console.log("cc",cartItems);
    };

    removeFromCart = (cartItemId) => {
      const { cartItems } = this.state;
      const updatedCartItems = cartItems.filter((item) => item.id !== cartItemId);
      this.setState({ cartItems: updatedCartItems });
    };

    fetchCreditUser = async () => {
      const shopId = localStorage.getItem('shopId');
     try {
      const response = await axios.get(`${var_api}/api/credituser/data/${shopId}` );
      const data = response.data;
      this.setState({ mobileInput: data });
    }catch (error) {
      console.log('Error fetching data:', error);
      this.setState({ mobileInput: [] });
      }
    };


    //find the big order number
getExitingOrderNo() {
  const shop_id = localStorage.getItem('shopId');
  axios.get(`${var_api}/api/food/bigorderno/${shop_id}`)
    .then((response) => {
      const data = response.data.results;
      console.log("i",data)
      this.setState({ order_no:data[0] });

    })
    .catch((error) => {
      console.error('Error fetching order no:', error);
      // Handle the error if needed
    });
}


clearSelectedValue = () => {
  this.setState({ selectedData: ""}); // Clear the selected value
};


    generateCreditOrder = async () => {
      const { cartItems, order_no, isParcelChecked } = this.state;

  
      //check cart is empty
      if(cartItems.length === 0){
        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"Your cart is empty. Please add items to your cart before placing an order.",
          confirmButtonColor:"#007500"
        })
      }
      else{
        if( this.state.selectedData.value && this.state.selectedData.label && this.state.selectedData.iden){
          

          const user_balance = this.state.amountDetail.balance;
          const maxLimit = this.state.selectedData.maxCredit;
          const itemTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
            return accumulator + cartItem.quantity * cartItem.food.price;
          }, 0);
          const totalAmount = user_balance + itemTotal;
          console.log("uuu",user_balance,maxLimit,itemTotal,totalAmount)
        if(maxLimit>=totalAmount){
          this.setState({iscredit:true});
        const shopName = localStorage.getItem('shopName');
      // console.log("shop category:",shopName)
      // const orderRef = collection(db, `shops/${shopName}/orders`);
    
      const orderItems = this.state.cartItems.map((cartItem) => {
        return {
          food_id:cartItem.food.id,
          food_name: cartItem.food.food_name,
          quantity: cartItem.quantity,
          unit_price: cartItem.food.price,
          item_code: cartItem.food.item_code,
          category_code:cartItem.food.category_code,
          // Add more properties as needed
        };
      });

      const total = this.state.cartItems.reduce((accumulator, cartItem) => {
        return accumulator + cartItem.quantity * cartItem.food.price;
      }, 0);

      const totalWithServiceCharge = total + 0; // Add a service charge of 5 to the total

console.log('Total with Service Charge:', totalWithServiceCharge);
    
      // const totalItems = this.state.cartItems.reduce((accumulator, cartItem) => {
      //   return accumulator + cartItem.quantity;
      // }, 0);

      const totalItems = this.state.cartItems.length;
    
      try {
        // const timestamp = serverTimestamp();
        // const querySnapshot = await getDocs(orderRef);
        // const orderNumber = querySnapshot.size + 1;
        const currDate = new Date();
        const dd = String(currDate.getDate()).padStart(2, '0');
        const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = currDate.getFullYear();
        
        const formattedDate = `${dd}-${mm}-${yyyy}`;
        const currTime = new Date().toLocaleTimeString();
        let maxOrderNo = 0;
        // if (order_no > maxOrderNo) {
        //   console.log("orderno",order_no)
        //    maxOrderNo = order_no.max_order_no;
        //   }
          console.log("m",order_no)
          maxOrderNo = order_no.max_order_no + 1
          console.log("m",maxOrderNo)

          var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";


    //     const customerName = document.querySelector('#name').value;
    //     const customerPhone = document.querySelector('#mobile').value;


    //     // Validate name (only strings)
    // if (!/^[A-Za-z\s]+$/.test(customerName)) {
    //   alert('Invalid name. Please enter a valid name.');
    //   return;
    // }

    // // Validate mobile number (only 10 digits)
    // if (!/^\d{10}$/.test(customerPhone)) {
    //   alert('Invalid mobile number. Please enter a 10-digit mobile number.');
    //   return;
    // }
// debugger;
console.log("name and phone:",this.state.selectedData.label,this.state.selectedData.value)
const userID = localStorage.getItem('userId');
const shopiD = localStorage.getItem('shopId');
const empID = localStorage.getItem("admin_employee")
const userEmail = localStorage.getItem("emailId");
   // const selectedCreditCustomer = JSON.parse( document.querySelector('#selectedCreditCustomer').value);
       

        // Prepare the data to be sent to the Node.js API
        const orderData = {
          admin_id: parseInt(userID),
          admin_email: userEmail,
          creditUser_id:this.state.selectedData.iden,
          shop_name:shopName,
          shop_id:parseInt(shopiD),
          cust_name: this.state.selectedData.label,
          phone_no: this.state.selectedData.value,
          total_items:totalItems,
          order_no:maxOrderNo,
          total: totalWithServiceCharge,
          order_type:0,
          table_no:0,
          paid_status: 0,
          attender_name: "-",
          captain:"Admin",
          parcel_status:0,
          print_status:0,
          order_label:1,
          items: orderItems,
          ordered_at:formattedDate,
          ordered_time:currTime,
          is_group_location_order:0,
          group_location_order_id:0,
          pay_mode: "CREDIT ORDER",
          remarks:remark,
          is_parcel: isParcelChecked ? 1 : 0,
          user_id:parseInt(userID),
          emp_id:parseInt(empID),
          kitchen_parcel_status:0
        };

         // Send the order data to the Node.js API using axios
    const response = await axios.post(`${var_api}/api/creditorder/api/post`, orderData);
    if (response.status === 200) {

      console.log("customer id:",response.data.idCust)
      const customerID = response.data.idCust;
      const creditUserId =  response.data.creditId;
      const traData = response.data.insertedTransItesIds;
      const paymodesArray = [
        {
          order_id:customerID, 
          shop_id:parseInt(shopiD), 
          id:10, 
          amount:total,
          pay_mode_name:"CREDIT ORDER",
          ordered_at:formattedDate
        }
      ]

      try {
        const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
        console.log(response.data); // Log response from server
        console.log( "Succes", response.data.message );
        const entered_Paymodes = response.data.results;
        const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
        this.setState({enteredPaymodes: enteredPaymodesString});
      } catch (error) {
        console.error('Error posting pay modes:', error);
      }

      document.querySelector('#remark').value = '';
      this.setState({ selectedPayMode: null});
      this.setState({orderResponse:response.data})
     
      console.log("credit user ids",creditUserId,traData);


      // const creditorderItems = this.state.cartItems.map((cartItem,index) => {
      //   const tradata = response.data.insertedTransItesIds[index]; 
      //   return {
      //     food_name: cartItem.food.food_name,
      //     quantity: cartItem.quantity,
      //     unit_price: cartItem.food.price,
      //     item_code: cartItem.food.item_code,
      //     category_code:cartItem.food.category_code,
      //     trans_id: tradata,
      //     // Add more properties as needed
      //   };
      // });
      // console.log("cccc",creditorderItems,cartItems)

      // await addDoc(orderRef, {
      //   // customer_name: selectedCreditCustomer.name,
      //   // customer_phone: selectedCreditCustomer.phone_no,
      //   customer_name: this.state.selectedData.label,
      //   customer_phone: this.state.selectedData.value,
      //   order_items: creditorderItems,
      //   total: totalWithServiceCharge,
      //   total_items: totalItems,
      //   order_no:orderNumber,
      //   ordered_at: timestamp,
      //   sub_total:total,
      //   process_code:0,
      //   print_status:0,
      //   paid_status:0,
      //   parcel_status:0,
      //   customer_id :customerID,
      //   creditUser_id:creditUserId
      //   // Add more properties as needed
      // });
      
      // Handle successful order submission to both databases
      console.log('Order submitted successfully to Firestore and Node.js');
    }

       
       
    
        console.log('Order posted to Firestore successfully!');
        const email = localStorage.getItem('emailId');
        console.log('shop admin:',email);
        const orderItemsString = JSON.stringify(orderItems);
        

        // const billHtml = ReactDOMServer.renderToString(<Bill order={order} />);
        const emailData = {
          to:[this.state.selectedData.mail,email],
          subject: `${this.state.selectedData.label} ordered new order from ${shopName}..!`,
        }

        const username = this.state.selectedData.label
        const shopID = localStorage.getItem('shopId')
        try {
          const response = await axios.post(`${var_api}/api/mailnotify/send-orderemail/${this.state.selectedData.mail}/${shopID}`, emailData);
          console.log('Email sent:', response.data);
          
        } catch (error) {
          console.error('Error sending email:', error);
          this.setState({iscredit:false});
        }

        this.setState({isCreditModal:false, isParcelChecked:false});
        this.setState({isOrderConfirmation:true});
    
      
        this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
        this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
         // Remove the items from the cartItems array
    this.setState({ cartItems: [] });
    
    this.clearSelectedValue();
    const audio = new Audio('https://spbiller.com/audio/place-order.mp3');
    audio.play();
       
        this.setState({iscredit:false});
    console.log('Cart items removed from state!');
    
  this.getExitingOrderNo();
      } catch (error) {
        console.error('Error posting order:', error);
        this.setState({iscredit:false});
      }
        // Fetch and display order history
        // this.fetchOrderHistory();
        
      
      }else{

        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"The order reached the credit limit",
          confirmButtonColor:"#007500"
        })
        }


      }else{
        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"Select Credit User",
          confirmButtonColor:"#007500"
        })
      }
    }
  }
  


  handleGenerateOrderModal = () => {
    //  //check cart is empty
    //  if(this.state.cartItems.length === 0){
    //   Swal.fire({
    //     icon:"error",
    //     title:"Oops...",
    //     text:"Your cart is empty. Please add items to your cart before placing an order.",
    //     confirmButtonColor:"#007500"
    //   })
    // }else{
      this.setState({ isCreditModal: true});
    // }
    
  }


  handleOpenRoomConfirmation = () => {
    //  //check cart is empty
    //  if(this.state.cartItems.length === 0){
    //   Swal.fire({
    //     icon:"error",
    //     title:"Oops...",
    //     text:"Your cart is empty. Please add items to your cart before placing an order.",
    //     confirmButtonColor:"#007500"
    //   })
    // }else{
      this.setState({ isNormalCreditModal: true});
    // }
    
  }

  handleGenerateNormalOrderModal = () => {
    //check cart is empty
    if(this.state.cartItems.length === 0){
     Swal.fire({
       icon:"error",
       title:"Oops...",
       text:"Your cart is empty. Please add items to your cart before placing an order.",
       confirmButtonColor:"#007500"
     })
   }else{

    if( this.state.selectedData.value && this.state.selectedData.label && this.state.selectedData.iden){
     this.setState({ isNormalCreditModal: true});
   }  else{
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"Select Credit User",
        confirmButtonColor:"#007500"
      })
    }
   
 }
}



handleGeneratecreditOrderModal = () => {
  //check cart is empty
  if(this.state.cartItems.length === 0){
   Swal.fire({
     icon:"error",
     title:"Oops...",
     text:"Your cart is empty. Please add items to your cart before placing an order.",
     confirmButtonColor:"#007500"
   })
 }else{

  if( this.state.selectedData.value && this.state.selectedData.label && this.state.selectedData.iden){
    const user_balance = this.state.amountDetail.balance;
    const maxLimit = this.state.selectedData.maxCredit;
    const itemTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
      return accumulator + cartItem.quantity * cartItem.food.price;
    }, 0);
    const totalAmount = user_balance + itemTotal;
    console.log("uuu",user_balance,maxLimit,itemTotal,totalAmount)

    if(maxLimit>=totalAmount){
   this.setState({ isCreditModal: true});
    }else{

      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"The order reached the credit limit",
        confirmButtonColor:"#007500"
      })
      }


 }  else{
    Swal.fire({
      icon:"error",
      title:"Oops...",
      text:"Select Credit User",
      confirmButtonColor:"#007500"
    })
  }
 
}
}



  generateOrder = async () => {
    const { cartItems, order_no, isParcelChecked } = this.state;


    //check cart is empty
    if(cartItems.length === 0){
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"Your cart is empty. Please add items to your cart before placing an order.",
        confirmButtonColor:"#007500"
      })
    }
    else{

       // Calculate the total amount from cartItems
  const cartTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.food.price;
  }, 0);

  // Calculate the total amount from selectedAmounts
  const selectedTotal = this.state.selectedAmounts.reduce((accumulator, amount) => {
    return accumulator + amount;
  }, 0);

  
  console.log("amounts",cartTotal,selectedTotal)
      if(selectedTotal > 0){

        if(selectedTotal === cartTotal){

      const shopName = localStorage.getItem('shopName');
      this.setState({isLogin:true});
    // console.log("shop category:",shopName)
    // const orderRef = collection(db, `shops/${shopName}/orders`);
  
    const orderItems = this.state.cartItems.map((cartItem) => {
      return {
        food_id:cartItem.food.id,
        food_name: cartItem.food.food_name,
        quantity: cartItem.quantity,
        unit_price: cartItem.food.price,
        item_code: cartItem.food.item_code,
        category_code:cartItem.food.category_code,
        // Add more properties as needed
      };
    });

    const total = this.state.cartItems.reduce((accumulator, cartItem) => {
      return accumulator + cartItem.quantity * cartItem.food.price;
    }, 0);

    const totalWithServiceCharge = total + 0; // Add a service charge of 5 to the total
  
    // const totalItems = this.state.cartItems.reduce((accumulator, cartItem) => {
    //   return accumulator + cartItem.quantity;
    // }, 0);

    const totalItems = this.state.cartItems.length;

    
  
    try {
      // const timestamp = serverTimestamp();
      // const querySnapshot = await getDocs(orderRef);
      // const orderNumber = querySnapshot.size + 1;
      const currDate = new Date();
      const dd = String(currDate.getDate()).padStart(2, '0');
      const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = currDate.getFullYear();
      
      const formattedDate = `${dd}-${mm}-${yyyy}`;
      const currTime = new Date().toLocaleTimeString();

      let maxOrderNo = 0;
      // if (order_no > maxOrderNo) {
      //   console.log("orderno",order_no)
      //    maxOrderNo = order_no;
      //   }
      console.log("m",order_no)
      maxOrderNo = order_no.max_order_no + 1
      console.log("m",maxOrderNo)
      

      // var customerName = document.querySelector('#name').value;
      // var customerPhone = document.querySelector('#mobile').value;

      var customerName = document.querySelector('#name') ? document.querySelector('#name').value : "-";
      var customerPhone = document.querySelector('#mobile') ? document.querySelector('#mobile').value : "-";
      var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";

  //     if(!this.state.isChecked){
        
  // const selectedCreditCustomer = JSON.parse( document.querySelector('#selectedCreditCustomer').value);
  //        customerName =selectedCreditCustomer.name;
  //      customerPhone = selectedCreditCustomer.phone_no;
  //     }

      // Validate name (only strings)
  // if (!/^[A-Za-z\s]+$/.test(customerName)) {
  //   alert('Invalid name. Please enter a valid name.');
  //   return;
  // }

  // Validate mobile number (only 10 digits)
  // if (!/^\d{5}$/.test(customerPhone)) {
  //   alert('Invalid mobile number. Please enter a 10-digit mobile number.');
  //   return;
  // }

const userID = localStorage.getItem('userId');
const empID = localStorage.getItem("admin_employee");
const userEmail = localStorage.getItem("emailId");
 const shop_id = localStorage.getItem('shopId');
     

      // Prepare the data to be sent to the Node.js API
      const orderData = {
        admin_id: parseInt(userID),
        admin_email: userEmail,
        shop_name:shopName,
        cust_name: customerName,
        phone_no: customerPhone,
        total_items:totalItems,
        order_no:maxOrderNo,
        total: totalWithServiceCharge,
        order_type:0,
        table_no:0,
        paid_status: 0,
        order_label:0,
        is_group_location_order:0,
        group_location_order_id:0,
        ordered_at:formattedDate,
        ordered_time:currTime,
        shop_id:shop_id,
        credit_user_id:0,
        items: orderItems,
        pay_mode: "-",
        remarks:remark,
        is_parcel: isParcelChecked ? 1 : 0,
        user_id:parseInt(userID),
        emp_id:parseInt(empID),
        kitchen_parcel_status:0
      };
      

       // Send the order data to the Node.js API using axios
  const response = await axios.post(`${var_api}/api/food/api/post`, orderData);
  if (response.status === 200) {
    console.log("customer id:", response.data.idUser);
    const customerId = response.data.idUser;



      // Create a new array with amounts inserted into matched pay modes
    const paymodesArray = this.state.payModes.map((mode, index) => {
      const amount = this.state.selectedAmounts[index] || 0; // Get amount from selectedAmounts array
      return { ...mode, amount, shop_id:shop_id, order_id: customerId, ordered_at:formattedDate  }; // Create a new object with amount inserted
    });

    console.log("amountwitharray",paymodesArray);

    try {
      const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
      console.log(response.data); // Log response from server
      console.log( "Succes", response.data.message );
      const entered_Paymodes = response.data.results;
      const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
      this.setState({selectedAmounts:[], enteredPaymodes: enteredPaymodesString});
    } catch (error) {
      console.error('Error posting pay modes:', error);
    }



    const data = response.data.insertedTransIds;
    console.log("o.r",response.data)
    this.setState({orderResponse:response.data})

    // Extract the insertedTransIds array from the response
    console.log("transaction id", data);
    console.log('Order submitted successfully to Firestore and Node.js');

    // Clear the text fields by setting their values to an empty string
    document.querySelector('#name').value = '';
    document.querySelector('#mobile').value = '';
    document.querySelector('#remark').value = '';
    this.setState({isNormalModal:false, isParcelChecked:false});
    this.setState({isOrderConfirmation:true});

    // Play the notification sound
   // Play the notification sound
const audio = new Audio('https://spbiller.com/audio/place-order.mp3');
audio.play();

}


     
      this.setState({isLogin:false});
  
      console.log('Order posted to Firestore successfully!');

       // Remove the items from the cartItems array
  this.setState({ cartItems: [] });
  this.setState({ selectedItemCode: null});
  this.setState({ selectedPayMode: null});
  this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
  this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
  
this.getExitingOrderNo();


  console.log('Cart items removed from state!');
    } catch (error) {
      console.error('Error posting order:', error);
      this.setState({isLogin:false});
    }
      // Fetch and display order history
      // this.fetchOrderHistory();
  }
  else{
    Swal.fire({
      icon:"error",
      title:"Oops...",
      text:"pay mode amount is not equal to total amount",
      confirmButtonColor:"#007500"
    })
  }

}
  
  else{
        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"Enter the pay mode amount",
          confirmButtonColor:"#007500"
        })
      }
    }
  };


  generateNormalOrder = async () => {
    const { cartItems, order_no, isParcelChecked } = this.state;


    //check cart is empty
    if(cartItems.length === 0){
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"Your cart is empty. Please add items to your cart before placing an order.",
        confirmButtonColor:"#007500"
      })
    }
    else{
      if( this.state.selectedData.value && this.state.selectedData.label && this.state.selectedData.iden){

         // Calculate the total amount from cartItems
  const cartTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.food.price;
  }, 0);

  // Calculate the total amount from selectedAmounts
  const selectedTotal = this.state.selectedAmounts.reduce((accumulator, amount) => {
    return accumulator + amount;
  }, 0);


        if(selectedTotal > 0){

          if(selectedTotal === cartTotal){

          const shopName = localStorage.getItem('shopName');
          this.setState({isLogin:true});
       
        const orderItems = this.state.cartItems.map((cartItem) => {
          return {
            food_id:cartItem.food.id,
            food_name: cartItem.food.food_name,
            quantity: cartItem.quantity,
            unit_price: cartItem.food.price,
            item_code: cartItem.food.item_code,
            category_code:cartItem.food.category_code,
            // Add more properties as needed
          };
        });
    
        const total = this.state.cartItems.reduce((accumulator, cartItem) => {
          return accumulator + cartItem.quantity * cartItem.food.price;
        }, 0);
    
        const totalWithServiceCharge = total + 0; // Add a service charge of 5 to the total
      
        
    
        const totalItems = this.state.cartItems.length;
    
        
      
        try {
          
          const currDate = new Date();
          const dd = String(currDate.getDate()).padStart(2, '0');
          const mm = String(currDate.getMonth() + 1).padStart(2, '0'); // January is 0!
          const yyyy = currDate.getFullYear();
          
          const formattedDate = `${dd}-${mm}-${yyyy}`;
          const currTime = new Date().toLocaleTimeString();
    
          let maxOrderNo = 0;
          // if (order_no > maxOrderNo) {
          //   console.log("orderno",order_no)
          //    maxOrderNo = order_no;
          //   }
          console.log("m",order_no)
          maxOrderNo = order_no.max_order_no + 1
          console.log("m",maxOrderNo)
          var remark = document.querySelector('#remark') ? document.querySelector('#remark').value : "-";
          const userID = localStorage.getItem('userId');
          const empID = localStorage.getItem("admin_employee")
          const userEmail = localStorage.getItem("emailId");
    
          // Prepare the data to be sent to the Node.js API
          const orderData = {
            admin_id: parseInt(userID),
            admin_email: userEmail,
            // admin_id: 33,
            // admin_email: "jesiugcs2019@gmail.com",
            shop_name:shopName,
            cust_name:  this.state.selectedData.label,
            phone_no: this.state.selectedData.value,
            total_items:totalItems,
            order_no:maxOrderNo,
            total: totalWithServiceCharge,
            order_type:0,
            table_no:0,
            paid_status: 0,
            order_label:0,
            is_group_location_order:0,
            group_location_order_id:0,
            ordered_at:formattedDate,
            ordered_time:currTime,
            shop_id:localStorage.getItem('shopId'),
            credit_user_id:this.state.selectedData.iden,
            items: orderItems,
            user_id:localStorage.getItem('userId'),
            emp_id: localStorage.getItem('admin_employee'),
            pay_mode: "-",
            remarks:remark,
            is_parcel: isParcelChecked ? 1 : 0,
            kitchen_parcel_status:0
          };
          
    
           // Send the order data to the Node.js API using axios
      const response = await axios.post(`${var_api}/api/food/api/post`, orderData);
      if (response.status === 200) {
    
        console.log("customer id:",response.data.idUser)
        const customerid = response.data.idUser;
    
        const data = response.data.insertedTransIds; 
        this.setState({orderResponse:response.data})
    
        // Extract the insertedTransIds array from the response
       console.log("transaction id",data);
       

        // Create a new array with amounts inserted into matched pay modes
    const paymodesArray = this.state.payModes.map((mode, index) => {
      const amount = this.state.selectedAmounts[index] || 0; // Get amount from selectedAmounts array
      return { ...mode, amount, shop_id:localStorage.getItem('shopId'), order_id: customerid, ordered_at:formattedDate  }; // Create a new object with amount inserted
    });

    console.log("amountwitharray",paymodesArray);


    try {
      const response = await axios.post(`${var_api}/api/order-paymode/post`, paymodesArray);
      console.log(response.data); // Log response from server
      console.log( "Succes", response.data.message );
      const entered_Paymodes = response.data.results;
      const enteredPaymodesString = entered_Paymodes.map(paymode => paymode.pay_mode_name).join(', ');
      this.setState({selectedAmounts:[], enteredPaymodes: enteredPaymodesString});
    } catch (error) {
      console.error('Error posting pay modes:', error);
    }

    
       document.querySelector('#remark').value = '';
       this.setState({ selectedPayMode: null});
       this.setState({isNormalCreditModal:false, isParcelChecked:false});
        this.setState({isOrderConfirmation:true});
    
        // Handle successful order submission to both databases
        console.log('Order submitted successfully to Firestore and Node.js');
        const audio = new Audio('https://spbiller.com/audio/place-order.mp3');
        audio.play();
    
      }
    
        
          this.setState({isLogin:false});
      
          console.log('Order posted to Firestore successfully!');
    
           // Remove the items from the cartItems array
      this.setState({ cartItems: [] });
      this.setState({ selectedItemCode: null});
      this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
      this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value);
      
    this.getExitingOrderNo();
    this.clearSelectedValue();
    
      console.log('Cart items removed from state!');
        } catch (error) {
          console.error('Error posting order:', error);
          this.setState({isLogin:false});
        }
          // Fetch and display order history
          // this.fetchOrderHistory();

        }
        else{
          Swal.fire({
            icon:"error",
            title:"Oops...",
            text:"pay mode amount is not equal to total amount",
            confirmButtonColor:"#007500"
          })
        }
      }else{
          Swal.fire({
            icon:"error",
            title:"Oops...",
            text:"Enter the pay mode Amount!",
            confirmButtonColor:"#007500"
          })
        }
        
    }
    else{
      Swal.fire({
        icon:"error",
        title:"Oops...",
        text:"Select Credit User",
        confirmButtonColor:"#007500"
      })
    }
  };
}

    handleAddCard = () => {
      // Navigate to a specific route using history.push
      this.props.history.push('/cate');
    };

    handleDropdownChange = (selectedOption) => {
      this.setState({ selectedData: selectedOption });
      console.log("credit user",selectedOption)
       // Perform validation checks here
    this.fetchCustomerBalance(selectedOption.iden)
    
    };

     // Function to update parcel status
  updateParcelInfo = (custId,id) => {

    const shopID = localStorage.getItem('shopId');
    const shopName = localStorage.getItem('shopName');

    const updatedData = {
     parcel_status:1,
    }


      // const orderRef = doc(db, `shops/${shopName}/orders`, id);
    
      // // Update the process_code field to 1
      // updateDoc(orderRef, {
      //   parcel_status:1,
      // })
      // .then(() => {
      //   console.log('process_code updated successfully');
      // })
      // .catch(error => {
      //   console.error('Error updating process_code:', error);
      // });

    axios
    .put(`${var_api}/api/food/parcelput/${shopID}/${custId}`, updatedData)
    .then((response) => {
      toast('Order added to parcel order',{autoClose:2000})
      console.log('Data updated successfully:', response.data.message);
      // Handle success, e.g., show a success message or update the UI
    })
    .catch((error) => {
      console.error('Error updating data:', error);
      // Handle error, e.g., show an error message or update the UI accordingly
    });
};


//update the print status
handlePrint = (id,orderId) => {

  const shopName = localStorage.getItem('shopName');
  // const orderRef = doc(db, `shops/${shopName}/orders`, orderId);

  // // Update the process_code field to 1
  // updateDoc(orderRef, {
  //   print_status: 1
  // })
  // .then(() => {
  //   console.log('process_code updated successfully');
  // })
  // .catch(error => {
  //   console.error('Error updating process_code:', error);
  // });
  
  const shopID = localStorage.getItem('shopId');
   
  const updatedStatus = {
    print_status:1,
   }

   axios
   .put(`${var_api}/api/food/put/print/${shopID}/${id}`, updatedStatus)
   .then((response) => {
    //  Swal.fire({
    //    text:"print status updated",
    //    confirmButtonColor:"#007500"
    //  })
    toast('print status updated',{autoClose:2000})
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  // this.setState({ isPrinted: true }); // Set the state to indicate that the button has been clicked
  // // Call the ReactToPrint logic here if needed
};


//to update paid status to 1
handlePaidStatus = (id,orderId) => {

  const shopName = localStorage.getItem('shopName');
  // const orderRef = doc(db, `shops/${shopName}/orders`, orderId);

  // // Update the process_code field to 1
  // updateDoc(orderRef, {
  //   paid_status: 1
  // })
  // .then(() => {
  //   console.log('process_code updated successfully');
  // })
  // .catch(error => {
  //   console.error('Error updating process_code:', error);
  // });
  
  const shopID = localStorage.getItem('shopId');
   
  const updatedStatus = {
    paid_status:1,
   }

   axios
   .put(`${var_api}/api/food/put/paidstatus/${shopID}/${id}`, updatedStatus)
   .then((response) => {
    //  Swal.fire({
    //    text:"paid status updated",
    //    confirmButtonColor:"#007500"
    //  })
     toast('paid status updated',{autoClose:2000})
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  // this.setState({ isPrinted: true }); // Set the state to indicate that the button has been clicked
  // // Call the ReactToPrint logic here if needed
};



//to update paid status to 1
handleCancelStatus = (id,orderId) => {

  const shopName = localStorage.getItem('shopName');
  // const orderRef = doc(db, `shops/${shopName}/orders`, orderId);

  // // Update the process_code field to 1
  // updateDoc(orderRef, {
  //   paid_status: 2
  // })
  // .then(() => {
  //   console.log('process_code updated successfully');
  // })
  // .catch(error => {
  //   console.error('Error updating process_code:', error);
  // });
  
  const shopID = localStorage.getItem('shopId');
   
  const updatedStatus = {
    paid_status:2,
   }

   axios
   .put(`${var_api}/api/food/put/paidstatus/${shopID}/${id}`, updatedStatus)
   .then((response) => {
    toast('order cancelled',{autoClose:2000})
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  // this.setState({ isPrinted: true }); // Set the state to indicate that the button has been clicked
  // // Call the ReactToPrint logic here if needed
};


handleOrderLabelSelectChange = (selectedOption) => {
  this.setState({ selectedOrderLabel: selectedOption });
  this.fetchAllOrdersHistory(selectedOption.value);
  this.fetchAllOrdersSummary(selectedOption.value)
}


//onchange function for payment mode
handlePayModeSelectChange = (selectedOption) => {
  this.setState({ selectedPayMode: selectedOption });
  console.log(selectedOption)
}

handleStartDateChange = (event) => {
  const startDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = startDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the start date, e.g., store it in state
  this.setState({ startDate:formattedDate });
  
}

handleEndDateChange = (event) => {
  const endDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = endDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the end date, e.g., store it in state
  this.setState({ endDate:formattedDate });
  
}


handleOpenSelectDate = () => {
  this.setState ({isOpenSelectDate:true});
}


handleCloseDateConfirmation = () => {
  this.setState ({isOpenSelectDate:false});
}

handleSelectDateRange = () => {
  this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
  this.setState ({isOpenSelectDate:false});
  this.fetchAllOrdersSummary(this.state.selectedOrderLabel.value)
  
}

formatDate(dateString) {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
}

//open the summary modal
handleOpenOrderSumModal = () => {
  this.setState({isOpenOrderSummary:true});
}


//close the summary modal
handleCloseSummaryConfirmation = () => {
  this.setState({isOpenOrderSummary:false});
}


//close the summary modal
handleCloseNormalConfirmation = () => {
  this.setState({isNormalModal:false});
}


//close the summary modal
handleCloseNormalCreditConfirmation = () => {
  this.setState({isNormalCreditModal:false});
}


//close the summary modal
handleCloseCreditConfirmation = () => {
  this.setState({isCreditModal:false});
}


//close the summary modal
handleChangeRoom = () => {
  this.setState({isRoom:true});
}


//close the summary modal
handleChangeSeat = () => {
  this.setState({isRoom:false});
}

//close the summary modal
handleCloseOrderConfirmation = () => {
  this.setState({isOrderConfirmation:false});
}

//close the summary modal
handleOpenEyeOrderDetail = () => {
  this.setState({isOrderConfirmation:true});
}


//close the summary modal
handleCloseInstantAdding = () => {
  this.setState({isOpenAddInstant:false});
}


//close the summary modal
handleCloseaPayModeEdit = () => {
  this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
  this.setState({isPayModeEdit:false});
}



// Function to handle checkbox change
handleCheckboxChange = () => {
  // Update state to toggle isChecked value
  this.setState(prevState => ({
    isParcelChecked: !prevState.isParcelChecked
  }));
};

//switch for image status onchange
handleSwitchToggle() {
  // Toggle the switch state
  const newStatus = !this.state.imageStatus;
  this.setState({ imageStatus: newStatus });
  // Update localStorage
  localStorage.setItem('image_status', newStatus ? '1' : '0');
  console.log("imagestatus",localStorage.getItem('image_status'));
}

//to open the  edit modal
handleOpenEditPayModal = (order) => {
  // Sort pay_modes in ascending order based on pay_mode_id
  const sortedPayModes = order.pay_modes.sort((a, b) => a.paymode_id - b.paymode_id);

  this.setState({isPayModeEdit:true, editPaymodeOrder:order, editSelectedAmounts:sortedPayModes, editpaymode: {label:order.pay_mode, value: order.pay_mode}, updateRemark:order.remarks});
  console.log("paymodd order",order);
  console.log("editSelectedAmounts",this.state.editSelectedAmounts, order.pay_modes);
  console.log("editSelectedAmounts", sortedPayModes);
}


//onchange the edit modal
handlePayModeEditChange = (selectedOption) => {
  this.setState({ editpaymode: selectedOption });
  console.log(selectedOption)
}

// Update the remark value in state when input changes
handleRemarkChange = (event) => {
  this.setState({ updateRemark: event.target.value });
};


//update method for paymode
handleUpdatePaymode = () => { 
const ModeTotal = this.state.editSelectedAmounts && this.state.editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0)
  if(this.state.editPaymodeOrder.total !== ModeTotal){
    Swal.fire({
            icon:"error",
            title:"Oops...",
            text:"Paymode Total is not Equal to Total Amount",
            confirmButtonColor:"#007500"
          })

  }
  else{
  this.setState({isPayModeEditLoading:true});
  const shopID = localStorage.getItem('shopId');

   console.log("update",this.state.editSelectedAmounts)
   const paymodesArray = this.state.editSelectedAmounts.map((mode, index) => ({
    amount:  mode.amount ? mode.amount : 0,
    id: mode.id,
    shop_id: parseInt(shopID),
    remarks : this.state.updateRemark,
    order_id:mode.order_id
}));


   axios
   .put(`${var_api}/api/order-paymode/edit-order-paymodes`, paymodesArray)
   .then((response) => {
  
     toast('Updated Successfully',{autoClose:2000});
     this.fetchAllOrdersHistory(this.state.selectedOrderLabel.value);
     this.setState({isPayModeEdit:false,isPayModeEditLoading:false});
     console.log('Data updated successfully:', response.data.message);
   })
   .catch((error) => {
     console.error('Error updating data:', error);
     this.setState({isPayModeEditLoading:false});
     // Handle error, e.g., show an error message or update the UI accordingly
   });
  }
};





//onclick method paymode card
handleCardClick = (id) => {
  const { cartItems } = this.state;
  // Find the index of the pay mode with the given ID
  const index = this.state.payModes.findIndex(mode => mode.id === id);
  console.log("inddx", index,this.state.payModes);
  

  const total= cartItems.reduce((accumulator, cartItem) => {
    return accumulator + cartItem.quantity * cartItem.food.price;
  }, 0);

    // Create an array to hold the new amounts, initialized with zeros
    const newSelectedAmounts = Array(this.state.payModes.length).fill(0);
 
  // Set the amount for the clicked pay mode
  newSelectedAmounts[index] = total; // Set the initial value here
 
  // Update the state
  this.setState({ selectedAmounts: newSelectedAmounts, isAmountSetByCardClick: true });

  console.log("after setting", newSelectedAmounts, this.state.isAmountSetByCardClick);
}



handleEditCardClick = (id, total) => {
  const { editSelectedAmounts } = this.state;
  // Find the index of the pay mode with the given ID
  const index = this.state.editSelectedAmounts.findIndex(mode => mode.id === id);
  console.log("inddx", index,this.state.editSelectedAmounts);
  
  const updatedEditSelectedAmounts = editSelectedAmounts.map(item => ({
    ...item,
    amount: 0
}));

if (index !== -1) {
  updatedEditSelectedAmounts[index].amount = total;
}

// Update the component state with the new array
this.setState({ editSelectedAmounts: updatedEditSelectedAmounts });
 
 
  console.log("after setting", updatedEditSelectedAmounts);
}


handleEditAmountChange = (index, value) => {
 const newSelectedAmounts = [...this.state.editSelectedAmounts];

  // Convert the value to a number
  const newValue = value !== '' ? parseInt(value) : 0;
  // Check if the value is 0 and the user wants to clear it
  if (newValue === 0) {
    newSelectedAmounts[index].amount  = null; // Set the value to an empty string
  } else {
    // Update the quantity at the specified index
    newSelectedAmounts[index].amount = newValue;
  }
  // Set the amount for the corresponding index
  // newSelectedAmounts[index].amount = parseFloat(value) || 0;
  console.log("after setting textfield", newSelectedAmounts);
  // Clear the card clicked index
  this.setState({ editSelectedAmounts: newSelectedAmounts});
}


handleAmountChange = (index, value) => {

  // Check if the amount is being set due to card clicking
  // if (this.state.isAmountSetByCardClick) {
  //    // Create an array to hold the new amounts, initialized with zeros
  //    const newSelectedAmounts = Array(this.state.payModes.length).fill(0);
  //    console.log("if", newSelectedAmounts);
  //   // Reset the flag
  //   this.setState({ selectedAmounts: newSelectedAmounts, isAmountSetByCardClick: false });
  // }
 // Create a copy of the current selectedAmounts array
 const newSelectedAmounts = [...this.state.selectedAmounts];

   // Convert the value to a number
  const newValue = value !== '' ? parseInt(value) : 0;

  // Check if the value is 0 and the user wants to clear it
  if (newValue === 0) {
    newSelectedAmounts[index] = null; // Set the value to an empty string
  } else {
    // Update the quantity at the specified index
    newSelectedAmounts[index] = newValue;
  }
  console.log("after setting textfield", newSelectedAmounts);
  // Clear the card clicked index
  this.setState({ selectedAmounts: newSelectedAmounts});
}


//to open the instant food modal
handletoOpenInstantFood = () => {
  this.setState({ isOpenAddInstant: true});
}


//onchange function for instant food name
handleInstandFoodChange(event) {
  this.setState({
    instantFoodName : event.target.value
  });
}


//onchange function for instant quantity
handleInstandQuantityChange(event) {
  this.setState({
    instantQuantity : event.target.value
  });
}


//onchange function for instant price
handleInstandPriceChange(event) {
  this.setState({
    instantPrice : event.target.value
  });
}


//to adding instant food in cart items
handleToInstantAddingFood = () => {
  const { cartItems, instantFoodName, instantQuantity, instantPrice } = this.state;
  if(instantFoodName && instantQuantity && instantPrice && instantPrice > 0 && instantQuantity > 0){
const existingItemIndex = cartItems.findIndex((item) => item.food.food_name === instantFoodName);

if (existingItemIndex !== -1) {
const updatedCartItems = [...cartItems];
updatedCartItems[existingItemIndex].quantity += instantQuantity;
this.setState({ cartItems: updatedCartItems });
this.setState({ isOpenAddInstant: false, instantFoodName:null, instantQuantity:null, instantPrice:null});
} else {

const food = {
   food_name: instantFoodName,
   price: instantPrice || 1,
   category_code:" ",
   category_id:0,
   cost:0,
   currency:" ",
   id:0,
   image:" ",
   item_code:`instant+${instantFoodName}`,
   uom:" "
}
const cartItemId = Date.now();
this.setState((prevState) => ({
  cartItems: [...prevState.cartItems, {  id: cartItemId,food: food, quantity: instantQuantity }],
}));
this.setState({ isOpenAddInstant: false, instantFoodName:null, instantQuantity:null, instantPrice:null});
 }
console.log("cc",cartItems);
  }else{
    toast('Please Check the values',{autoClose:2000})
  }
};
    
    

    // fetchData = async () => {
    //   const data = localStorage.getItem('userId')
    //  try {
    //   const response = await axios.get('http://localhost:4300/api/shop/shopData/'+data );
    //   // Assuming setUsers is a function to update your state with the response data.
    //   const use = response.data
    //   // const use = [response.data.id,response.data.name,response.data.email,response.data.password,response.data.country,response.data.mobile]
    //   console.log(use)
    //   this.users(use);
    // }catch (error) {
    //   console.log('Error fetching data:', error);
      
    //   }
    // };
    
    

    render() {
      const { activeTab,isLogin,iscredit } = this.state;
      const { customers } = this.state;
      const { selectedList } = this.state;
      const { orderNo,showTable } = this.state;
      const { shopName } = this.state;
      const { users } = this.state;
      const { categories } = this.state;
      const { foods } = this.state;
      const { orders } = this.state;
      const { searchQuery } = this.state;
      const { checkedCategories } = this.state;
      const { loading,editPaymodeOrder } = this.state;
      const { isPrinted,ItemCodeOptions,selectedItemCode,orderHistoryItems } = this.state;

      console.log("shop Id:",localStorage.getItem('shopId')); 
      // console.log("email Id:",this.state.selectedData.mail); 
      
     // Filter orders based on the search query
    const filteredOrders = orders.filter((order) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        order.customer_name.toLowerCase().includes(searchLower) ||
        order.customer_phone.includes(searchQuery) ||
       ( order.order_no + '').includes(searchQuery) ||
        order.order_items.some((item) =>
          item.food_name.toLowerCase().includes(searchLower)
        )
      );
    });

    // Check if there are no matching results
const noResults = orderHistoryItems.length === 0;

const CartTotal = this.state.cartItems.reduce((accumulator, cartItem) => {
  return accumulator + cartItem.quantity * cartItem.food.price;
}, 0);


// Calculate the total amount from selectedAmounts
const modeTotal = this.state.selectedAmounts.reduce((accumulator, amount) => {
  return accumulator + amount;
}, 0);


const editModeTotal = this.state.editSelectedAmounts && this.state.editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0)
     


 

   

    
//     const collectionName = 'jaya';
// const documentName = 'twenty'; // The document name you want to find

// // Create a query to search for the document by its name
// const q = query(collection(db, collectionName));

// // Execute the query and get the matching documents
// getDocs(q)
//   .then((querySnapshot) => {
//     let foundDocumentId = null;
//     querySnapshot.forEach((docSnapshot) => {
//       const documentData = docSnapshot.data();
//       if (documentData && docSnapshot.id === documentName) {
//         // Document with the given name found
//         foundDocumentId = docSnapshot.id;
//         console.log('Document ID:', foundDocumentId);
//         console.log('Document Data:', documentData);
//       }
//     });

//     if (foundDocumentId === null) {
//       console.log('No document found with the given name.');
//     }
//   })
//   .catch((error) => {
//     console.error('Error fetching documents:', error);
//   });




  return (
    
    <>
       <div id="sbfix" style={{position:"fixed", left: "43%", transform: "translateX(-50%)" }}>
    <h1 class="sbbanner" style={{textTransform:"uppercase",maxWidth: "90vw", fontSize: "clamp(6px, 4vw, 25px)", whiteSpace: "nowrap" }}><div class="sbglow">&nbsp;</div>{ shopName }</h1>
   </div>
  <div style={{display: 'flex', alignItems: 'center', padding: '5px', marginLeft: '10px', marginTop: '5px', borderRadius: '5px', position: 'fixed', zIndex: '2'}}>
    
  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleNav} className="sbcustom-dropdown">
  <DropdownToggle className='sbwine'> <i class="bi bi-list-stars" style={{fontSize:"23px"}}></i> </DropdownToggle>
  <DropdownMenu>
         <DropdownItem className="sbdropItem" onClick={this.routeSbReports}>
           Reports
         </DropdownItem>

          <DropdownItem className="sbdropItem" onClick={this.routeAttendance}>
            Attendance Breaks
          </DropdownItem>


          {/* <DropdownItem className="sbdropItem" onClick={this.toggleMastersMenu}>MASTERS
          {this.state.isMastersMenuOpen ? <i class="bi bi-caret-up-fill" style={{marginLeft:"7px"}}></i> : <i class="bi bi-caret-down-fill" style={{marginLeft:"7px"}}></i>} </DropdownItem> 
          
          {this.state.isMastersMenuOpen && (
           
             <>
              <DropdownItem className="sbdropItem" onClick={this.routeShop}>
                SHOP MASTER
              </DropdownItem>
              <DropdownItem className="sbdropItem" onClick={this.routeUom}>
                UOM MASTER
              </DropdownItem>
              <DropdownItem className="sbdropItem" onClick={this.routeChange}>
                CATEGORY MASTER
              </DropdownItem>
              <DropdownItem className="sbdropItem" onClick={this.routeTablemaster}>
                TABLE MASTER
              </DropdownItem>
              <DropdownItem className="sbdropItem" onClick={this.routeSubKitchenmaster}>
                SUB KITCHEN MASTER
              </DropdownItem>
              <DropdownItem className="sbdropItem" onClick={this.routeEmployee}>
                EMPLOYEE MASTER
              </DropdownItem>
              <DropdownItem className="sbdropItem" onClick={this.routeSupplier}>
                SUPPLIER MASTER
              </DropdownItem>
              <DropdownItem className="sbdropItem" onClick={this.routeRawMaterial}>
                RAW MATERIAL MASTER
              </DropdownItem>
             </>
          )} */}

        </DropdownMenu>
      </Dropdown>
      <div className="form-check form-switch" style={{ marginLeft: "30px" }}>
  <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{ float: 'left', marginRight: '40px', textTransform:"uppercase",fontWeight:"bolder",color:"rgb(230, 70, 187)" }}>Image Status</label>
  <input 
   className="form-check-input"
   type="checkbox"
   role="switch"
   id="flexSwitchCheckDefault"
   style={{ backgroundColor: 'rgb(230, 70, 187)', borderColor: 'rgb(230, 70, 187)', float: 'right' }}
   checked={this.state.imageStatus}
   onChange={this.handleSwitchToggle} />
</div>
 
  </div>

  <Dropdown isOpen={this.state.shadropdownOpen} toggle={this.toggleChange} className="sbcustom-dropdown">
  <DropdownToggle className='sbwine' style={{marginLeft:"1200px",backgroundColor:"white",border:"none",position:"fixed"}}><i class="bi bi-shop-window" style={{fontSize:"35px",color:"rgb(230, 70, 187)"}}></i></DropdownToggle>
  
  <DropdownMenu >
  {users.map(user=>(
    <DropdownItem key={user.id} className="sbdropItem" onClick={() => this.handleNameSelection(user)}>{user.shop_name}</DropdownItem>
    ))}
  </DropdownMenu>
 
  </Dropdown>

  <button className="btn btn-outline-success" style={{position:"fixed",marginLeft:"1400px",marginTop:"20px",backgroundColor:"rgb(230, 70, 187)",borderColor:"rgb(230, 70, 187)",color:"white"}} onClick={this.handleOnclick}>Logout</button>

    {/* <div id='header' style={{backgroundColor:"rgba(10, 241, 33, 0.133)"}}>
      <nav>
        <ul id="capt">
          <li id="first">BILLS</li>
          <li id="second">
          <button style={{border:"none",backgroundColor:"rgba(10, 241, 33, 0.133)",textShadow: '2px 2px 2px black'}}
              
              onClick={this.routeChance}
            >
             KITCHEN
              </button>
          </li>
          <li id='third'>
          <button style={{border:"none",backgroundColor:"rgb(230, 70, 187)",textShadow: '2px 2px 2px black'}}
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
             CATEGORIES
              </button>
          </li>
          <li id="four">
          <button style={{border:"none",backgroundColor:"rgb(230, 70, 187)",textShadow: '2px 2px 2px black'}}
             onClick={this.routeChange}>
             MASTER
              </button>
          </li>
          <li id="five">
          <button style={{border:"none",backgroundColor:"rgb(230, 70, 187)",textShadow: '2px 2px 2px black'}}
             onClick={this.routeDay}>
             DELIVERY
              </button>
          </li>
        </ul>
      </nav>
    </div> */}



  
    <div id='sbleft-side' style={{zIndex:"1"}}>

    <br/>
   <h4 id="sbhefo"><b>Booking Cart</b></h4>
 
    {/* <button className={!this.state.isChecked ? "btn btn-secondary" : "btn btn-success"} onClick={() => this.setState({ isChecked: true})}>Normal Order</button> 
         <button className={this.state.isChecked ? "btn btn-secondary" : "btn btn-success" } onClick={() => this.setState({ isChecked: false})}>Credit Order</button> */}

       <div id="sbleftcont">
         <div style={{backgroundColor:"rgb(230, 70, 187)",padding:"10px"}}>
          {/* <select hidden={this.state.isChecked}  name="selectedCreditCustomer" id="selectedCreditCustomer">
         {this.state.mobileInput.map((user)=>(
          
          <option value={JSON.stringify(user)} >{user.name} {user.phone_no}</option>

        
         ))}
         </select> 
          
          <Input hidden={!this.state.isChecked} type="text" id="name" placeholder="Name" required />
        <Input hidden={!this.state.isChecked} type="number" id="mobile" placeholder="Mobile No" maxLength="10" style={{marginTop:"2px"}} required/>
         */}

{this.state.isChecked ? (
  <div>
  <Input hidden={!this.state.isChecked} type="text" id="name" placeholder="Name" required />
   <Input hidden={!this.state.isChecked} type="number" id="mobile" placeholder="Mobile No" maxLength="10" style={{marginTop:"2px"}} required/>
 </div>
 
) : (
  <SearchableDropdown onSelect={this.handleDropdownChange} selectedValue={this.state.selectedData}/>
  
)}
   {/* <Select
    options={ItemCodeOptions}
    placeholder="Select Item Code"
    value={selectedItemCode}
    onChange={this.handleCodeSelectChange}
    isSearchable={true}
    className="customSelect"
  />  <span><button className="btn btn-primary" onClick={this.handleAddByItemCode} style={{float:"right",marginTop:"-38px"}}>Add</button></span>  */}

{this.state.selectedData.iden  && !this.state.isChecked &&
  <h6 style={{paddingTop:"5px", color:"white"}}>maximum Credit limit: {" "}{this.state.selectedData && this.state.selectedData.maxCredit && this.state.selectedData.maxCredit.toFixed(3)} <br/><span>Outstanding Payment : {this.state.amountDetail && this.state.amountDetail.balance && this.state.amountDetail.balance.toFixed(3)} 
  </span></h6>}
  <h6 style={{paddingTop:"5px", color:"white"}}>No.of Services:1
  {/* <span>
  <div class="form-check form-check-inline" style={{float:"right",margin:"0px 5px 0px 5px"}}>
  <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          value="option1"
          
          checked={this.state.isParcelChecked}
          
          onChange={this.handleCheckboxChange}
        />
                  <label class="form-check-label text-light" for="inlineCheckbox1">Parcel</label>
                </div>
  </span> */}
  <br/><span>Total: 20 SGD
  {/* <span style={{marginLeft:"5px"}} ><button className="btn btn-primary" style={{borderRadius:"15px",padding:"1px 5px 1px 5px",fontSize:"15px"}} onClick={this.handletoOpenInstantFood}><i class="bi bi-plus-circle-fill"></i>{" "}Instant Food</button></span> */}
  </span>
 
  </h6>
  

 
  
{/* {this.state.selectedData && (
          <div style={{color:"white"}}>
            Mobile.No: {this.state.selectedData.value}
          </div>
        )} */}
           
         </div>
         <div id="sbkbox">
         
       
           <div id="sbbox1" >
           
             <table>
             
              <tbody >

               <tr>
             
                 <td style={{color:"rgb(230, 70, 187)",textShadow:"1px 1px 1px black",fontSize:"17px"}}>
                Basic Facial
                 </td>  <i class="bi bi-x-circle-fill" style={{marginLeft:"100px",float:"right"}}></i>

                 {/* <td style={{fontSize:"15px",textAlign:"right",width:"70%",marginLeft:"80px",float:"right",marginTop:"15px"}}>
                 20 mins
                 </td> */}
                
               </tr>
               <tr>
                 <td colSpan='2'>price: <span style={{color:"rgb(230, 70, 187)",fontSize:"15px"}}>
                 20 SGD
                  </span> <span>{" "}Total Price: <b>  20 SGD</b> </span></td>
               </tr>
                
              </tbody>
            
             </table>
               
           </div>
           
           <br/>
          

         </div>
         {this.state.isChecked}
         <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: !this.state.isChecked ? '10px' : '0'  }}>
   
       
        {isLogin ? (
          <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        ) :  this.state.isChecked ? 
        <button onClick={this.handleGenerateOrderModal} className="btn btn-success" style={{ marginRight: '10px',backgroundColor:"rgb(230, 70, 187)",borderColor:"rgb(230, 70, 187)" }}>Place Booking</button> 
        : (<button onClick={this.handleGenerateNormalOrderModal} className="btn btn-success" style={{ marginRight: '10px' }}>Normal Order</button>) }
        
    
    {!this.state.isChecked && (
        
          iscredit ? (
          <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        ) : <button disabled={this.state.isChecked} className="btn btn-danger" onClick={this.handleGeneratecreditOrderModal}>Credit Order</button>
            
        
    )}
</div>

       
          
       </div>
       {/* <table style={{marginTop:"-60px"}}>
         <tr >
           <td>Item No</td>
           <td style={{fontSize:"20px",color:"rgb(230, 70, 187)",position:"absolute",left:"230px"}}></td>
         </tr>
         <tr >
           <td>Total</td>
           <td style={{fontSize:"19px",color:"rgb(230, 70, 187)",position:"absolute",left:"200px"}}></td>
         </tr>
       </table>
       <br/><br/> */}
      
    
    {/* <br/>
   
      <h5>Orders</h5>
     {customers.map((customer) =>(
        <React.Fragment key={customer.id}>
          <div id="leftcont">
            <div style={{backgroundColor:"rgb(230, 70, 187)",padding:"5px"}}>
              <h3 style={{color:"white",textAlign:"left",textShadow:"2px 2px 2px black",height:"50px"}}>{customer.data.name}<span style={{fontSize:"11px"}}>{customer.data.phone}</span></h3>
            </div>
            {Object.values(customer.data).map((fieldValue) => {
               if (Array.isArray(fieldValue)) {
                return (
            <div id="kbox" key={fieldValue}>
              {fieldValue.map((item, index) => (
              <div id="box1" >
               
                <table>
                  <tr key={index}>
                    <td style={{color:"rgb(230, 70, 187)",textShadow:"1px 1px 1px black",fontSize:"17px"}}>{item.item_name} {" "} </td>
                    <td style={{fontSize:"15px"}}>{" "} - {item.quantity}</td>
                  </tr>
                  <tr>
                    <td>unit price: <span style={{color:"rgb(230, 70, 187)",fontSize:"15px"}}>{' '}INR. {item.price}</span></td>
                  </tr>
                </table>
                
              </div>
               ))}
              <br/>
            </div>
              );
            }
            return null;
           })}
          </div>
          <table style={{marginTop:"-60px"}}>
            <tr >
              <td>Item No</td>
              <td style={{fontSize:"20px",color:"rgb(230, 70, 187)",position:"absolute",left:"230px"}}>{customer.data.item_no}</td>
            </tr>
            <tr >
              <td>Total</td>
              <td style={{fontSize:"19px",color:"rgb(230, 70, 187)",position:"absolute",left:"200px"}}>INR. {customer.data.total}</td>
            </tr>
          </table>
          <br/><br/>
          <button id="press" onClick={this.handleGenerateNo}>Generate no</button>
        </React.Fragment>
      ))
     } */}
     </div>







  
    <div id='sbmain-side'>
    <TabContent activeTab={this.state.activeTab}>
    <TabPane tabId="2">
            <Row>
              <Col md="12">
                <div style={{width:"50%",margin:"auto"}}>
                <ul style={{textAlign:"center"}}>
                {categories.map((category) => (
                  <li key={category.id} className="sblisting" style={{border:"1px solid rgb(230, 70, 187)",padding:"5px",marginTop:"5px",fontWeight:"bold",listStyleType:"none",textTransform:"capitalize"}}>
                    <a href='#' className="sblinking" onClick={() => this.handleListClick(category.id)}>{category.category_name}
                    <span style={{fontSize:"8px",letterSpacing:"1px"}}>({category.category_code})</span>
                    </a></li>
                  ))}
                </ul>
                </div>
              </Col>
            </Row>
          </TabPane>
      </TabContent>
    <section className="food-area my-0">
      <div className="container" style={{width:"900px",padding:"10px"}}>
      {/* {loading ? (
        <div className="fooder-container">
      	  <div className="fooddinner"></div>
        </div>
      ) : (
      
      foods.map((food) => (
        <> */}
        <h5 style={{color:"rgb(230, 70, 187)",fontFamily:"serif",fontWeight:"bolder"}}>Facial</h5>
        <div className="row" id="sbmafix" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: '25px' }}>
    {/* {food.sub_food.map((item, index) => ( */}
        <div className="col mb-1 mt-1 ftco-animate"style={{ flex: '0 0 auto', maxWidth: '12rem' }}>
            <div className="card" id="sbeffe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"-8px" }}>
              <img src={basic} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> 
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"rgb(230, 70, 187)", textTransform:"capitalize", fontSize:"18px"}}>Basic Facial</h6>
                    <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>Duration: <b> 60 min</b> </p>
                    <h6  style={{color:"rgb(230, 70, 187)",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> 20 SGD </h6>
                    {/* {
                      item.is_active === 1 ?   */}
                      <button className="btn" style={{backgroundColor:"rgb(230, 70, 187)", color:"white",padding:"5px"}} 
                      // onClick={() => this.addToCart(item)}
                      ><i className="bi bi-cart3"></i> Add to Cart</button> 
                      {/* : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
        <div className="col mb-1 mt-1 ftco-animate"style={{ flex: '0 0 auto', maxWidth: '12rem' }}>
            <div className="card" id="sbeffe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"-8px" }}>
              <img src={delux} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> 
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"rgb(230, 70, 187)", textTransform:"capitalize", fontSize:"18px"}}>Delux Facial</h6>
                    <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>Duration: <b> 120 min</b> </p>
                    <h6  style={{color:"rgb(230, 70, 187)",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> 40 SGD </h6>
                    {/* {
                      item.is_active === 1 ?   */}
                      <button className="btn" style={{backgroundColor:"rgb(230, 70, 187)", color:"white",padding:"5px"}} 
                      // onClick={() => this.addToCart(item)}
                      ><i className="bi bi-cart3"></i> Add to Cart</button> 
                      {/* : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
        <div className="col mb-1 mt-1 ftco-animate"style={{ flex: '0 0 auto', maxWidth: '12rem' }}>
            <div className="card" id="sbeffe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"-8px" }}>
              <img src={pamper} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> 
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"rgb(230, 70, 187)", textTransform:"capitalize", fontSize:"18px"}}>Pampering Facial</h6>
                    <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>Duration: <b> 160 min</b> </p>
                    <h6  style={{color:"rgb(230, 70, 187)",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> 60 SGD </h6>
                    {/* {
                      item.is_active === 1 ?   */}
                      <button className="btn" style={{backgroundColor:"rgb(230, 70, 187)", color:"white",padding:"5px"}} 
                      // onClick={() => this.addToCart(item)}
                      ><i className="bi bi-cart3"></i> Add to Cart</button> 
                      {/* : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
    {/* ))} */}
</div>

<h5 style={{color:"rgb(230, 70, 187)",fontFamily:"serif",fontWeight:"bolder"}}>Skin Treatment</h5>
        <div className="row" id="sbmafix" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: '25px' }}>
    {/* {food.sub_food.map((item, index) => ( */}
        <div className="col mb-1 mt-1 ftco-animate"style={{ flex: '0 0 auto', maxWidth: '12rem' }}>
            <div className="card" id="sbeffe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"-8px" }}>
              <img src={skin1} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> 
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"rgb(230, 70, 187)", textTransform:"capitalize", fontSize:"18px"}}>Skin1</h6>
                    <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>Duration: <b> 30 min</b> </p>
                    <h6  style={{color:"rgb(230, 70, 187)",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> 10 SGD </h6>
                    {/* {
                      item.is_active === 1 ?   */}
                      <button className="btn" style={{backgroundColor:"rgb(230, 70, 187)", color:"white",padding:"5px"}} 
                      // onClick={() => this.addToCart(item)}
                      ><i className="bi bi-cart3"></i> Add to Cart</button> 
                      {/* : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
        <div className="col mb-1 mt-1 ftco-animate"style={{ flex: '0 0 auto', maxWidth: '12rem' }}>
            <div className="card" id="sbeffe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"-8px" }}>
              <img src={skin2} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> 
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"rgb(230, 70, 187)", textTransform:"capitalize", fontSize:"18px"}}>Skin2</h6>
                    <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>Duration: <b> 20 min</b> </p>
                    <h6  style={{color:"rgb(230, 70, 187)",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> 20 SGD </h6>
                    {/* {
                      item.is_active === 1 ?   */}
                      <button className="btn" style={{backgroundColor:"rgb(230, 70, 187)", color:"white",padding:"5px"}} 
                      // onClick={() => this.addToCart(item)}
                      ><i className="bi bi-cart3"></i> Add to Cart</button> 
                      {/* : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
        <div className="col mb-1 mt-1 ftco-animate"style={{ flex: '0 0 auto', maxWidth: '12rem' }}>
            <div className="card" id="sbeffe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"-8px" }}>
              <img src={skin3} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> 
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"rgb(230, 70, 187)", textTransform:"capitalize", fontSize:"18px"}}>Skin3</h6>
                    <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>Duration: <b> 30 min</b> </p>
                    <h6  style={{color:"rgb(230, 70, 187)",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> 30 SGD </h6>
                    {/* {
                      item.is_active === 1 ?   */}
                      <button className="btn" style={{backgroundColor:"rgb(230, 70, 187)", color:"white",padding:"5px"}} 
                      // onClick={() => this.addToCart(item)}
                      ><i className="bi bi-cart3"></i> Add to Cart</button> 
                      {/* : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
    {/* ))} */}
</div>
       
        {/* </>
        ))
        
     
      )} */}
       </div>
      
    </section>

       {/* {foods.length === 0 && checkedCategories && (
        
    <div className="container" style={{ textAlign: "center", marginTop: "20px" }}>
      <div className="col mb-1 mt-1 ftco-animate" >
        <div className="card" style={{ width: "12rem",height:"20rem",padding:"10px",marginBottom:"-27px",float:"left",boxShadow:"inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)"}}>
          <h5 onClick={this.handleAddCard} style={{color:"rgb(230, 70, 187)",padding:"15px",fontFamily:" 'DM Serif Display', serif",marginTop:"93px"}}> <i className="bi bi-plus-circle"></i> Add Categories</h5>
        </div>
      </div>
    </div>
  )} */}
    </div>




    <div id="sbhinFood">
   <h5 style={{color:"black",fontWeight:"bolder",letterSpacing:"1px"}} id="hefo" >Customer History
   <span >
     <button className="btn btn-danger text-white p-1" style={{fontSize:"15px"}}  onClick={this.handleOpenOrderSumModal}>Summary</button>
     </span> 
   </h5>
   {/* <div style={{ display: 'flex', alignItems: 'center', gap:"5px" }}>
   
</div> */}
   
   <div style={{ display: 'flex', alignItems: 'center', gap:"10px" }}>
   {/* <label style={{fontFamily:"serif",fontWeight:"bolder"}}>Type: 
    <span className="text-primary" onClick={this.handleOpenSelectDate}>{this.state.startDate} to {this.state.endDate}</span> 
    </label> */}
   
   
      <input type='date' name='date'  style={{border:"2px ridge rgb(230, 70, 187)",borderRadius:"15px",height:"35px",padding:"10px"}} />
     <Select
        options={this.state.labelCodeOptions}
        placeholder="Select Order"
        // value={this.state.selectedOrderLabel}
        // onChange={this.handleOrderLabelSelectChange}
        isSearchable={true}
        menuPlacement="top"
        className="sbcustomSelectFood"
        styles={{ menu: (provided, state) => ({
          ...provided,
          zIndex: 9999 // Increase the z-index to ensure the dropdown is displayed above other elements
      }) }}
       
    />
   
</div>
   </div>






    
    <div id='sbright-side'>
   
    <div id="sssb">
      {/* {
        this.state.orderLoading ? (
          <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        ) : (
          noResults ? (
            <p style={{textAlign:"center",fontWeight:"bolder"}}>Today No Booking</p>
          ) : (
            orderHistoryItems.slice().reverse().map((order, index) => ( */}
              <div  style={{marginTop:"-10px"}}>
                <Bill/>
                <div style={{display: "flex", justifyContent: "center" }}>
               
                {/* <ReactToPrint
                  trigger={() => (
                    <div style={{ textAlign: 'center' }}>
                        <button className='btn-md btn-primary' style={{ borderRadius: "5px", border: "none", padding: "7px", margin:"5px", padding:"0px auto" }}><i className="bi bi-printer-fill"></i></button>
                    </div>
                )}
                  content={() => this[`componentRef${index}`]}
                  onAfterPrint={() => this.handlePrint(order.customer_id,order.id)}
                /> */}
                
                {/* <hr style={{border:"1px dashed grey",position:"relative",bottom:"110px"}}/> */}
                {/* <button className="btn-md btn-success" style={{marginRight:"4px",borderRadius:"5px",border:"none",padding:"7px"}} 
                onClick={() => this.handlePaidStatus(order.customer_id,order.id)} disabled={order.paid_status === 1}
                >{ order.paid_status === 1 ? "Paid" : "Pay" }</button>
                <button className={`btn-md btn-danger ${order.paid_status === 2 ? 'smallText' : '' }`} style={{marginRight:"4px",borderRadius:"5px",border:"none",padding:"7px"}}
                onClick={() => this.handleCancelStatus(order.customer_id,order.id)} disabled={order.paid_status === 2}
                >{ order.paid_status === 2 ? "Cancelled" : "Cancel" }</button> */}
    
               {/* { order.parcel_status === 0 &&
                <button className="btn-md btn-warning" onClick={()=> this.updateParcelInfo(order.customer_id,order.id)} style={{marginTop:"10px",borderRadius:"5px",border:"none",padding:"7px"}}>Parcel</button>
               } */}
              {/* {
               (order.pay_modes.some(mode => mode.amount > 0 && (mode.pay_mode_name === "CREDIT ORDER" || mode.pay_mode_name === "GROUP LOCATION CREDIT ORDER"))) ? null :
                <div style={{ textAlign: 'center' }}>
                <button className="btn btn-danger" style={{borderRadius: "5px", border: "none", margin:"5px", padding:"0px 6px" }} onClick={() => this.handleOpenEditPayModal(order)}><i class="bi bi-pencil-square" style={{fontSize:"14px"}}></i></button>
                </div>
              } */}
               </div>
               <br/>
               
                </div>
            
            {/* ))
            )

        )

      } */}
    
        
       
    </div>
      
    {/* {showTable && ( */}
    {/* <div id="sss">
    {orders.map((order, index) => (
      <div key={index} style={{backgroundColor:"rgba(63, 68, 63, 0.082)",padding:"0px 10px"}}>
      <hr style={{border:"1px dashed grey",position:"relative",top:"123px"}}/>
        <h4 style={{textAlign:"center"}}>order no: <span style={{color:"rgb(230, 70, 187)",fontSize:"20px"}}>{order.order_no}</span></h4>
 
        <table>
     
      <caption style={{captionSide:"top",fontSize:"13px",fontWeight:"bolder"}}>Name: <span style={{color:"rgb(230, 70, 187)",fontSize:"16px",textTransform:"capitalize"}}>{order.customer_name}</span><br/>Phone No: <span style={{color:"rgb(230, 70, 187)",fontSize:"16px"}}>{order.customer_phone}</span>
      </caption>
        <thead>
          <th style={{padding:"5px",color:"black"}}>item</th>
          <th style={{padding:"5px",color:"black"}}>quantity</th>
          <th style={{padding:"5px",color:"black"}}>price(INR)</th>
        </thead>

        <tbody>
          {order.order_items.map((item, itemIndex) => (
          <tr key={itemIndex}>
            <td style={{padding:"5px",color:"grey",textAlign:"center"}}>{item.food_name}</td>
            <td style={{padding:"5px",color:"grey",textAlign:"center"}}>{item.quantity}</td>
            <td style={{padding:"5px",color:"grey",textAlign:"center"}}>{item.unit_price}</td>
          </tr>
          
          ))}
        </tbody>
        <tr>
        <td colspan="3" style={{padding:"5px",color:"black",textAlign:"right",fontWeight:"bolder"}}>Total: {order.total}</td>
        </tr>
      </table>
      <button className="btn btn-success">Print</button>
      <hr style={{border:"1px dashed grey",position:"relative",bottom:"90px"}}/>
    
      </div>
    ))}
      
      </div>

   */}

<Modal
        show={this.state.show}
        onHide={this.handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(230, 70, 187, 0.367)",padding:"12px"}}>
          <Modal.Title  style={{fontFamily:"serif",fontWeight:"bolder",marginLeft:"190px"}}>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px",backgroundColor:"white"}}>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure You want to Logout ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={this.handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={this.handleModLogout}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>



      
      
      <Modal
        show={this.state.isOpenSelectDate}
        onHide={this.handleCloseDateConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            
            <div className="col">
              <label style={{color:"rgb(230, 70, 187)", fontFamily:"serif", fontWeight:"bolder"}}>Start Date:</label>
              <input type='date' name='date'  style={{border:"2px ridge rgb(230, 70, 187)",borderRadius:"15px",height:"35px",padding:"10px"}}
              placeholder='choose date' onChange={this.handleStartDateChange} value={this.formatDate(this.state.startDate)} />{" "}
            </div>
            <div className="col">
              <label style={{color:"rgb(230, 70, 187)", fontFamily:"serif", fontWeight:"bolder"}}>End Date:</label>
              <input type="date" name='date'  placeholder="choose date" onChange={this.handleEndDateChange} 
                 style={{border:"2px ridge rgb(230, 70, 187)",borderRadius:"15px",height:"35px",padding:"10px"}} value={this.formatDate(this.state.endDate)}
              />
            </div>
          </div>
     <br/>
         <div style={{textAlign:"center"}}>
         <Button variant="danger"  onClick={this.handleCloseDateConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{marginRight:"5px"}} onClick={this.handleSelectDateRange}>
            Ok
          </Button>
         </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={this.state.isOpenOrderSummary}
        onHide={this.handleCloseSummaryConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(234, 195, 223, 0.367)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}> Today Booking Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h5 style={{fontFamily:"serif",fontWeight:"bolder",color:"rgb(230, 70, 187)",textAlign:"center"}}>Total Booking:{" "} 
              <span style={{textAlign:"center",color:"black",fontSize:"18px"}}>4</span></h5>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between",padding:"0px 20px"}}>
  <h5 style={{ fontFamily: "serif", fontWeight: "bolder", color: "rgb(230, 70, 187)" }}>
    Room Booking: {" "}
    <span style={{ textAlign: "center", color: "black", fontSize: "18px" }}>2</span>
  </h5>

  <h5 style={{ fontFamily: "serif", fontWeight: "bolder", color: "rgb(230, 70, 187)" }}>
    Seat Booking: {" "}
    <span style={{ textAlign: "center", color: "black", fontSize: "18px" }}>2</span>
  </h5>
</div>

     <br/>
         <div style={{textAlign:"center"}}>
         <Button variant="danger"  onClick={this.handleCloseSummaryConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={this.state.isNormalModal}
        onHide={this.handleCloseNormalConfirmation}
        backdrop="static"
        keyboard={false}
        size="xl" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(230, 70, 187, 0.367)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}> Select Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auto",padding:"10px 20px 10px 20px"}}>
        <h5 style={{color:"rgb(230, 70, 187)",fontFamily:"serif",fontWeight:"bolder"}}>Rooms</h5>
        <div className="row" id="sbmafix" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: '25px' }}>
    {/* {food.sub_food.map((item, index) => ( */}
        <div className="col mb-1 mt-1 ftco-animate"style={{ flex: '0 0 auto', maxWidth: '12rem' }}>
            <div className="card" id="sbeffe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"-8px" }}>
              <img src={room1} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> 
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"rgb(230, 70, 187)", textTransform:"capitalize", fontSize:"18px"}}>Room1</h6>
                    <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>Desc: <b> A/C</b> </p>
                    {/* <h6  style={{color:"rgb(230, 70, 187)",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> 20 SGD </h6> */}
                    {/* {
                      item.is_active === 1 ?   */}
                      <button className="btn" style={{backgroundColor:"rgb(230, 70, 187)", color:"white",padding:"5px"}} 
                      onClick={() => this.addToCart()}
                      > Select</button> 
                      {/* : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
        <div className="col mb-1 mt-1 ftco-animate"style={{ flex: '0 0 auto', maxWidth: '12rem' }}>
            <div className="card" id="sbeffe" style={{ width: '12rem', height:"auto", padding:"10px", marginTop:"-8px" }}>
              <img src={room2} alt='image' height="8rem" style={{ height:"8rem", marginTop:"1px" }} /> 
                <div style={{textAlign:"center", padding:"10px"}}>
                    <h6 className="card-title" style={{color:"rgb(230, 70, 187)", textTransform:"capitalize", fontSize:"18px"}}>Room2</h6>
                    <p style={{textAlign:"center", fontSize:"13px", margin:"3px 8px"}}>Desc: <b> Non - A/C</b> </p>
                    {/* <h6  style={{color:"rgb(230, 70, 187)",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}> 20 SGD </h6> */}
                    {/* {
                      item.is_active === 1 ?   */}
                      <button className="btn" style={{backgroundColor:"rgb(230, 70, 187)", color:"white",padding:"5px"}} 
                      onClick={() => this.addToCart()}
                      > Select</button> 
                      {/* : 
                      <span className="badge rounded-pill bg-danger p-2" style={{fontWeight:"normal",fontSize:"15px"}}><i class="bi bi-exclamation-triangle"></i>{" "}Not Available</span>
                    } */}
                   
                </div>
            </div>
        </div>
        
    {/* ))} */}
</div>

       
          {/* {
            isLogin ? (
              <div>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
            ) :  <Button variant="success"  onClick={this.generateOrder} style={{float:"right"}}>Complete Order</Button>
          }
            
          
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Cust.Name: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{document.querySelector('#name') ? document.querySelector('#name').value : "-"}</span></h6>
         <h6 style={{fontWeight:"bolder", fontFamily:"serif"}}>Mobile No: <span style={{fontWeight:"normal", fontFamily:"serif"}}>{document.querySelector('#mobile') ? document.querySelector('#mobile').value : "-"}</span></h6>
         <h6 style={{fontFamily:"serif",fontWeight:"bolder", fontSize:"20px"}}>Bill Amount: <span style={{fontFamily:"monospace",fontWeight:"normal",color:"rgb(230, 70, 187)"}}>{this.state.cartItems.reduce((accumulator, cartItem) => {
      return accumulator + cartItem.quantity * cartItem.food.price;
    }, 0)}</span> <span style={{float:"right"}}>Paymode Total: <b style={{color: CartTotal === modeTotal ? "rgb(230, 70, 187)" : "red"}}>{this.state.selectedAmounts.reduce((accumulator, amount) => {
      return accumulator + amount;
    }, 0)}</b></span></h6>
         <br/>
       <div style={{marginTop:"-20px"}}>
       
       <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
    {
      this.state.payModes
      .filter(mode => mode.pay_mode_name !== "CREDIT ORDER" && mode.pay_mode_name !== "GROUP LOCATION CREDIT ORDER")
      .map((mode,index)=>(
    <tr key={index}>
      <td>
        <div class={`card ${this.state.selectedAmounts[index] > 0 ? 'bg-success' : ''} ${this.state.selectedAmounts[index] > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() => this.handleCardClick(mode.id)}>
          <div class="card-body" style={{textAlign:"center"}}>
            {mode.pay_mode_name}
          </div>
        </div>
      </td>
      <td>
       <Input  type="number" id={`paymodeAmount${mode.id}`} placeholder="0" value={this.state.selectedAmounts[index]} onChange={(e) => this.handleAmountChange(index, e.target.value)} style={{width:"140px",margin:"auto"}} min="0" />    
      </td>
    </tr>
      ))
    }
    </tbody>
        </table>
       
    <div style={{ display: 'flex', alignItems: 'center' }}>
    <label style={{fontWeight:"bolder", fontFamily:"serif"}}>Remarks:</label>{" "}
       <Input  type="text" id="remark" placeholder="Remark" required  style={{width:"280px"}}/>    
    </div>
    
    
       </div>
        */}
     
         <div style={{textAlign:"center", marginTop:"5px"}}>
        {" "}
         <Button variant="danger"  onClick={this.handleCloseNormalConfirmation}>
            Close
          </Button>{' '} 
         
         </div>
          
        </div>
        </Modal.Body>
      </Modal>


      


      <Modal
        show={this.state.isNormalCreditModal}
        onHide={this.handleCloseNormalCreditConfirmation}
        backdrop="static"
        keyboard={false}
        size="lg" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(230, 70, 187, 0.367)",padding:"12px"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}>Update Room1   <i class="bi bi-calendar"></i> 26-04-2024 <i class="bi bi-clock"></i> 11:00 AM </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{padding:"10px"}}>
             

                  

            </div>

                  <div class="card" style={{ width: "30rem", margin: "auto", border: "1px solid rgb(230, 70, 187)", padding: "10px", height: "auto", backgroundColor:"rgba(234, 195, 223, 0.367)",borderRadius:"15px" }} >
                  <div style={{ display: 'flex', alignItems: 'center', gap:"10px", margin:"auto" }}>
    <label style={{fontFamily:"serif",fontWeight:"bolder"}}>Select Beautician: </label>
    <Select
        options={[
            { value: 'Kalpana', label: 'Kalpana' },
            { value: 'Jenie', label: 'Jenie' },
            { value: 'Maria', label: 'Maria' },
            { value: 'Rani', label: 'Rani' },
            { value: 'Sheela', label: 'Sheela' }
        ]}
        placeholder="Select Beautician"
        onChange={this.handleOrderLabelSelectChange}
        isSearchable={true}
        menuPlacement="top"
        className="sbcustomSelectFood"
        styles={{ menu: (provided, state) => ({
            ...provided,
            zIndex: 9999 // Increase the z-index to ensure the dropdown is displayed above other elements
        }) }}
    />
   
</div>

                      <div class="card-body">
   
    <div className="row">
      <div className="col">
        <h6 style={{color:"rgb(230, 70, 187)"}}>Booked By:{" "}<b style={{color:"black"}}>vinoth</b> </h6>
      </div>
      <div className="col">
        <h6 style={{color:"rgb(230, 70, 187)"}}>Mobile: {" "}<b style={{color:"black"}}>9035678765</b></h6>
      </div>
    </div>
   <hr/>



<div class="card" style={{ width: "20rem", margin: "auto", border: "1px solid rgb(230, 70, 187)", padding: "5px", height: "auto", backgroundColor:"white",borderRadius:"15px" }} >
                 
                      <div class="card-body">
   <div className="row align-items-center">
        <div className="col-auto">
          <img src={basic} alt="Room" style={{ borderRadius: "50%", height: "50px", width: "50px" }} />
        </div>
        <div className="col">
          <span>Basic Facial <span style={{float:"right",fontSize:"18px",color:"rgb(236, 0, 173)"}}><i class="bi bi-trash"></i></span></span><br />
          <span style={{color:"black",fontWeight:"bolder"}}>12 SGD</span>

        </div>
      </div>
      </div>
      </div>
    <br/>
    <div className="text-center">
    <button className="btn btn-success" style={{color:"white", backgroundColor:"rgb(236, 0, 173)",borderColor:"rgb(236, 0, 173)"}} ><i class="bi bi-plus-circle"></i> Add Services</button>
    <br/>
    <br/>
      <button className="btn btn-success" > <i class="bi bi-pencil-square"></i> Update Booking</button>
      </div>
   
   
  </div>
 
</div>
<br/>


                
        </Modal.Body>
      </Modal>


      <Modal
        show={this.state.isCreditModal}
        onHide={this.handleCloseCreditConfirmation}
        backdrop="static"
        keyboard={false}
        size="xl" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(230, 70, 187, 0.367)",padding:"10px"}}>
        <Modal.Title style={{ fontSize: "19px", fontWeight: "bold" }}>
          <div className="d-flex align-items-center">
            <h6 className="mr-" style={{ whiteSpace: 'nowrap',fontWeight:"bolder" }}>Booking Date:</h6>
            <input
              type="date"
              className="form-control mr-2"
              id="floatingOpenTime"
              required
            />
            <span style={{ whiteSpace: 'nowrap',marginLeft:"180px" }}>Booking - Room & Slot</span>
          </div>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auto",padding:"20px"}}>
        <div className="row" style={{padding:"0px 30px",marginTop:"-25px"}} >
         
          
          <div style={{ display: 'flex', alignItems: 'center', gap:"10px", maxWidth:"400px", margin:"auto" }}>
            <h6 style={{fontWeight:"bolder"}}>Sitting Type: </h6>
    {/* First Card for Seat */}
    <div className="card" style={{ width: "auto",height:"auto",backgroundColor: this.state.isRoom ? "rgba(230, 70, 187, 0.367)":"white" }} 
    onClick={this.handleChangeRoom}>
        <div className="card-body">
            <h5 className="card-title">Room</h5>
        </div>
    </div>

    {/* Second Card for Room */}
    <div className="card" style={{ width: "auto",height:"auto", backgroundColor: this.state.isRoom ? "white":"rgba(230, 70, 187, 0.367)" }} onClick={this.handleChangeSeat}>
        <div className="card-body">
            <h5 className="card-title">Seat</h5>
        </div>
    </div>
</div>



<h6 style={{position:"relative", top:"90px", right:'55px',fontSize:"17px",fontWeight:"bolder" }}>10 AM</h6>
          <h6 style={{position:"relative", top:"180px", right:'55px',fontSize:"17px",fontWeight:"bolder" }}>11 AM</h6>
          <h6 style={{position:"relative", top:"270px", right:'55px',fontSize:"17px",fontWeight:"bolder" }}>12 PM</h6>
          <h6 style={{position:"relative", top:"360px", right:'55px',fontSize:"17px",fontWeight:"bolder" }}>1 PM</h6>


          { this.state.isRoom ? (
            <div className="row" style={{marginTop:"-100px"}}>
            <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card" style={{borderRadius:"20px",width:"300px",border:"none",height:"650px"}} >
              <div class="card-header" style={{border:"none",color:"rgb(230, 70, 187)",fontWeight:"bolder",fontSize:"18px",lineHeight:"12px"}}>
              <div className="row align-items-center">
        <div className="col-auto">
          <img src={room1} alt="Room" style={{ borderRadius: "50%", height: "50px", width: "50px" }} />
        </div>
        <div className="col">
          <span>Room1</span><br />
          <span style={{fontSize:"10px",color:"black"}}>A/C</span>
        </div>
      </div>
              </div>
              <div className="card-body" id="scr" style={{marginTop:"20px"}} >
              <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>JayaPriya</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>987654567
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}} onClick={this.handleOpenEyeOrderDetail}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-danger" style={{color:"white",fontWeight:"normal",float:"right"}}>Cancelled</span>
          <h6 style={{textAlign:"left"}}>Angel</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>875455678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-warning" style={{color:"white",fontWeight:"normal",float:"right"}}>Inprogress</span>
          <h6 style={{textAlign:"left"}}>Teena</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>7645676789
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2 m-auto">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",fontSize:"15px"}} onClick={this.handleOpenRoomConfirmation}><i class="bi bi-plus-circle"></i>{" "}Book Now</span>
          
          
             
          </Card.Body>
         
           </Card>
              
              </div>
            </div>
          </div>




          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card" style={{borderRadius:"20px",width:"300px",border:"none",height:"650px"}} >
              <div class="card-header" style={{border:"none",color:"rgb(230, 70, 187)",fontWeight:"bolder",fontSize:"18px",lineHeight:"12px"}}>
              <div className="row align-items-center">
        <div className="col-auto">
          <img src={room2} alt="Room" style={{ borderRadius: "50%", height: "50px", width: "50px" }} />
        </div>
        <div className="col">
          <span>Room2</span><br />
          <span style={{fontSize:"10px",color:"black"}}>Non-A/C</span>
        </div>
      </div>
              </div>
              <div className="card-body" id="scr">
              <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>Vidhya</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>8544567654
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>Daina</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>9876545678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>babby</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>96545678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2 m-auto">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",fontSize:"15px"}} onClick={this.handleOpenRoomConfirmation}><i class="bi bi-plus-circle"></i>{" "}Book Now</span>
          
          
             
          </Card.Body>
         
           </Card>
             
              </div>
            </div>
          </div>




          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card" style={{borderRadius:"20px",width:"300px",border:"none",height:"650px"}} >
              <div class="card-header" style={{border:"none",color:"rgb(230, 70, 187)",fontWeight:"bolder",fontSize:"18px",lineHeight:"12px"}}><div className="row align-items-center">
        <div className="col-auto">
          <img src={room1} alt="Room" style={{ borderRadius: "50%", height: "50px", width: "50px" }} />
        </div>
        <div className="col">
          <span>Room3</span><br />
          <span style={{fontSize:"10px",color:"black"}}>A/C</span>
        </div>
      </div>
              </div>
              <div className="card-body" id="scr">
              <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>Philomena</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>8544567654
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>Ayisha</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>9876545678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-warning" style={{color:"white",fontWeight:"normal",float:"right"}}>Inprogress</span>
          <h6 style={{textAlign:"left"}}>Jackshini</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>96545678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2 m-auto">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",fontSize:"15px"}} onClick={this.handleOpenRoomConfirmation}><i class="bi bi-plus-circle"></i>{" "}Book Now</span>
          
          
             
          </Card.Body>
         
           </Card>
             
              </div>
           </div>
          </div>
            </div>
          ) : (
            <div className="row" style={{marginTop:"-100px"}}>
            <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card" style={{borderRadius:"20px",width:"300px",border:"none",height:"650px"}} >
              <div class="card-header" style={{border:"none",color:"rgb(230, 70, 187)",fontWeight:"bolder",fontSize:"18px",lineHeight:"12px"}}>
              <div className="row align-items-center">
        <div className="col-auto">
          <img src={chair1} alt="Room" style={{ borderRadius: "50%", height: "50px", width: "50px" }} />
        </div>
        <div className="col">
          <span>Seat1</span><br />
          <span style={{fontSize:"10px",color:"black"}}>Gusion</span>
        </div>
      </div>
              </div>
              <div className="card-body" id="scr" >
              <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>JayaPriya</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>987654567
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}} onClick={this.handleOpenEyeOrderDetail}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-danger" style={{color:"white",fontWeight:"normal",float:"right"}}>Cancelled</span>
          <h6 style={{textAlign:"left"}}>Angel</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>875455678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-warning" style={{color:"white",fontWeight:"normal",float:"right"}}>Inprogress</span>
          <h6 style={{textAlign:"left"}}>Teena</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>7645676789
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2 m-auto">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",fontSize:"15px"}} onClick={this.handleOpenRoomConfirmation}><i class="bi bi-plus-circle"></i>{" "}Book Now</span>
          
          
             
          </Card.Body>
         
           </Card>
              
              </div>
            </div>
          </div>




          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card" style={{borderRadius:"20px",width:"300px",border:"none",height:"650px"}} >
              <div class="card-header" style={{border:"none",color:"rgb(230, 70, 187)",fontWeight:"bolder",fontSize:"18px",lineHeight:"12px"}}>
              <div className="row align-items-center">
        <div className="col-auto">
          <img src={chair2} alt="Room" style={{ borderRadius: "50%", height: "50px", width: "50px" }} />
        </div>
        <div className="col">
          <span>Seat2</span><br />
          <span style={{fontSize:"10px",color:"black"}}>Non-Gusion</span>
        </div>
      </div>
              </div>
              <div className="card-body" id="scr">
              <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>Vidhya</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>8544567654
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>Daina</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>9876545678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>babby</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>96545678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2 m-auto">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",fontSize:"15px"}} onClick={this.handleOpenRoomConfirmation}><i class="bi bi-plus-circle"></i>{" "}Book Now</span>
          
          
             
          </Card.Body>
         
           </Card>
             
              </div>
            </div>
          </div>




          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card" style={{borderRadius:"20px",width:"300px",border:"none",height:"650px"}} >
              <div class="card-header" style={{border:"none",color:"rgb(230, 70, 187)",fontWeight:"bolder",fontSize:"18px",lineHeight:"12px"}}><div className="row align-items-center">
        <div className="col-auto">
          <img src={chair3} alt="Room" style={{ borderRadius: "50%", height: "50px", width: "50px" }} />
        </div>
        <div className="col">
          <span>Seat3</span><br />
          <span style={{fontSize:"10px",color:"black"}}>Gusion</span>
        </div>
      </div>
              </div>
              <div className="card-body" id="scr">
              <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>Philomena</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>8544567654
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",float:"right"}}>Completed</span>
          <h6 style={{textAlign:"left"}}>Ayisha</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>9876545678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2">
          <span class="badge rounded-pill bg-warning" style={{color:"white",fontWeight:"normal",float:"right"}}>Inprogress</span>
          <h6 style={{textAlign:"left"}}>Jackshini</h6>
          <h6 style={{textAlign:"left",fontSize:"12px"}}>96545678
          <span style={{float:"right"}}><button className="btn btn-secondary" style={{ marginRight:"25px",paddingTop:"2px", paddingBottom:"2px"}}><i class="bi bi-eye-fill"></i></button></span>
          </h6>
          
             
          </Card.Body>
         
           </Card>
           <br/>
           <Card style={{borderRadius:"10px",width:"220px",marginBottom:"0px",marginTop:"12px",height:"80px", borderColor:"rgb(230, 70, 187)",borderLeft: "10px solid rgb(230, 70, 187)"}}> 
          
          <Card.Body className="p-2 m-auto">
          <span class="badge rounded-pill bg-success" style={{color:"white",fontWeight:"normal",fontSize:"15px"}} onClick={this.handleOpenRoomConfirmation}><i class="bi bi-plus-circle"></i>{" "}Book Now</span>
          
          
             
          </Card.Body>
         
           </Card>
             
              </div>
           </div>
          </div>
            </div>
          )
        }

        </div>
        </div>
        </Modal.Body>
      </Modal>










      <Modal
        show={this.state.isOrderConfirmation}
        onHide={this.handleCloseOrderConfirmation}
        backdrop="static"
        keyboard={false}
        size="lg" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{padding:"12px",textAlign:"center"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold",textAlign:"center"}}> Room1   <i class="bi bi-calendar"></i> 26-04-2024 <i class="bi bi-clock"></i> 11:00 AM </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <h6 style={{textAlign:"center", fontWeight:"bolder"}}>Booking Details  <span class="badge rounded-pill bg-success" style={{fontWeight:"normal",fontSize:"18px", marginLeft:"20px"}}>Completed</span></h6>
 
                   <div class="card" style={{ width: "30rem", margin: "auto", border: "1px solid rgb(230, 70, 187)", padding: "5px", height: "auto", backgroundColor:"rgba(234, 195, 223, 0.367)",borderRadius:"15px" }} >
                  
                       <div class="card-body">
                       <div className="row">
       <div className="col">
         <h6 style={{color:"rgb(230, 70, 187)"}}>Beautician:{" "}<b style={{color:"black"}}>Kalpana</b> </h6>
       </div>
       <div className="col">
       </div>
     </div>
                        
    
     <div className="row">
       <div className="col">
         <h6 style={{color:"rgb(230, 70, 187)"}}>Customer Name:{" "}<b style={{color:"black"}}>vinoth</b> </h6>
       </div>
       <div className="col">
         <h6 style={{color:"rgb(230, 70, 187)"}}>Mobile: {" "}<b style={{color:"black"}}>9035678765</b></h6>
       </div>
     </div>
    <hr/>
 
 
 
 <div class="card" style={{ width: "20rem", margin: "auto", border: "1px solid rgb(230, 70, 187)", padding: "5px", height: "auto", backgroundColor:"white",borderRadius:"15px" }} >
                  
                       <div class="card-body">
    <div className="row align-items-center">
         <div className="col-auto">
           <img src={basic} alt="Room" style={{ borderRadius: "50%", height: "50px", width: "50px" }} />
         </div>
         <div className="col">
           <span>Basic Facial</span><br />
           <span style={{color:"black",fontWeight:"bolder"}}>12 SGD</span>
         </div>
       </div>
       </div>
       </div>
    
   </div>
  
 </div>
 <br/>
 
 
      
        </Modal.Body>
      </Modal>


      <Modal
        show={this.state.isOpenAddInstant}
        onHide={this.handleCloseInstantAdding}
        backdrop="static"
        keyboard={false}
        size="lg" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{padding:"12px",textAlign:"center"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold",textAlign:"center"}}> Instant Food Adding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{margin:"auto",padding:"20px"}}>
        <div class="row">
         <div class="col">
            <label for="input1" style={{color:"rgb(230, 70, 187)",fontFamily:"serif",fontWeight:"bolder"}}>Food Name</label>
           <input type="text" class="form-control" placeholder="Enter food name" id="input1" name="food_name" style={{borderRadius:"12px"}} value={this.state.instantFoodName} onChange={this.handleInstandFoodChange} />
         </div>
         <div class="col">
           <label for="input3" style={{color:"rgb(230, 70, 187)",fontFamily:"serif",fontWeight:"bolder"}}>Unit Price</label>
           <input type="number" class="form-control" placeholder="Enter price" id="input3" name="quantity" style={{borderRadius:"12px"}} value={this.state.instantPrice} onChange={this.handleInstandPriceChange} required min="0.1"/>
         </div>
         <div class="col">
           <label for="input2" style={{color:"rgb(230, 70, 187)",fontFamily:"serif",fontWeight:"bolder"}}>Qty</label>
           <input type="number" class="form-control" placeholder="Enter quantity" id="input2" name="quantity" style={{borderRadius:"12px"}} value={this.state.instantQuantity} onChange={this.handleInstandQuantityChange} required min="1" />
         </div>
        </div>
        <br/>
        {
          this.state.instantQuantity && this.state.instantPrice &&
        <h6 style={{fontFamily:"monospace", fontWeight:"bolder", color:"blue"}}>Food Total: {this.state.instantQuantity * this.state.instantPrice}</h6>
    }
        </div>
        <div style={{textAlign:"center"}}>
        <Button variant="success"  onClick={this.handleToInstantAddingFood}>
            Add
          </Button>{' '} 
         
         <Button variant="danger"  onClick={this.handleCloseInstantAdding}>
            Close
          </Button>{' '} 
         
         </div>
        </Modal.Body>
      </Modal>



      <Modal
        show={this.state.isPayModeEdit}
        onHide={this.handleCloseaPayModeEdit}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{padding:"12px",textAlign:"center"}}>
        <Modal.Title style={{fontSize:"19px", fontWeight:"bold",textAlign:"center"}}>Update Paymode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <div className="p-3">
           
        {
  this.state.isPayModeEditLoading ? (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <Button variant="success"  onClick={this.handleUpdatePaymode} style={{float:"right"}}>
      <i className="bi bi-vector-pen"></i> Update
      </Button>
  )
}
<br/>
          <h6 style={{color:"blue", textAlign:"left"}}>Order No: <b>{editPaymodeOrder ? editPaymodeOrder.order_no: " "}</b></h6>
          <h6 style={{color:"rgb(230, 70, 187)"}}>Total Amount: <b>{editPaymodeOrder ? editPaymodeOrder.total : ""}</b> <span style={{float:"right"}}>Paymode Total: 
          <b style={{color: editPaymodeOrder && editPaymodeOrder.total == editModeTotal ? "rgb(230, 70, 187)" : "red"}}>{this.state.editSelectedAmounts ? this.state.editSelectedAmounts.reduce((sum, currentItem) => sum + currentItem.amount, 0) : ""}</b></span></h6>
          
          <div className="row">
            <div className="col">
            <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" style={{textAlign:"center"}}>Paymode</th>
      <th scope="col" style={{textAlign:"center"}}>Amount</th>
    </tr>
  </thead>
  <tbody>
    {
     this.state.editSelectedAmounts && this.state.editSelectedAmounts
      .filter(mode => mode.pay_mode_name !== "CREDIT ORDER" && mode.pay_mode_name !== "GROUP LOCATION CREDIT ORDER")
      .map((mode,index)=>(
    <tr key={index}>
      <td>
        <div class={`card ${this.state.editSelectedAmounts[index].amount > 0 ? 'bg-success' : ''} ${this.state.editSelectedAmounts[index].amount > 0 ? 'text-light' : ''}`} style={{width:"auto", height:"auto", margin:"0px"}} onClick={() => this.handleEditCardClick(mode.id, editPaymodeOrder && editPaymodeOrder.total)}>
          <div class="card-body" style={{textAlign:"center"}}>
            {mode.pay_mode_name}
          </div>
        </div>
      </td>
      <td>
       <Input  type="number" id={`paymodeEditAmount${mode.id}`}  value={mode.amount} onChange={(e) => this.handleEditAmountChange(index, e.target.value)} style={{width:"140px",margin:"auto"}} min="0" />    
      </td>
    </tr>
      ))
    }
    </tbody>
        </table>
            {/* <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Paymode</label>
            <Select
        options={this.state.payModeOptions}
        placeholder="Select mode"
        value={this.state.editpaymode}
        onChange={this.handlePayModeEditChange}
        isSearchable={true}
        className="customSelectpaymode"
    /> */}
            </div>
            <div className="col">
            <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Remark</label>
            <Input  type="text" id="editremark" placeholder="Remark" required  style={{width:"280px"}} value={this.state.updateRemark} onChange={this.handleRemarkChange} />    
            </div>
          </div>
        </div>
        <div style={{textAlign:"center"}}>
      
          {" "}
         <Button variant="danger"  onClick={this.handleCloseaPayModeEdit}>
            Close
          </Button>{' '} 
         
         </div>
        </Modal.Body>
      </Modal>
    </div>

   
    </>
    );
  }
}




export default withRouter(SbHOme);

