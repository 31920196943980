import React from 'react';
import './table_master.css';
import profile from '../../images/splash/user.png';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useRef } from 'react';
import { Tooltip } from 'reactstrap';


const TableMaster = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    table_no: null, 
    no_of_seats: null, 
    shop_id: localStorage.getItem('shopId') || "", 
    is_active: 0, 
    table_image:null,
  });
   const [tables, setTables] = useState([]);
   const [usedTablesCount, setUsedTablesCount] = useState(0);
   const [unusedTablesCount, setUnusedTablesCount] = useState(0);
   const [ searchQuery,setSearchQuery] = useState('');
   const [show, setShow] = useState(false);
   const [tableD, setTableD] = useState({});
   const [ isEdit,setIsEdit ] = useState(false);
   const [ loading, setLoading ] = useState(false);
   const [switches, setSwitches] = useState([]);
   const shopName = localStorage.getItem('shopName');
   const [puttooltipOpen, setPutToolTipOpen] = useState([]);
   const [updateFormData, setUpdateFormData] = useState({
    id:null,
    table_no: "",
    table_image: "",
    no_of_seats: "",
    is_active:null,
    shop_id:null
  });
  const fileInputRef = useRef(null);

    useEffect(() => {
        fetchTables();
       
      }, []);


      const fetchTables = async () => {
        setLoading(true);
        try {
           const shop = localStorage.getItem('shopId')
           const response = await axios.get(`${var_api}/api/table-details/get-All-table/${shop}`);
           console.log("response",response)
          const user =response.data;
          setLoading(false);
          const initialSwitches = user.map((location) => location.is_used === 1);
    console.log("initialSwitches",initialSwitches)
    setSwitches(initialSwitches)
          setTables(user);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };


      useEffect(() => {
        let usedCount = 0;
        let unusedCount = 0;
      
        tables.forEach(table => {
          if (table.is_used === 1) {
            usedCount++;
          } else {
            unusedCount++;
          }
        });
      
        setUsedTablesCount(usedCount);
        setUnusedTablesCount(unusedCount);
      }, [tables]);
      
      

     //search the transaction data
     const filteredSuppliers = tables.filter((order) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (order.table_no && order.table_no.toString().toLowerCase().includes(searchLower)) ||
        (order.no_of_seats && order.no_of_seats.toString().toLowerCase().includes(searchLower)) 
      );
    });

//handle image change
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, table_image: file });
      };

//handle text field change
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };


      //post the form data
    const Submit = async (e) => {
        e.preventDefault();  
      
        const formDataToSend = new FormData();
        formDataToSend.append('table_no', formData.table_no);
        formDataToSend.append('no_of_seats', formData.no_of_seats);
        formDataToSend.append('table_image', formData.table_image);
        formDataToSend.append('is_active', formData.is_active);
        formDataToSend.append('shop_id', formData.shop_id);
        
      
         try {
          const response = await fetch(`${var_api}/api/table-details/post`, {
            method: 'POST',
          body: formDataToSend,
        });
          if(response.status == 200){
            const data = await response.json();
            console.log('Registration successful!',data);
           // Reset the form fields
           setFormData({
            ...formData,
            table_no: "",
            no_of_seats: ""
        });
        // Clear the file input field
  const fileInput = document.getElementById('myFormFile'); // Replace 'table_image' with the actual ID of your file input field
  fileInput.type = 'text'; // Change the input type to 'text'
  fileInput.type = 'file'; 
              Swal.fire({
                icon:"success",
                text:'Table Created Successfully',
                confirmButtonColor:"#007500"
              })
              fetchTables();
         
          }else {
            console.error('Failed to create shop:', response.status);
            // Handle error, e.g., show an error message to the user
           
              if(response.status === 400){
                Swal.fire({
                  icon:"error",
                  text:'Table Already Exist',
                  confirmButtonColor:"#007500"
                })
              }else{
                Swal.fire({
                  icon:"error",
                  text:'Failed to Create Table',
                  confirmButtonColor:"#007500"
                })
              }
          }

          
          // Handle success scenario as needed
        } catch (error) {
            console.error('Error:', error);
            // Handle error scenario as needed
          }
    };


    //reset the values
    const handleToReset = () => {
         // Reset the form fields individually
       // Reset the form fields by setting them to empty strings
       setFormData({
        ...formData,
        table_no: "",
        no_of_seats: ""
    });

    // Clear the file input field
  const fileInput = document.getElementById('myFormFile'); // Replace 'table_image' with the actual ID of your file input field
  fileInput.type = 'text'; // Change the input type to 'text'
  fileInput.type = 'file'; 

    }

    //Go to home page
    const handleButtonClick = () => {
      history.push('/home');
    }

    //go to table order page
    const handleGOtoTableOrder = () => {
      history.push('/tableOrders');
    }


    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };


       //to open the form for slot updation 
    const handleEditTable = async (user) => {
      console.log(user);
      setIsEdit(true);
      setUpdateFormData({
        id: user.id,
        table_no: user.table_no,
        table_image: user.table_image,
        no_of_seats: user.no_of_seats,
        is_active: user.is_active,
        shop_id: user.shop_id
      })
      console.log("updateFormData",updateFormData);
          // const { value: formValues } = await Swal.fire({
          //   title: 'Update Table',
          //   html: `
          //    <label class="swalLa">Table No</label>
          //    <br/>
          //     <input id="swal-input1" class="swal2-input fin" name="table_no" placeholder="Table No" value="${data.table_no || ''}">
          //     <br/>
          //     <br/>
          //     <label class="swalLa">No.Of.Seats</label>
          //     <input id="swal-input2" class="swal2-input fin" name="no_of_seats" placeholder="No of Seats" value="${data.no_of_seats || ''}">
          //     <br/>
          //     <br/>
          //     <label class="swalLa">Table Image</label>
          //     <input id="swal-input3" class="swal2-input fin" name="table" type="file" accept="image/*">
          //   `,
          //   focusConfirm: false,
          //   showCancelButton: true,
          //   confirmButtonColor: '#007500',
          //   confirmButtonText: 'Submit',
          //   preConfirm: () => {
          //     return new Promise(async (resolve) => {
          //       const input1 = document.getElementById('swal-input1').value;
          //       const input2 = document.getElementById('swal-input2').value;
          //       const input3 = document.getElementById('swal-input3').files[0];
        
          //       if (!input1 || !input2) {
          //         Swal.showValidationMessage('Please fill in all the required fields');
          //         resolve(null);
          //       } else {

          //         console.log("categories",data);
          //         console.log("categories",data.image);
          //         // Create a FormData object to handle the file upload
          //         const formData = new FormData();
          //         formData.append('table_no', input1 || data.table_no);
          //         formData.append('no_of_seats', input2 || data.no_of_seats);
          //         formData.append('table_image', input3 || data.table_image);
          //         formData.append('is_active', data.is_active);
          //         formData.append('shop_id',data.shop_id);
        
          //         try {
          //           // Make a PUT request to update the category
          //           const categoryId = data.id; // Replace with the actual category ID
          //           const response = await axios.put(${var_api}/api/table-details/table-put/${categoryId}, formData, {
          //             headers: {
          //               'Content-Type': 'multipart/form-data', // Set the content type for file upload
          //             },
          //           });
        
          //           if (response.status === 200) {
          //             fetchTables();
          //             // Category updated successfully
          //             Swal.fire({
          //               icon: 'success',
          //               text: 'Table updated successfully',
          //               confirmButtonColor: '#007500',
          //             });
          //           } else {
          //             Swal.fire({
          //               icon: 'error',
          //               text: 'Failed to update Table',
          //               confirmButtonColor: '#007500',
          //             });
          //           }
          //         } catch (error) {
          //           console.error('Error updating Table:', error);
          //           Swal.fire('Error', 'Failed to update Table. Please try again.', 'error');
          //         }
        
          //         resolve(formData);
          //       }
          //     });
          //   },
          // });
    };


     //delete method for remove UOM
   const handleDeleteTable = (id, no) => {
    setShow(true);
    setTableD({id:id,table_no:no})
     
    }

    //close the modal
    const handleCloseConfirmation = () => {
      setShow(false)
    }

    const handleDelete = () => {
      axios
      .delete(`${var_api}/api/table-details/table-delete/${tableD.id}`)
      .then((response) => {
        if (response.status === 200) {
          fetchTables();
          setShow(false);
          Swal.fire("success"," Table Removed Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Table not found');
          Swal.fire("error","Table not found!","error")
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Table!","error")
        // Handle the error as needed
      });
    }

    //close the update modal
  const handleEditClose = () =>{
    setIsEdit(false);
  }


  //post method for creating the shop
  const handleUpdateSubmit = async (e) => {
    console.log("update data before put",updateFormData)
    e.preventDefault(); 
   
    const formDataToSend = new FormData();
    formDataToSend.append('table_no', updateFormData.table_no);
    formDataToSend.append('table_image', updateFormData.table_image);
    formDataToSend.append('no_of_seats', updateFormData.no_of_seats);
    formDataToSend.append('is_active', updateFormData.is_active);
    formDataToSend.append('shop_id', updateFormData.shop_id);
    
  
     try {
      const response = await fetch(`${var_api}/api/table-details/table-put/${updateFormData.id}`, {
        method: 'PUT',
      body: formDataToSend,
    });
      if(response.status == 200){
        setIsEdit(false);
        Swal.fire({
          title: 'Success',
          text: 'Table Updated Successfully.',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
        setUpdateFormData({
          id:null,
          table_no: "",
          table_image: "",
          no_of_seats: "",
          is_active:null,
          shop_id:null  
        });
         // Clear the value of the file input element
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    fetchTables();
        const data = await response.json();
        console.log('Registration successful!',data);
     
      }else {
        console.error('Failed to update shop:', response.statusText);
        // Handle error, e.g., show an error message to the user
        Swal.fire({
          title: 'error',
          text: 'Failed to update Table...Please Try Again!',
          icon: 'error',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      }
      
      // Handle success scenario as needed
    } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    }

    //input onchange for update
  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({ ...updateFormData, [name]: value });
  };


    //onchange function for file for update
  const handleImageChangeUpdate = (e) => {
    const file = e.target.files[0];
    setUpdateFormData({ ...updateFormData, table_image: file || updateFormData.shop_image});
  };

   //reset form for update
   const handleResetUpdateShop = () => {
    setUpdateFormData({
      id:null,
      table_image:null,
      table_no:'',
      no_of_seats:''
    });
    
  if (fileInputRef.current) {
    fileInputRef.current.value = '';
  }
  }

  //toggle switch for is_active updation
  const handleToggle = async (index,id) => {
    const newSwitches = [...switches];
    console.log('newSwitches', newSwitches);
    console.log('newSwitches', !newSwitches[index]);
    newSwitches[index] = !newSwitches[index];
    console.log('newSwitches', newSwitches[index]);
   
    setSwitches(newSwitches);
    console.log('newSwitches', newSwitches);
    

// Make an API request to update the location_status
try {
const response = await axios.put(`${var_api}/api/table-details/update-switch-status/${id}`, { is_used: newSwitches[index] ? 1 : 0 });
if (response.status === 200) {
  console.log('Location status updated successfully');
  fetchTables();
}
} catch (error) {
console.log('Error updating location status:', error);
// Revert the switch state in case of an error
newSwitches[index] = !newSwitches[index];
setSwitches(newSwitches);
}
  };


  const togglePutTooltip = (index) => {
    const putTooltipOpen = [...puttooltipOpen];
    putTooltipOpen[index] = !putTooltipOpen[index];
    setPutToolTipOpen(putTooltipOpen);
  };



    return(
        <div className='whole' style={{backgroundColor:"white"}}>
            <div class="split left">
                <div class="centered">
                    <h3 className='ushet'>TABLE MASTER</h3>
                    <br/>
                    <div style={{marginLeft:"12px",padding:"12px"}}>

                    <form onSubmit={Submit}>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Table No</lable>
            <br/>
            <input  className="form-inputt" type='number' name='table_no' placeholder='enter table no'
             required
             value={formData.table_no}
             onChange={handleInputChange}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>No.Of.Seats</lable>
            <input  className="form-inputt" type='number' name='no_of_seats' placeholder='enter seats'
            required
             value={formData.no_of_seats}
             onChange={handleInputChange}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Table Image</lable>
            <input  className="form-inputt" type='file' name='table_image' onChange={handleImageChange} id='myFormFile'
            />

            </div>
            <br/>
            <br/>
            <button className='btn btn-success' style={{float:"right"}}>Create</button> {' '}
            </form>
            
            <button className='btn btn-secondary' style={{float:"right",marginRight:"3px"}} onClick={handleToReset} >Reset</button> {' '}
                       
                        
                    </div>
                   
                </div>
            </div>

            <div class="split right">
            <nav className="navbar" 
            style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"5px",height:"auto",width:"100%",position:"relative",top:"-10px"}} >
              <div style={{ display: "flex"}}>
                <h4 id='tableshopname'>
                 
                  { shopName }
                  </h4>
                {/* <button className="btn" onClick={handleGOtoTableOrder} style={{backgroundColor:"white",color:"green",marginLeft:"250px"}}>
                <i class="bi bi-easel"></i> Table Order</button>{" "} */}
                  <button className="btn" onClick={handleButtonClick} id='tablegohome'>
                  <i class="bi bi-house"></i> Go to Home</button>
              </div>
      </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"640px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      
      <div>
        
      <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={handleSearchInput}/>
      <div style={{display:"flex", alignItems:"center", gap:"60px"}}>
      <h5 style={{textAlign:"left",fontFamily:"serif",fontWeight:"bolder"}}>No.Of.Tables: <span style={{fontFamily:"monospace",color:"green",fontSize:"20px"}}>{filteredSuppliers.length > 0 ? filteredSuppliers.length : 0}</span> </h5>
      <h5 style={{textAlign:"left",fontFamily:"serif",fontWeight:"bolder"}}>Active Tables:<span style={{fontFamily:"monospace",color:"green",fontSize:"20px"}}>{usedTablesCount}</span> </h5>
      <h5 style={{textAlign:"left",fontFamily:"serif",fontWeight:"bolder"}}>Inactive Tables: <span style={{fontFamily:"monospace",color:"red",fontSize:"20px"}}>{unusedTablesCount}</span> </h5>
      </div>
      <br/>
      <br/>
      <br/>
     <div className="table-container" style={{ height:"500px", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",borderColor:"grey",width:"90%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col">S.No</th>
      <th scope="col">Table Image</th>
      <th scope="col">Table No</th>
      <th scope="col">No.Of.Seats</th>
      <th scope="col">Edit</th>
      <th scope="col">Active Status</th>
    </tr>
  </thead>
  {loading ? (
        <div className="loader-containeru">
      	  <div className="spinneru"></div>
        </div>
  ) : (
  <tbody class="body-half-screen">
   {filteredSuppliers.length > 0 ? (
   filteredSuppliers.map((data,index) =>(

    <tr style={{padding:"5px"}}>
      <th scope="row">{index+1}</th>
      <td style={{textTransform:"capitalize"}}><img src={`${var_api}/private/${data.table_image}`} class="rounded"  alt='profile' width="65px" height="50px" /></td>
      <td>{data.table_no}</td>
      <td>{data.no_of_seats}</td>
      <td>
      <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='left'
      >
          Edit Table
        </Tooltip>
      <i class="bi bi-pencil-square"  id={`putButton${index}`}  onClick={()=>{handleEditTable(data); togglePutTooltip(index); }}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      {/* <i class="bi bi-trash" onClick={()=>handleDeleteTable(data.id,data.table_no)}   style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i> */}
      </td>
      <td>
      <div className="form-check form-switch">
      <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id={`flexSwitchCheckChecked_${index}`} checked={switches[index]}  onChange={() => handleToggle(index,data.id)}/>
</div>
</div>

      </td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="6" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )}
 

   </tbody>
)}
</table>

      </div>
<br/>
<br/>


        </div>
      
      </div>
            </div>

            <Modal
        show={isEdit}
        onHide={handleEditClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Table </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       {
       updateFormData && 
       <form  className="py-2" onSubmit={handleUpdateSubmit}>
       <div class="row">
         <div class="col">
            <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Table Name</label>
           <input type="number" class="form-control" placeholder="enter table name" id="input1"  name="table_no"  value={updateFormData.table_no}  onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
         <div class="col">
           <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>No.Of.Seats</label>
           <input type="number" class="form-control" placeholder="enter no.of.seats" id="input2" name="no_of_seats" value={updateFormData.no_of_seats} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
       </div>
       <br/>
       <div class="row">
         <div class="col">
         <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Table Image</label>
           <input ref={fileInputRef} class="form-control" type="file" id="formFile" onChange={handleImageChangeUpdate} style={{borderRadius:"12px"}} />
         </div>
         <div class="col">
           
         </div>
        
       </div>
       <br/>
       <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
       </form> 
       }
          <button className="btn btn-danger" onClick={handleEditClose} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>


            <Modal
        show={show}
        onHide={handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM !</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete 
          <span style={{color:"red"}}> Table No: {tableD.table_no}</span> ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal> 
        </div>

        
    )
}

export default TableMaster;