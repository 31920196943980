// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAoOoKUmWPfXzSVCpKRQmcjme5vmWZTyfk",
  authDomain: "biller-551ea.firebaseapp.com",
  projectId: "biller-551ea",
  storageBucket: "biller-551ea.appspot.com",
  messagingSenderId: "695199546110",
  appId: "1:695199546110:web:0e9cd3a13303245fb243af"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);