import React, { useState,useEffect } from "react";
import deliv from './delImg/take.jpg';
import './deliver.css';
import male from '../kitchen/kitImag/male.svg';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { collection, onSnapshot, where, query, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase.js';
import DChildCard1 from "./chCard1";
import DChildCard2 from "./chCard2";
import DChildCard3 from "./chCard3";



function Deliver(){


    
    return(
        <div className="full">
          <nav className="navbar  navbar-light py-1 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",border:"none",backgroundColor:"white"}} >
        <h3 style={{textAlign:"center",color:"#28a745"}} className="hefo"> <Link to='/home' style={{padding:"10px",color:"#28a745"}}><i class="bi bi-arrow-left-circle" style={{fontSize:"25px"}}></i></Link>
        TakeAway
        </h3>
          <img src={deliv} alt='pic' height='55px' style={{marginRight:"1273px"}}/>
        </nav>
        <div>
        <div className="row" style={{padding:"0px 30px",marginTop:"-25px",backgroundColor:"rgba(10, 241, 33, 0.133)"}} >
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card" style={{borderRadius:"20px",width:"350px",border:"none",height:"625px"}} >
              <div class="card-header" style={{border:"none",color:"white",fontSize:"18px",backgroundColor:"#28a745"}}> Ready to Parcel
              </div>
              <div className="card-body" id="scr" style={{marginTop:"-40px"}}>
                <DChildCard1/>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card" style={{borderRadius:"20px",width:"350px",border:"none",height:"625px"}} >
              <div class="card-header" style={{border:"none",color:"white",fontSize:"18px",backgroundColor:"#28a745"}}> Parcel Sent to  customer
              </div>
              <div className="card-body" id="scr" style={{marginTop:"-40px"}}>
                <DChildCard2/>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="card" style={{borderRadius:"20px",width:"350px",border:"none",height:"625px"}} >
              <div class="card-header" style={{border:"none",color:"white",fontSize:"18px",backgroundColor:"#28a745"}}> Delivered
              </div>
              <div className="card-body" id="scr" style={{marginTop:"-40px"}}>
                <DChildCard3/>
              </div>
            </div>
          </div> */}
        </div>
        
      </div>
        </div>
    )
}

export default Deliver;