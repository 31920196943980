import React,{useState, useEffect} from "react";
import { Link,useParams } from "react-router-dom";
import '../categories/cate.css';
import axios from "axios";
import { var_api } from "../constant";
import Splash from "../../images/splash/shop_logo.jpeg";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Swal from "sweetalert2";


function AdminCategoryMaster(){
    const [cart, setCart] = useState([]);
    const [images, setImages] = useState([]);
    const [search, setSearch] = useState('');
    const shopName= localStorage.getItem('shopName');
    const [ loading, setLoading ] = useState(false);
    const [querySearch, setQuerySearch] = useState('');
    // const getQuery = event => setSearchQuery(event.target.value);
    const [users, setUsers] = useState([]);
    console.log(shopName);

    const handleSearchChange = (event) => {
      const newSearch = event.target.value;
      setSearch(newSearch)
      // setQuerySearch(newSearch);
      // onSearchChange(newSearch);
    }

    const handleAdd = async()=>{
      const { value: formValues } = await Swal.fire({
        title: 'Create Category',
        html:
          '<label class="swalLa">Category Name</label>'+
          '<br/>'+
          '<input id="swal-input1" class="swal2-input fin" placeholder="Category Name">' +
          '<br/>'+
          '<br/>'+
          '<label class="swalLa">Category Code</label>'+
          '<input id="swal-input2" class="swal2-input fin" placeholder="Category Code">' +
          '<br/>'+
          '<br/>'+
          '<label class="swalLa">Category Image</label>'+
          '<input id="swal-input3" class="swal2-input fin" placeholder="Select File" type="file">',
        focusConfirm: false,
        confirmButtonColor: '#007500',
        confirmButtonText: 'submit',
        showCancelButton: true,
        preConfirm: () => {
          const input1 = document.getElementById('swal-input1').value;
          const input2 = document.getElementById('swal-input2').value;
          const input3 = document.getElementById('swal-input3').files[0];

          if (!input1 || !input2 ) {
            Swal.showValidationMessage('Please fill in all the required fields');
          } else {
            return [input1, input2, input3];
          }
        }
      })
      
      if (formValues) {
      const [ categoryName, categoryCode, selectedFile ] = formValues;
      const shopName = localStorage.getItem('shopName');
      const shopId =  localStorage.getItem('shopId')
      
       // Create a FormData object to send the file
       const formData = new FormData();
       formData.append('category_name', categoryName);
       formData.append('category_code', categoryCode);
       formData.append('cate_image', selectedFile);
       formData.append('shop_name', shopName);
       formData.append('shop_id',shopId)

    
      try { 
  
      // Send the order data to the Node.js API using axios
        const response = await fetch(`${var_api}/api/categories/api/post`, {
          method: 'POST',
        body: formData,
      });
        if (response.status === 200) {
          fetchData();
          // Handle successful order submission to both databases
          Swal.fire({
            icon: 'success',
            text: 'category added  successfully',
            confirmButtonColor: '#007500',
          });
        }
  
       
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      }
    };
  
  
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };
  
  
    const showSuccessAlert = () => {
      Swal.fire({
        title: 'Success',
        text: 'Profile updated successfully!',
        icon: 'success',
        confirmButtonColor: '#007500', // Set the custom color for the Confirm button
      });
    };
  
    const showFailureAlert = () => {
      Swal.fire({
        title: 'Success',
        text: 'Failed to update profile. Please try again.',
        icon: 'success',
        confirmButtonColor: '#007500', // Set the custom color for the Confirm button
      });
    };
  
    useEffect(() => {
      console.log('Users at useEffect:', users);
     fetchUserData();
    }, []);
  
    const handleUpdate = async () => {
      const { value: formValues } = await Swal.fire({
        title: 'Update Profile',
        html:
          `<input id="swal-input1" class="swal2-input" name='name' placeholder="Name" value="${users.name || ''}">` +
          `<input id="swal-input2" class="swal2-input" name='email' placeholder="Email" value="${users.email || ''}">` +
          `<input id="swal-input3" class="swal2-input" name='Password' placeholder="Password" value="${users.password || ''}">` +
          `<input id="swal-input41" class="swal2-input" name='country' placeholder="Country" value="${users.country || ''}">` +
          `<input id="swal-input5" class="swal2-input" name='mobile' placeholder="Mobile" value="${users.mobile || ''}">`,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonColor: '#007500',
        confirmButtonText: 'Submit',
        preConfirm: () => {
          const input1 = document.getElementById('swal-input1').value;
          const input2 = document.getElementById('swal-input2').value;
          const input3 = document.getElementById('swal-input3').value;
          const input4 = document.getElementById('swal-input41').value;
          const input5 = document.getElementById('swal-input5').value;
    
          if (!input1 || !input2 || !input3 || !input4 || !input5) {
            Swal.showValidationMessage('Please fill in all the required fields');
          } else {
            return [input1, input2, input3, input4, input5];
          }
        },
      });
    
      if (formValues) {
        const [name, email, password, country, mobile] = formValues;
    
        try {
          // Make a PUT request to update the profile
          const data = localStorage.getItem('userId')
          const response = await axios.put(`${var_api}/api/user/put/`+data, {
            name: name,
            email: email,
            password: password,
            country: country,
            mobile: mobile,
          });
    
          if (response.status === 200) {
            // Profile updated successfully
            showSuccessAlert()
          } else {
            showFailureAlert ()
          }
        } catch (error) {
          console.error('Error updating profile:', error);
          Swal.fire('Error', 'Failed to update profile. Please try again.', 'error');
        }
      }
    };
  
    const fetchUserData = async () => {
      const data = localStorage.getItem('userId')
     try {
      const response = await axios.get(`${var_api}/api/user/data/`+data );
      // Assuming setUsers is a function to update your state with the response data.
      const use = response.data
      // const use = [response.data.id,response.data.name,response.data.email,response.data.password,response.data.country,response.data.mobile]
      console.log(use)
      setUsers(use);
    }catch (error) {
      console.log('Error fetching data:', error);
      setUsers([]);
      }
    };


    // useEffect(() => {
    //   setLoading(true);
    //   const colRef = collection(db, `shops/${shopName}/categories`);
    //   const q = query(colRef);
    //   const unsub = onSnapshot(q, (snapshot) => {
    //     setImages(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
    //     setLoading(false);
    //   });
  
    //   return () => {
    //     unsub();
    //   };
    // }, []);

    // const handleSearchChange = (newSearch) => {
    //   setSearch(newSearch); // Update the search state in the parent
    // };


    useEffect(() => {
     
      fetchData();
    }, []);

    const fetchData = async () => {
      setLoading(true);
      const shopId = localStorage.getItem('shopId');
     try {
      const response = await axios.get(`${var_api}/api/categories/api/categories/${shopId}` );
      const use = response.data

    console.log("categories",use)
      setImages(use);
      setLoading(false);
    }catch (error) {
      console.log('Error fetching data:', error);
      
      }
    };


  //  // Function to convert a Buffer to a data URL
  //  const bufferToDataURL = (buffer) => {
  //   // Convert the Buffer to a binary string
  //   let binary = '';
  //   const bytes = new Uint8Array(buffer);
  //   for (let i = 0; i < bytes.length; i++) {
  //     binary += String.fromCharCode(bytes[i]);
  //   }

  //   // Encode the binary string as base64
  //   const base64 = btoa(binary);

  //   return `data:image/jpeg;base64,${base64}`;
  // };


      // Filter orders based on the search query
      const filteredOrders = images.filter((image) => {
        const searchLower = search.toLowerCase();
        return (
          image.category_name.toLowerCase().includes(searchLower)
        );
      });

      const noResults = filteredOrders.length === 0;



      const handleCategoryUpdate = async (data) => {
          const updatedData = {
            category_name: '',
            category_code: '',
            image: '',
          };
        
          const { value: formValues } = await Swal.fire({
            title: 'Update Category',
            html: `
             <label class="swalLa">Category Name</label>
             <br/>
              <input id="swal-input1" class="swal2-input fin" name="category_name" placeholder="Category Name" value="${data.category_name || ''}">
              <br/>
              <br/>
              <label class="swalLa">Category Code</label>
              <input id="swal-input2" class="swal2-input fin" name="category_code" placeholder="Category Code" value="${data.category_code || ''}">
              <br/>
              <br/>
              <label class="swalLa">Category Image</label>
              <input id="swal-input3" class="swal2-input fin" name="image" type="file" accept="image/*">
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#007500',
            confirmButtonText: 'Submit',
            preConfirm: () => {
              return new Promise(async (resolve) => {
                const input1 = document.getElementById('swal-input1').value;
                const input2 = document.getElementById('swal-input2').value;
                const input3 = document.getElementById('swal-input3').files[0];
        
                if (!input1 || !input2) {
                  Swal.showValidationMessage('Please fill in all the required fields');
                  resolve(null);
                } else {

                  console.log("categories",data);
                  console.log("categories",data.image);
                  // Create a FormData object to handle the file upload
                  const formData = new FormData();
                  formData.append('category_name', input1 || data.category_name);
                  formData.append('category_code', input2 || data.category_code);
                  formData.append('cate_image', input3 || data.image);
                  formData.append('shop_name', data.shop_name);
                  formData.append('shop_id',data.shop_id);
        
                  try {
                    // Make a PUT request to update the category
                    const categoryId = data.id; // Replace with the actual category ID
                    const response = await axios.put(`${var_api}/api/categories/api/put/${categoryId}`, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type for file upload
                      },
                    });
        
                    if (response.status === 200) {
                      fetchData();
                      // Category updated successfully
                      Swal.fire({
                        icon: 'success',
                        text: 'Category updated successfully',
                        confirmButtonColor: '#007500',
                      });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: 'Failed to update category',
                        confirmButtonColor: '#007500',
                      });
                    }
                  } catch (error) {
                    console.error('Error updating category:', error);
                    Swal.fire('Error', 'Failed to update category. Please try again.', 'error');
                  }
        
                  resolve(formData);
                }
              });
            },
          });
      };
      

    // console.log(JSON.stringify(images))

  // const { shopName } = useParams();
    // console.log(shopName); 
  
    
  // useEffect(() => {
  //   const colRef = collection(db, 'categories');
  //   const q = query(colRef);
  //   const unsub = onSnapshot(q, (snapshot) => {
  //     setImages(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
  //   });

  //   return () => {
  //     unsub();
  //   };
  // }, []);


  

    return(
        <>
        {/* <h1>{shopName}</h1> */}
        {/* <Header onSearchChange={handleSearchChange}  /> */}

        <nav className="navbar navbar-expand navbar-light bg-white py-1 sticky-top" style={{boxShadow: "0px 0px 1px 0px green",height:"80px"}} >
    <Link to="/AdminDashboard" className="navbar-brand">
      <img src={Splash} alt="logo" style={{marginLeft:"15px"}} />
    </Link>
    
    <div className='container'>
      
      <div>
        
        <button type="button" class="btn btn-md my-6 my-md-4" style={{backgroundColor:"green"}} onClick={handleAdd}><i class="bi bi-plus-square" style={{color:"white",border:"none"}}> ADD</i></button>
        
      </div>
      <div>
    <p style={{fontFamily:'serif', fontWeight:"bolder", color:"green", fontSize:"24px"}}>{shopName}</p> 
  </div>
      <input type="text" placeholder="Search" style={{marginRight:"-320px",height:"40px",width:"350px",borderRadius:"5px",padding:"10px"}} value={search} onChange={handleSearchChange}/>
    </div>

    
    <div className='search-box col-md-4 '>
   <Link to='/AdminHome'>
   <button className="btn" id="home" style={{marginLeft:"350px"}}><i class="bi bi-house"></i> Go to home</button> 
   </Link>
      
      
    </div>
 
  </nav>
        <br/>
        <h5 className="fade-in-down"> <span style={{color:"green",fontSize:"30px",fontWeight:"bolder"}}>Categories</span></h5>
        
        <div style={{padding:"50px"}}>
        {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (

      <div className="row row-cols-1 row-cols-md-0 row-cols-lg-5 g-3" >
        {noResults ? (
          <h6>no category</h6>
        ) : (
          filteredOrders.map((image, index) => (
          <div className="col mb-0 ftco-animate" key={image.id} >
          
            <div className="card"  style={{ width: '15rem',height:"13rem",boxShadow:"5px 5px 5px #aaaaaa",borderRadius:"10px",marginBottom:"70px",marginTop:"-10px" }}>
            <Link to={{ pathname: `/adminfoodm/${image.id}`, search: `?title=${encodeURIComponent(image.category_name)}&code=${encodeURIComponent(image.category_code)}&id=${encodeURIComponent(image.id)}` }}>
              <img src={`${var_api}/private/${image.image}`} alt={`Image ${index + 1}`} name='image'
              onError={(e) => {
                console.error(`Error loading image ${index}:`, e);
                console.error('Image URL:', e.target.src);
                console.error('Image data:', image);
              }}
              
              style={{height:"13rem",width:"15rem",marginTop:"1px"}} /></Link>
              <div>
              {/* <div className="text py-1"> mb-0 */}
                <div className="ribbon-wrapper ">
                <marquee className="mt-0 " direction='right' style={{color:"white",textAlign:"center",letterSpacing:"1.5px",textTransform:"capitalize"}}>{image.category_name} {' '}
                <span style={{fontSize:"7px"}}>{image.category_code}</span></marquee>
                </div>
                </div>
                
                <button id="uped" onClick={()=>handleCategoryUpdate(image)}>Update</button> 
            </div>
            
            
          </div>
        ))
        )}
        <br/>
      </div>
      )}
      
    </div>
        </>
    );
}

export default AdminCategoryMaster;


