import React from 'react';
import './supplier_master.css';
import profile from '../../images/splash/user.png';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Tooltip } from 'reactstrap';

const SupplierMaster = () => {
  const history = useHistory();
   const [supplier_name, setSupplier_name] = useState("");
   const [supplier_code, setSupplier_code] = useState("");
   const [address, setAddress] = useState("");
   const [mobile_no, setMobile_no] = useState("");
   const [gst_no, setGst_no] = useState("");
   const [gst_amount, setGst_amount] = useState("");
   const [suppliers, setSuppliers] = useState([]);
   const [ searchQuery,setSearchQuery] = useState('');
   const [show, setShow] = useState(false);
   const [isEdit, setisedit] = useState(false);
   const [supplierD, setSupplierD] = useState({});
   const shopName = localStorage.getItem('shopName');
   const [ updatedSupplier, setUpdatedSupplier] = useState({
    id:null,
    supplier_name:"",
    supplier_code:"",
    address:"",
    supplier_mobile:"",
    gst_code:"", 
    gst_amount:""
   });
  const [puttooltipOpen, setPutToolTipOpen] = useState([]);
  const [deletetooltipOpen, setDeleteToolTipOpen] = useState([]);

    useEffect(() => {
      fetchSuppliers();
      }, []);


      const fetchSuppliers = async () => {
        try {
           const shop = localStorage.getItem('shopId')
           const response = await axios.get(`${var_api}/api/supplier-master/get-supplier/${shop}`);
           console.log("response",response)
          const user =response.data;
          setSuppliers(user);
        } catch (error) {
          console.log(error);
        }
      };
      
      

     //search the transaction data
     const filteredSuppliers = suppliers.filter((order) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (order.supplier_name && order.supplier_name.toString().toLowerCase().includes(searchLower)) ||
        (order.supplier_code && order.supplier_code.toString().toLowerCase().includes(searchLower)) ||
        (order.supplier_address && order.supplier_address.toString().toLowerCase().includes(searchLower)) ||
        (order.mobile_no && order.mobile_no.toString().toLowerCase().includes(searchLower)) ||
        (order.gst_code && order.gst_code.toString().toLowerCase().includes(searchLower))
        
      );
    });


    // Function to convert string to camelCase
  const capitalizeEachWord = (str) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, function (match) {
      return match.toUpperCase();
    });
  };

  // Function to capitalize the first letter of a string
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
 };

 const togglePutTooltip = (index) => {
  const putTooltipOpen = [...puttooltipOpen];
  putTooltipOpen[index] = !putTooltipOpen[index];
  setPutToolTipOpen(putTooltipOpen);
};


const toggleDeleteTooltip = (index) => {
  const deleteTooltipOpen = [...deletetooltipOpen];
  deleteTooltipOpen[index] = !deleteTooltipOpen[index];
  setDeleteToolTipOpen(deleteTooltipOpen);
};



      //post the form data
    const Submit = async (e) => {
      e.preventDefault();
    
        // Trim whitespace from the input values
  const trimmedSupplierCode = supplier_code.trim();
  const trimmedSupplierName = supplier_name.trim();
  const trimmedAddress = address.trim();
  const trimmedGstNo = gst_no.trim();
  const trimmedGstAmount = gst_amount.trim();


  // Check if the trimmed values are empty
  if (trimmedSupplierCode === '' || trimmedSupplierName === '' || trimmedAddress === '' || trimmedGstNo === '' || trimmedGstAmount === '') {
    // Display an error message to the user
    Swal.fire({
      icon: "error",
      text: "All fields are required",
      confirmButtonColor: "#dc3545"
    });
    return; // Exit the function early if validation fails
  }

      try {
        const response = await axios.post(`${var_api}/api/supplier-master/post`, {
          supplier_code: supplier_code, 
          supplier_name: capitalize(supplier_name), 
          supplier_mobile: mobile_no, 
          supplier_address: capitalizeEachWord(address), 
          gst_code:gst_no,
          gst_amount:gst_amount,
          shop_id: localStorage.getItem('shopId') || ""
        });
    
        if (response.status === 200) {
          Swal.fire({
            icon:"success",
            text:`Supplier Created Successfully`,
            confirmButtonColor:"#007500"
          })
          setAddress(" ");
          setGst_no(" ");
          setSupplier_code(" ");
          setMobile_no(" ");
          setSupplier_name(" ");
          setGst_amount(' ')
          fetchSuppliers();
          console.log('Data inserted successfully.')

      }
      } catch (error) {
        Swal.fire({
            icon:"error",
            text:`Failed to Create Supplier profile`,
            confirmButtonColor:"#007500"
          })
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    };


    //reset the values
    const handleToReset = () => {
        setSupplier_code(" ");
        setSupplier_name(" "); 
        setAddress(" ");
        setGst_no(" ");
        setMobile_no(" ");
        setGst_amount(' ')
    }

    //Go to home page
    const handleButtonClick = () => {
      history.push('/home')
    }


    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };


       //to open the form for slot updation 
    const handleEditSuppliers = async (data) => {
      setisedit(true);
      setUpdatedSupplier({
        id: data.id,
        supplier_name: data.supplier_name,
        supplier_code: data.supplier_code,
        address: data.supplier_address,
        supplier_mobile: data.supplier_mobile,
        gst_code: data.gst_code,
        gst_amount: data.gst_amount
      })
      // const { value: formValues } = await Swal.fire({
      //     title: 'Update Supplier',
      //     html: `
      //      <label class="swalLa">Name</label>
      //      <br/>
      //       <input id="swal-input1" class="swal2-input fin" name="supplier_name" placeholder="Supplier Name" value="${data.supplier_name || ''}">
      //       <br/>
      //       <br/>
      //       <label class="swalLa">Code</label>
      //       <input id="swal-input2" class="swal2-input fin" name="supplier_code" placeholder="Supplier Code" value="${data.supplier_code || ''}">
      //       <br/>
      //       <br/>
      //       <label class="swalLa">Address</label>
      //       <input id="swal-input3" class="swal2-input fin" name="address" placeholder="Address" value="${data.supplier_address || ''}">
      //       <br/>
      //       <br/>
      //       <label class="swalLa">Mobile No</label>
      //       <input id="swal-input4" class="swal2-input fin" name="mobile" placeholder="Mobile" value="${data.supplier_mobile || ''}">
      //       <br/>
      //       <br/>
      //       <label class="swalLa">GST No</label>
      //       <input id="swal-input5" class="swal2-input fin" name="gst" placeholder="GST No" value="${data.gst_code || ''}">
      //     `,
      //     focusConfirm: false,
      //     showCancelButton: true,
      //     confirmButtonColor: '#007500',
      //     confirmButtonText: 'Submit',
      //     preConfirm: () => {
      //       return new Promise(async (resolve) => {
      //         const input1 = document.getElementById('swal-input1').value;
      //         const input2 = document.getElementById('swal-input2').value;
      //         const input3 = document.getElementById('swal-input3').value;
      //         const input4 = document.getElementById('swal-input4').value;
      //         const input5 = document.getElementById('swal-input5').value;
      
      //         if (!input1 || !input2 || !input3 || !input4 || !input5) {
      //           Swal.showValidationMessage('Please fill in all the required fields');
      //           resolve(null);
      //         } else {

      //           const requestData = {
      //             supplier_name: input1 || data.supplier_name,
      //             supplier_code: input2 || data.supplier_code,
      //             supplier_address: input3 || data.supplier_address,
      //             supplier_mobile: input4 || data.supplier_mobile,
      //             gst_code: input5 || data.gst_code,
      //           };
      
      //           try {
      //             // Make a PUT request to update the UOM
      //             const response = await axios.put(`${var_api}/api/supplier-master/put/${data.id}`, requestData, {
      //               headers: {
      //                 'Content-Type': ' application/json', 
      //               },
      //             });
      
      //             if (response.status === 200) {
      //               fetchSuppliers();
      //               // Category updated successfully
      //               Swal.fire({
      //                 icon: 'success',
      //                 text: 'Supplier Details updated successfully',
      //                 confirmButtonColor: '#007500',
      //               });
      //             } else {
      //               Swal.fire({
      //                 icon: 'error',
      //                 text: 'Failed to update Supplier Details',
      //                 confirmButtonColor: '#007500',
      //               });
      //             }
      //           } catch (error) {
      //             console.error('Error updating Supplier Details:', error);
      //             Swal.fire('Error', 'Failed to update Supplier Details. Please try again.', 'error');
      //           }
      
      //           resolve(requestData);
      //         }
      //       });
      //     },
      //   });
    };


     //delete method for remove UOM
   const handleDeleteSupplier = (id,name) => {
    setShow(true);
    setSupplierD({id:id,supplier_name:name})
     
    }

    //close the modal
    const handleCloseConfirmation = () => {
      setShow(false)
    }


    const handleDelete = () => {
      axios
      .delete(`${var_api}/api/supplier-master/supplier-delete/${supplierD.id}`)
      .then((response) => {
        if (response.status === 200) {
          fetchSuppliers();
          setShow(false);
          Swal.fire("success"," Supplier Removed Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Supplier not found');
          Swal.fire("error","Supplier not found!","error")
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Supplier!","error")
        // Handle the error as needed
      });
    }

    const handleResetShop = () => {
      setUpdatedSupplier({
        id:null,
        supplier_name:"",
        supplier_code:"",
        address:"",
        supplier_mobile:"",
        gst_code:""
      })
    }

    const handleUpdateSuppliers = async(e) => {

      e.preventDefault()
       // Trim whitespace from the input values
  const trimmedSupplierCode = updatedSupplier.supplier_code.trim();
  const trimmedSupplierName = updatedSupplier.supplier_name.trim();
  const trimmedAddress = updatedSupplier.address.trim();
  const trimmedGstNo = updatedSupplier.gst_code.trim();
  const trimmedGstAmount = updatedSupplier.gst_amount.trim();


  // Check if the trimmed values are empty
  if (trimmedSupplierCode === '' || trimmedSupplierName === '' || trimmedAddress === '' || trimmedGstNo === '' || trimmedGstAmount === '') {
    // Display an error message to the user
    Swal.fire({
      icon: "error",
      text: "All fields are required",
      confirmButtonColor: "#dc3545"
    });
    return; // Exit the function early if validation fails
  }

      const requestData = {
        supplier_name: capitalize(updatedSupplier.supplier_name),
        supplier_code: updatedSupplier.supplier_code,
        supplier_address: capitalizeEachWord(updatedSupplier.address),
        supplier_mobile: updatedSupplier.supplier_mobile,
        gst_code: updatedSupplier.gst_code,
        gst_amount: updatedSupplier.gst_amount
      };
        
       try {
        // Make a PUT request to update the UOM
        const response = await axios.put(`${var_api}/api/supplier-master/put/${updatedSupplier.id}`, requestData, {
          headers: {
            'Content-Type': ' application/json', 
           },
        });
        
        if (response.status === 200) {
          fetchSuppliers();
          setisedit(false);
        // Category updated successfully
          Swal.fire({
            icon: 'success',
            text: 'Supplier Details updated successfully',
            confirmButtonColor: '#007500',
          });
        } else {
           Swal.fire({
            icon: 'error',
            text: 'Failed to update Supplier Details',
            confirmButtonColor: '#007500',
          });
        }
      } catch (error) {
        console.error('Error updating Supplier Details:', error);
        Swal.fire('Error', 'Failed to update Supplier Details. Please try again.', 'error');
      }
        
    } 




    return(
        <div className='whole' style={{backgroundColor:"white"}}>
            <div class="split left">
                <div class="centered">
                    <h3 className='ushe'>SUPPLIER MASTER</h3>
                    <div style={{marginLeft:"12px",padding:"12px"}}>

                    <form onSubmit={Submit}>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Supplier Name</lable>
            <br/>
            <input  className="form-input" type='text' name='supplier_name' placeholder='Enter Name'
             required
             value={supplier_name}
             onChange={(e) => setSupplier_name(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Supplier Code</lable>
            <input  className="form-input" type='text' name='supplier_code' placeholder='Enter Code'
            required
             value={supplier_code}
             onChange={(e) => setSupplier_code(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Address</lable>
            <input  className="form-input" type='text' name='address' placeholder='Enter Address'
            required
             value={address}
             onChange={(e) => setAddress(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Mobile No</lable>
            <input  className="form-input" type='number' name='mobile' placeholder='Enter Mobile'
            required
             value={mobile_no}
             onChange={(e) => setMobile_no(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>GST No</lable>
            <input  className="form-input" type='text' name='gst' placeholder='Enter GST'
             value={gst_no}
             onChange={(e) => setGst_no(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>GST Amount</lable>
            <input  className="form-input" type='number' name='gst_amo' placeholder='Enter GST Amount'
             value={gst_amount}
             onChange={(e) => setGst_amount(e.target.value)}/>
            </div>
            <br/>
            <br/>
            <button className='btn btn-success' style={{float:"right"}}>Create Supplier</button> {' '}
            </form>
            
            <button className='btn btn-secondary' style={{float:"right",marginRight:"3px"}} onClick={handleToReset} >Reset</button> {' '}
                       
                        
                    </div>
                   
                </div>
            </div>

            <div class="split right">
            <nav className="navbar" 
            style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"5px",height:"50px",width:"1065px",position:"relative",top:"-10px"}} >
              <div style={{ display: "flex"}}>
                <h4 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "250px", color: "white" }}>{ shopName }</h4>
                <button className="btn" onClick={handleButtonClick} style={{marginLeft:"200px",backgroundColor:"white",color:"green"}}>
                  <i class="bi bi-house"></i> Go to Home</button>
              </div>
      </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"640px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      
      <div>
        
      <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={handleSearchInput}/>
      {/* <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder"}}>Current Suppliers</h3> */}
      <br/>
      <br/>
      <br/>
     <div className="table-container" style={{ height:"500px", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",borderColor:"grey",width:"90%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col">S.No</th>
      <th scope="col">Name</th>
      <th scope="col">Code</th>
      <th scope="col">Address</th>
      <th scope="col">Mobile No</th>
      <th scope="col">GST No</th>
      <th scope="col">GST Amount</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {filteredSuppliers.length > 0 ? (
   filteredSuppliers.map((data,index) =>(

    <tr style={{padding:"5px"}}>
      <th scope="row">{index+1}</th>
      <td>{data.supplier_name}</td>
      <td>{data.supplier_code}</td>
      <td style={{textAlign:"center"}}>{data.supplier_address}</td>
      <td>{data.supplier_mobile}</td>
      <td>{data.gst_code}</td>
      <td>{data.gst_amount}</td>
      <td>
      <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='left'
      >
          Edit Supplier
        </Tooltip>
      <i class="bi bi-pencil-square" id={`putButton${index}`} onClick={()=>{handleEditSuppliers(data); togglePutTooltip(index);}}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      <Tooltip
        isOpen={deletetooltipOpen[index]} // Use tooltip state based on index
        target={`deleteButton${index}`} // Specify the target ID of the button
        toggle={() => toggleDeleteTooltip(index)}
        placement='right'
      >
          Delete Supplier
        </Tooltip>
      <i class="bi bi-trash" id={`deleteButton${index}`} onClick={()=>{handleDeleteSupplier(data.id,data.supplier_name); toggleDeleteTooltip(index);}}   style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i>
      </td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )}
 

   </tbody>

</table>

      </div>
<br/>
<br/>


        </div>
      
      </div>
            </div>
            <Modal
        show={show}
        onHide={handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM !</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete supplier 
          <span style={{color:"red",textTransform:"uppercase"}}> {supplierD.supplier_name}</span> ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>


      <Modal
        show={isEdit}
        onHide={()=>setisedit(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Suppliers </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleUpdateSuppliers}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Supplier Name</label>
              <input type="text" class="form-control" placeholder="enter supplier name" id="input1"  name="supplier_name"  value={updatedSupplier.supplier_name}  onChange={(e) => setUpdatedSupplier({...updatedSupplier, supplier_name:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Supplier Code</label>
              <input type="text" class="form-control" placeholder="enter supplier code" id="input2" name="supplier_code" value={updatedSupplier.supplier_code} onChange={(e) => setUpdatedSupplier({...updatedSupplier, supplier_code:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Address</label>
              <input type="text" class="form-control" placeholder="enter address" id="input3"  name="address"  value={updatedSupplier.address}  onChange={(e) => setUpdatedSupplier({...updatedSupplier, address:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Mobile No</label>
              <input type="number" class="form-control" placeholder="enter mobile no" id="input4" name="mobile_no" value={updatedSupplier.supplier_mobile} onChange={(e) => setUpdatedSupplier({...updatedSupplier, supplier_mobile:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Gst No</label>
              <input type="text" class="form-control" placeholder="enter gst no" id="input5"  name="gst_no"  value={updatedSupplier.gst_code}  onChange={(e) => setUpdatedSupplier({...updatedSupplier, gst_code:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
            <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Gst Amount</label>
            <input type="text" class="form-control" placeholder="enter gst amount" id="input5"  name="gst_amount"  value={updatedSupplier.gst_amount}  onChange={(e) => setUpdatedSupplier({...updatedSupplier, gst_amount:e.target.value})} style={{borderRadius:"12px"}} required/> 
            </div>
          </div>
         
          <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
          </form>
          <button className="btn btn-danger" onClick={()=>setisedit(false)} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal> 

     
        </div>

        
    )
}

export default SupplierMaster;