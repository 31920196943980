import React, {useState, useEffect} from "react";
import { Card, Button, Form, Row, Col  } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { var_api } from "../constant";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import Loader from "../Loader/Loader";
import swal from 'sweetalert';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

toast.configure()

const PurchaseOrders = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isCreatePO, setIscreatePO] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [latestPODetails, setlatestPODetails] = useState([]);
    const [latestAddedPO, setLatestAddedPO] = useState(null);
    const [selectedPOid, setSelectedPOid] = useState(null);
    const [selectedPOno, setSelectedPOno] = useState(' ');
    const [isEditMode, setIsEditMode] = useState(false);
    const [isItemEditMode, setIsItemEditMode] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [items, setItems] = useState([]);
    const [stockItems, setStockItems] = useState([]);
    const [isOpenNonStock, setIsOpenNonStock] = useState(false);
    const [nonStockCode, setNonStockCode] = useState("");
    const [nonStockUOM, setNonStockUOM] = useState('');
    const [nonStockPrice, setNonStockPrice] = useState(null);
    const [nonStockDiscount, setNonStockDiscount] = useState(null);
    const [editModes, setEditModes] = useState(Array(stockItems.length).fill(false));
    const [editedDiscounts, setEditedDiscounts] = useState(stockItems.map(item => item.discount_per));
    const [editedRequiredQty, setEditedRequiredQty] = useState(stockItems.map(item => item.required_qty));
    const [editedOrderedQty, setEditedOrderedQty] = useState(stockItems.map(item => item.ordered_qty));
    const [editedUnitPrice, setEditedUnit_price] = useState(stockItems.map(item => item.unit_price));
    const [netUnitPrices, setNetUnitPrices] = useState(stockItems.map(item => item.unit_price * (1 - item.discount_per / 100)));
    const shopId = localStorage.getItem("shopId");
    const [poLoader, setPoLoader] = useState(false);
    const [poItemsLoader, setPoItemsLoader] = useState(false);
    const [poAmount, setPoAmount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    // Function to format the date as dd-mm-yyyy
const formatDate = (date) => {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    const year = d.getFullYear();
  
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
  
    return `${day}-${month}-${year}`;
  };
    const [formData, setFormData] = useState({
        purchase_order_date: " ",
        required_date: "",
        supplier_id: null,
        supplier_refno: '',
        supplier_cont_person: '',
        contact_no: '',
        email: '',
        discount_per: parseFloat(0),
        request_type: '',
        terms:null,
        term_of_packing: '',
        supplier_address: '',
        purchase_order_amount: 0,
        gst_amount:0,
        total_amount: 0,
        net_amount: 0,
        inpurchase_order_status: 0,
        inprogress_status: 0,
        purchase_order_status: 0,
        special_instruction: "",
        created_date: formatDate(new Date()),
        updated_date: formatDate(new Date()),
        shop_id: shopId,
        revision:" ",
        is_active: 0
        // Add other fields here
      });

    const handleClose = () => {
        setIscreatePO(false);
    }


    const handleCloseNonStock = () => {
      setIsOpenNonStock(false);
  }

    const handleToOpenCreatePO = () => {
        setIscreatePO(true);
    }


    const fetchGstAmount = async (supplierId) => {
      // Add your logic to fetch or calculate GST amount based on supplier ID
      // This is just a placeholder, replace it with actual logic
      const selectedSupplier = suppliers.find(supplier => supplier.id === parseInt(supplierId));
      if (selectedSupplier) {
        // Example calculation or fetching logic
        return selectedSupplier.gst_amount || 0; // Adjust according to your data structure
      }
      return 0;
    };

    const handleChange = async (e) => {
      const { id, value } = e.target;
      let updatedFormData = {
        ...formData,
        [id]: value,
      };
  
      if (id === 'supplier_id') {
        const gstAmount = await fetchGstAmount(value);
        updatedFormData = {
          ...updatedFormData,
          gst_amount: gstAmount,
        };
      }
  
      setFormData(updatedFormData);
      };


      const handleChangePOno = (e) => {
        const [poId, poNo] = e.target.value.split(',');
        console.log("Selected P/O id&No:", poId,poNo); // Purchase Order Number
        setSelectedPOid(poId);
        setSelectedPOno(poNo);
        fetchSelectedPO(poId);
        fetchStockItems(poId);
      }


      const fetchSelectedPO = async (po_id) => {
        setPoLoader(true);
        try {
          const response = await axios.get(`${var_api}/api/purchase-orders/get-purchase-order/${shopId}/${po_id}`);
          setLatestAddedPO(response.data);
          setSelectedPOid(response.data.id);
        } catch (error) {
          console.error('Error fetching po:', error);
          toast('Error fetching PO')
        }
        finally{
          setPoLoader(false);
        }
      };


      const fetchStockItems = async (id) => {
        setPoItemsLoader(true);
        try {
          const response = await axios.get(`${var_api}/api/purchase-order-items/get-items/${shopId}/${id}`);
          console.log("po stock code",response.data);
          setStockItems(response.data);
        } catch (error) {
          console.error('Error fetching suppliers:', error);
          toast("Error fetching PO items");
        }finally{
          setPoItemsLoader(false);
        }
      };


      
      const fetchSuppliers = async () => {
        try {
          const response = await axios.get(`${var_api}/api/supplier-master/get-supplier/${shopId}`);
          setSuppliers(response.data);
        } catch (error) {
          console.error('Error fetching suppliers:', error);
          toast("Error fetching Suppliers");
        }
      };


      const fetchPoDetails = async () => {
        setPoLoader(true);
        try {
          const response = await axios.get(`${var_api}/api/purchase-orders/get-orders/${shopId}`);
          const poDetails = response.data;
            // Find the object with the largest id
           const lastAddedPO = poDetails.reduce((max, po) => (po.id > max.id ? po : max), poDetails[0]);
          setlatestPODetails(poDetails);
          setSelectedPOid(lastAddedPO.id);
          setSelectedPOno(lastAddedPO.purchase_order_no);
          setLatestAddedPO(lastAddedPO);
          fetchStockItems(lastAddedPO.id);
          console.log("PO with Largest ID:", lastAddedPO);
          console.log("po",poDetails);
        } catch (error) {
          console.error('Error fetching suppliers:', error);
          toast('Error fetching PO list');
        }finally{
          setPoLoader(false);
        }
      };

      useEffect(() => {
        fetchSuppliers();
        fetchPoDetails();
        fetchItems();
      }, []);


      const fetchItems = async () => {
        try {
          const response = await axios.get(`${var_api}/api/raw-items/get-items/${shopId}`);
          console.log("stock Items",response.data)
          setItems(response.data);
        } catch (error) {
          console.error('Error fetching items:', error);
          toast('Error Fetching items');
        }
      };


      const formatDateForPost = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
      };


      const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);
        const formattedData = {
          ...formData,
          purchase_order_date: formatDateForPost(formData.purchase_order_date),
          required_date: formatDateForPost(formData.required_date),
          purchase_order_amount:0
        };
        axios.post(`${var_api}/api/purchase-orders/post`, formattedData)
          .then(response => {
            console.log(response.data);
            toast('Purchase order created successfully');
            setFormData({
              purchase_order_date: " ",
              required_date: "",
              supplier_id: null,
              supplier_refno: '',
              supplier_cont_person: '',
              contact_no: '',
              email: '',
              discount_per: '',
              request_type: '',
              terms:null,
              term_of_packing: '',
              supplier_address: '',
              purchase_order_amount: 0,
              gst_amount:null,
              total_amount: 0,
              net_amount: 0,
              inpurchase_order_status: 0,
              inprogress_status: 0,
              purchase_order_status: 0,
              special_instruction: "",
              created_date: formatDate(new Date()),
              updated_date: formatDate(new Date()),
              shop_id: shopId,
              revision:" ",
              is_active: 0
            })
            handleClose();
            fetchPoDetails();
            setIsLoading(false);
          })
          .catch(error => {
            console.error('There was an error creating the purchase order!', error);
            setIsLoading(false);
            toast("There was an error creating the purchase order!")
          });
      };


      const handleReset = () => {
        setLatestAddedPO({
          purchase_order_no: '',
    purchase_order_date: '',
    required_date: '',
    supplier_name: '',
    supplier_refno: '',
    supplier_cont_person: '',
    contact_no: '',
    email: '',
    terms: '',
    request_type: '',
    purchase_order_status: '',
    inprogress_status: '',
    special_instruction: '',
    term_of_packing: '',
    supplier_address: '',
    discount_per: '',
    purchase_order_amount: '',
    gst_amount: '',
        });
        setSelectedPOid(null);
        setSelectedPOno(null);
      }

 
      const handleToSave = async() => {
        setIsLoading(true);
        const updatedData = {
          purchase_order_date: formatDateForPost(latestAddedPO.purchase_order_date),
          required_date: formatDateForPost(latestAddedPO.required_date),
          supplier_refno: latestAddedPO.supplier_refno,
          purchase_order_status: 1
        }
          try {
            const response = await axios.put(`${var_api}/api/purchase-orders/put/${latestAddedPO.id}`, updatedData);
            console.log('Update response:', response.data);
            toast('Purchase order updated successfully');
            setIsEditMode(false);
            fetchSelectedPO(latestAddedPO.id);
          } catch (error) {
            console.error('Error updating purchase order:', error);
            toast('Error updating purchase order');
          }
          finally{
            setIsLoading(false);
          }
      }

      const handleToEdit = () => {
        setIsEditMode(true);
      }


      const handleEditChange = (e) => {
        const { id, value } = e.target;
       
        setLatestAddedPO(prevState => ({
          ...prevState,
          [id]: value
        }));
      };


      const formatDateForInput = (date) => {
        const [day, month, year] = date.split('-');
        console.log("check", day,month,year);
        return `${year}-${month}-${day}`;
      };

      const handleChangeStockCode = (e) => {
        const [stockId, stockNo] = e.target.value.split(',');
        console.log("Selected Stock id & No:", stockId, stockNo); 
    
        // Find the selected item
        const selectedItem = items.find(item => item.id === parseInt(stockId));
        console.log("Selected item:", selectedItem); 
        setSelectedItem(selectedItem);
      }


      const handleAddSotckItems = () => {

        if(!selectedItem){
          toast("please select the stock code");
          return;
        }
        setIsLoading(true);
        const formattedData = {
          purchase_order_id: selectedPOid,
          stock_code: selectedItem.stock_code,
          non_stock_code: "",
          required_qty: 0,
          free_stock_qty: 0,
          indent_qty: 0,
          spa_qty: 0,
          reserved_qty: 0,
          ordered_qty: 0,
          received_qty: 0,
          indent_no: 0,
          spa_no: 0,
          unit_price: selectedItem.default_unit_purchase_price,
          imw_code_price: 0,
          created_by:0,
          updated_by:0,
          non_stock_item:0,
          discount_val: 0,
          uom: selectedItem.uom_name,
          tmp_free_stock_qty: 0,
          tmp_good_receipt_qty: 0,
          tmp_cancel_qty: 0,
          isexist_spa: false,
          purchase_plan_item_suppl:0,
          remarks: "",
          shop_id: parseInt(shopId),
          discount_per:0,
          net_unit_price: 0,
          total_net_unit_price: 0,
          required_date: new Date().toISOString(),
          created_date:new Date().toISOString(),
          updated_date:new Date().toISOString()
        };
        axios.post(`${var_api}/api/purchase-order-items/post`, formattedData)
          .then(response => {
            console.log(response.data);
            toast('Purchase order item added successfully');
            handleClose();
            fetchStockItems(selectedPOid);
            setIsLoading(false);
          })
          .catch(error => {
            console.error('There was an error creating the purchase order!', error);
            toast('There was an error adding the purchase order items!')
            setIsLoading(false);
          });

      }


      const handleCreateNonStockCode = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formattedData = {
          purchase_order_id: selectedPOid,
          stock_code: '',
          non_stock_code: nonStockCode,
          required_qty: 0,
          free_stock_qty: 0,
          indent_qty: 0,
          spa_qty: 0,
          reserved_qty: 0,
          ordered_qty: 0,
          received_qty: 0,
          indent_no: 0,
          spa_no:0,
          unit_price: nonStockPrice,
          imw_code_price: 0,
          created_by: 0,
          updated_by: 0,
          non_stock_item: 0,
          discount_val: 0,
          uom: nonStockUOM,
          tmp_free_stock_qty: 0,
          tmp_good_receipt_qty: 0,
          tmp_cancel_qty: 0,
          isexist_spa: false,
          purchase_plan_item_suppl: 0,
          remarks: "",
          shop_id:shopId,
          discount_per:nonStockDiscount,
          net_unit_price: 0,
          total_net_unit_price: 0,
          required_date: new Date().toISOString(),
          created_date:new Date().toISOString(),
          updated_date:new Date().toISOString()
        };
        axios.post(`${var_api}/api/purchase-order-items/post`, formattedData)
          .then(response => {
            console.log(response.data);
            toast('Purchase order item added successfully');
            handleCloseNonStock();
            fetchStockItems(selectedPOid);
            setNonStockCode("");
            setNonStockUOM("");
            setNonStockPrice(null);
            setNonStockDiscount(null);
            setIsLoading(false);
          })
          .catch(error => {
            console.error('There was an error creating the purchase order!', error);
            setIsLoading(false);
            toast('Error adding non-stock code');
          });

      }

      const handleAddNonSotckItems = () =>{
        setIsOpenNonStock(true);
      }


      const handleNonStockReset = () => {
        setNonStockCode('');
        setNonStockUOM("");
        setNonStockPrice('');
        setNonStockDiscount('');
      }


      const handleOpenEditMode = ( index) =>{
        const newEditModes = [...editModes];
        newEditModes[index] = true;
        setEditModes(newEditModes);
      }

      const handleCancelEditMode = (index) => {
        const newEditModes = [...editModes];
        newEditModes[index] = false;
        setEditModes(newEditModes);
        setEditedDiscounts(stockItems.map(item => item.discount_per)); // Reset edited discounts to original values
        setEditedRequiredQty(stockItems.map(item => item.required_qty)); 
        setEditedOrderedQty(stockItems.map(item => item.ordered_qty)); 
        setEditedUnit_price(stockItems.map(item => item.unit_price)); 
        setNetUnitPrices(stockItems.map(item => item.unit_price * (1 - item.discount_per / 100))); // Reset net unit prices to original values
      };


      // const handleSave = (index, items) => {
      //   const item = stockItems[index];
      //   const newNetUnitPrices = [...netUnitPrices];
      //   const newDiscount = [...editedDiscounts];
      //   newNetUnitPrices[index] = (stockItems[index].unit_price || items.unit_price) * (1 - (newDiscount[index] || items.discount_per) / 100);
      //   setNetUnitPrices(newNetUnitPrices);
      //   const balQty  = (parseInt(editedOrderedQty[index]) || item.ordered_qty) - (item.received_qty)
      //   const totalNet = netUnitPrices[index] * parseInt(editedOrderedQty[index]);
      //   const updatedItem = { discount_per: editedDiscounts[index] || item.discount_per, 
      //                         net_unit_price: netUnitPrices[index] || item.net_unit_price, 
      //                         required_qty: parseInt(editedRequiredQty[index]) || item.required_qty, 
      //                         ordered_qty: parseInt(editedOrderedQty[index]) || item.ordered_qty, 
      //                         balance_qty: balQty,
      //                         unit_price: parseInt(editedUnitPrice[index]) || item.unit_price,	
      //                         total_net_unit_price: totalNet || item.total_net_unit_price
      //                     };
      //   setIsLoading(true);
      //   axios.put(`${var_api}/api/purchase-order-items/put/${item.id}`, updatedItem)
      //     .then(response => {
      //       console.log('Update successful:', response.data);
      //       // Update the local state to reflect the changes
      //       const newEditModes = [...editModes];
      //       newEditModes[index] = false;
      //       setEditModes(newEditModes);
      //       toast("Updated Successfully");
      //       fetchStockItems(selectedPOid);
      //       setIsLoading(false);
      //     })
      //     .catch(error => {
      //       console.error('There was an error updating the item!', error);
      //       setIsLoading(false);
      //       toast("Error updating the PO items");
      //     });
      // };


      // const handleDiscountChange = (index, newDiscount) => {
      //   const newDiscounts = [...editedDiscounts];
      //   newDiscounts[index] = newDiscount;
      //   setEditedDiscounts(newDiscounts);
      // };


      // const handleRequiredQtyChange = (index, newqty) => {
      //   const newqtyz = [...editedRequiredQty];
      //   newqtyz[index] = newqty;
      //   setEditedRequiredQty(newqtyz);
      // };


      // const handleOrderedQtyChange = (index, newqty) => {
      //   const newqtyz = [...editedOrderedQty];
      //   newqtyz[index] = newqty;
      //   setEditedOrderedQty(newqtyz);
      // };


      // const handleUnitPriceChange = (index, newqty) => {
      //   const newqtyz = [...editedUnitPrice];
      //   newqtyz[index] = newqty;
      //   setEditedUnit_price(newqtyz);
      // };


      const handleSave = async (index) => {
        const item = stockItems[index];
        const discount = item.discount_per || 0;
        const unitPrice = item.unit_price || 0;
        const orderedQty = item.ordered_qty || 0;
        const receivedQty = item.received_qty || 0;
    
        const netUnitPrice = unitPrice * (1 - discount / 100);
        const balanceQty = orderedQty - receivedQty;
        const totalNetUnitPrice = netUnitPrice * orderedQty;
    
        const updatedItem = {
            ...item,
            discount_per: item.discount_per,
            net_unit_price: netUnitPrice,
            required_qty: item.required_qty,
            ordered_qty: orderedQty,
            balance_qty: balanceQty,
            unit_price: unitPrice,
            total_net_unit_price: totalNetUnitPrice
        };
    
        setIsLoading(true);
        try {
            const response = await axios.put(`${var_api}/api/purchase-order-items/put/${item.id}`, updatedItem);
            console.log('Update successful:', response.data);
            const newEditModes = [...editModes];
            newEditModes[index] = false;
            setEditModes(newEditModes);
            toast("Updated Successfully");
            fetchStockItems(selectedPOid);
        } catch (error) {
            console.error('There was an error updating the item!', error);
            toast("Error updating the PO items");
        } finally {
            setIsLoading(false);
        }
    };
    



      const handleInputChange = (index, field, value) => {
        const newStockItems = [...stockItems];
        newStockItems[index] = {
            ...newStockItems[index],
            [field]: value
        };
        setStockItems(newStockItems);
    };
    



       //Go to home page
    const handleButtonClick = () => {
      history.push('/home')
    }

    // //post button
    const handlePostPO = async() => {
      setIsLoading(true);

      if(stockItems.length == 0){
        swal('Validation Error', 'Add stock Items!', 'error');
        setIsLoading(false);
        return;
      }


      const isValid = stockItems.every(item => item.required_qty > 0 && item.ordered_qty > 0);

      if (!isValid) {
        swal('Validation Error', 'Required and Ordered quantities must be greater than 0 for all items.', 'error');
        setIsLoading(false);
        return;
      }
      const updatedData = {
        purchase_order_status: 2,
        total_amount:totalAmount
      }
        try {
          const response = await axios.put(`${var_api}/api/purchase-orders/put/${latestAddedPO.id}`, updatedData);
          console.log('Update response:', response.data);
          toast('Purchase order updated successfully');
          setIsEditMode(false);
          fetchSelectedPO(latestAddedPO.id);
        } catch (error) {
          console.error('Error updating purchase order:', error);
          toast('Error updating purchase order');
        }
        finally{
          setIsLoading(false);
        }
    }
    // const generateGRN = async () => {
    //   setIsLoading(true);
    //   console.log("po",latestAddedPO)
    //      // Prepare GRN Items data
    //      const grnItemsData = stockItems.map(item => ({
    //       shop_id: shopId,
    //       po_detail_stock_id: item.id,
    //       po_detail_stock_code: item.stock_code || item.non_stock_code,
    //       grn_quantity_received: item.received_qty,
    //       service_amount_per_unit: item.unit_price,
    //       stock_in_itemLevel: 0, //doubt
    //       dp_mos_amount_per_unit: 0, //doubt
    //       tc_onwards_amount_per_unit: 0, //doubt
    //       accounts_miscellaneous_types_id: 0, //doubt
    //       created_at: new Date().toISOString(),
    //       updated_at: new Date().toISOString(),
    //     }));
  
    //   // Prepare GRN data
    //   const grnData = {
    //     shop_id: shopId,
    //     grn_date: formatDate(new Date()), // Current date in YYYY-MM-DD format
    //     store_id: 1, //doubt
    //     grn_type: "Type", //dpubt
    //     po_number: latestAddedPO.purchase_order_no,
    //     supplier_do_no: 0, //doubt
    //     supplier_invoice_no: "INV0001", // doubt
    //     posted: 1, //doubt
    //     postby: "User", //doubt
    //     postdate: formatDate(new Date()),
    //     xrate: 1.0, //doubt
    //     stock_in_value: 100.0, //doubt
    //     allocated_amount: 100.0, //doubt
    //     exists_service_supplier: 1, //doubt
    //     currency: "USD", //doubt
    //     do_date: "",
    //     supplierid: latestAddedPO.supplier_id,
    //     allocated_service_amount: 0, //doubt
    //     service_amount: 0, //doubt
    //     dp_mos_amount: 0, //doubt
    //     tc_onwards_amount: 0, //doubt
    //     foreclose: 0, //doubt
    //     created_at: new Date().toISOString(),
    //     updated_at: new Date().toISOString(),
    //     grn_items: grnItemsData
    //   };
  
    //   try {
    //     // Make the API call to create GRN
    //     const grnResponse = await axios.post(`${var_api}/api/store-grn/post`, grnData);
    //     console.log('result', grnResponse.data);
    //     setIsLoading(false);
    //     toast('GRN and GRN Items created successfully');
    //   } catch (error) {
    //     console.error('Error creating GRN:', error);
    //     setIsLoading(false);
    //     toast('Failed to create GRN');
    //   }
    // };


    useEffect(() => {
      // Calculate PO Amount and Discount Amount
      const newPoAmount = stockItems.reduce((sum, item) => sum + (item.total_net_unit_price || 0), 0);
      const newDiscountAmount = stockItems.reduce((sum, item) => sum + (item.discount_per || 0), 0);
  
      // Calculate Sub Total
      const newSubTotal = newPoAmount + newDiscountAmount;
  
      // Calculate Total Amount
      const newTotalAmount = newSubTotal + (latestAddedPO && latestAddedPO.gst_amount || 0);
  
      // Update state with the calculated values
      setPoAmount(newPoAmount);
      setDiscountAmount(newDiscountAmount);
      setSubTotal(newSubTotal);
      setTotalAmount(newTotalAmount);
    }, [stockItems, latestAddedPO]);

  

    const handlePrint = () => {
      const doc = new jsPDF();
      const shopName = localStorage.getItem('shopName');

        // Get current date and time
        const now = new Date();
        const formattedDate = now.toLocaleDateString('en-GB'); // Format: dd/mm/yyyy
        const formattedTime = now.toLocaleTimeString('en-GB'); // Format: HH:mm:ss

         // Centered shop name
       doc.setFontSize(18);
       doc.text("PO Report", doc.internal.pageSize.getWidth() / 2, 10, 'center');
    
      // Centered shop name
      doc.setFontSize(13);
      doc.text(shopName, doc.internal.pageSize.getWidth() / 2, 16, 'center');

       // Current date and time at the top right corner
  doc.setFontSize(10);
  doc.text(`${formattedDate} ${formattedTime}`, doc.internal.pageSize.getWidth() - 15, 10, { align: 'right' });
    
      // Supplier details on the left
      const supplierDetails = [
        `Supplier Name: ${latestAddedPO.supplier_name}`,
        `Supplier Address: ${latestAddedPO.supplier_address}`,
        `Supplier Contact: ${latestAddedPO.supplier_contact}`,
      ];
      doc.setFontSize(8);
      supplierDetails.forEach((detail, index) => {
        doc.text(detail, 20, 30 + (index * 10));
      });
    
      // PO details on the right
      const poDetails = [
        `PO Number: ${latestAddedPO.purchase_order_no}`,
        `PO Date: ${latestAddedPO.purchase_order_date}`,
        `Supplier Ref.no: ${latestAddedPO.supplier_refno}`,
        `Required Date: ${latestAddedPO.required_date}`,
      ];
      poDetails.forEach((detail, index) => {
        doc.text(detail, doc.internal.pageSize.getWidth() - 70, 30 + (index * 10));
      });
    
      // Table with stock items
      const tableColumn = ["Stock Code", "UOM", "Unit Price", "Discount %", "Net Unit Price", "Total Net Price"];
      const tableRows = [];
    
      stockItems.forEach(item => {
        const rowData = [
          item.stock_code || item.non_stock_code,
          item.uom,
          item.unit_price,
          item.discount_per,
          item.net_unit_price,
          item.total_net_unit_price
        ];
        tableRows.push(rowData);
      });
    
      // Add some vertical space before the table
      doc.autoTable(tableColumn, tableRows, { startY: 70 });


  // Add the PO summary section on the right side after the table
  const finalY = doc.lastAutoTable.finalY + 10;
  const summaryX = 155;
  // const summaryX = doc.internal.pageSize.getWidth() - 90; // Adjust this value as needed

  doc.setFontSize(10);
  doc.text(`Discount Amount: ${discountAmount}`, summaryX, finalY + 10);
  doc.text(`Sub Total: ${subTotal}`, summaryX, finalY + 20);
  doc.text(`GST Amount: ${latestAddedPO.gst_amount}`, summaryX, finalY + 30);
    // Draw a line before the Total Amount
    const lineY = finalY + 40; // Y-coordinate for the line
    doc.setLineWidth(0.5); // Line thickness
    doc.line(summaryX - 40, lineY, summaryX + 80, lineY); // Draw horizontal line
    
    // Add the Total Amount below the line
    doc.text(`Total Amount: ${totalAmount.toFixed(2)}`, summaryX, lineY + 10);

       // Add signature sections in the same line with small fonts
  const signatureY = lineY + 30; // Y-coordinate for the signature section
  doc.setFontSize(9);
  doc.text('Issued By:', 10, signatureY);
  doc.text('Approved By:', doc.internal.pageSize.getWidth() / 2, signatureY); // Centered horizontally

  // Draw signature lines
  doc.setLineWidth(0.5); // Line thickness
  doc.line(50, signatureY + 2, 90, signatureY + 2); // Line for Issued By
  doc.line(doc.internal.pageSize.getWidth() / 2 + 60, signatureY + 2, doc.internal.pageSize.getWidth() / 2 + 130, signatureY + 2); // Line for Approved By
    
      // Save the PDF
      doc.save(`Purchase_Order_${latestAddedPO.id}.pdf`);
    };
    

     
    return(
        <>
          {isLoading && <Loader />}
        <Navbar expand="sm" className="bg-body-tertiary bg-success p-0" style={{padding:"0px"}}>
            <Container className="p-0">
                <Navbar.Brand className="p-0 text-light fw-bolder">PURCHASE ORDERS</Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                 <button className="btn btn-outline-success" onClick={handleButtonClick} style={{backgroundColor:"white",color:"green"}}>
                  <i class="bi bi-house"></i> Go to Home</button>
                </Navbar.Collapse>
           </Container>
        </Navbar>
        <Card style={{ Width: '100%', height:"auto", margin:"15px", borderRadius:"5px", border:"none", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding:"10px" }}>
            <Card.Body>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
                <Button variant="secondary" style={{ marginRight: '10px', padding:"3px 7px"}} onClick={handleToOpenCreatePO}  disabled={isEditMode}>Create PO</Button>
                  <Button variant="primary" style={{ marginRight: '10px', padding:"3px 7px" }} disabled={isEditMode || latestAddedPO && latestAddedPO.purchase_order_status == 2} onClick={handleToEdit}>Edit</Button>
                  <Button variant="success" style={{ marginRight: '10px', padding:"3px 7px" }} disabled={!isEditMode || latestAddedPO && latestAddedPO.purchase_order_status == 2} onClick={handleToSave}>Save</Button>
                <Button variant="danger" style={{ marginRight: '10px', padding:"3px 7px"}} onClick={handleReset}>Reset</Button>
                <Button variant="success" style={{ padding:"3px 7px",  marginRight: '10px'}}  disabled={isEditMode} onClick={handlePostPO}>Post</Button>
                <Button variant="warning" style={{ padding:"3px 7px"}}  disabled={isEditMode} onClick={handlePrint}><i class="bi bi-printer"></i> Print</Button>
            </div>
            <br/>

            {

              poLoader ? (
                <div class="text-center" >
                <div class="spinner-border" role="status" >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              ) : (

                latestAddedPO && (
                  <Form>
            <Row >
              <Col>
                <Form.Group controlId="field1">
                  <Form.Label>P/O No.</Form.Label>
                  <Form.Control as="select" value={`${selectedPOid},${selectedPOno}`} onChange={handleChangePOno}>
                    <option value="">Select P/O No</option>
                    {latestPODetails.map(po => (
                      <option key={po.id} value={`${po.id},${po.purchase_order_no}`}>
                        {po.purchase_order_no}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="purchase_order_date">
                  <Form.Label>P/O Date</Form.Label>
                  <Form.Control type="date" value={formatDateForInput(latestAddedPO.purchase_order_date)} readOnly={!isEditMode} onChange={handleEditChange}/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="required_date">
                  <Form.Label>Required Date</Form.Label>
                  <Form.Control type="date" value={formatDateForInput(latestAddedPO.required_date)} readOnly={!isEditMode} onChange={handleEditChange}/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="supplier_name">
                  <Form.Label>Supplier</Form.Label>
                  <Form.Control type="text" value={latestAddedPO.supplier_name} readOnly/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="supplier_refno">
                  <Form.Label>Supplier Ref</Form.Label>
                  <Form.Control type="text" value={latestAddedPO.supplier_refno} readOnly={!isEditMode} onChange={handleEditChange}/>
                </Form.Group>
              </Col>
            </Row>
  
            <Row className="mt-2">
             
              <Col>
                <Form.Group controlId="field6">
                  <Form.Label>Contact Person</Form.Label>
                  <Form.Control type="text" value={latestAddedPO.supplier_cont_person} readOnly/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="field7">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control type="text" value={latestAddedPO.contact_no} readOnly/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="field8">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="text" value={latestAddedPO.email} readOnly/>
                </Form.Group>
              </Col>
            </Row>
  
  
  
            <Row className="mt-2">
            <Col></Col>
              <Col>
                  <Form.Label>Terms: <span style={{color:"red"}}>{latestAddedPO.terms}</span> days</Form.Label>
              </Col>
              <Col>
                  <Form.Label>Request Type: <span style={{color:"red"}}> {latestAddedPO.request_type}</span></Form.Label>
              </Col>
              <Col>
                  <Form.Label>P/O Status: <span style={{color:"red"}}>{latestAddedPO.purchase_order_status == 0 ? "Draft" : latestAddedPO.purchase_order_status == 1 ? "submitted" : "P/O Generated"}</span></Form.Label>
              </Col>
            
            </Row>
  
  
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <Form.Group controlId="textArea1" style={{ flex: '0 0 17%', marginRight: '20px' }}>
                <Form.Label>Special Instrcution</Form.Label>
                <Form.Control as="textarea" value={latestAddedPO.special_instruction} rows={9} readOnly/>
              </Form.Group>
              <Form.Group controlId="textArea2" style={{ flex: '0 0 17%', marginRight: '20px' }}>
                <Form.Label>Terms of Packing</Form.Label>
                <Form.Control as="textarea" value={latestAddedPO.term_of_packing} rows={9} readOnly/>
              </Form.Group>
              <Form.Group controlId="textArea3" style={{ flex: '0 0 17%', marginRight: '20px' }}>
                <Form.Label>Supplier Address</Form.Label>
                <Form.Control as="textarea" value={latestAddedPO.supplier_address} rows={9} readOnly/>
              </Form.Group>
              <Card style={{ flex: '0 0 43%', height: '100%', padding:"10px",borderRadius:"5px", border:"none", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                <Card.Body>
                <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
                    {/* <div style={{ flex: '0 0 auto' }}>
                      <Form.Label>Discount %</Form.Label>
                    </div>
                    <div style={{ flex: '1' }}>
                      <Form.Control type="number" value={latestAddedPO.discount_per} readOnly/>
                    </div> */}
                    <div style={{ flex: '0 0 auto' }}>
                      <Form.Label>Rounding Adj.</Form.Label>
                    </div>
                    <div style={{ flex: '1' }}>
                      <Form.Control type="number" placeholder="Enter" readOnly={!isEditMode}/>
                    </div>
                  </div>
                  <br/>
                  <div style={{maxWidth:"70%"}}>
                  <h6>
          P/O Amount <span style={{ fontSize: '18px', color: 'blue', float: 'right' }}>{poAmount}</span>
        </h6>
        <h6>
          Discount Amount<span style={{ color: 'red' }}> (consolidated)</span> <span style={{ fontSize: '18px', color: 'blue', float: 'right' }}>{discountAmount}</span>
        </h6>
        <h6>
          Sub Total <span style={{ fontSize: '18px', color: 'blue', float: 'right' }}>{subTotal}</span>
        </h6>
        <h6>
          GST Amount <span style={{ fontSize: '18px', color: 'blue', float: 'right' }}>{latestAddedPO.gst_amount}</span>
        </h6>
        <h6>
          Total Amount <span style={{ fontSize: '18px', color: 'blue', float: 'right' }}>{totalAmount}</span>
        </h6>
                  </div>
                </Card.Body>
              </Card>
            </div>
  
  
  
          </Form>
                )

              )
              
            }
            </Card.Body>
        </Card>
        <br/>








        {/* {
                  latestAddedPO && latestAddedPO.purchase_order_status != 2 && <> */}
        <div style={{ padding:"10px", maxWidth: "60%", display:"flex"}}>
        <Form.Label className="fw-bold text-success">Stock Code: </Form.Label>
        <Form.Group controlId="field11" style={{marginLeft:"10px"}}>
                <Form.Control as="select" onChange={handleChangeStockCode} readOnly={ latestAddedPO && latestAddedPO.purchase_order_status == 2} >
                <option value="">Enter Stock Code</option>
        {items.map(item => (
          <option key={item.id} value={`${item.id},${item.stock_code}`}>
            {item.stock_code} {/* Assuming stock_code is the field you want to display */}
          </option>
        ))}
                </Form.Control>
              </Form.Group>
            
              <Button variant="success" style={{ padding:"3px 7px", marginLeft:"10px"}} onClick={handleAddSotckItems} disabled={latestAddedPO && latestAddedPO.purchase_order_status == 2}><i class="bi bi-plus-circle"></i></Button>
              <Button variant="info" style={{ padding:"3px 7px", marginLeft:"10px", color: 'white'}} onClick={handleAddNonSotckItems} disabled={latestAddedPO && latestAddedPO.purchase_order_status == 2}><i class="bi bi-cone-striped"></i> Non Stock Code</Button>
             
        </div>
        {/* </>
                } */}


        {

          poItemsLoader ? (
            <div class="text-center" >
            <div class="spinner-border" role="status" >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          ) : (

            stockItems.length === 0 ? (
              <Card style={{ Width: '100%', height: "auto", margin: "15px", borderRadius: "5px", border: "none", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding: "10px" }}>
                <Card.Header style={{ fontSize: "18px", color: "blue" }}>No Stock Items</Card.Header>
                <Card.Body>
                  <p style={{textAlign:"center"}}>No stock items available at the moment.</p>
                </Card.Body>
              </Card>
            ) : (

            stockItems.map((items, index)=>(
              <Card style={{ Width: '100%', height:"auto", margin:"15px", borderRadius:"5px", border:"none", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding:"10px" }}>
              <Card.Header style={{fontSize:"20px", color:"blue"}}>{index+1}<span style={{float:"right", fontWeight:"bolder"}}>
              {
                  editModes[index]  ? (
                   <>
                    <button className="btn btn-success" onClick={() => handleSave(index, items)}>Save</button> {" "}
                    <button className="btn btn-danger" onClick={() => handleCancelEditMode(index)}>Cancel</button>
                   </>
                  ) :  <button className="btn btn-primary" disabled={latestAddedPO && latestAddedPO.purchase_order_status == 2} style={{padding:"4px 10px"}}>
                    <i class="bi bi-pencil-fill" style={{fontSize:"15px"}} onClick={()=>handleOpenEditMode(index)}></i>
                  </button>
                }
               </span></Card.Header>
                  <Card.Body>
                  <Form>
                <Row >
                  <Col>
                    <Form.Group controlId="field1">
                      <Form.Label>Stock Code</Form.Label>
                      <Form.Control type="text" value={items.stock_code || items.non_stock_code} readOnly/>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="uom_name">
                      <Form.Label>UOM</Form.Label>
                      <Form.Control type="text" value={items.uom} readOnly/>
                    </Form.Group>
                  </Col>
                  {/* <Col>
                    <Form.Group controlId="field3">
                      <Form.Label>Unit Price</Form.Label>
                      <Form.Control type="number" value={editedUnitPrice[index] || items.unit_price} readOnly ={!editModes[index]} onChange={(e) => handleUnitPriceChange(index, e.target.value)} />
                    </Form.Group>
                  </Col> */}
                    <Col>
                        <Form.Group controlId={`unit_price_${index}`}>
                            <Form.Label>Unit Price</Form.Label>
                            <Form.Control type="number" value={items.unit_price} readOnly={!editModes[index]} onChange={(e) => handleInputChange(index, 'unit_price', e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={`discount_${index}`}>
                            <Form.Label>Discount %</Form.Label>
                            <Form.Control type="number" value={items.discount_per} readOnly={!editModes[index]} onChange={(e) => handleInputChange(index, 'discount_per', e.target.value)} />
                        </Form.Group>
                    </Col>
                  {/* <Col>
                    <Form.Group controlId="field4">
                      <Form.Label>Discount %</Form.Label>
                      <Form.Control type="number" placeholder="Enter Discount%"   value={editedDiscounts[index] || items.discount_per} readOnly={!editModes[index]} onChange={(e) => handleDiscountChange(index, e.target.value)}/>
                    </Form.Group>
                  </Col> */}
                  {/* <Col>
                    <Form.Group controlId="field5">
                      <Form.Label>Rounding Adj</Form.Label>
                      <Form.Control type="number" placeholder="Enter Rounding Adj" />
                    </Form.Group>
                  </Col> */}
                  {/* <Col>
                    <Form.Group controlId="netunitprice">
                      <Form.Label>Net Unit Price</Form.Label>
                      <Form.Control type="number" placeholder="Enter Net Unit Price" value={items.net_unit_price} readOnly/>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="field1">
                      <Form.Label>Required</Form.Label>
                      <Form.Control type="number" placeholder="Enter Stock Code" value={editedRequiredQty[index] || items.required_qty} readOnly ={!editModes[index]} onChange={(e) => handleRequiredQtyChange(index, e.target.value)}/>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="field1">
                      <Form.Label>Ordered</Form.Label>
                      <Form.Control type="number" placeholder="Enter Stock Code" value={editedOrderedQty[index] || items.ordered_qty} readOnly ={!editModes[index]} onChange={(e) => handleOrderedQtyChange(index, e.target.value)}/>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="field2">
                      <Form.Label>Received</Form.Label>
                      <Form.Control type="number" placeholder="Enter UOM" value={items.received_qty} readOnly/>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="field3">
                      <Form.Label>Balance</Form.Label>
                      <Form.Control type="number" placeholder="Enter balance qty" value={items.balance_qty} readOnly/>
                    </Form.Group>
                  </Col> */}
                    <Col>
                        <Form.Group controlId={`net_unit_price_${index}`}>
                            <Form.Label>Net Unit Price</Form.Label>
                            <Form.Control type="number" value={items.net_unit_price} readOnly />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={`required_qty_${index}`}>
                            <Form.Label>Required</Form.Label>
                            <Form.Control type="number" value={items.required_qty} readOnly={!editModes[index]} onChange={(e) => handleInputChange(index, 'required_qty', e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={`ordered_qty_${index}`}>
                            <Form.Label>Ordered</Form.Label>
                            <Form.Control type="number" value={items.ordered_qty} readOnly={!editModes[index]} onChange={(e) => handleInputChange(index, 'ordered_qty', e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={`received_qty_${index}`}>
                            <Form.Label>Received</Form.Label>
                            <Form.Control type="number" value={items.received_qty} readOnly />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={`balance_qty_${index}`}>
                            <Form.Label>Balance</Form.Label>
                            <Form.Control type="number" value={items.balance_qty} readOnly />
                        </Form.Group>
                    </Col>
                  <Col>
                    <Form.Group controlId="field5">
                      <Form.Label>Total Net Price</Form.Label>
                      <Form.Control type="number" placeholder="Enter Total Net Price" value={items.total_net_unit_price} readOnly/>
                    </Form.Group>
                  </Col>
                </Row>
      
      <br/>
                <Row>
                 
                  {/* <Col style={{flex: 3}}>
                    <Form.Group controlId="field4">
                      <Form.Label>LMW Code-T6</Form.Label>
                      <Form.Control type="number" placeholder="Enter Discount%" />
                    </Form.Group>
                  </Col>
                  <Col style={{flex: 4}}>
                    <Form.Group controlId="field5">
                      <Form.Label>Text Description</Form.Label>
                      <Form.Control as="textarea" placeholder="Enter text here" rows={5} />
                    </Form.Group>
                  </Col> */}
                
                </Row>
                </Form>
                  </Card.Body>
              </Card>
      
  
            ))

          ))
         
        }
       
        <Modal show={isCreatePO} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Create PO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group controlId="purchase_order_date">
                <Form.Label>P/O Date</Form.Label>
                <Form.Control type="date" placeholder="Enter P/O Date" value={formData.purchase_order_date} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="required_date">
                <Form.Label>Required Date</Form.Label>
                <Form.Control type="date" placeholder="Enter Required Date" value={formData.required_date} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="supplier_id">
                <Form.Label>Supplier</Form.Label>
                <Form.Control as="select" value={formData.supplier_id} onChange={handleChange}>
                  <option value="">Select Supplier</option>
                  {suppliers.map(supplier => (
                    <option key={supplier.id} value={supplier.id}>
                      {supplier.supplier_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="supplier_refno">
                <Form.Label>Supplier Ref</Form.Label>
                <Form.Control type="text" placeholder="Enter Supplier Ref" value={formData.supplier_ref} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="supplier_cont_person">
                <Form.Label>Contact Person</Form.Label>
                <Form.Control type="text" placeholder="Enter Contact Person" value={formData.contact_person} onChange={handleChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Form.Group controlId="contact_no">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control type="number" placeholder="Enter Contact No" value={formData.contact_number} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="Enter Email" value={formData.email_address} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="terms">
                <Form.Label>Terms</Form.Label>
                <Form.Control type="number" placeholder="Enter terms" value={formData.terms} onChange={handleChange} />
              </Form.Group>
            </Col>
            {/* <Col>
              <Form.Group controlId="discount_per">
                <Form.Label>Discount %</Form.Label>
                <Form.Control type="text" placeholder="Enter discount" value={formData.discount} onChange={handleChange} />
              </Form.Group>
            </Col> */}
            <Col>
              <Form.Group controlId="request_type">
                <Form.Label>Request Type</Form.Label>
                <Form.Control as="select" value={formData.request_type} onChange={handleChange}>
                  <option value="">Select Request Type</option>
                  <option value="Component">Component</option>
                  <option value="type2">Type 2</option>
                  <option value="type3">Type 3</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <div style={{ display: 'flex', marginTop: '10px' }}>
          {/*  <Form.Group controlId="purchase_order_amount" style={{ flex: '0 0 18%', marginRight: '20px' }}>
               <Form.Label>PO Amount</Form.Label>
                <Form.Control type="number" placeholder="Enter PO amount" value={formData.po_amount} onChange={handleChange} />
              </Form.Group>
              <Form.Group controlId="gst_amount" style={{ flex: '0 0 18%', marginRight: '20px' }}>
                <Form.Label>GST Amount</Form.Label>
                <Form.Control type="number" placeholder="Enter GST amount" value={formData.gst_amount} onChange={handleChange} />
              </Form.Group> */}
            <Form.Group controlId="special_instruction" style={{ flex: '0 0 30%', marginRight: '20px' }}>
              <Form.Label>Special Instruction</Form.Label>
              <Form.Control as="textarea" placeholder="Enter text here" rows={9} value={formData.special_instruction} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="term_of_packing" style={{ flex: '0 0 30%', marginRight: '20px' }}>
              <Form.Label>Terms of Packing</Form.Label>
              <Form.Control as="textarea" placeholder="Enter text here" rows={9} value={formData.term_of_packing} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="supplier_address" style={{ flex: '0 0 30%', marginRight: '20px' }}>
              <Form.Label>Supplier Address</Form.Label>
              <Form.Control as="textarea" placeholder="Enter text here" rows={9} value={formData.supplier_address} onChange={handleChange} />
            </Form.Group>
          </div>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>


    <Modal show={isOpenNonStock} onHide={handleCloseNonStock} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Non Stock Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form onSubmit={handleCreateNonStockCode}>
              <Row >
                <Col style={{flex: 2}}>
                  <Form.Group controlId="field1">
                    <Form.Label className="text-success">Stock Code</Form.Label>
                    <Form.Control type="text" placeholder="Enter Stock Code" value={nonStockCode} onChange={(e)=>setNonStockCode(e.target.value)}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="uom_name">
                    <Form.Label className="text-success">UOM</Form.Label>
                    <Form.Control type="text" placeholder="Enter Uom Name" value={nonStockUOM} onChange={(e)=>setNonStockUOM(e.target.value)}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="field3">
                    <Form.Label className="text-success">Unit Price</Form.Label>
                    <Form.Control type="number" placeholder="Enter Unit Price" value={nonStockPrice} onChange={(e)=>setNonStockPrice(e.target.value)}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="field4">
                    <Form.Label className="text-success">Discount %</Form.Label>
                    <Form.Control type="number" placeholder="Enter Discount%" value={nonStockDiscount} onChange={(e)=>setNonStockDiscount(e.target.value)}/>
                  </Form.Group>
                </Col>
                {/* <Col>
                  <Form.Group controlId="field5">
                    <Form.Label>Rounding Adj</Form.Label>
                    <Form.Control type="number" placeholder="Enter Rounding Adj" />
                  </Form.Group>
                </Col> */}
                {/* <Col>
                  <Form.Group controlId="field5">
                    <Form.Label>Net Unit Price</Form.Label>
                    <Form.Control type="number" placeholder="Enter Net Unit Price" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="field1">
                    <Form.Label>Required</Form.Label>
                    <Form.Control type="text" placeholder="Enter Stock Code"/>
                  </Form.Group>
                </Col> */}
                {/* <Col>
                  <Form.Group controlId="field2">
                    <Form.Label>Received</Form.Label>
                    <Form.Control type="number" placeholder="Enter UOM" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="field3">
                    <Form.Label>Balance</Form.Label>
                    <Form.Control type="number" placeholder="Enter Unit Price" />
                  </Form.Group>
                </Col> */}
                {/* <Col>
                  <Form.Group controlId="field5">
                    <Form.Label>Total Net Price</Form.Label>
                    <Form.Control type="number" placeholder="Enter Total Net Price" />
                  </Form.Group>
                </Col> */}
              </Row>
    
    <br/>
              <Row>
               
                {/* <Col style={{flex: 3}}>
                  <Form.Group controlId="field4">
                    <Form.Label>LMW Code-T6</Form.Label>
                    <Form.Control type="number" placeholder="Enter Discount%" />
                  </Form.Group>
                </Col>
                <Col style={{flex: 4}}>
                  <Form.Group controlId="field5">
                    <Form.Label>Text Description</Form.Label>
                    <Form.Control as="textarea" placeholder="Enter text here" rows={5} />
                  </Form.Group>
                </Col> */}
              
              </Row>
              <Button variant="success" style={{float:"right"}} type="submit">Submit</Button>
              
              </Form>
              <Button variant="danger" style={{float:"right", marginRight:"5px"}} onClick={handleNonStockReset}>Reset</Button>
      </Modal.Body>
    </Modal>

        </>
    )
}

export default PurchaseOrders;