import React from 'react';
import './Settlements.css';
import profile from '../../images/splash/user.png';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useRef } from 'react';
import Select from 'react-select';
import { Tooltip } from 'reactstrap';


const Settlements = () => {
  const history = useHistory();
  const shopName= localStorage.getItem('shopName');
  const [suppliers, setSuppliers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedSupplier, setSelectedSupplier] = useState(null); // State to hold the selected supplier
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [paidAmount, setPaidAmount] = useState(""); 
  const [remarks, setRemarks] = useState(""); 
   const [tables, setTables] = useState([]);
   const [ searchQuery,setSearchQuery] = useState('');
   const [show, setShow] = useState(false);
   const [tableD, setTableD] = useState({});
   const [ isEdit,setIsEdit ] = useState(false);
   const [puttooltipOpen, setPutToolTipOpen] = useState([]);
   const [deletetooltipOpen, setDeleteToolTipOpen] = useState([]);
   const [updateFormData, setUpdateFormData] = useState({
    id:null,
    supplier_name: "",
    paid_amount: "",
    remarks: "",
    shop_id:null
  });
  const fileInputRef = useRef(null);

    useEffect(() => {
        fetchSuppliers();
        fetchSettlements();
      }, [selectedDate]);

      const fetchSuppliers = async () => {
        try {
           const shop = localStorage.getItem('shopId')
           const response = await axios.get(`${var_api}/api/supplier-master/get-supplier/${shop}`);
           console.log("response",response)
          const user =response.data;
          setSuppliers(user);
        } catch (error) {
          console.log(error);
        }
      };


      const togglePutTooltip = (index) => {
        const putTooltipOpen = [...puttooltipOpen];
        putTooltipOpen[index] = !putTooltipOpen[index];
        setPutToolTipOpen(putTooltipOpen);
      };
      
      
      const toggleDeleteTooltip = (index) => {
        const deleteTooltipOpen = [...deletetooltipOpen];
        deleteTooltipOpen[index] = !deleteTooltipOpen[index];
        setDeleteToolTipOpen(deleteTooltipOpen);
      };

      const fetchSettlements = async () => {
        try {
           const shop = localStorage.getItem('shopId');
           const formattedDate = formatDate(selectedDate);
           const response = await axios.get(`${var_api}/api/settlements/get-settlement/${shop}/${formattedDate}`);
           console.log("response",response)
          const user =response.data;
          setTables(user);
        } catch (error) {
          console.log(error);
        }
      };

      const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };
const formatDate = (dateString) => {
    const [yyyy, mm, dd] = dateString.split('-');
    return `${dd}-${mm}-${yyyy}`;
};
      const handleSupplierSelectChange = (selectedOption) => {
        setSelectedSupplier(selectedOption); 
        // Update the selected supplier 
        setSelectedSupplierId(selectedOption.value);
      }; 


      //post the form data
      const Submit = async (e) => {
        e.preventDefault();

      try{
        const response = await axios.post(`${var_api}/api/settlements/post`, {
            supplier_id: selectedSupplierId, // Assuming you have selectedSupplierId state holding the selected supplier ID
            shop_id: localStorage.getItem('shopId') || "",
            paid_amount: paidAmount,
            pay_mode: "CASH",
            remarks: remarks,
            paid_date: getCurrentDate(), // Function to get current date
            paid_time: getCurrentTime(), // Function to get current time
          });
      
          if (response.status === 200) {
            Swal.fire({
              icon:"success",
              text:`Settlement Successfully`,
              confirmButtonColor:"#007500"
            })
            setSelectedSupplier("");
            setPaidAmount("");
            setRemarks("");
            fetchSettlements();
            console.log('Data inserted successfully.')
  
        }
        } catch (error) {
          Swal.fire({
              icon:"error",
              text:`Failed to Create Supplier profile`,
              confirmButtonColor:"#007500"
            })
          console.error('Error:', error);
          // Handle error scenario as needed
        }
      };

      const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        let day = currentDate.getDate();
      
        // Add leading zero if month/day is single digit
        if (month < 10) {
          month = `0${month}`;
        }
        if (day < 10) {
          day = `0${day}`;
        }
      
        return `${day}-${month}-${year}`;
      };
      
      const getCurrentTime = () => {
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
      
        // Add leading zero if minutes is single digit
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      
        return `${hours}:${formattedMinutes}`;
      };
      const handlePaidAmountChange = (event) => {
        setPaidAmount(event.target.value); // Update the paid amount state
      };

      const handleRemarksChange = (event) => {
        setRemarks(event.target.value); // Update the remarks state
      };

    //reset the values
    const handleToReset = () => {
       setSelectedSupplier("");
            setPaidAmount("");
            setRemarks("");
    };

    const getTotalAmount = () => {
        let totalAmount = 0;
        tables.forEach((settlement) => {
          totalAmount += settlement.paid_amount;
        });
        return totalAmount.toFixed(2); // Ensure the total amount is formatted with 2 decimal places
      };


    //Go to home page
    const handleButtonClick = () => {
      history.push('/home')
    }

    //Go to supplier page
    const handleButtonClickSupplier = () => {
      history.push('/supplier-master')
    }


    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };

     //delete method for remove UOM
   const handleDeleteTable = (id, no) => {
    setShow(true);
    setTableD({id:id,table_no:no})
     
    }

    //close the modal
    const handleCloseConfirmation = () => {
      setShow(false)
    }

    const handleDelete = () => {
      axios
      .delete(`${var_api}/api/settlements/settlement-delete/${tableD.id}`)
      .then((response) => {
        if (response.status === 200) {
            fetchSettlements();
          setShow(false);
          Swal.fire("success"," Settlement Removed Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Settlement not found');
          Swal.fire("error","Settlement not found!","error")
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Settlement!","error")
        // Handle the error as needed
      });
    }

    //close the update modal
  const handleEditClose = () =>{
    setIsEdit(false);
  };
//to open the form for slot updation 
const handleEditTable = async (data) => {
    console.log(data);
    setIsEdit(true);
    setUpdateFormData({
        id:data.id,
        supplier_id: data.supplier_id,
      supplier_name: data.supplier_details.supplier_name,
      paid_amount: data.paid_amount,
      remarks: data.remarks,
    })
    console.log("updateFormData",updateFormData);
  };


  const handleUpdateSubmit = async (e) => {
    e.preventDefault(); 
        const requestData = {
            supplier_id:updateFormData.supplier_id,
          paid_amount: updateFormData.paid_amount,
          remarks: updateFormData.remarks,
        };
          
         try {
          // Make a PUT request to update the UOM
          const response = await axios.put(`${var_api}/api/settlements/put/${updateFormData.id}`, requestData, {
            headers: {
              'Content-Type': ' application/json', 
             },
          });
          
          if (response.status === 200) {
            fetchSettlements();
            setIsEdit(false);
          // Category updated successfully
            Swal.fire({
              icon: 'success',
              text: 'Supplier Details updated successfully',
              confirmButtonColor: '#007500',
            });
          } else {
             Swal.fire({
              icon: 'error',
              text: 'Failed to update Supplier Details',
              confirmButtonColor: '#007500',
            });
          }
        } catch (error) {
          console.error('Error updating Supplier Details:', error);
          Swal.fire('Error', 'Failed to update Supplier Details. Please try again.', 'error');
        }
  };


    return(
        <div className='whole' style={{backgroundColor:"white"}}>
            <div class="split left">
                <div class="centered">
                    <h3 className='ushe'>EXPENSE LOG</h3>
                    <br/>
                    <div style={{marginLeft:"12px",padding:"12px"}}>

                    <form onSubmit={Submit}>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Supplier</lable>
            <br/>
            <Select
  className="form-input" // You can add any custom class here if needed
  name="table_no"
  value={selectedSupplier} // Assuming you have selectedSupplier state
  onChange={handleSupplierSelectChange} // Assuming you have a function to handle select change
  options={suppliers.map((supplier) => ({
    value: supplier.id,
    label: supplier.supplier_name
  }))}
  placeholder="Select Supplier"
  isSearchable={true}
/>

            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Paid Amount</lable>
            <input  className="form-input" type='number' name='no_of_seats'  value={paidAmount}  onChange={handlePaidAmountChange} placeholder='Enter Paid Amount'
            required/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Remarks</lable>
            <input className="form-input" type='text' placeholder='Enter Remarks' value={remarks} onChange={handleRemarksChange} />

            </div>
            <br/>
            <br/>
            <button className='btn btn-success' style={{float:"right"}}>Submit</button> {' '}
            </form>
            
            <button className='btn btn-secondary' style={{float:"right",marginRight:"3px"}} onClick={handleToReset} >Reset</button> {' '}
                       
                        
                    </div>
                   
                </div>
            </div>

            <div class="split right">
            <nav className="navbar" style={{ boxShadow: "0px 0px 1px 0px black", backgroundColor: "green", border: "none", padding: "5px", height: "50px", width: "100%", position: "relative"}}>
            <div style={{ display: "flex"}}>
                <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "70px", color: "white" }}>{ shopName }</h3>
                <button className="btn" onClick={handleButtonClickSupplier} style={{ marginLeft:"150px", marginRight:"10px", backgroundColor: "white", color: "green" }}>
                <i class="bi bi-box-seam-fill"></i> Supplier Master
                </button>{" "}
                <button className="btn" onClick={handleButtonClick} style={{  backgroundColor: "white", color: "green" }}>
                    <i class="bi bi-house"></i> Go to Home
                </button>
            </div>
        </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"640px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      
      <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',}}>
  <p style={{marginRight:"40px"}}>No. of Settlements: <span  style={{ fontWeight:"bold"}}>{tables.length}</span></p>
  <p style={{marginRight:"40px"}}>Total Amount: <span  style={{ fontWeight:"bold"}}>{getTotalAmount()}</span></p>
  <div className="card" style={{ width: '300px', marginLeft: '10px' }}>
    <div className="card-body" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
      <p className="card-text" style={{ marginRight: '10px' }}>Date: </p>
      <i className="bi bi-search" style={{ marginRight: '5px', marginBottom: '10px' }}></i>
      <input
        type="date"
        id="yesterdayDateInput"
        className="form-control"
        style={{ marginRight: '5px', marginBottom: '15px', color: "blue" }}
        value={selectedDate}
        onChange={handleDateChange}
      />
    </div>
  </div>
</div>

      {/* <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder"}}>Current Tables</h3> */}
      <br/>
      <br/>
      <br/>
     <div className="table-container" style={{ height:"500px", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",borderColor:"grey",width:"90%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col">S.No</th>
      <th scope="col">Supplier Name</th>
      <th scope="col">Paid Amount</th>
      <th scope="col">Paid Time</th>
      <th scope="col">Remarks</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {tables.length > 0 ? (
   tables.map((data,index) =>(

    <tr style={{padding:"5px"}}>
      <th scope="row">{index+1}</th>
      <td>{data.supplier_details ? data.supplier_details.supplier_name : "-"}</td>
      <td>{data.paid_amount}</td>
      <td>{data.paid_time}</td>
      <td>{data.remarks}</td>
      <td>
      <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='left'
      >
          Edit 
        </Tooltip>
      <i class="bi bi-pencil-square" id={`putButton${index}`} onClick={()=>{handleEditTable(data); togglePutTooltip(index);}}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      <Tooltip
        isOpen={deletetooltipOpen[index]} // Use tooltip state based on index
        target={`deleteButton${index}`} // Specify the target ID of the button
        toggle={() => toggleDeleteTooltip(index)}
        placement='right'
      >
          Delete
        </Tooltip>
      <i class="bi bi-trash" id={`deleteButton${index}`} onClick={()=>{handleDeleteTable(data.id,data.supplier_details.supplier_name); toggleDeleteTooltip(index);}}   style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i>
      </td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="6" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )}
 

   </tbody>

</table>

      </div>
<br/>
<br/>


        </div>
      
      </div>
            </div>

            <Modal
        show={isEdit}
        onHide={handleEditClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Settlement </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       {
       updateFormData && 
       <form  className="py-2" onSubmit={handleUpdateSubmit}>
       <div class="row">
         <div class="col">
            <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Supplier</label>
           <input type="text" class="form-control" placeholder="Select Supplier" id="input1"  name="supplier_name"  value={updateFormData.supplier_name} style={{borderRadius:"12px"}} readOnly required/>
         </div>
         <div class="col">
           <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Paid Amount</label>
           <input type="number" class="form-control" placeholder="enter paid Amount" id="input2" name="paid_amount" value={updateFormData.paid_amount} onChange={(e) => setUpdateFormData({...updateFormData, paid_amount:e.target.value})} style={{borderRadius:"12px"}} required/>
         </div>
       </div>
       <br/>
       <div class="row">
         <div class="col">
         <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Remarks</label>
         <input type="text" class="form-control" placeholder="enter remarks" id="input2" name="remarks" value={updateFormData.remarks}  onChange={(e) => setUpdateFormData({...updateFormData, remarks:e.target.value})} style={{borderRadius:"12px"}} required/>
         </div>
         <div class="col">
           
         </div>
        
       </div>
       <br/>
       <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
       </form> 
       }
          <button className="btn btn-danger" onClick={handleEditClose} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
      </Modal>


            <Modal
        show={show}
        onHide={handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM !</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete 
          <span style={{color:"red"}}> {tableD.table_no}</span> Settlement?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal> 
        </div>

        
    )
}

export default Settlements;