import React,{ useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './attendanceBreaks.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Dropdown } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { var_api } from "../../constant";
import axios from "axios";
import jsPDF from "jspdf";
import Select from 'react-select';


const AttendanceBreaks = () => {
    const history = useHistory();
    const shopname = localStorage.getItem('shopName');
    const today = new Date();
    const [viewMod, setViewMod] = useState(false);
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // Months are zero-based, so add 1
    const currentDay = today.getDate();
    const [todayIncome, setTodayIncome] = useState(null);
    const [monthlyIncome, setMonthlyIncome] = useState(null);
    const [ orders, setOrders ] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(true);

    const labelCodeOptions = [
        { value: 'Kalpana', label: 'Kalpana' },
        { value: 'Jenie', label: 'Jenie' }
    ];

    //Navigate to home page
    const handleGoTOHome = () => {
        history.push('/sb-home');
    };


   //to handle view the report
   function handleViewReport(){
    setViewMod(true);
   }

    //close the modal
    const handleCloseMod = () => setViewMod(false);

    useEffect(() => {
      getIncome();
      getOrders();
     }, []);


      // fetch the today income and monthly income
      function getIncome(){
      const shop_id = localStorage.getItem('shopId');
      axios.get(`${var_api}/api/food/income/${shop_id}`)
        .then((response) => {
          const { TodayIncome, MonthlyIncome } = response.data;
          setTodayIncome(TodayIncome);
          setMonthlyIncome(MonthlyIncome);
         
        })
        .catch((error) => {
          console.log('Error fetching data:', error);
        });
      }


      // fetch the today income and monthly income
      function getOrders(){
        const shop_id = localStorage.getItem('shopId');
        axios.get(`${var_api}/api/food/orderData/${shop_id}`)
          .then((response) => {
            const order = response.data.groupedResults;
          
            setOrders(order);
            console.log("orderarray:",orders)
           
           
          })
          .catch((error) => {
            console.log('Error fetching data:', error);
          });
        }


       


    return (
    <div id="firdiv" style={{ backgroundColor: "rgba(234, 195, 223, 0.367)"}}>
        <nav className="navbar  navbar-light py-2 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"rgb(236, 0, 173)",border:"none",padding:"10px"}} >
            <h3 style={{textAlign:"center",color:"white"}} id="kifo"><i class="bi bi-person-fill-gear"></i>{" "}Attendance Breaks</h3>
            <button className="btn" style={{color:"rgb(236, 0, 173)", backgroundColor:"white"}} onClick={handleGoTOHome}><i class="bi bi-house"></i> Go to home</button>
        </nav>
        <br/>
        <div style={{padding:"15px"}}>


            <div style={{display: "flex", justifyContent: "space-between",width:"1100px",margin:"auto"}}>
            <div style={{ margin: "auto", width: "60%" }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: "5px" }}>
    <label style={{fontWeight:"bolder",fontFamily:"serif",color: "rgb(236, 0, 173)"}}>Beautician: </label>
    <Select
      options={labelCodeOptions}
      placeholder="Kalpana"
      // onChange={handleOrderLabelSelectChange}
      isSearchable={true}
      styles={{ menu: (provided, state) => ({
        ...provided,
        zIndex: 9999, // Increase the z-index to ensure the dropdown is displayed above other elements
        width:"300px"
    }) }}
    />
    {/* <i className="bi bi-calendar text-primary" style={{ marginLeft: '5px', fontSize: "20px" }} ></i> */}
    
    <div className="card" style={{width:"auto",margin:"0px",height:"auto", borderRadius:"10px"}}>
        <div className="card-body">
            <div className="row">
                <div className="col">
                <label style={{color: "rgb(236, 0, 173)"}}>From Date:</label>
                <input type="date" placeholder="From time" value="2024-04-01" />{" "}
                </div>
                <div className="col">
                <label style={{color: "rgb(236, 0, 173)"}}>To Date:</label><br/>
                <input type="date" placeholder="To time" value="2024-04-30" />
                </div>
            </div>
        </div>
    </div>
  </div>
</div>

<div className="card " style={{ width: '350px', height:'auto',margin:"0px auto",backgroundColor:"white !important"}}>
    <div className="card-header" style={{textAlign:"center",fontWeight:"bolder",color: "rgb(236, 0, 173)"}}>Report Summary<br/><span style={{fontSize:"14px",color: "black"}}>01-04-2024 to 05-04-2024</span></div>
    <div className="card-body" style={{padding:"10px",backgroundColor:"white", lineHeight:"8px"}}>
        <h6 style={{color: "rgb(236, 0, 173)",fontFamily:"serif",fontWeight:"bolder"}}>Beautician: <b style={{color:"black"}}>Kalpana</b> </h6>
        <p style={{color: "rgb(236, 0, 173)",fontWeight:"bolder",fontFamily:"serif"}}>Total Working Days: <b style={{color:"black"}}>23</b></p>
        <p style={{color: "rgb(236, 0, 173)",fontWeight:"bolder",fontFamily:"serif"}}>Total Leave Days: <b style={{color:"black"}}>3</b></p> 
        <p style={{color: "rgb(236, 0, 173)",fontWeight:"bolder",fontFamily:"serif"}}>Total General Holidays: <b style={{color:"black"}}>4</b></p>  
    </div>
</div>
            </div>
        
<div className="card" style={{height:"450px",borderRadius:"20px"}}>
    <div className="card-body">
    <div className="table-container" style={{ height: "400px", overflowX: 'auto', overflowY: 'scroll' }}>
                <table class="table table-hover small-first-col" style={{ border: "none", borderColor: "grey", width: "95%", margin: "auto" ,height:"50%"}}>
                    <thead>
                        <tr style={{ fontWeight: "bolder" }}>
                            <th scope="col">S.No</th>
                            <th scope="col">Date</th>
                            <th scope="col">Day</th>
                            <th scope="col">Login-Time</th>
                            <th scope="col">Break-Start</th>
                            <th scope="col">Break-End</th>
                            <th scope="col">Logout-Time</th>
                            <th scope="col">Total Working Hours</th>
                            <th scope="col">Remarks</th>
                        </tr>
                    </thead>
                    <tbody class="body-half-screen">
                       
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">1</td>
                                    <td>01-04-2024</td>
                                    <td>Monday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">2</td>
                                    <td>02-04-2024</td>
                                    <td>Tuesday</td>
                                    <td colSpan="5" style={{textAlign:"center"}}>Leave(Health Issues)</td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">3</td>
                                    <td>03-04-2024</td>
                                    <td>Wednesday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">4</td>
                                    <td>04-04-2024</td>
                                    <td>Thursday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">5</td>
                                    <td>05-04-2024</td>
                                    <td>Friday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">6</td>
                                    <td>06-04-2024</td>
                                    <td>Saturday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">7</td>
                                    <td>07-04-2024</td>
                                    <td>Sunday</td>
                                    <td colSpan="5" style={{textAlign:"center"}}>General Holiday</td>
                                    
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">8</td>
                                    <td>08-04-2024</td>
                                    <td>Monday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">9</td>
                                    <td>09-04-2024</td>
                                    <td>Tuesday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">10</td>
                                    <td>10-04-2024</td>
                                    <td>Wednesday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">11</td>
                                    <td>11-04-2024</td>
                                    <td>Thursday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">12</td>
                                    <td>12-04-2024</td>
                                    <td>Friday</td>
                                    <td colSpan="5" style={{textAlign:"center"}}>Leave(Health Issues)</td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">13</td>
                                    <td>13-04-2024</td>
                                    <td>Saturday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">14</td>
                                    <td>14-04-2024</td>
                                    <td>Sunday</td>
                                    <td colSpan="5" style={{textAlign:"center"}}>General Holiday</td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">15</td>
                                    <td>15-04-2024</td>
                                    <td>Monday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">16</td>
                                    <td>16-04-2024</td>
                                    <td>Tuesday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">17</td>
                                    <td>17-04-2024</td>
                                    <td>Wednesday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">18</td>
                                    <td>18-04-2024</td>
                                    <td>Thursday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">19</td>
                                    <td>19-04-2024</td>
                                    <td>Friday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">20</td>
                                    <td>20-04-2024</td>
                                    <td>Saturday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">21</td>
                                    <td>21-04-2024</td>
                                    <td>Sunday</td>
                                    <td colSpan="5" style={{textAlign:"center"}}>General Holiday</td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">22</td>
                                    <td>22-04-2024</td>
                                    <td>Monday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">23</td>
                                    <td>23-04-2024</td>
                                    <td>Tuesday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">24</td>
                                    <td>24-04-2024</td>
                                    <td>Wednesday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">25</td>
                                    <td>25-04-2024</td>
                                    <td>Thursday</td>
                                    <td colSpan="5" style={{textAlign:"center"}}>Leave(Personal Reasons)</td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">26</td>
                                    <td>26-04-2024</td>
                                    <td>Friday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">27</td>
                                    <td>27-04-2024</td>
                                    <td>Saturday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">28</td>
                                    <td>28-04-2024</td>
                                    <td>Sunday</td>
                                    <td colSpan="5" style={{textAlign:"center"}}>General Holiday</td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">29</td>
                                    <td>29-04-2024</td>
                                    <td>Monday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">30</td>
                                    <td>30-04-2024</td>
                                    <td>Tuesday</td>
                                    <td>10:00 AM</td>
                                    <td>12:00 PM</td>
                                    <td>1:30 PM</td>
                                    <td>6:00 PM</td>
                                    <td>
                                   6hrs 30mins
                                    </td>
                                    <td>
                                  -
                                    </td>
                                  
                                </tr>
                              
                    </tbody>
                </table>
                <br />
               
            </div>
    </div>
</div>


        </div>
            <Modal className="temp"
            size="lg"
            show={viewMod}
            onHide={handleCloseMod}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title> FINANCIAL REPORT</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'scroll' }}>
              
            </Modal.Body>
           
          </Modal>

          
        
            
        </div>

  )
}





export default AttendanceBreaks;