import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import "./bill.css";
import { db } from '../../firebase';
import { updateDoc, doc, getDoc ,setDoc} from "firebase/firestore";
import axios from 'axios';
import logo from '../../images/splash/shop_logo.jpeg';
import { var_api } from '../constant';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()


class Bill extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      updatedQuantities: {}, // State to hold updated quantities for food items
      total: null,
      showAdd:false,
      categoryName:[],
      food_name:[],
      SelectedCate:"",
      selectedItem:"",
      cateCodeSelect:"",
      unitPrice:null,
      foodCodeSelect:"",
      shopDetails:null,
      transId:null,
      totalItems:0,

    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

   // Handle input change for food quantities and update the state
   handleQuantityChange = (foodItemId, quantity,transId) => {
    this.setState((prevState) => ({
      updatedQuantities: {
        ...prevState.updatedQuantities,
        [foodItemId]: quantity,
      },

    }));

    this.setState({ transId : transId})
    console.log("customer setstate trans id", this.state.transId)
  };

  
  updateFood = (orderId,cust_id) => {
    const { updatedQuantities,transId } = this.state;
   
    console.log("updatedQuantities",updatedQuantities)
    const shopName = localStorage.getItem('shopName');
    const orderRef = doc(db, `shops/${shopName}/orders`, orderId);
    let itemsToDelete = []; // Declare itemsToDelete at a higher scope
  
    // Fetch the order document from Firestore
    getDoc(orderRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const orderData = docSnapshot.data();
          console.log("orderData",orderData);

  
          // Calculate the updated quantities and totals
          const updatedOrderItems = orderData.order_items.map((item, index) => {
            const updatedQuantity = updatedQuantities[index] 
          // Use updatedQuantity if it's defined, otherwise, fall back to item.quantity
         const quantityToUse = updatedQuantity !== undefined ? updatedQuantity : item.quantity;
            const updatedSubtotal = quantityToUse * item.unit_price;
    
 

            console.log("updatedQuantity",updatedQuantity);
            console.log("updatedSubtotal",updatedSubtotal);
  
            return {
              ...item,
              quantity: quantityToUse,
              subtotal: updatedSubtotal,
            };
          });

          // Create a new array without elements with quantity 0
      const updatedOrderItemsWithoutZeroQuantity = updatedOrderItems.filter(
        (item) => item.quantity !== 0
      );


      // Filter items with quantity 0
       itemsToDelete = updatedOrderItems.filter(
        (item) => item.quantity === 0);

      

        console.log("itemsToDelete",itemsToDelete);

       

      console.log(
        "updatedOrderItemsWithoutZeroQuantity",
        updatedOrderItemsWithoutZeroQuantity
      );



  
          // Calculate the updated sub-total and total
          const updatedSubTotal = updatedOrderItemsWithoutZeroQuantity.reduce(
            (total, item) => total + item.subtotal,
            0
          );

          

          console.log("updatedSubTotal",updatedSubTotal);
          const updatedTotal = updatedSubTotal + 5; // Assuming service charge of ₹15
          console.log("updatedTotal",updatedTotal);
           this.setState({total:updatedTotal});

          const totalItems = updatedOrderItemsWithoutZeroQuantity.length;
          this.setState({ totalItems: totalItems })
          console.log("updated Order Items Without Zero Quantity",updatedOrderItemsWithoutZeroQuantity);
      


          // Update the entire `order_items`, `sub_total`, and `total` in the Firestore document
          return updateDoc(orderRef, {
            order_items: updatedOrderItemsWithoutZeroQuantity,
            sub_total: updatedSubTotal,
            total: updatedTotal,
            total_items: totalItems,
          })
          .then(() => {
            console.log('Order items updated successfully');
            // Now, you can set the `foodItemsToUpdate` array based on `updatedOrderItems`.
            
            const foodItemsToUpdate = updatedOrderItems.map((item) => ({
              item_code: item.item_code,
              quantity: item.quantity,
            }));
            return foodItemsToUpdate;
          })
          .catch((error) => {
            console.error('Error updating Firestore:', error);
            throw error; // Rethrow the error to handle it below
          });
      } else {
        throw new Error('Order document does not exist');
      }
    })
    .then((foodItemsToUpdate) => {
      const shopID = localStorage.getItem('shopId');
      const { total,totalItems } =  this.state;


   
      // Now you have the `foodItemsToUpdate` array based on Firestore data
      // Make the Axios request with this data
      return axios.put(`${var_api}/api/food/update-food-and-total/${cust_id}/${transId}`, {
        foodItemsToUpdate,
        total:total,
        total_items: totalItems,
      });
    })
    .then((response) => {
      toast('updated successfully',{autoClose:2000})
      this.setState({updatedQuantities: {}})
      console.log('Update successful', response);

      const shopID = localStorage.getItem('shopId');
      console.log("customer id", cust_id)
      console.log("customer trans id", transId)

      if(itemsToDelete.length > 0){
        itemsToDelete.forEach((item) => {
          const item_code = item.item_code; // Access the item_code property
          console.log("item_code:", item_code);
            
 
            
    // Make the Axios DELETE request to delete items with quantity 0
   axios
   .delete(`${var_api}/api/food/delete-items/${cust_id}/${transId}/${item_code}`)
   .then((response) => {
    // Calling toast method by passing string
    toast('Item removed successfully',{autoClose:2000})
     console.log('Items with quantity 0 deleted successfully from the API', response);
     // Handle any further actions after deletion from the API
   })
   .catch((error) => {
     console.error('Error deleting items with quantity 0 from the API:', error);
     // Handle the error as needed
   });

          });
      }
      // Handle any further actions after the update is successful
      this.toggle(); // Close the modal after updating
    })
    .catch((error) => {
      console.error('Error updating food and total:', error);
    });
};


//update and delete for credit order
handleCreditUpdate= (orderId,cust_id,creditId) => {
  const { updatedQuantities,transId } = this.state;
 
  console.log("updatedQuantities",updatedQuantities)
  const shopName = localStorage.getItem('shopName');
  const orderRef = doc(db, `shops/${shopName}/orders`, orderId);
  let itemsToDelete = []; // Declare itemsToDelete at a higher scope

  // Fetch the order document from Firestore
  getDoc(orderRef)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        const orderData = docSnapshot.data();
        console.log("orderData",orderData);
        
        // Calculate the updated quantities and totals
        const updatedOrderItems = orderData.order_items.map((item, index) => {
          const updatedQuantity = updatedQuantities[index] 
        // Use updatedQuantity if it's defined, otherwise, fall back to item.quantity
       const quantityToUse = updatedQuantity !== undefined ? updatedQuantity : item.quantity;
          const updatedSubtotal = quantityToUse * item.unit_price;
  
          console.log("updatedQuantity",updatedQuantity);
          console.log("updatedSubtotal",updatedSubtotal);

          return {
            ...item,
            quantity: quantityToUse,
            subtotal: updatedSubtotal,
          };
        });

        // Create a new array without elements with quantity 0
    const updatedOrderItemsWithoutZeroQuantity = updatedOrderItems.filter(
      (item) => item.quantity !== 0
    );

 // Filter items with quantity 0
     itemsToDelete = updatedOrderItems.filter(
      (item) => item.quantity === 0);
   console.log("itemsToDelete",itemsToDelete);

 console.log(
      "updatedOrderItemsWithoutZeroQuantity",
      updatedOrderItemsWithoutZeroQuantity
    );

// Calculate the updated sub-total and total
        const updatedSubTotal = updatedOrderItemsWithoutZeroQuantity.reduce(
          (total, item) => total + item.subtotal,0);
          console.log("updatedSubTotal",updatedSubTotal);
        const updatedTotal = updatedSubTotal + 5; // Assuming service charge of ₹15
        console.log("updatedTotal",updatedTotal);
         this.setState({total:updatedTotal});

        const totalItems = updatedOrderItemsWithoutZeroQuantity.length;
        this.setState({ totalItems: totalItems })
        console.log("updated Order Items Without Zero Quantity",updatedOrderItemsWithoutZeroQuantity);
    
      // Update the entire `order_items`, `sub_total`, and `total` in the Firestore document
        return updateDoc(orderRef, {
          order_items: updatedOrderItemsWithoutZeroQuantity,
          sub_total: updatedSubTotal,
          total: updatedTotal,
          total_items: totalItems,
        })
        .then(() => {
          console.log('Order items updated successfully');
          // Now, you can set the `foodItemsToUpdate` array based on `updatedOrderItems`.
          
          const foodItemsToUpdate = updatedOrderItems.map((item) => ({
            item_code: item.item_code,
            quantity: item.quantity,
          }));
          return foodItemsToUpdate;
        })
        .catch((error) => {
          console.error('Error updating Firestore:', error);
          throw error; // Rethrow the error to handle it below
        });
    } else {
      throw new Error('Order document does not exist');
    }
  })
  .then((foodItemsToUpdate) => {
    const shopID = localStorage.getItem('shopId');
    const { total,totalItems } =  this.state;

// Now you have the `foodItemsToUpdate` array based on Firestore data
    // Make the Axios request with this data
    return axios.put(`${var_api}/api/creditorder/updateFood-CreditOrder/${cust_id}/${transId}/${creditId}`, {
      foodItemsToUpdate,
      total:total,
      total_items: totalItems,
    });
  })
  .then((response) => {
    toast('updated successfully',{autoClose:2000})
    this.setState({updatedQuantities: {}})
    console.log('Update successful', response);

    const shopID = localStorage.getItem('shopId');
    console.log("customer id", cust_id)
    console.log("customer trans id", transId)

    if(itemsToDelete.length > 0){
      itemsToDelete.forEach((item) => {
        const item_code = item.item_code; // Access the item_code property
        console.log("item_code:", item_code);
          
// Make the Axios DELETE request to delete items with quantity 0
 axios
 .delete(`${var_api}/api/creditorder/delete-creditOrderitems/${cust_id}/${transId}/${item_code}/${creditId}`)
 .then((response) => {
  // Calling toast method by passing string
  toast('Item removed successfully',{autoClose:2000})
   console.log('Items with quantity 0 deleted successfully from the API', response);
   // Handle any further actions after deletion from the API
 })
 .catch((error) => {
   console.error('Error deleting items with quantity 0 from the API:', error);
   // Handle the error as needed
 });
});
    }
    // Handle any further actions after the update is successful
    this.toggle(); // Close the modal after updating
  })
  .catch((error) => {
    console.error('Error updating food and total:', error);
  });
};



//add food Item icon
handleAddItem=()=>{
 this.setState({ showAdd : true })
 this.fetchCategory();
}

//fetch category name
fetchCategory= async () =>{
  const shopId = localStorage.getItem('shopId');
  try {
   const response = await axios.get(`${var_api}/api/categories/api/categories/${shopId}` );
   const cateNames = response.data

 console.log("categories",cateNames)
 this.setState({ categoryName : cateNames})
 }catch (error) {
   console.log('Error fetching data:', error);
   }
}

//select handler for category
 categoryhandler = (e) => {
  const selectedIndex = e.target.selectedIndex;
  const selectedOption = e.target.options[selectedIndex];
  const categoryId = selectedOption.getAttribute('data-id'); // Get the category ID from the "data-id" attribute
 
  this.setState({ SelectedCate: selectedOption.value });
  console.log('Selected Option ID:', categoryId);
  
  const CategoryCode = selectedOption.getAttribute('data-cateCode');
  this.setState({
    cateCodeSelect : CategoryCode
  },
  () => {
    // This callback function will run after the state has been updated
    console.log('Selected code:', this.state.cateCodeSelect);
  })
  
  this.fetchFoodItems(categoryId);

} 


//get the value for select food
ChangeFoodHandler = (e) => {
  const selectedIndex = e.target.selectedIndex;
  console.log("selectedIndex",selectedIndex)
  const selectedOption = e.target.options[selectedIndex]
  console.log("selectedOption",selectedOption)
  this.setState({ selectedItem: selectedOption.value });

  const unitPrice = selectedOption.getAttribute('data-price');
  const ItemCode = selectedOption.getAttribute('data-codeItem');
   this.setState({
    unitPrice : unitPrice,
    foodCodeSelect : ItemCode,
   },
   () => {
    console.log("selectedPrice and code",this.state.unitPrice,this.state.foodCodeSelect);
   })
}


 //list the food items in select
 fetchFoodItems=async(optionId)=>{

  try {
    const response = await axios.get(`${var_api}/api/foodMaster/api/subFood/${optionId}` );
    const use = response.data

  console.log("Items",use)
  this.setState({food_name:use});
   
  }catch (error) {
    console.log('Error fetching data:', error);
    
    }
 
}

//update => add item to the db
handlemoreItem = async (orderId,custID) =>{
  try {
  const {selectedItem, unitPrice, foodCodeSelect, cateCodeSelect} = this.state
  console.log("order id",orderId)
  const shopID = localStorage.getItem('shopId');
  var quantity = document.querySelector('#newQuan').value;
  const shopName = localStorage.getItem('shopName');

  const orderRef = doc(db, `shops/${shopName}/orders`, orderId);

  // Wrap your code in an async function
  const updateOrder = async () => {
    // Retrieve the existing order_items array from Firestore
    const orderDoc = await getDoc(orderRef);

    const orderItems = 
    {
       food_name:selectedItem,
       quantity: parseInt(quantity),
       unit_price: parseFloat(unitPrice),
       item_code: foodCodeSelect,
       category_code:cateCodeSelect,
       trans_id: 0,
       // Add more properties as needed
     };

     console.log("ordered items:",orderItems)

     const existingOrderItems = orderDoc.data().order_items || [];
     const existingItemsLength = existingOrderItems.length;

     // Append the new orderItems to the existing order_items array
     const updatedOrderItems = [...existingOrderItems, orderItems];

    
    // Calculate the total based on unit_price and quantity for each item
    const total = updatedOrderItems.reduce((accumulator, item) => {
      return accumulator + item.quantity * item.unit_price;
    }, 0);

    const grandTOtal = total + 5;

    const totalItems =  existingItemsLength + 1;

       // Update the Firestore document with the modified order_items array and the calculated total
       await updateDoc(orderRef, {
        order_items: updatedOrderItems,
        sub_total: total,
        total: grandTOtal, // Update the total field with the calculated total
        total_items: totalItems, 
      });
  

    const noorderItems = 
    {
       food_name:selectedItem,
       quantity: parseInt(quantity),
       unit_price: parseFloat(unitPrice),
       item_code: foodCodeSelect,
       category_code:cateCodeSelect,
       shop_name:shopName,
      total:grandTOtal,
      total_items:totalItems,
     };

   //to make the POST request
  try {
    const response = await axios.post(`${var_api}/api/food/api/newpost/${custID}`, noorderItems)
   
      if (response.status === 200) {
          const trans_id =  response.data.addTrid;

       // Update the trans_id in the orderItems object
      orderItems.trans_id = trans_id;

       // Update the Firestore document again with the modified order_items array (including the updated trans_id)
       await updateDoc(orderRef, {
        order_items: updatedOrderItems,
        sub_total: total,
        total: grandTOtal,
        total_items: totalItems,
      });
       

        // Clear the selected option by setting selectedItem to an empty string
        this.setState( { SelectedCate: " "})
        this.setState({ selectedItem: ' ' });
        
        // Clear the input field with id 'newQuan' if needed
        document.querySelector('#newQuan').value = '';

  
        // Display a success message (you may want to use a toast or other UI feedback)
        toast('Item added successfully', { autoClose: 2000 });
  
        console.log('POST request successful', response);
        this.toggle(); // Close the modal after updating
      }
      // toast('Item added successfully',{autoClose:2000})
      // this.setState({ selectedItem: '' });
      // document.querySelector('#newQuan').value='';
      // console.log('POST request successful', response);
      // // Handle any further actions after a successful request
    }catch(error) {
      console.error('Error making POST request:', error);
      // Handle the error as needed
    };
    console.log('Order items updated successfully');
  };

  // Call the async function
  await updateOrder();
} catch (error) {
  console.error('Error adding order item:', error);
}
};


//update => add item for credit user
handleCreditAddItem = async (orderId,custId,creditId) =>{
  try {
  const {selectedItem, unitPrice, foodCodeSelect, cateCodeSelect} = this.state
  console.log("order id",orderId)
  const shopID = localStorage.getItem('shopId');
  var quantity = document.querySelector('#newQuan').value;
  const shopName = localStorage.getItem('shopName');

  const orderRef = doc(db, `shops/${shopName}/orders`, orderId);

  // Wrap your code in an async function
  const updateOrder = async () => {
    // Retrieve the existing order_items array from Firestore
    const orderDoc = await getDoc(orderRef);

    const orderItems = 
    {
       food_name:selectedItem,
       quantity: parseInt(quantity),
       unit_price: parseFloat(unitPrice),
       item_code: foodCodeSelect,
       category_code:cateCodeSelect,
       trans_id: 0,
       // Add more properties as needed
     };

     console.log("ordered items:",orderItems)

     const existingOrderItems = orderDoc.data().order_items || [];
     const existingItemsLength = existingOrderItems.length;

     // Append the new orderItems to the existing order_items array
     const updatedOrderItems = [...existingOrderItems, orderItems];

    
    // Calculate the total based on unit_price and quantity for each item
    const total = updatedOrderItems.reduce((accumulator, item) => {
      return accumulator + item.quantity * item.unit_price;
    }, 0);

    const grandTOtal = total + 0;

    const totalItems =  existingItemsLength + 1;

       // Update the Firestore document with the modified order_items array and the calculated total
       await updateDoc(orderRef, {
        order_items: updatedOrderItems,
        sub_total: total,
        total: grandTOtal, // Update the total field with the calculated total
        total_items: totalItems, 
      });
  

    const noorderItems = 
    {
       food_name:selectedItem,
       quantity: parseInt(quantity),
       unit_price: parseFloat(unitPrice),
       item_code: foodCodeSelect,
       category_code:cateCodeSelect,
       shop_name:shopName,
      total:grandTOtal,
      total_items:totalItems,
     };

   //to make the POST request
  try {
    const response = await axios.post(`${var_api}/api/creditorder/api/newCreditpost/${custId}/${creditId}`, noorderItems)
   
      if (response.status === 200) {
          const trans_id =  response.data.addTrid;

       // Update the trans_id in the orderItems object
      orderItems.trans_id = trans_id;

       // Update the Firestore document again with the modified order_items array (including the updated trans_id)
       await updateDoc(orderRef, {
        order_items: updatedOrderItems,
        sub_total: total,
        total: grandTOtal,
        total_items: totalItems,
      });
       

        // Clear the selected option by setting selectedItem to an empty string
        this.setState( { SelectedCate: " "})
        this.setState({ selectedItem: ' ' });
        
        // Clear the input field with id 'newQuan' if needed
        document.querySelector('#newQuan').value = '';

  
        // Display a success message (you may want to use a toast or other UI feedback)
        toast('Item added successfully', { autoClose: 2000 });
  
        console.log('POST request successful', response);
        this.toggle(); // Close the modal after updating
      }
      // toast('Item added successfully',{autoClose:2000})
      // this.setState({ selectedItem: '' });
      // document.querySelector('#newQuan').value='';
      // console.log('POST request successful', response);
      // // Handle any further actions after a successful request
    }catch(error) {
      console.error('Error making POST request:', error);
      // Handle the error as needed
    };
    console.log('Order items updated successfully');
  };

  // Call the async function
  await updateOrder();
} catch (error) {
  console.error('Error adding order item:', error);
}
};


//get shop details
// componentDidMount() {
//   const shopID = localStorage.getItem('shopId');
//   // Make the API request
//   axios
//     .get(`${var_api}/api/shop/shopDetails/${shopID}`)
//     .then((response) => {
//       this.setState({
//         shopDetails: response.data
//       });
//       console.log("shop details:",this.state.shopDetails)
//     })
//     .catch((error) => {
//       console.error('Error fetching data:', error);
//     });
// }
  
  
  
  
  


  render() {
    const { order, shopDetails } = this.props;
    const currentDate = new Date();
    const currentDateFormatted = currentDate.toLocaleDateString();
    const currentTime = currentDate.toLocaleTimeString();
    const { categoryName,food_name } = this.state;

    return (
        
      <div style={{backgroundColor:"rgba(63, 68, 63, 0.082)",padding:"2px 2px 2px 2px",maxWidth:"330px", height:"auto"}}>
        <div 
        // className="hidden-on-screen"
        >
        {/* <img src={logo} alt="logo" style={{display: "block",marginLeft:" auto",marginRight: "auto",height:"50px",width:"50px"}} /> */}
        <p 
        // className='hidden-on-screen' 
        style={{textAlign:"center",fontWeight:"bolder",textTransform:"uppercase",color:"black",fontFamily:"sans-serif", fontSize:"25px"}}><b>{localStorage.getItem('shopName')}</b></p>
       
        {shopDetails ? (
        <div style={{fontFamily:"sans-serif", marginTop:"-10px"}}>
          <p  style={{textAlign:"center", fontWeight:"bolder",fontSize:"20px", lineHeight:"25%"}}>#{shopDetails.address}</p>
          <p  style={{textAlign:"center", fontWeight:"bolder",fontSize:"20px", lineHeight:"25%"}}>{shopDetails.state}</p>
          <p  style={{textAlign:"center", fontWeight:"bolder",fontSize:"20px", lineHeight:"25%"}}>{shopDetails.country_name}</p>
        </div>
      ) : (
        <div>Loading...</div>
      )}
       
        
      </div>
      <hr 
      // className='hidden-on-screen'  
      style={{border:"2px dashed black",padding:"0px",margin:"0px"}}/>

      

      {/* {(() => {
    if (order.order_type === 0 && order.order_label === 0 && order.is_group_location_order === 0) {
        return <h6 style={{fontSize:"15px",lineHeight:"2px", fontWeight:"bolder"}}>Normal Order <span className="hidden-on-screen" style={{fontSize:"15px", textAlign:"right"}}>{" "}Date:{order.ordered_at}</span></h6>;
    }
    else if (order.order_type === 1 && order.order_label === 0 && order.is_group_location_order === 0) {
      return <h6 style={{fontSize:"15px",lineHeight:"2px", fontWeight:"bolder"}}> Normal Table Order <span className="hidden-on-screen" style={{fontSize:"15px", textAlign:"right"}}>{" "}Date:{order.ordered_at}</span></h6>;
  }
  else if (order.order_label === 1  &&order.is_group_location_order === 1 && order.order_type ===0 ) {
    return <h6 style={{fontSize:"15px",lineHeight:"2px", fontWeight:"bolder"}}>Group Location Order <span className="hidden-on-screen" style={{fontSize:"15px", textAlign:"right"}}>{" "}Date:{order.ordered_at}</span></h6>;
  }
  else if (order.order_type === 0 && order.order_label === 1 && order.is_group_location_order === 0) {
    return <h6 style={{fontSize:"15px",lineHeight:"2px", fontWeight:"bolder"}}>Credit Order <span className="hidden-on-screen" style={{fontSize:"15px", textAlign:"right"}}>{" "}Date:{order.ordered_at}</span></h6>;
}
else if (order.order_type === 1 && order.order_label === 1 && order.is_group_location_order === 0) {
  return <h6 style={{fontSize:"15px",lineHeight:"2px", fontWeight:"bolder"}}> Credit Table Order <span className="hidden-on-screen" style={{fontSize:"15px", textAlign:"right"}}>{" "}Date:{order.ordered_at}</span></h6>;
}
    })()} */}
        <h6 style={{fontWeight:"bolder",color:"black", fontSize:"20px"}} >Order No:{" "}<b>{order.order_no}</b>
        <span style={{fontSize:"20px", float:"right", fontWeight:"bolder"}}>{" "}<b>{order.ordered_at}</b></span>
        {/* <span style={{fontSize:"15px", textAlign:"right"}}>{" "}Time:{order.ordered_time}</span> */}
        {/* <span>
          <button className='btn-sm btn-dark' style={{float:"right"}} disabled={order.paid_status !== 0} onClick={this.toggle}>{order.paid_status !== 0 || order.print_status !== 0 ? "unable to Edit" : "Edit"}</button>
        </span> */}
        </h6>
        {(() => {
    if (order.order_type === 0 && order.order_label === 0 && order.is_group_location_order === 0) {
        return <h6 style={{fontWeight:"bolder",color:"black", fontSize:"20px"}} >Normal Order{" "}
        <span style={{fontSize:"20px", float:"right",fontWeight:"bolder"}}>{order.ordered_time}</span>
        </h6>;
    }
    else if (order.order_type === 1 && order.order_label === 0 && order.is_group_location_order === 0) {
      return <h6 style={{fontWeight:"bolder",color:"black", fontSize:"20px"}} >Normal Table Order{" "}
      <span style={{fontSize:"20px", float:"right",fontWeight:"bolder"}}>{order.ordered_time}</span>
      </h6>;
  }
  else if (order.order_label === 1  &&order.is_group_location_order === 1 && order.order_type ===0 ) {
    return <h6 style={{fontWeight:"bolder",color:"black", fontSize:"20px"}} >Group Location Order {" "}
    <span style={{fontSize:"20px", float:"right",fontWeight:"bolder"}}>{order.ordered_time}</span>
    </h6>;
  }
  else if (order.order_type === 0 && order.order_label === 1 && order.is_group_location_order === 0) {
    return <h6 style={{fontWeight:"bolder",color:"black", fontSize:"20px"}} >Credit Order{" "}
    <span style={{fontSize:"20px", float:"right", fontWeight:"bolder"}}>{order.ordered_time}</span>
    </h6>;
}
else if (order.order_type === 1 && order.order_label === 1 && order.is_group_location_order === 0) {
  return <h6 style={{fontWeight:"bolder",color:"black", fontSize:"20px"}} > Credit Table Order{" "}
  <span style={{fontSize:"20px", float:"right",fontWeight:"bolder"}}>{order.ordered_time}</span>
  </h6>;
}
    })()}
        
{/* 
        <hr  style={{border:"1px dashed black",padding:"0px",margin:"0px"}}/> */}
        
 {/* <hr className="hidden-on-screen" style={{border:"1px dashed black",marginTop:"27px"}}/> */}
 <table style={{borderCollapse:"collapse",width:"100%",marginBottom:"10px"}}>

{/* <caption style={{captionSide:"top",fontSize:"15px",fontWeight:"bolder",color:'black'}}>Name: <span style={{color:"black",fontSize:"16px",textTransform:"capitalize",fontWeight:"bolder"}}>{order.customer_name}</span><br/>Phone No: <span style={{color:"black",fontWeight:"bolder",fontSize:"16px"}}>{order.customer_phone}</span>
</caption> */}

 <thead style={{borderTop:"2px solid black",borderBottom:"1px solid black",maxWidth:"100%"}}>
   <th style={{padding:"2px",color:"black",fontSize:"20px"}}>Item</th>
   <th style={{padding:"2px",color:"black",fontSize:"20px"}}>Qty</th>
   <th style={{padding:"2px",color:"black",fontSize:"20px"}}>Price</th>
   <th style={{padding:"2px",color:"black",fontSize:"20px"}}>Total</th>
 </thead>

 <tbody>
   {order && order.items.map((item, itemIndex) => (
   <tr key={itemIndex}>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize",fontSize:"20px",fontWeight:"bolder"}}>{item.food_name}</td>
     <td style={{padding:"2px",color:"black",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}>{item.quantity}</td>
     <td style={{padding:"2px",color:"black",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}>{item.unit_price}</td>
     <td style={{padding:"2px",color:"black",textAlign:"center",fontSize:"20px",fontWeight:"bolder"}}>{(item.quantity * item.unit_price).toFixed(2)}</td>
   </tr>
   
   ))}
 </tbody>
 {/* <tr>
  <td style={{color:"black",textAlign:"left",fontWeight:"bolder",padding:"1px"}}>Sub Total</td>
  <td></td>
  <td style={{color:"black",textAlign:"right",fontWeight:"bolder",padding:"1px"}}><span style={{fontSize:"15px"}}>{shopDetails ? shopDetails.shop_currency : "null"}</span>.{(Math.round(order.total * 100) / 100).toFixed(1)}</td>
  </tr> */}
  {/* <tr>
    <td style={{color:"black",textAlign:"left",fontWeight:"bolder",padding:"1px"}}>Service Charge</td>
    <td></td>
    <td  style={{color:"black",textAlign:"right",fontWeight:"bolder",padding:"1px"}}><span style={{fontSize:"15px"}}>{shopDetails ? shopDetails.shop_currency : "null"}</span>.0.00</td>
  </tr> */}
</table>
<hr style={{border:"2px solid black",position:"relative",top:"-13px",padding:"0px",marginBottom:"10px"}}/>
        <h6 style={{padding:"1px",color:"black",textAlign:"center",fontWeight:"bolder",marginTop:"-20px",fontFamily:"monospace",marginBottom:"-2px", fontSize:"25px"}}><b>Total: {" "}
          {(Math.round(order.total * 100) / 100).toFixed(2)}</b> <span style={{fontSize:"20px"}}>{shopDetails ? shopDetails.shop_currency : null}</span></h6>
        {/* <hr style={{border:"1px dashed black",position:"relative",top:"-15px"}}/> */}

        
        <table style={{ borderCollapse: "collapse", border: "2px solid black", width: "100%" }}>
    <thead>
      <tr>
        <th style={{ border: "2px solid black", padding: "1px 4px 1px 4px",fontSize:"20px" }}>Paymode</th>
        <th style={{ border: "2px solid black", padding: "1px 4px 1px 4px",fontSize:"20px" }}>Amount</th>
      </tr>
    </thead>
    <tbody>
      {/* <tr>
        <td style={{ border: "1px solid black", padding: "1px 4px 1px 4px", textAlign: "center" }}>{order.pay_mode}</td>
        <td style={{ border: "1px solid black", padding: "1px 4px 1px 4px", textAlign: "center" }}>{(Math.round(order.total * 100) / 100).toFixed(1)}</td>
      </tr> */}
      {order.pay_modes.map((mode,index)=>(
         mode.amount !== 0 && (
      <tr key={index}>
        <td style={{ border: "2px solid black", padding: "1px 4px 1px 4px", textAlign: "center" ,fontSize:"20px",fontWeight:"bolder"}}>{mode.pay_mode_name}</td>
        <td style={{ border: "2px solid black", padding: "1px 4px 1px 4px", textAlign: "center",fontSize:"20px",fontWeight:"bolder" }}>{(mode.amount).toFixed(2)}</td>
      </tr>
         )
    ))}
    </tbody>
  </table>
      
        <h6 
        // className='hidden-on-screen' 
        style={{margin:"2px",padding:"1px", border:"2px solid black", fontSize:"20px", fontWeight:"bolder"}}>Shop Ph : {shopDetails? shopDetails.user.mobile : null}</h6>
        <h6 
        // className='hidden-on-screen' 
        style={{margin:"2px",padding:"1px",border:"2px solid black", fontSize:"20px", fontWeight:"bolder"}}>Thank you for Dine With Us!</h6>

     
                {/* <h6 style={{fontSize:"15px",fontWeight:"bolder",color:"black",fontFamily: "monospace",marginTop:"-20px"}}>Captain Name: 
                <span style={{color:"black",textTransform:"capitalize",fontWeight:"bolder"}}>{order.captain_name}</span>
                <br/>No of Items: <span style={{color:"black",fontWeight:"bolder",marginLeft:"5px"}}>{' '} {order.items.length}</span>
                <br/>Pay Mode: <span style={{color:"black",fontWeight:"bolder",marginLeft:"5px"}}>{' '} {order.pay_mode}</span>
                </h6>
                */}
{/* <div className="hidden-on-screen" style={{marginTop:"-21px",lineHeight:"10px"}}>
   <br/>
       <h6 style={{textAlign:"center",fontFamily:"monospace",fontWeight:"bolder",color:"black",margin:"5px"}}>Thanks For Visiting...!</h6>
      </div> */}

      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader style={{fontWeight:"bolder"}}>EDIT ORDER</ModalHeader>
          <ModalBody>
          <table>
 <thead>
   <th style={{padding:"2px",color:"green",fontWeight:"bolder" }}>Item</th>
   <th style={{padding:"2px",color:"green",fontWeight:"bolder"}}>Qty</th>
   <th style={{padding:"2px",color:"green",fontWeight:"bolder"}}>UnitPrice(INR)</th>
 </thead>

 <tbody>
   {order.items.map((item, itemIndex) => (
   <tr key={itemIndex} >
     <td style={{padding:"8px",color:"black",textAlign:"left",fontWeight:"bolder",textTransform:"capitalize"}}>{item.food_name}</td>
     <td style={{padding:"8px",color:"black",textAlign:"center",fontWeight:"bolder"}}><input type='number' placeholder={this.state.updatedQuantities[itemIndex] || item.quantity}
     onChange={(e) => this.handleQuantityChange(itemIndex,  parseInt(e.target.value), item.trans_id)} min='0'
      style={{width:"90px",border:"none",borderBottom:"1px solid black"}} /></td>
     <td style={{padding:"8px",color:"black",textAlign:"center",fontWeight:"bolder"}}>{item.unit_price}</td>
     <td>
      {
        order.creditUser_id === undefined ?  <Button color="success" onClick={() => this.updateFood(order.id,order.customer_id)}>Update</Button>
        :
        <Button color='success' style={{backgroundColor:"green"}} onClick={() => this.handleCreditUpdate(order.id,order.customer_id,order.creditUser_id) }>Update</Button>
      }
     </td>
   </tr>
   
   ))}
 </tbody>
</table>
<br/>
<i class="bi bi-plus-square" onClick={()=>this.handleAddItem()} style={{fontSize:"20px",color:"green"}}> </i>
<br/>

{ this.state.showAdd &&
   <div>
    <select class="itSelect" aria-label="Default select example" onChange={this.categoryhandler} required>
   <option value="" disabled selected>select Category</option>
   {categoryName.map((names) =>(
    <option value={names.category_name} key={names.id}  data-id={names.id} data-cateCode={names.category_code}>{names.category_name}</option>
   ))}
 </select>
 <br/><br/>
<select class="itSelect" aria-label="Default select example" onChange={this.ChangeFoodHandler} required>
 <option value="" disabled selected>select food</option>
 {
  food_name.map((food) => (
<option  value={food.food_name} key={food.id} data-price={food.price} data-codeItem={food.item_code}  >{food.food_name}</option>
  
  ))
 }
</select>
<br/><br/>
<Input type='number' id='newQuan' className='quain' min='0' placeholder='Enter a Quantity' />
<br/>
{
  order.creditUser_id === undefined ? <button className='btn btn-success' onClick={() => this.handlemoreItem(order.id,order.customer_id)}>ADD</button> 
  : 
  <button className='btn btn-success' style={{backgroundColor:"green"}} onClick={() => this.handleCreditAddItem(order.id,order.customer_id,order.creditUser_id)}>ADD</button>
}

   </div>
}
          </ModalBody>
          <ModalFooter>
          {/* <Button color="success" onClick={() => this.updateFood(order.id,order.customer_id,order.order_no)}>Update</Button> */}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
  
      </div>
      
    );
  }
}

export default Bill;