import React, { useState, useEffect } from "react";
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { var_api } from "../constant";
import { useHistory } from 'react-router-dom';
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";

const StockTransaction = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [stock, setStock] = useState([]);
    const [stockDetails, setStockDetails] = useState([]);
    const [selectedStockCode, setSelectedStockCode] = useState('');
    const shopId = localStorage.getItem('shopId');

    // Go to home page
    const handleButtonClick = () => {
        history.push('/home')
    }

    useEffect(() => {
        fetchStockcode();
    }, []);

    const fetchStockcode = async () => {
        try {
            const response = await axios.get(`${var_api}/api/raw-items/get-items/${shopId}`);
            console.log("stock Items", response.data)
            setStock(response.data);
            const stockId = response.data[0];
            fetchgetlist(stockId.stock_code);
        } catch (error) {
            console.error('Error fetching items:', error);
            toast('Error Fetching items');
        }
    };

    const fetchgetlist = async (stockCode) => {
        try {
            const response = await axios.get(`${var_api}/api/stock-transaction/get-stock-details/${shopId}/${stockCode}`);
            console.log("stock Details", response.data)
            setStockDetails(response.data);
        } catch (error) {
            console.error('Error fetching items:', error);
            toast('Error Fetching items');
        }
    };

    const handleChangeStockCode = (e) => {
        const [stockId, stockCode] = e.target.value.split(',');
        console.log("Selected Stock id & No:", stockId, stockCode);

        // Set the selected stock code
        setSelectedStockCode(stockCode);
        
        // Fetch the stock details using the selected stock code
        fetchgetlist(stockCode);
    };

    return (
        <>
            {isLoading && <Loader />}
            <Navbar expand="sm" className="bg-body-tertiary bg-success p-0" style={{ padding: "0px" }}>
                <Container className="p-0">
                    <Navbar.Brand className="p-0 text-light fw-bolder">STOCK TRANSACTION</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <button className="btn btn-outline-success" onClick={handleButtonClick} style={{ backgroundColor: "white", color: "green" }}>
                            <i className="bi bi-house"></i> Go to Home
                        </button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <br />
           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
           <label htmlFor="stockCodeSelect" style={{  fontWeight:"bold", marginRight:"120px" }}>Select Stock Code:</label>
           <Form.Control as="select" onChange={handleChangeStockCode} style={{ width: "20%", padding: "10px" }}>
                <option value="">Enter Stock Code</option>
                {stock.map(item => (
                    <option key={item.id} value={`${item.id},${item.stock_code}`}>
                        {item.stock_code}
                    </option>
                ))}
            </Form.Control>
           </div>
            <br/>
            <div >
          <table class="table table-responsive-sm table-hover" style={{maxWidth:"100%"}}>   
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Date</th>
      <th scope="col">Oeration</th>
      <th scope="col">Operation Code</th>
      <th scope="col">Quantity</th>
      <th scope="col">Unit value</th>
    </tr>
  </thead>
  
  <tbody >
   {stockDetails.length > 0 ? (
   stockDetails.map((data, index) => (
  
    <tr key={index}>
      <th scope="row">{index + 1}</th>
      <td>{data.post_date}</td>
      <td >{data.operation}</td>
      <td >{data.operation_code}</td>
      <td >{data.qty}</td>
      <td >{data.stock_in_perunit}</td>
    </tr>
  
)) 
): (
    <tr>
      <td colSpan="7" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )
}
   </tbody>
</table>
          </div>
        </>
    );
}
export default StockTransaction;
