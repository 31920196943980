import React, {useState, useEffect} from "react";
import { Card, Button, Form, Row, Col  } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { var_api } from "../constant";
import { useHistory } from 'react-router-dom';
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';


const StoreGrn = () => {
  const history = useHistory();
  const [isOpenGrn, setIsOpenGrn] = useState(false);
  const [poList, setPoList] = useState([]);
  const shopId = localStorage.getItem('shopId')
  const userID = localStorage.getItem("userId");
  const empID = localStorage.getItem("admin_employee");
  const [poDetail, setPoDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [supplierDoNo, setSupplierDoNo] = useState('');
  const [doDate, setDoDate] = useState('');
  const [grnLists, setGrnLists] = useState({ items: [] });
  const [glaccounts, setAccounts] = useState([]);
  // const [receivedQuantities, setReceivedQuantities] = useState(
  //   grnLists.items.reduce((acc, item) => {
  //     acc[item.id] = item.grn_quantity_received;
  //     return acc;
  //   }, {})
  // );


  const fetchPoDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${var_api}/api/purchase-orders/generated-purchase-orders/${shopId}`);
      const poDetails = response.data;
      setPoList(poDetails);
      console.log("po",poDetails);
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
    finally{
      setIsLoading(false);
    }
  };


  useEffect(()=>{
    fetchPoDetails();
    getAccounts();
  },[]);
//

  const fetchParticularGrn = async (ponumber) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${var_api}/api/purchase-orders/particular-purchase-orders/${ponumber}/${shopId}`);
      const poDetails = response.data;
      setGrnLists(poDetails);
      console.log("posss",poDetails);
    } catch (error) {
      setGrnLists({ items: [] });
      console.error('Error fetching grns:', error);
    }
    finally{
      setIsLoading(false);
    }
  };


  const handleGenerateGRN = (po) => {
    setIsOpenGrn(true);
    console.log("po", po)
    setPoDetail(po)
    fetchParticularGrn(po.purchase_order_no)
  }

  const handleBack = () =>{
    setIsOpenGrn(false);
  }

  
       //Go to home page
       const handleButtonClick = () => {
        history.push('/home')
      }


      const [receivedQuantities, setReceivedQuantities] = useState(
        grnLists.items.reduce((acc, item) => ({ ...acc, [item.id]: 0 }), {})
      );
    
   // Format the date to dd-mm-yyyy
   const formatDate = (date) => {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1; // Months are zero indexed
    const year = d.getFullYear();

    // Add leading zero if day or month is less than 10
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return `${day}-${month}-${year}`;
  };

      // const handleSave = async (e) => {
      //   e.preventDefault();
      //   const neededColumns = ['stock_code', 'non_stock_code', 'ordered_qty', 'received_qty', 'balance_qty'];
  
      //   // Prepare the payload
      //   const payload = grnLists.items.map(item => ({
      //     id: item.id,
      //     ...neededColumns.reduce((acc, col) => {
      //       if (item.hasOwnProperty(col)) {
      //         acc[col] = item[col];
      //       }
      //       return acc;
      //     }, {}),
      //     received_qty: receivedQuantities[item.id] || item.received_qty, // Update received_qty if provided
      //     balance_qty: item.ordered_qty - (receivedQuantities[item.id] || item.received_qty),
      //   }));
        
      //   try {
      //     const response = await axios.put(${var_api}/api/purchase-order-items/edit-multiple-items, payload);
      //     console.log('Update response:', response.data);
      //     setReceivedQuantities(
      //       grnLists.items.reduce((acc, item) => {
      //         acc[item.id] = item.grn_quantity_received;
      //         return acc;
      //       }, {})
      //     )
      //     // handlePost();
      //     fetchParticularGrn(poDetail.purchase_order_no);
      //   } catch (error) {
      //     console.error('Error updating GRN items:', error);
      //   }
      // };

      const handleSubmit = async (e) => {
        e.preventDefault();
      
        const formattedDoDate = formatDate(new Date(doDate), 'dd-MM-yyyy');
        const currentDateTime = formatDate(new Date(), 'dd-MM-yyyy\'T\'HH:mm:ss');
      
        // Prepare the items for grn_items
        const grnItems = grnLists.items.map(item => ({
          po_detail_stock_id: item.id,
          po_detail_stock_code: item.stock_code ? item.stock_code : item.non_stock_code,
          grn_quantity_received: receivedQuantities[item.id] || item.received_qty,
          service_amount_per_unit: 0, // Replace with actual value if needed
          stock_in_itemLevel: 0, // Replace with actual value if needed
          dp_mos_amount_per_unit: 0, // Replace with actual value if needed
          tc_onwards_amount_per_unit: 0, // Replace with actual value if needed
          accounts_miscellaneous_types_id: 0, // Replace with actual value if needed
          xstatus: 0,
          created_at:  new Date().toISOString().slice(0, 19).replace('T', ' '),
          updated_at:  new Date().toISOString().slice(0, 19).replace('T', ' ')
        }));
      
        // Prepare the payload
        const payload = {
          shop_id: parseInt(shopId), // Replace with actual value if needed
          grn_date: currentDateTime,
          store_id: 0, // Replace with actual value if needed
          grn_type: 0, // Replace with actual value if needed
          po_number: poDetail.purchase_order_no, // Replace with actual value if needed
          supplier_do_no: supplierDoNo,
          supplier_invoice_no: '', // Replace with actual value if needed
          posted: 0, // Replace with actual value if needed
          postby: parseInt(empID), // Replace with actual value if needed
          postdate: currentDateTime,
          xrate: 0, // Replace with actual value if needed
          stock_in_value: 0, // Replace with actual value if needed
          allocated_amount: 0, // Replace with actual value if needed
          exists_service_supplier: false, // Replace with actual value if needed
          currency: 'INR', // Replace with actual value if needed
          do_date: formattedDoDate,
          supplierid: poDetail.supplier_id, // Replace with actual value if needed
          allocated_service_amount: 0, // Replace with actual value if needed
          service_amount: 0, // Replace with actual value if needed
          dp_mos_amount: 0, // Replace with actual value if needed
          tc_onwards_amount: 0, // Replace with actual value if needed
          foreclose: false, // Replace with actual value if needed
          created_at:  new Date().toISOString().slice(0, 19).replace('T', ' '),
          updated_at:  new Date().toISOString().slice(0, 19).replace('T', ' '),
          grn_items: grnItems
        };
      
        // Log the payload to ensure it is structured correctly
        console.log('Data to be saved:', payload);
      
        try {
          for (const grn of grnLists.items) {
            const individualPayload = {
              ...payload, 
            };
      
            if (grn.grn_xstatus !== null && grn.grn_quantity_received !== null) {
              const response = await axios.put(`${var_api}/api/store-grn/overall-put/${grn.grn_id}`, individualPayload);
              console.log('Put response:', response.data);
            } else {
              
              const response = await axios.post(`${var_api}/api/store-grn/post`, individualPayload);
              console.log('Post response:', response.data);
            }
          }
      
          // Update received quantities
          const receivedQuantities = grnLists.reduce((acc, grn) => {
            acc[grn.grn_id] = grn.grn_quantity_received;
            return acc;
          }, {});
          setReceivedQuantities(receivedQuantities);
      
          // Fetch particular GRN
          fetchParticularGrn(poDetail.purchase_order_no);
        } catch (error) {
          console.error('Error updating GRN items:', error);
        }};


      const handleInputChange = (e, id) => {
        const newValue = parseInt(e.target.value, 10);
        const balanceQty = grnLists.items.find(item => item.id === id).balance_qty;
    
        if (newValue <= balanceQty) {
          setReceivedQuantities(prevState => ({
            ...prevState,
            [id]: newValue,
          }));
        } else {
          alert(`Received quantity cannot exceed the balance quantity of ${balanceQty}.`);
        }
      };


//handle grn put
      const handlegrnedit = async (e) => {
        e.preventDefault();
      
        const formattedDoDate = formatDate(new Date(doDate), 'dd-MM-yyyy');
        const currentDateTime = formatDate(new Date(), 'dd-MM-yyyy\'T\'HH:mm:ss');
        let allocatedAmount = 0;
      
        const grnItems = grnLists.items.map(item => {
          const receivedQty = receivedQuantities[item.id] || item.received_qty;
          const unitPrice = item.unit_price || 0; // Replace with actual value if needed
        
          // Calculate the total amount for the current item
          const totalAmount = receivedQty * unitPrice;
        
          // Accumulate the total amount to the allocatedAmount
          allocatedAmount += totalAmount;
        
          return {
            po_detail_stock_id: item.id,
            po_detail_stock_code: item.stock_code ? item.stock_code : item.non_stock_code,
            grn_quantity_received: receivedQty,
            service_amount_per_unit: 0, // Replace with actual value if needed
            stock_in_itemLevel: 0, // Replace with actual value if needed
            dp_mos_amount_per_unit: 0, // Replace with actual value if needed
            tc_onwards_amount_per_unit: 0, // Replace with actual value if needed
            accounts_miscellaneous_types_id: 0, // Replace with actual value if needed
            xstatus: 0,
            created_at:  new Date().toISOString().slice(0, 19).replace('T', ' '),
            updated_at:  new Date().toISOString().slice(0, 19).replace('T', ' ')
          };
        });
      
        // Prepare the payload
        const payload = {
          shop_id: parseInt(shopId), // Replace with actual value if needed
          grn_date: currentDateTime,
          store_id: 0, // Replace with actual value if needed
          grn_type: 0, // Replace with actual value if needed
          po_number: poDetail.purchase_order_no, // Replace with actual value if needed
          supplier_do_no: supplierDoNo,
          supplier_invoice_no: '', // Replace with actual value if needed
          posted: 1, // Replace with actual value if needed
          postby: parseInt(userID), // Replace with actual value if needed
          postdate: currentDateTime,
          xrate: 0, // Replace with actual value if needed
          stock_in_value: 0, // Replace with actual value if needed
          allocated_amount: allocatedAmount, // Replace with actual value if needed
          exists_service_supplier: false, // Replace with actual value if needed
          currency: 'INR', // Replace with actual value if needed
          do_date: formattedDoDate,
          supplierid: poDetail.supplier_id, // Replace with actual value if needed
          allocated_service_amount: 0, // Replace with actual value if needed
          service_amount: 0, // Replace with actual value if needed
          dp_mos_amount: 0, // Replace with actual value if needed
          tc_onwards_amount: 0, // Replace with actual value if needed
          foreclose: false, // Replace with actual value if needed
          created_at:  new Date().toISOString().slice(0, 19).replace('T', ' '),
          updated_at:  new Date().toISOString().slice(0, 19).replace('T', ' '),
          grn_items: grnItems
        };
      
        // Log the payload to ensure it is structured correctly
        console.log('Data to be saved:', payload);
      
        try {
          for (const grn of grnLists.items) {
            const individualPayload = {
              ...payload, 
            };
            console.log("grn", grn)
              const response = await axios.put(`${var_api}/api/store-grn/overall-put/${grn.grn_id}`, individualPayload);
              console.log('Put response:', response.data);
              handlePOput();
          }
      
          // Update received quantities
          const receivedQuantities = grnLists.reduce((acc, grn) => {
            acc[grn.grn_id] = grn.grn_quantity_received;
            return acc;
          }, {});
          setReceivedQuantities(receivedQuantities);
      
          // Fetch particular GRN
          fetchParticularGrn(poDetail.purchase_order_no);
        } catch (error) {
          console.error('Error updating GRN items:', error);
        }};

        // handle PO put
const handlePOput = async (e) => {
  if (e && e.preventDefault) {
    e.preventDefault();
  }
  const currentDateTime = formatDate(new Date(), 'dd-MM-yyyy\'T\'HH:mm:ss');

  let totalAmount = 0;

  // Construct purchase_order_items array from grnLists.items
  const purchaseOrderItems = grnLists.items.map(item => {
    const itemTotalAmount = item.unit_price * (item.ordered_qty || 0);

    // Accumulate the total amount
    totalAmount += itemTotalAmount;

    return {
      stock_code: item.stock_code,
      non_stock_code: item.non_stock_code || "",
      required_qty: item.required_qty || 0,
      free_stock_qty: item.free_stock_qty || 0,
      indent_qty: item.indent_qty || 0,
      spa_qty: item.spa_qty || 0,
      reserved_qty: item.reserved_qty || 0,
      ordered_qty: item.ordered_qty || 0,
      received_qty: item.received_qty || 0,
      required_date: item.required_date || "",
      indent_no: item.indent_no || "",
      spa_no: item.spa_no || "",
      unit_price: item.unit_price || 0,
      imw_code_price: item.imw_code_price || 0,
      created_by: item.created_by || "",
      created_date: item.created_date || "",
      updated_by: item.updated_by || "",
      updated_date: item.updated_date || "",
      shop_id: parseInt(shopId),
      non_stock_item: item.non_stock_item || false,
      discount_per: item.discount_per || 0,
      discount_val: item.discount_val || 0,
      uom: item.uom || "",
      tmp_free_stock_qty: item.tmp_free_stock_qty || 0,
      tmp_good_receipt_qty: item.tmp_good_receipt_qty || 0,
      balance_qty: item.balance_qty || 0,
      tmp_cancel_qty: item.tmp_cancel_qty || 0,
      isexist_spa: item.isexist_spa || true,
      purchase_plan_item_suppl: item.purchase_plan_item_suppl || "",
      net_unit_price: item.net_unit_price || 0,
      total_net_unit_price: item.total_net_unit_price || 0,
      remarks: item.remarks || "No remarks"
    };
  });

  // Prepare the payload
  const payload = {
      purchase_order_no: poDetail.purchase_order_no,
      purchase_order_date: poDetail.purchase_order_date,
      required_date: poDetail.required_date,
      description: poDetail.description, //doubt
      purchase_plan_id: 1, //doubt
      purchase_requestes: poDetail.purchase_requestes,
      request_type: poDetail.request_type,
      purchase_request_type: poDetail.purchase_request_type,
      purchase_order_controno: poDetail.purchase_order_controno,
      pm_no: poDetail.pm_no,
      pj_no: poDetail.pj_no,
      supplier_id: poDetail.supplier_id,
      supplier_refno: poDetail.supplier_refno,
      supplier_cont_person: poDetail.supplier_cont_person,
      supplier_address: poDetail.supplier_address,
      contact_no: poDetail.contact_no,
      email: poDetail.email,
      special_instruction: poDetail.special_instruction,
      term_of_packing: poDetail.term_of_packing,
      terms: poDetail.terms,
      xrate: poDetail.xrate,
      purchase_order_currency: "INR",
      partial_delivery: false,
      purchase_order_amount: poDetail.purchase_order_amount,
      gst_amount: poDetail.gst_amount,
      discount_per: poDetail.discount_per,
      total_amount: totalAmount ,
      net_amount: poDetail.net_amount,
      credit_term: poDetail.credit_term,
      inpurchase_order_status: poDetail.inpurchase_order_status,
      inprogress_status: poDetail.inprogress_status,
      purchase_order_status: poDetail.purchase_order_status,
      created_by: parseInt(empID),
      created_date: currentDateTime,
      updated_by: parseInt(empID),
      updated_date: currentDateTime,
      shop_id: parseInt(shopId),
      price_basic: poDetail.price_basic,
      approve_by_pm: poDetail.approve_by_pm,
      approve_pm_date: poDetail.approve_pm_date,
      approve_by_mgmt: poDetail.approve_by_mgmt,
      approve_mgmt_date: poDetail.approve_mgmt_date,
      tax_code: poDetail.tax_code,
      revision: 1,
      is_active: true,
      gst_percent: poDetail.gst_percent,
      buyer: poDetail.buyer,
      other_charges: poDetail.other_charges,
      created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
      updated_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
      purchase_order_items: purchaseOrderItems // Use the constructed purchaseOrderItems array here
  };

  try {
      const response = await axios.put(`${var_api}/api/purchase-orders/overall-put/${grnLists.id}`, payload);
      console.log('Update response:', response.data);
      setReceivedQuantities(
          grnLists.items.reduce((acc, item) => {
              acc[item.id] = item.grn_quantity_received;
              return acc;
          }, {})
      );
      handlestocktransactionpost();
      // fetchParticularGrn(poDetail.purchase_order_no);
  } catch (error) {
      console.error('Error updating GRN items:', error);
  }
};

//stock transaction post
        const handlestocktransactionpost = async (e) => {
          if (e && e.preventDefault) {
            e.preventDefault();
          }
        
          const currentDateTime = formatDate(new Date(), 'dd-MM-yyyy\'T\'HH:mm:ss');
        
          const payload = grnLists.items.map(item => ({
              shop_id: parseInt(shopId),
              stock_code:  item.stock_code ? item.stock_code : item.non_stock_code,
              stock_type_id: 1,
              transaction_type_code: "GRN_to_Store",
              operation: "credit",
              qty: item.grn_quantity_received,
              si_no: 1,
              grn_id: item.grn_id,
              grn_item_id: item.grn_item_id,
              created_by: parseInt(userID),
              created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              updated_by: parseInt(userID),
              updated_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              post_date: currentDateTime,
              stock_in_perunit: item.unit_price,
              book_in_perunit: item.unit_price,
              xrate: 0,
              currency_code: "INR",
              operation_code: item.grn_no 
            }));
        
          // Log the payload to ensure it is structured correctly
          console.log('Data to be saved:', payload);
        
          try {
                const response = await axios.post(`${var_api}/api/stock-transaction/post`, payload);
                console.log('Post response:', response.data);
                glaccountsposting();
            } catch (error) {
            console.error('Error updating GRN items:', error);
          }};

///api/gl-accounts-posting/post

const glaccountsposting = async (e) => {
  if (e && e.preventDefault) {
    e.preventDefault();
  }

  const currentDateTime = formatDate(new Date(), 'yyyy-MM');
  const currentDate = formatDate(new Date(), 'dd-MM-yyyy');
  const selectedAccount = glaccounts.find(account => account.account_name.includes("store_grn"));
  let totalAmount = 0;

  // Construct purchase_order_items array from grnLists.items
  const purchaseOrderItems = grnLists.items.map(item => {
    const itemTotalAmount = item.unit_price * (item.ordered_qty || 0);

    // Accumulate the total amount
    totalAmount += itemTotalAmount;})

  const payload = grnLists.items.map(item => ({
    gl_account_no: selectedAccount.account_no,
    account_description:selectedAccount.account_name,
    posting_date: currentDate,
    credit_transaction_amount: totalAmount,
    debit_transaction_amount: 0,
    indicator: "credit",
    document_no: item.grn_no,
    document_type: "store_grn",
    shop_id: parseInt(shopId),
    lost_center: 0,
    profit_center: 0,
    currency: "INR",
    reference_no: "",
    posting_period: currentDateTime,
    user_id: parseInt(empID),
    remarks: "",
    tax_code: "",
    business_area: ""
    }));

  // Log the payload to ensure it is structured correctly
  console.log('Data to be saved:', payload);

  try {
        const response = await axios.post(`${var_api}/api/gl-accounts-posting/post`, payload);
        console.log('Post response:', response.data);
    } catch (error) {
    console.error('Error updating GRN items:', error);
  }};


          // const handlePostAll = async (e) => {
          //   e.preventDefault();
            
          //   try {
          //     // Call the handlePOpost function
          //     await handlePOput(e);
          //     console.log('Purchase Order posted successfully');
          
          //     // Call the handlegrnedit function
          //     await handlegrnedit(e);
          //     console.log('GRN edited successfully');
          
          //     // Call the handlestocktransactionpost function
          //     await handlestocktransactionpost(e);
          //     console.log('Stock transaction posted successfully');
          
          //     // Perform any additional actions or state updates here if needed
          
          //   } catch (error) {
          //     console.error('Error during posting:', error);
          //   }
          // };

          const getAccounts = async () => {
            setIsLoading(true);
            try {
               const response = await axios.get(`${var_api}/api/gl-accounts-master/get-accounts/${shopId}`);
               console.log("response",response)
              const user =response.data;
              setAccounts(user); 
              setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
              console.log(error);
            }
          };
    
      const incrementQuantity = (id) => {
        setReceivedQuantities(prevState => {
          const newValue = (prevState[id] || 0) + 1;
          const balanceQty = grnLists.items.find(item => item.id === id).balance_qty;
    
          if (newValue <= balanceQty) {
            return {
              ...prevState,
              [id]: newValue,
            };
          } else {
            toast(`Received quantity cannot exceed the balance quantity of ${balanceQty}.`);
            return prevState;
          }
        });
      };
    
      const decrementQuantity = (id) => {
        setReceivedQuantities(prevState => {
          const newValue = (prevState[id] || 0) - 1;
    
          if (newValue >= 0) {
            return {
              ...prevState,
              [id]: newValue,
            };
          } else {
            alert('Received quantity cannot be less than zero.');
            return prevState;
          }
        });
      };


      const handlePrint = () => {
        const doc = new jsPDF();
        const shopName = localStorage.getItem('shopName');

          // Get current date and time
         const now = new Date();
         const formattedDate = now.toLocaleDateString('en-GB'); // Format: dd/mm/yyyy
         const formattedTime = now.toLocaleTimeString('en-GB'); // Format: HH:mm:ss


        // Calculate PO Amount and Discount Amount
      const newPoAmount = grnLists.items.reduce((sum, item) => sum + (item.total_net_unit_price || 0), 0);
      const newDiscountAmount = grnLists.items.reduce((sum, item) => sum + (item.discount_per || 0), 0);
  
      // Calculate Sub Total
      const newSubTotal = newPoAmount + newDiscountAmount;
  
      // Calculate Total Amount
      const newTotalAmount = newSubTotal + (grnLists && grnLists.gst_amount || 0);

       // Centered shop name
       doc.setFontSize(18);
       doc.text("GRN Report", doc.internal.pageSize.getWidth() / 2, 10, 'center');
      
        // Centered shop name
        doc.setFontSize(13);
        doc.text(shopName, doc.internal.pageSize.getWidth() / 2, 16, 'center');

          // Current date and time at the top right corner
  doc.setFontSize(10);
  doc.text(`${formattedDate} ${formattedTime}`, doc.internal.pageSize.getWidth() - 15, 10, { align: 'right' });
      
        // Supplier details on the left
        const supplierDetails = [
          `GRN NO: ${grnLists.items[0].grn_no}`,
        ];
        doc.setFontSize(10);
        supplierDetails.forEach((detail, index) => {
          doc.text(detail, 20, 30 + (index * 10));
        });
      
        // PO details on the right
        const poDetails = [
          `PO Number: ${grnLists.purchase_order_no}`,
          `PO Date: ${grnLists.purchase_order_date}`,
          `Supplier Ref.no: ${grnLists.supplier_refno}`,
          `Required Date: ${grnLists.required_date}`,
        ];
        poDetails.forEach((detail, index) => {
          doc.text(detail, doc.internal.pageSize.getWidth() - 70, 30 + (index * 10));
        });
      
        // Table with stock items
        const tableColumn = ["Stock Code", "UOM", "Unit Price", "Discount %", "Net Unit Price", "Total Net Price"];
        const tableRows = [];
      
        grnLists.items.forEach(item => {
          const rowData = [
            item.stock_code || item.non_stock_code,
            item.uom,
            item.unit_price,
            item.discount_per,
            item.net_unit_price,
            item.total_net_unit_price
          ];
          tableRows.push(rowData);
        });
      
        // Add some vertical space before the table
        doc.autoTable(tableColumn, tableRows, { startY: 70 });
  
  
    // Add the PO summary section on the right side after the table
    const finalY = doc.lastAutoTable.finalY + 10;
    const summaryX = 155;
    console.log("doc.internal.pageSize.getWidth()",doc.internal.pageSize.getWidth())
    // const summaryX = doc.internal.pageSize.getWidth() - 90; // Adjust this value as needed
  
    doc.setFontSize(10);
    doc.text(`Discount Amount: ${newDiscountAmount}`, summaryX, finalY + 10);
    doc.text(`Sub Total: ${newSubTotal}`, summaryX, finalY + 20);
    doc.text(`GST Amount: ${grnLists.gst_amount}`, summaryX, finalY + 30);
      // Draw a line before the Total Amount
      const lineY = finalY + 40; // Y-coordinate for the line
      doc.setLineWidth(0.5); // Line thickness
      doc.line(summaryX - 40, lineY, summaryX + 80, lineY); // Draw horizontal line
      
      // Add the Total Amount below the line
      doc.text(`Total Amount: ${newTotalAmount.toFixed(2)}`, summaryX, lineY + 10);
  
         // Add signature sections in the same line with small fonts
    const signatureY = lineY + 30; // Y-coordinate for the signature section
    doc.setFontSize(9);
    doc.text('Issued By:', 10, signatureY);
    doc.text('Approved By:', doc.internal.pageSize.getWidth() / 2, signatureY); // Centered horizontally
  
    // Draw signature lines
    doc.setLineWidth(0.5); // Line thickness
    doc.line(50, signatureY + 2, 90, signatureY + 2); // Line for Issued By
    doc.line(doc.internal.pageSize.getWidth() / 2 + 60, signatureY + 2, doc.internal.pageSize.getWidth() / 2 + 130, signatureY + 2); // Line for Approved By
      
        // Save the PDF
        doc.save(`Purchase_Order_${grnLists.purchase_order_date}.pdf`);
      };
      

 
    return(
    <>
    {isLoading && <Loader />}
    <Navbar expand="sm" className="bg-body-tertiary bg-success p-0" style={{padding:"0px"}}>
        <Container className="p-0">
            <Navbar.Brand className="p-0 text-light fw-bolder">STORE GRN</Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                 <button className="btn btn-outline-success" onClick={handleButtonClick} style={{backgroundColor:"white",color:"green"}}>
                  <i class="bi bi-house"></i> Go to Home</button>
            </Navbar.Collapse>
       </Container>
    </Navbar>
    <Card style={{ Width: '100%', height:"auto", margin:"15px", borderRadius:"5px", border:"none", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding:"10px" }}>
        <Card.Body>
       {
        isOpenGrn ? (
          <div>
            <Row>
              <Col>
              <i class="bi bi-arrow-left-circle-fill" style={{fontSize:"20px", color:"green"}} onClick={handleBack}></i>
              </Col>
        <Col>
        <Form>
      <Form.Group className="mb-6" controlId="formGroupEmail">
        <Form.Label>Supplier DO No</Form.Label>
        <Form.Control type="text" placeholder="Enter supplier do no"  value={supplierDoNo}
                onChange={(e) => setSupplierDoNo(e.target.value)}/>
      </Form.Group>
      <Form.Group className="mb-6" controlId="formGroupPassword">
        <Form.Label>DO Date</Form.Label>
        <Form.Control type="date" placeholder="Enter DO date"  value={doDate}
                onChange={(e) => setDoDate(e.target.value)}/>
      </Form.Group>
      <br/>
      <div>
        <button className="btn btn-success" style={{marginRight:'10px'}} onClick={handleSubmit}>Save</button>
        <button className="btn btn-primary" onClick={handlegrnedit} style={{marginRight:'10px'}}>Post</button>
        <button className="btn btn-warning text-light" onClick={handlePrint}> <i class="bi bi-printer"></i> Print</button>
      </div>
    </Form>
        </Col>
        <Col>
        <h6 style={{float:"right"}}>PO No: <b style={{color:"red", fontSize:"20px"}}>{poDetail && poDetail.purchase_order_no}</b>
        <br/>
        <span>PO Date: <b style={{color:"red", fontSize:"20px"}}>{poDetail && poDetail.purchase_order_date}</b>
        </span> 
        </h6>
        </Col>
       </Row>

       <br/>
       <Table hover variant="light">
      <thead>
        <tr>
          <th>Stock Code, Description / Summary</th>
          <th>Total Received</th>
        </tr>
      </thead>
      <tbody>
      {grnLists.items.map((list, index) => (
            <tr key={index}>
            <td>
              <h6 style={{color:"blue", fontSize:"18px"}}>{list.stock_code ? list.stock_code : list.non_stock_code}</h6>
              <h6 style={{color:"red", fontSize:"18px"}}>{list.description}</h6>
              <div style={{ display: 'flex', marginBottom: '10px', gap:"15px" }}>
              <h6>Ordered Qty: {list.ordered_qty}</h6>
              <h6>Received Qty: {list.received_qty}</h6>
              <h6>Balanced Qty: {list.balance_qty}</h6>

              </div>
            </td>
            <td> <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              <Button variant="secondary" onClick={() => decrementQuantity(list.id)}>-</Button>
              <Form.Control 
                type="number" 
                value={receivedQuantities[list.id] || 0}
                onChange={(e) => handleInputChange(e, list.id)}
                style={{ width: '60px', textAlign: 'center', margin: '0 10px' }} 
              />
              <Button variant="secondary" onClick={() => incrementQuantity(list.id)}>+</Button>
            </div></td>
          </tr>  
          ))
        }
      </tbody>
    </Table>
          </div>
        ) : (

          <Table hover variant="light">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Action</th>
              <th>P/O No</th>
              <th>P/O Status</th>
              <th>P/O Date</th>
              <th>Supplier</th>
            </tr>
          </thead>
          <tbody>
           {
            poList.map((po,index)=>(
              <tr>
              <td>{index+1}</td>
              <td>
                <button className="btn btn-primary" onClick={()=>handleGenerateGRN(po)}>GRN</button>
              </td>
              <td>{po.purchase_order_no}</td>
              <td>{po.purchase_order_status ===  0 ? "Draft" : po.purchase_order_status === 1 ? "submitted" : "P/O Generated"}</td>
              <td>{po.purchase_order_date}</td>
              <td>{po.supplier_name}</td>
            </tr>
            ))
           }
           
            
          </tbody>
        </Table>

        )
       }
        </Card.Body>
    </Card>
    </>
    )
}


export default StoreGrn;