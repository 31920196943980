import React, { useState, useEffect } from "react";
import { Link, useHistory} from "react-router-dom";
import { var_api } from "../constant";
import shop_logo from "../../images/splash/shop_logo.jpeg";
import "./checkotp.css";
import Swal from 'sweetalert2';
import axios from "axios";
import { Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';

const CheckOTP = () => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const history = useHistory();
   

    const handleChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        const otpValue = otp.join(''); // Join OTP array into a single string
        console.log("otp",otpValue);
        const email =  localStorage.getItem('forgotEmail');
        try {
          let response;
          const person = localStorage.getItem('passwordFor')
          console.log("person",person);
          if(person === "Employee"){
               // Make an API call with the OTP payload
              response = await axios.put(`${var_api}/api/employees/check-OTP-code`, { otp: parseInt(otpValue), email:email });

          }else{
              // Make an API call with the OTP payload
              response = await axios.put(`${var_api}/api/user/check-OTP-admin`, { otp: parseInt(otpValue), email:email });

          }
          
         
      
            console.log(response.data); // Log response from the API
      
            // Check if the status code is 200
            if (response.status === 200) {
              // Redirect to the change password page upon successful response
              history.push('/change-password');
            } else {
              console.error('Unexpected status code:', response.status);
              // Handle unexpected status codes appropriately
            }
          } catch (error) {
            console.error('Error:', error.response);
             // Show the Swal alert for a Bad Request (status code 400)
             Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
                confirmButtonColor: '#007500',
              });
          }
      };
    
      const inputfocus = (index, event) => {
        if (event.key === "Delete" || event.key === "Backspace") {
          const next = index - 1;
          if (next > -1) {
            event.target.form.elements[next].focus();
          }
        } else {
          const next = index + 1;
          if (next < 6) {
            event.target.form.elements[next].focus();
          }
        }
      };

      
return(
    <div className="sign-up">
        <br/> 
       {/* <Link to="/" className="navbar-brand">
          <img src={shop_logo} alt="logo" style={{marginLeft:"720px", width:"120px",height:"110px",marginTop:"5px"}} />
        </Link>
        <br/> 
        <br/>  */}
          <Card style={{width:"27rem",height:"25rem",padding:"10px",borderRadius:"25px",border:"none", display: "flex", justifyContent: "center", alignItems: "center",margin:"auto" }}>
        <CardBody>
          <CardTitle style={{textAlign:"center"}}> 
          <Link to="/" className="navbar-brand">
          <img src={shop_logo} alt="logo" style={{margin:"auto", width:"100px",height:"100px",borderRadius:"50px", marginTop:"20px"}} />
        </Link>
          </CardTitle>
          <CardSubtitle>
      <div className="container" style={{width:"370px",margin:"auto"}}>
        
       
          <form  className="py-2" onSubmit={handleSubmit}>
            <h1 className="lead text-center py-2" style={{color:"rgb(248, 113, 50)", fontSize:"15px", fontWeight: "bolder"}}>Verify Your OTP</h1>
            <h1 className="lead text-center" style={{color:"grey", fontSize:"15px", fontWeight: "bolder"}}>We Have Sent You a 6 Digit OTP to your Email</h1>
            
           <br/>
            <div className="otpContainer">
            {otp.map((value, index) => (
          <input
            key={index}
            name={`otp${index + 1}`}
            type="number"
            autoComplete="off"
            className="otpInput"
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
            maxLength="1"
            onKeyUp={(e) => inputfocus(index, e)}
          />
        ))}
            </div>
            <br/>
            <div className="d-grid">
              <button className="btn btn-success" type="submit" style={{borderRadius:'10px', fontFamily:"sans-serif"}}>
                Verify
              </button>
            </div>

           
           
          </form>
        
      </div>
      </CardSubtitle>
         
         </CardBody>
       </Card>
    </div>
)
};

export default CheckOTP;