import React from "react";
import './Renewsubscription.css';
import { withRouter } from 'react-router-dom';
import Swal from "sweetalert2";
import axios from 'axios';
import { var_api } from "../constant";
import Select from 'react-select';
import { Card, CardImg, CardTitle, CardText, CardColumns,
  CardSubtitle, CardBody } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import coupon from '../../images/discount.webp'


class Renewsubscription extends React.Component
{
  constructor()
  {

    super();
  
    this.state=
    {
      amount:"",
      plans:[],
      subscribed_by: localStorage.getItem('userId') || "",
      plan_id:"",
      person_name:"",
      person_mobile:"",
      person_email:"",
      person_country:"",
      person_transaction_id:"",
      curDesc:"",
      currencyOptions: [
      ],
      cOptions: [
      ],
      show:false,
      selectedCurrency:{ },
      loading: false,
      shopName: localStorage.getItem('shopName'),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnclick = this.handleOnclick.bind(this);
  // Bind the event handler function
  this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
  this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
  this.handleModLogout = this.handleModLogout.bind(this);
  this.handleButtonClickhome = this.handleButtonClickhome.bind(this);
  this.handleButtonClickMySubscription = this.handleButtonClickMySubscription.bind(this);
  }

  async componentDidMount() {
   
     this.fetchUserDetails();
     this.fetchcurrecny();
   };

   fetchcurrecny = async () => {
    try {
      const response = await axios.get(`${var_api}/api/currency-master/get-currency`);
      const responseData = response.data;
      
      if (Array.isArray(responseData)) {
         // Map currency names to currencyOptions
      const options = responseData.map(currency => ({
        value: currency.id,
        label: currency.currency_name
      }));

      const sgdCurrency = responseData.find(currency => currency.currency_name === 'SGD');
      const currencyId = sgdCurrency.id; // Assuming currency_id is the property name for currency ID
      const currency_name = sgdCurrency.currency_name;
      console.log("Currency ID for SGD:", currencyId);

      const selectedCur = {label:currency_name,value:currencyId} 
      this.setState({currencyOptions: options, selectedCurrency:selectedCur, curDesc:sgdCurrency.currency_description, cOptions:responseData});
      
     this.fetchPlans(currencyId);
      } else {
        // Handle the case when responseData is not an array
        console.error('API response is not an array:', responseData);
        
      }
    } catch (error) {
      console.log('Error fetching data:',error);
    }
   }


   //fetch currency
     fetchPlans = async (id) => {
      const curId = this.state.selectedCurrency.value
    try {
     const response = await axios.get(`${var_api}/api/subscription-plan/plans-by-currency/${id}` );
     const plansData = response.data;
   this.setState({plans: plansData});
   
   console.log("categories",this.state.plans);
   }catch (error) {
     console.log('Error fetching data:', error);
     
     }
   
  };
  

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.amount === "") {
      // Display an error message or perform any other validation logic
      alert("select any one before submitting");
      return;
    }

    // If the option is selected, proceed with saving and redirecting
    // this.save();
  this.addSub();
  }

  handleCoupon = async()=>{
    const { history } = this.props; 
    const { value: formValues } = await Swal.fire({
      title: 'Use Coupon',
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="Coupon Code">' +
        '<input id="swal-input2" class="swal2-input" placeholder="Name">'+
        '<input id="swal-input3" class="swal2-input" placeholder="Phone No">',
      focusConfirm: false,
      confirmButtonColor: '#007500',
      confirmButtonText: 'submit',
      preConfirm: async () => {
        const input1 = document.getElementById('swal-input1').value;
        const input2 = document.getElementById('swal-input2').value;
        const input3 = document.getElementById('swal-input3').value;
        
        if (!input1 || !input2 || !input3) {
          Swal.showValidationMessage('Please fill in all the required fields');
        } else {
          try {
            const response = await axios.post(`${var_api}/api/sub/buy_subscription`, {
              coupon_code: input1,
              name: input2,
              mobile_number: input3,
              subscribed_by: localStorage.getItem('userId') || "",
            });
      
            if (response.status === 201) {
              localStorage.setItem('hideAlert', 'true');
              // Use history.push instead of window.location.href
           history.push('/');
            }
          } catch (error) {
            Swal.fire('Error', 'Failed .', error);
          }
        }
      },
    });
  };
    

  // save() {
  //   const { history } = this.props; 

  //   fetch(`${var_api}/api/sub/subscribe`, {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json, text/plain, /',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(this.state)
  //   })
  //   .then(response => {
  //     if (response.status === 201) {
  //       localStorage.setItem('hideAlert', 'true');
  //       // Use history.push instead of window.location.href
  //       history.push('/');
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error:', error);
  //   });
  // }

  addSub(){
    const { history } = this.props; 
    const userID = localStorage.getItem("userId");
   console.log(this.state.person_country,this.state.person_email,this.state.person_mobile,this.state.person_name,this.state.selectedCurrency.value,this.state.plan_id,this.state.amount)

    var options = {
      key: "rzp_live_pjBqmpZwxNqOn2",
      key_secret:"FdhDNbFA0AqCFvPVNNIpQfQ7",
      amount: this.state.amount *100,
      currency:this.state.selectedCurrency.label,
      name:"SG BILLER",
      description:"for testing purpose",
      handler: (response) => {
        if (response.error) {
          // Payment failed
          console.error('Payment failed:', response.error.description);
          // Handle the failure, e.g., show an error message to the user
          alert('Payment failed. ' + response.error.description);
        }else if (response.razorpay_payment_id) {
        const detail = {
          "transaction_id":response.razorpay_payment_id, 
          "transaction_person_name":this.state.person_name, 
          "user_id":parseInt(userID), 
          "plan_id":this.state.plan_id,
          "is_tableorder": 0
      }
    
         fetch(`${var_api}/api/cash-subscription-details/post`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json, text/plain, /',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(detail)
        })
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            console.log("yeah its came")
            // alert(response.razorpay_payment_id);
            history.push('/Mysubscriptionhistory');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        })
      }
      },
      prefill: {
        name:this.state.person_name,
        email:this.state.person_email,
        contact:this.state.person_mobile
      },
      notes:{
        address:"Razorpay Corporate office"
      },
      theme: {
        color:"#3399cc"
      }
    };
    var pay = new window.Razorpay(options);
    pay.open();
 
    
  
}

  fetchUserDetails = async () => {
          
    const userId = localStorage.getItem('userId')
    try {
     const response = await axios.get(`${var_api}/api/user/data/${userId}` );
     const data = response.data;
   
      console.log("data",response,data);
       // If there are categories in the response, set the ID of the first category in the state
       this.setState({ person_name: data.name, person_mobile: data.mobile, person_email: data.email, person_country: data.country });
       
    
   }catch (error) {
     console.log('Error fetching data:', error);
     
     }
   };


   handleCurrencyChange = (selectedOption) => {
    const cur_id = selectedOption.value;
    const cur_name = selectedOption.label;
    const selectedCur = {label:cur_name,value:cur_id} 
    this.setState({ selectedCurrency: selectedCur }, () => {
      console.log(this.state.selectedCurrency,selectedOption.label,selectedOption.value);
    });
    // Uncheck the radio button
    this.setState({ amount: null, plan_id: null });
    this.fetchPlans(cur_id);
    const sgcur = this.state.cOptions.find(currency => currency.id === cur_id);
    this.setState({ curDesc: sgcur.currency_description });

    
  // Uncheck all radio buttons
  const radioButtons = document.getElementsByName('radioGroup');
  radioButtons.forEach(button => {
    button.checked = false;
  });
  };
  

  //function for open modal
  handleOnclick(){
    this.setState({show:true});
    
  }


  //function for logout
  handleModLogout(){
const logout= localStorage.removeItem('userId');
    const place = localStorage.getItem('userId');
    console.log("id",place)
    if(place==null){
      this.props.history.push('/signin')
    }
    localStorage.removeItem('shopName');
  }


  handleCloseConfirmation(){
    this.setState({show: false})
  }

  handleButtonClickhome(){
    // Navigate to a specific route using history.push
    this.props.history.push('/home');
  };

  handleButtonClickMySubscription(){
    // Navigate to a specific route using history.push
    this.props.history.push('/Mysubscriptionhistory');
  };
 
 
 
  render(){
    const { plans } = this.state;
    const { currencyOptions } = this.state;
    const { selectedCurrency } = this.state;
    const { loading } = this.state;
    const { shopName } = this.state;
    return(
      <>
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop:"20px"}}>
      <h4 style={{ fontFamily: "cursive", fontWeight: "bolder",textAlign:"center", color: "black" }}>{ shopName }</h4>
      <button className="btn btn-success" style={{position:"fixed",marginLeft:"1150px"}} onClick={this.handleButtonClickMySubscription}>Go Back</button>
      <button className="btn btn-success" style={{position:"fixed",marginLeft:"1400px"}} onClick={this.handleButtonClickhome}>Go To Home</button>
      </div>
      
      <br/>
      <Card style={{width:"27rem",height:"35rem",padding:"10px",borderRadius:"25px",border:"none"}}>
        <CardBody>
          <CardTitle>
          <h4 className="get"><span className="sub" ><b>RENEW SUBSCRIPTION</b></span> </h4> 
          </CardTitle>
          {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (<CardSubtitle>
          <div className="fris">

      


</div>
<br/>
<br/>
<form onSubmit={this.handleSubmit}>

<div style={{width:"300px",margin:"auto"}}>
<label style={{color:"green",fontWeight:"bold"}}>Select Currency: <span style={{color:"black",fontSize:"13px"}}>{this.state.curDesc}</span></label>
<Select
options={currencyOptions}
placeholder="Choose Currency"
isSearchable={true}
value={this.state.selectedCurrency} // Use this.state.selectedCurrency
onChange={this.handleCurrencyChange}
styles={{width:"200px"}}
/>
</div>
<br/>
{plans && plans.length > 0 && (
plans.map((plan,index)=>(
<div key={index}>
<div style={{ margin:"0px auto", width:"300px", height:"90px", border:"none", fontSize:"20px",padding:"20px",boxShadow:" 5px 10px 18px #888888"}} className="box">
<label style={{ display: 'inline', marginRight: '150px',fontSize:"16px",fontWesight:"bold",fontFamily:"serif",textTransform:"uppercase" }}>
<input type="radio" style={{width:"15px", height:"15px"}}  name="radioGroup"
value={plan.total_price}
required
onChange={(radio)=>{this.setState({amount:radio.target.value,plan_id:plan.sub_id})}}

/> {" "}
{plan.subscription_plan_name} </label>
<pre style={{position:"absolute",top:"23px",right:"40px",color:"green",fontSize:"22px",fontWeight:"bolder"}}> 
<span class="badge rounded-pill p-3" style={{color:"white",backgroundColor:"green",fontFamily:"monospace"}}>{plan.total_price} {plan.currency_name}</span> 

 {/* {
   plan.discount > 0 ? (<div style={{textDecorationLine:"line-through",marginRight: '50px',color:"black",fontSize:"13px"}}>Rs {plan.total_price}</div>) : " "
 } */}
</pre>
{/* <div className="arrow-ribbon">save {plan.discount}%</div> */}

</div>

<br/>


</div>
)))}

<br/>
<button style={{marginLeft:"50px",width:"280px",height:"45px",backgroundColor:"green",color:"white",border:"none",borderRadius:"9px",fontSize:"17px"}}>
Buy Now</button>
<br/>
{/* <button style={styles.button} onClick={this.handleCoupon}>
Use Coupon
<img src={coupon} alt="img" style={{width:"30px",height:"30px"}}/> */}
{/* </button>  */}
{/* <div className="new">
<h6 className="mt-0">Free</h6>
</div> */}
{/* <div style={{ margin:"0px auto",marginTop:"-25px", width:"300px", height:"90px", border:"none", fontSize:"20px",padding:"20px",boxShadow:" 5px 10px 18px #888888"}} className="box">
<label style={{ display: 'inline', marginRight: '150px',fontSize:"20px",fontWeight:"bold",fontFamily:"serif" }}>
<input type="radio" style={{width:"15px", height:"15px"}}  name="radioGroup"
value="3000"
required
onChange={(radio)=>{this.setState({amount:radio.target.value})}}

/> {" "}
Yearly </label>
<pre style={{position:"absolute",top:"23px",right:"80px",color:"green"}}> 
 Rs 3000 
 <div style={{textDecorationLine:"line-through",marginRight: '50px',color:"black",fontSize:"13px"}}>Rs 5000</div>

</pre>
<div className="arrow-ribbon">save 40%</div>
</div> */}

</form>

          </CardSubtitle>)}
         
        </CardBody>
      </Card>

      <Modal
        show={this.state.show}
        onHide={this.handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px",backgroundColor:"white"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM!</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure You want to Logout ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={this.handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={this.handleModLogout}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>
      
     </>

    )
  }
}

const styles={
  button: {
    border:"1px solid black",
    backgroundColor:"rgb(53, 55, 53)",
    color:"white",
    marginLeft:"630px",
    width:"280px",
    height:"45px",
    border:"none",
    borderRadius:"9px",
    fontSize:"17px",
    marginTop:"10px"
  },
}
     


export default withRouter(Renewsubscription);


// 8111053647@ybl

// addSub(){
//   const { history } = this.props; 
//   const userID = localStorage.getItem("userId");
//  console.log(this.state.person_country,this.state.person_email,this.state.person_mobile,this.state.person_name,this.state.selectedCurrency,this.state.plan_id,this.state.amount)

//   var options = {
//     key: "rzp_live_pjBqmpZwxNqOn2",
//     key_secret:"FdhDNbFA0AqCFvPVNNIpQfQ7",
//     amount: this.state.amount *100,
//     currency:this.state.selectedCurrency,
//     name:"SG BILLER",
//     description:"for testing purpose",
//     handler: (response) => {
//       alert(response.razorpay_payment_id,response);
//       const detail = {
//         "transaction_id":response.razorpay_payment_id, 
//         "transaction_person_name":this.state.person_name, 
//         "user_id":parseInt(userID), 
//         "plan_id":this.state.plan_id
//     }
  
//        fetch(`${var_api}/api/cash-subscription-details/post`, {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json, text/plain, /',
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(detail)
//       })
//       .then(response => {
//         console.log(response);
//         if (response.status === 200) {
//           console.log("yeah its came")
//           alert(response.razorpay_payment_id,response);
//           history.push('/');
//         }
//       })
//       .catch(error => {
//         console.error('Error:', error);
//       })
//     },
//     prefill: {
//       name:this.state.person_name,
//       email:this.state.person_email,
//       contact:this.state.person_mobile
//     },
//     notes:{
//       address:"Razorpay Corporate office"
//     },
//     theme: {
//       color:"#3399cc"
//     }
//   };
//   var pay = new window.Razorpay(options);
//   pay.open();

  

// }