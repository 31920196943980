import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./myprofile.css";
import shop_logo from "../../images/splash/shop_logo.jpeg";
import axios from "axios";
import Swal from 'sweetalert2';
import { var_api } from "../constant";
import { Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';

const MyProfile = () => {
  const history = useHistory();
  const shopName = localStorage.getItem("shopName");
  const [Profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [updateformData, setUpdateFormData] = useState({
    e_name: "", 
    e_email: null, 
    e_password: null,
    e_phone: null,
    profile: null
  });
  const [putLoader, setPutLoader] = useState(false);

    // Check if userId is available in localStorage when the component mounts
  useEffect(() => {
   fetchProfile();
  }, []);
  

  const handleToRedirectTableOrder = () => {
    // Navigate to a specific route using history.push
    history.push('/tableOrders');
  };

  const fetchProfile = async () => {
    setIsLoading(true);
    const shop_id = localStorage.getItem('shopId');
    const empId = localStorage.getItem('empId');
     try {
       const response = await axios.get(`${var_api}/api/employees/get-employee-full-details/${shop_id}/${empId}`); // Replace with your API endpoint
       console.log("data",response.data)
       setIsLoading(false);
       setProfile(response.data.results[0]);
     } catch (error) {
       console.error('Error fetching data:', error);
       setIsLoading(false);
     }
   };


   const handleOpenEditModal = () => {
    setIsEdit(true);
    console.log("data",Profile)
    setUpdateFormData({
        e_name: Profile.e_name, 
        e_email: Profile.e_email, 
        e_password: Profile.e_password,
        e_phone: Profile.e_phone,
        profile: Profile.e_profile,
        e_role: Profile.e_role,
        shops: Profile.shops
    })
   }

     //Function for update text field onchange
const handleInputUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({ ...updateformData, [name]: value });
  };

  //onchange function for file 
const handleImageUpdateChange = (e) => {
    const file = e.target.files[0];
    console.log("file",file)
    setUpdateFormData({ ...updateformData, profile: file });
  };

  // Function to capitalize the first letter of a string
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
   };

  //update method for employee
const handleUpdateSubmit = async (e) => {
    e.preventDefault();  
    console.log("updateformData",updateformData);
   setPutLoader(true);
    
    const camelCaseEName = capitalize(updateformData.e_name);
  
    const formDataToSend = new FormData();
    formDataToSend.append('e_name', camelCaseEName);
    formDataToSend.append('e_email', updateformData.e_email);
    formDataToSend.append('e_password', updateformData.e_password);
    formDataToSend.append('e_phone', updateformData.e_phone);
    formDataToSend.append('profile', updateformData.profile); 
    formDataToSend.append('e_role', updateformData.e_role);
  
    // Append shop data (if any)
   updateformData.shops.forEach((shop, index) => {
    formDataToSend.append(`shops[${index}][shop_name]`, shop.shop_name);
    formDataToSend.append(`shops[${index}][shop_id]`, shop.shop_id);
    formDataToSend.append(`shops[${index}][is_active]`, shop.is_active);
    formDataToSend.append(`shops[${index}][id]`, shop.id);
  });
    
  
     try {
      const response = await fetch(`${var_api}/api/employees/emp-put/${Profile.id}`, {
        method: 'PUT',
      body: formDataToSend,
    });
      if(response.status == 200){
        fetchProfile();
        setIsEdit(false);
        setPutLoader(false);
        Swal.fire({
          title: 'Success',
          text: 'Employee Updated Successfully.',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
        
        const data = await response.json();
        console.log('Registration successful!',data);
     
      }else {
        console.error('Failed to Update Employee:', response.statusText);
        // Handle error, e.g., show an error message to the user
        Swal.fire({
          title: 'error',
          text: 'Failed to Update Employee...Please Try Again!',
          icon: 'error',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
        setPutLoader(false);
      }
      
      // Handle success scenario as needed
    } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
        setPutLoader(false);
      }
    };
  


  return (
    
    <div className="psign-up">
        <nav className="navbar" style={{ boxShadow: "0px 0px 1px 0px black", backgroundColor: "green", border: "none", padding: "5px", height: "50px", width: "100%", position: "relative"}}>
                <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "10px", color: "white" }}><i class="bi bi-person-add"></i> My Profile</h3>
                <button className="btn" onClick={handleToRedirectTableOrder} style={{  backgroundColor: "white", color: "green",float:"left" }}>
                <i class="bi bi-easel"></i> Go to Table Order
                </button>
                </nav>
                {isLoading ? (
                    <div class="text-center mt-4">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                    <Card id="psignupcard">
                    <CardBody>
                      <CardTitle style={{textAlign:"center"}}> 
                      <Link to="/" className="navbar-brand">
                      <img src={`${var_api}/private/${Profile.e_profile}`} alt="logo" id="psigninLogo"/>
                    </Link>
                      </CardTitle>
                      <br/>
                     <div style={{padding:"0px 40px"}}>
                     <h4 style={{fontFamily:"serif",fontWeight:"bold"}}><i class="bi bi-person-fill-check text-success" style={{fontSize:"20px"}}></i> {Profile.e_name}</h4>
                     <h4 style={{fontFamily:"serif",fontWeight:"bold"}}><i class="bi bi-briefcase-fill text-success" style={{fontSize:"20px"}}></i> {Profile.e_role}</h4>
                     <h4 style={{fontFamily:"serif"}}><i class="bi bi-telephone-fill text-success" style={{fontSize:"20px"}}></i> {Profile.e_phone}</h4>
                     <h4 style={{fontFamily:"serif"}}><i class="bi bi-envelope-fill text-success" style={{fontSize:"20px"}}></i> {Profile.e_email}</h4>
                     </div>
                     <br/>
                     <div className="text-center">
                     <button className="btn btn-success" onClick={handleOpenEditModal}><i class="bi bi-pencil-square" style={{fontSize:"14px"}}></i> Edit Profile</button>
                     </div>
                     
                     </CardBody>
                   </Card> 
                )}
     <Modal
        show={isEdit}
        onHide={()=>setIsEdit(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Employee </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleUpdateSubmit}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Employee Name</label>
              <input type="text" class="form-control" placeholder="enter employee name" id="input1" value={updateformData.e_name}  name="e_name"  onChange={handleInputUpdateChange}  style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Employee Email</label>
              <input type="email" class="form-control" placeholder="enter employee email" id="input2" name="e_email" value={updateformData.e_email} onChange={handleInputUpdateChange}  style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Password</label>
              <input type="text" class="form-control" placeholder="enter password" id="input3"  name="e_password" value={updateformData.e_password} onChange={handleInputUpdateChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Phone No</label>
              <input type="number" class="form-control" placeholder="enter phone no" id="input4" name="e_phone" value={updateformData.e_phone} onChange={handleInputUpdateChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
          <div class="col">
            <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Employee Image{" "}</label>
              <input type="file" class="form-control" id="formFile"  name='profile'  style={{borderRadius:"12px"}} onChange={handleImageUpdateChange} />
            </div>
            <div class="col" >
              
            </div>
           
          </div>
         
         <br/>
         {
              putLoader ? (
               
                    <div style={{float:"right",margin:"5px"}}>
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>)
                : 
          <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>
            Update
            </button>
}
          </form>
          <button className="btn btn-danger" onClick={()=>setIsEdit(false)} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
      </Modal> 
    </div>
  );
};

export default MyProfile;
