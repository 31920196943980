import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./contract.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from "axios";
import { var_api } from "../constant";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from 'react-select';
import SearchableDropdown from "../creditCustomers/search";


const ContractFood = () =>{
    const history = useHistory();
    const [addForm,setAddForm] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [ creditUsers,setCreditUsers] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [selectedUser,setselectedUser] = useState({});
    const [default_item_1,setdefault_item_1] = useState("");
    const [default_item_2,setdefault_item_2] = useState("");
    const [default_spl_item_1,setdefault_spl_item_1] = useState("");
    const [default_spl_item_2,setdefault_spl_item_2] = useState("");
    const [spl_item_3,setspl_item_3] = useState("");
    const [spl_item_4,setspl_item_4] = useState("");
    const [spl_item_5,setspl_item_5] = useState("");
    const [spl_item_6,setspl_item_6] = useState("");
    
    //handle to add item for open the modal
    const handleAddMenu=()=>{
      setAddForm(true);
    }

    //to close the modal
    const closeModal = () => {
      setAddForm(false);
    }


    //get credit user data
    const fetchData = async () => {
      const shopid = localStorage.getItem('shopId');
      try {
        const response = await axios.get(`${var_api}/api/contract-users/getall/${shopid}` );
        const use = response.data.results;
        console.log("credit",use)
        setCreditUsers(use);
      }catch (error) {
        console.log('Error fetching data:', error);
        setCreditUsers([]);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);


    //navigate to another page
    const handleButtonClick = () => {
        // Navigate to a specific route using history.push
        history.push('/home');
      };

      //handle select date data
      const handleDateChange = (e) => {
        setSelectedDate(new Date(e.target.value));
      };

      //get the dates in month
      const getDaysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Month is zero-based
        const lastDay = new Date(year, month, 0).getDate();
        const daysArray = Array.from({ length: lastDay }, (_, i) => i + 1);
        return daysArray;
      };


      useEffect(() => {
        setDaysInMonth(getDaysInMonth(selectedDate));
      }, [selectedDate]);
      
  
      const today = new Date().toISOString().slice(0, 10);
      const currentDate = new Date().getDate(); // Get the current day of the month

      //handle to select the credit user
      const handleDropdownChange = (selectedOption) => {
        setselectedUser(selectedOption);
        console.log("selected user:",selectedUser)
      };

      //Post method for add credit user
      const handleCreateConUser= async ()=>{
        console.log("selected user add:",selectedUser)
        const shopid = localStorage.getItem('shopId');
        try {
          const response = await axios.post(`${var_api}/api/contract-users/post`, {
            credit_user_id: selectedUser.iden,
            credit_user_name: selectedUser.label,
            email: selectedUser.mail,
            phone_no:selectedUser.value,
            shop_id:shopid
          });
          if(response.status===200){
          console.log('API Response:', response.data);
          Swal.fire("success"," User Added Successfully!","success")
          // You can handle the response as needed, such as displaying a success message to the user.
          fetchData();
          }
        } catch (error) {
          console.error('API Error:', error);
          Swal.fire("error","Failed to Add User!","error")
          // Handle the error, e.g., show an error message to the user.
        }
      }

       //delete method for remove credit user
      const handleDeleteuser = (id) => {
        axios
        .delete(`${var_api}/api/contract-users/delete/${id}`)
        .then((response) => {
          if (response.status === 200) {
            console.log('Credit user deleted successfully');
            fetchData();
            Swal.fire("success"," User Removed Successfully!","success")
            // Perform any other actions you need on success
          } else if (response.status === 404) {
            console.log('Credit user not found');
            Swal.fire("error","Failed to Remove User!","error")
          }
        })
        .catch((error) => {
          console.error('Error executing the request:', error);
          // Handle the error as needed
        });
      }

    //handle to create contract food
    const handleAddContractItem = async (e) => {
      e.preventDefault();
      const shopid = localStorage.getItem('shopId');
        try {
          const response = await axios.post(`${var_api}/api/food-contract-content/post`, {
            default_item_1:default_item_1,
            default_item_2:default_item_2,
            default_spl_item_1:default_spl_item_1,
            default_spl_item_2:default_spl_item_2,
            spl_item_3:spl_item_3,
            spl_item_4:spl_item_4,
            spl_item_5:spl_item_5,
            spl_item_6:spl_item_6,
            shop_id:shopid
          });
          if(response.status===200){
          console.log('API Response:', response.data);
          Swal.fire("success"," contract Item Created Successfully!","success")
          closeModal();
          // You can handle the response as needed, such as displaying a success message to the user.
          }
        } catch (error) {
          console.error('API Error:', error);
          Swal.fire("error","Failed to Create Contract Item!","error")
          // Handle the error, e.g., show an error message to the user.
        }
      }

    
      
    return(
    <div className='conwhole' style={{backgroundColor:"white"}}>
      <div class="consplit conleft">
        <div class="centered">
          <h3 className='conushe'>CONTRACT FOODS</h3>
          <div style={{marginLeft:"12px",padding:"7px"}}>
            <button id='concusadd' onClick={handleAddMenu} ><i class="bi bi-plus-circle" style={{border:"none"}}></i> {' '}
               Add Contract Foods
            </button>
          </div>
                   
        </div>
      </div>
      <div class="consplit conright">
        <div class="centered"style={{paddingLeft:"8px"}}>
          <Tabs
            defaultActiveKey="profile"
            id="fill-tab-example"
            className="mb-1 ml-1 tabclr"
            fill
          >
            <Tab eventKey="schedule" title={<span className="conushe">schedule</span>}>
              <div style={{margin:"60px 10px 0px 10px ",padding:"15px",textAlign:"center"}}>
                <input type='date' name='date' value={selectedDate.toISOString().slice(0, 10)}  onChange={handleDateChange} style={{borderTop:"8px solid green",borderRadius:"15px",padding:"10px",fontSize:'25px'}} placeholder='choose date' />
                <br/>
                <br/>
                <div className="horizontal-days">
                  {daysInMonth.map((day) => (
                  <div key={day} className={day === currentDate ? "current-date" : ""}>{day}</div>
                  ))}
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div class="card-deck d-flex justify-content-center">
                <div class="card text-white  mb-3" style={{maxWidth: "17rem",maxHeight:"15rem",border:"none",borderRadius:"50px"}}>
                  <div class="card-header bg-success" style={{borderRadius:"20px 20px 0px 0px"}}>Selected Month contract Food</div>
                  <div class="card-body itembo">
                    <h5 class="card-title">Default Items:</h5>
                    <ul style={{listStyleType: "circle"}}>
                      <li>Rice</li>
                      <li>Sambaar</li>
                      <li>Rasam</li>
                      <li>Potato Fry</li>
                      <li>Apalam</li>
                    </ul>
                  </div>
                </div>
                <div class="card text-white" style={{maxWidth: "17rem",maxHeight:"12rem",border:"none",borderRadius:"50px"}}>
                  <div class="card-header bg-success" style={{borderRadius:"20px 20px 0px 0px"}}></div>
                  <div class="card-body itembo">
                    <h5 class="card-title">Other items:</h5>
                    <ul style={{listStyleType: "disc"}}>
                      <li>Egg Fry</li>
                      <li>Carrot Masala</li>
                      <li>Raw Banana Fry</li>
                      <li>Beetroot Fries</li>
                    </ul>
                  </div>
                </div>
                </div>
              </div> 

            </Tab>
            <Tab eventKey="Credit Users" title={<span className="conushe">Credit Users</span>}>
              <div style={{margin:"30px auto 10px",padding:"15px",width:"420px"}}>
                <h4 className="conushe" style={{color:"black",textAlign:"center"}}>Add Credit User</h4>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="custom-searchable-dropdown">
                    <SearchableDropdown
                       onSelect={handleDropdownChange}  
                    />
                  </div>
                  <button className="btn btn-success" style={{ marginLeft: "10px" }} onClick={handleCreateConUser}>ADD</button>
                </div>
              </div>
             {creditUsers.map((user,index) => (
              <div id='crediv' key={index}>
                <div style={{border:"none"}}>
                  <div style={{border:"none",margin:"0px 0px 0px 0px"}}>
                    <h6 style={{textTransform:"capitalize",fontWeight:"bolder",color:"green"}}>{user.credit_user_name} {' '}
                      <span><i class="bi bi-trash" onClick={()=>handleDeleteuser(user.id)} style={{float:"right",fontSize:"20px",color:"black"}}></i></span> 
                    </h6>
                    <h6 style={{fontSize:"12px"}}> <i class="bi bi-telephone"></i> {'  '} {user.phone_no}</h6>
                    <h6 style={{fontSize:"12px"}}><i class="bi bi-envelope"></i> {'  '} {user.email}</h6>
                  </div>
                </div>
              </div>
             ))}
           </Tab>
         </Tabs>
        </div>
      </div>
      <Modal isOpen={addForm} toggle={closeModal}>
        <ModalHeader>ADD MENU FORM</ModalHeader>
        <ModalBody>
          <div className="form-floating mb-3">
            <label htmlFor="floatingInput">Default Item 1</label>
            <input
             type="text"
             className="form-control"
             id="floatingInput"
             onChange={(e) => setdefault_item_1(e.target.value)}
            />
          </div>
          <div className="form-floating mb-3">
            <label htmlFor="floatingPassword">Default Item 2</label>
            <input
             type="text"
             className="form-control"
             id="floatingPassword"
             onChange={(e) => setdefault_item_2(e.target.value)}
            />
          </div>
          <div className="form-floating mb-3">
            <label htmlFor="floatingPassword">Default Special Item 1</label>
            <input
             type="text"
             className="form-control "
             id="floatingPassword"
             onChange={(e) => setdefault_spl_item_1(e.target.value)}
            />
          </div>
          <div className="form-floating mb-3">
            <label htmlFor="floatingPassword">Default Special Item 2</label>
            <input
              type="text"
              className="form-control"
              id="floatingPassword"
             onChange={(e) => setdefault_spl_item_2(e.target.value)}
            />
          </div>
          <div className="form-floating mb-3">
            <label htmlFor="floatingPassword">Special Item 3</label>
            <input
             type="text"
             className="form-control"
             id="floatingPassword"
             onChange={(e) => setspl_item_3(e.target.value)}
            />
          </div>
          <div className="form-floating mb-3">
            <label htmlFor="floatingPassword">Special Item 4</label>
            <input
             type="text"
             className="form-control"
             id="floatingPassword"
             onChange={(e) => setspl_item_4(e.target.value)}
            />
          </div>
          <div className="form-floating mb-3">
            <label htmlFor="floatingPassword">Special Item 5</label>
            <input
             type="text"
             className="form-control"
             id="floatingPassword"
             onChange={(e) => setspl_item_5(e.target.value)}
            />
          </div>
          <div className="form-floating mb-3">
            <label htmlFor="floatingPassword">Special Item 6</label>
            <input
             type="text"
             className="form-control"
             id="floatingPassword"
             onChange={(e) => setspl_item_6(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleAddContractItem}>Submit</Button> 
          <Button color="secondary" onClick={closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal>  
    </div>
  )
}

export default ContractFood;