import React, {useState, useEffect} from "react";
import { Card, Button, Form, Row, Col, Modal  } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { var_api } from "../constant";
import { useHistory,useLocation } from 'react-router-dom';
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import Pagination from 'react-js-pagination';

const StockTakeDetails = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const shopId = localStorage.getItem('shopId');
    const [stock, setStock] = useState([]);
    const [qty, setQty] = useState([]);
    const [allstock, setAllStock] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15; // Number of items per page
    const [totalItems, setTotalItems] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [selectedStockCode, setSelectedStockCode] = useState('');
    const [qtyonhand, setQtyonHand] = useState('');
    const [totalcredit, settotalcredit] = useState('');
    const [totaldebit, settotaldebit] = useState('');
    const [qtycounted, setQtyCounted] = useState('');
    const [stockunit, setStockUnit] = useState('');
    const [searchTermAllstock, setSearchTermAllstock] = useState('')
    const location = useLocation();
//    const data = location.state?.data;
   const searchparams = new URLSearchParams(location.search);
   const takeDate = searchparams.get('takedate');
   const endDate = searchparams.get('enddate');
   const stocktakeID = searchparams.get('id');
   const referenceNumber = searchparams.get('refno');
   const [isStockExisting, setIsStockExisting] = useState(false);
   const [putstockid, setPutStockId] = useState(null);
   const empID = localStorage.getItem("admin_employee");
   const [currentPageall, setCurrentPageall] = useState(1);
    const itemsPerPageall = 15; // Number of items per page
    const [totalItemsall, setTotalItemsall] = useState(0);
    const [startIndexall, setStartIndexall] = useState(0);


//Go to home page
const handleButtonClick = () => {
    history.push('/home')
  }

  const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchChangeAllstock = (e) => {
        setSearchTermAllstock(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handlePageChangeall = (pageNumber) => {
        setCurrentPageall(pageNumber);
    };

  useEffect(() => {
    fetchStockcode();
    fetchallstocklist();
}, []);

useEffect(() => {
    if (selectedStockCode) {
        checkStockExists();
    }
}, [selectedStockCode, allstock]);

const checkStockExists = () => {
    const existingStock = allstock.find(stock => stock.stock_code === selectedStockCode);

    if (existingStock) {
        setIsStockExisting(true);
        setQtyonHand(existingStock.qty_in_hand);
        setQtyCounted(existingStock.qty_counted);
        setStockUnit(existingStock.stock_in_unit_value);
        setPutStockId(existingStock.id);
    } else {
        setIsStockExisting(false);
        setQtyonHand('');
        setQtyCounted('');
        setStockUnit('');
        setPutStockId(null);
        fetchqtyonhand(existingStock);
    }
};

  const fetchStockcode = async () => {
    setIsLoading(true);
    const startIndexCalc = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndexCalc + itemsPerPage;

    try {
        const response = await axios.get(`${var_api}/api/raw-items/get-items/${shopId}`);
        console.log("stock Items", response.data)
        setStock(response.data.slice(startIndexCalc, endIndex));
        const total = response.data.length;
        setIsLoading(false);
        setTotalItems(total); // Update total items for pagination
        setStartIndex(startIndexCalc);
    } catch (error) {
        console.error('Error fetching items:', error);
        setIsLoading(false);
        toast('Error Fetching items');
    }
};

//http://localhost:4500/api/stock-transaction/get-qty-in-hand/92/wh1/10-07-2024/15-07-2024

const fetchqtyonhand = async (stockCode) => {
    setIsLoading(true);

    try {
        const response = await axios.get(`${var_api}/api/stock-transaction/get-qty-in-hand/${shopId}/${stockCode}/${takeDate}/${endDate}`);
        if (response.data && response.data.length > 0 && response.data[0].hasOwnProperty('quantity_in_hand')) {
            setQty(response.data);
            setQtyonHand(response.data[0].quantity_in_hand);
            settotalcredit(response.data[0].total_credit);
            settotaldebit(response.data[0].total_debit);
        } else {
            setQty([]);
            setQtyonHand("");
            settotalcredit("");
            settotaldebit("");
        }

        setIsLoading(false);
    } catch (error) {
        console.error('Error fetching items:', error);
        setIsLoading(false);
        toast('Error Fetching items');
    }
};

const fetchallstocklist = async () => {
    setIsLoading(true);
    const startIndexCalcall = (currentPageall - 1) * itemsPerPageall;
        const endIndexall = startIndexCalcall + itemsPerPageall;

    try {
        const response = await axios.get(`${var_api}/api/stock-take-details/get-all/${shopId}/${stocktakeID}`);
        console.log("stock Items", response.data)
        setAllStock(response.data.slice(startIndexCalcall, endIndexall));
        const totalall = response.data.length;
        setIsLoading(false);
        setTotalItemsall(totalall); // Update total items for pagination
        setStartIndexall(startIndexCalcall);
    } catch (error) {
        console.error('Error fetching items:', error);
        setIsLoading(false);
        toast('Error Fetching items');
    }
};

// Format the date to dd-mm-yyyy
const formatDate = (date) => {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1; // Months are zero indexed
    const year = d.getFullYear();

    // Add leading zero if day or month is less than 10
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return `${day}-${month}-${year}`;
  };

//create button post 
const currentDate= formatDate(new Date(), 'dd-MM-yyyy');
const currentDated = new Date();
const currentTime = currentDated.toLocaleTimeString();
const handlePayload = () => {
    
    const difference = qtycounted - qtyonhand;
    let stockStatus = 0;
    let isPass = 0;
    let isExtra = 0;
    let isMissing = 0;
    
    if (qtycounted === 0 && qtyonhand === 0) {
        stockStatus = 0;
        isPass = 0
        isExtra = 0
        isMissing = 0
    } else if (qtycounted > qtyonhand) {
        stockStatus = 1;
        isExtra = difference; // Difference when totalCredit > qtyInHand
        isPass = 0
        isMissing = 0
    } else if (qtycounted < qtyonhand) {
        stockStatus = 2;
        isMissing = Math.abs(difference); // Difference when totalCredit < qtyInHand
        isExtra = 0; // Assuming is_extra is the same as is_missing for this case
        isPass = 0
    }

    return {
        stock_status: stockStatus,
        is_pass: isPass,
        is_extra: isExtra,
        is_missing: isMissing
    };
};

// Example usage
const stockData = handlePayload();

const Submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
        const response = await axios.post(`${var_api}/api/stock-take-details/post`, {
                stock_take_id: parseInt(stocktakeID),
                shop_id: parseInt(shopId),
                stock_code: selectedStockCode,
                qty_in_hand: qtyonhand ? parseFloat(qtyonhand):0,
                qty_counted: parseFloat(qtycounted),
                stock_in_unit_value: parseInt(stockunit),
                post_date: currentDate,
                post_time: currentTime,
                ...stockData
        });

        if (response.status === 200) {
            toast("Stock take created successfully");
            setIsLoading(false);
            fetchallstocklist();
            setQtyonHand("");
            setQtyCounted('');
            setStockUnit('');
        }
    } catch (error) {
        toast("Stock code already exists for the given ST No");
        console.error('Error:', error);
        setIsLoading(false);
    } finally {
        setIsLoading(false);
    }
};

const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        const response = await axios.put(`${var_api}/api/stock-take-details/put/${putstockid}`, {
            stock_take_id: parseInt(stocktakeID),
            shop_id: parseInt(shopId),
            stock_code: selectedStockCode,
            qty_in_hand: parseFloat(qtyonhand),
            qty_counted: parseFloat(qtycounted),
            stock_in_unit_value: parseInt(stockunit),
            post_date: currentDate,
            post_time: currentTime,
            ...stockData
        });

        if (response.status === 200) {
            toast("Stock take updated successfully");
            setIsLoading(false);
            setQtyonHand("");
            setQtyCounted('');
            setStockUnit('');
            fetchallstocklist(); // Refresh the stock list
        }
    } catch (error) {
        toast("Error updating stock take");
        console.error('Error:', error);
        setIsLoading(false);
    }
};

//post button put method
const stocktakeput = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
        const response = await axios.put(`${var_api}/api/stock-take/put/${stocktakeID}`, {
            status:1
        });

        if (response.status === 200) {
            stocktakepost();
            toast("Stock take updated successfully");
            setIsLoading(false); // Refresh the stock list
        }
    } catch (error) {
        toast("Error updating stock take");
        console.error('Error:', error);
        setIsLoading(false);
    }
};

const computeAdjustmentDetails = () => {
    return allstock.map(stock => {
        const { qty_in_hand, qty_counted, stock_code, stock_in_unit_value } = stock;

        const isExtra = qty_counted > qty_in_hand ? qty_counted - qty_in_hand : 0;
        const isMissing = qty_counted < qty_in_hand ? qty_in_hand - qty_counted : 0;
        const reason_code = stock.reason_code || '';
        if (isExtra > 0 || isMissing > 0) {
            return {
                stock_code,
                reason_code,
                stock_unit_in_value: stock_in_unit_value, // Example value
                stock_in_value: isExtra * stock_in_unit_value, // Example calculation
                stock_in_qty: isExtra,
                stock_out_value: isMissing * stock_in_unit_value, // Example calculation
                stock_out_qty: isMissing
            };
        }
        return null; // Filter out entries with no adjustments
    }).filter(detail => detail !== null); // Remove null entries
};

//post button post method
const stocktakepost = async () => {
    // e.preventDefault();
    setIsLoading(true);
    const adjustmentDetails = computeAdjustmentDetails();
    try {
        const response = await axios.post(`${var_api}/api/stock-adjustments/full-post`, {
            reference_no: referenceNumber,
            authorized_by: parseInt(empID),
            checked_by: parseInt(empID),
            adjustment_date: currentDate,
            description: "",
            shop_id: parseInt(shopId),
            status: 0,
            adjustment_details:adjustmentDetails,
            created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            updated_at: new Date().toISOString().slice(0, 19).replace('T', ' ')
        });

        if (response.status === 200) {
            toast("Stock Adjustment created successfully");
            setIsLoading(false); // Refresh the stock list
            history.push('/stock-take');
        }
    } catch (error) {
        toast("Error creating stock adjustment");
        console.error('Error:', error);
        setIsLoading(false);
    }
};


const resetbutton = () =>{
setQtyonHand("");
setQtyCounted('');
setStockUnit('');
}


const filteredData = stock.filter(item =>
    item.stock_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.description.toLowerCase().includes(searchTerm.toLowerCase()) 
);


const filteredDataall = allstock.filter(item =>
    item.stock_code.toLowerCase().includes(searchTermAllstock.toLowerCase()) ||
    item.qty_in_hand.toString().toLowerCase().includes(searchTermAllstock.toLowerCase()) || 
    item.qty_counted.toString().toLowerCase().includes(searchTermAllstock.toLowerCase()) || 
    item.stock_in_unit_value.toString().toLowerCase().includes(searchTermAllstock.toLowerCase()) || 
    item.post_time.toLowerCase().includes(searchTermAllstock.toLowerCase())
);

const handleRowClick = async (stockCode) => {
    setSelectedStockCode(stockCode);
};


    return(
        <>
        {isLoading && <Loader />}
        <Navbar expand="sm" className="bg-body-tertiary bg-success p-0" style={{padding:"0px"}}>
            <Container className="p-0">
                <Navbar.Brand className="p-0 text-light fw-bolder">STOCK TAKE DETAILS</Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                     <button className="btn btn-outline-success" onClick={handleButtonClick} style={{backgroundColor:"white",color:"green"}}>
                      <i class="bi bi-house"></i> Go to Home</button>
                </Navbar.Collapse>
           </Container>
        </Navbar>
        <br/>
        <div className="d-flex justify-content-center align-items-center" >
    <div className="card" style={{ width: '90%' }}>
        <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
                <button className="btn btn-primary" onClick={stocktakeput}>Post</button>
                <h6 className="mb-3">Reference Number: <span>{referenceNumber}</span></h6>
                <h6 className="mb-3">Stock Take Date: <span>{takeDate}</span></h6>
                <h6 className="mb-3">Stock End Date: <span>{endDate}</span></h6>
            </div>
        </div>
    </div>
</div>

        <br/>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{ flex: "1", margin: "0 10px", padding: "10px", border: "1px solid #ddd"}}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px" }}>
                    <h5 style={{ color: 'blue' }}>Select Stock Code</h5>
                    <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    id="example-search-input"
                    style={{ width: "230px", borderRadius: "20px", border: "1px solid black" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    />
                </div>
                <br/>
                <div>
                <Table responsive="sm" hover>
                    <thead>
                        <tr>
                            <th>Stock Code</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.length > 0 ? (
                            filteredData.map((data, index) => (
                                <tr key={index} onClick={() => handleRowClick(data.stock_code)} style={{ cursor: 'pointer' }}>
                                    <td style={{color:'blue'}}>{data.stock_code}</td>
                                    <td>{data.description}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-end"
                    activeClass="active-pagination"
                />
            </div>
            </div>
            <div style={{ flex: "1", margin: "0 10px", padding: "10px", border: "1px solid #ddd"}}>
            <h5 style={{ color: 'blue' }}>Stock Code: {selectedStockCode}</h5>
            <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Group controlId="remarks1" style={{ flex: 1 }}>
                <Form.Label>Quantity on Hand</Form.Label>
                <Form.Control
                    type="text"
                    placeholder=""
                    value={qtyonhand}
                    onChange={(e) => setQtyonHand(e.target.value)}
                    readOnly
                />
            </Form.Group>

            <Form.Group controlId="remarks2" style={{ flex: 1 }}>
                <Form.Label>Quantity Counted</Form.Label>
                <Form.Control
                    type="text"
                    placeholder=""
                    value={qtycounted}
                    onChange={(e) => setQtyCounted(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="remarks3" style={{ flex: 1 }}>
                <Form.Label>stock in unit value</Form.Label>
                <Form.Control
                    type="text"
                    placeholder=""
                    value={stockunit}
                    onChange={(e) => setStockUnit(e.target.value)}
                />
            </Form.Group>
        </div>
        <br/>
        <div style={{ display: 'flex', gap: '10px' }}>
        <button className={`btn ${isStockExisting ? 'btn-primary' : 'btn-success'}`} onClick={isStockExisting ? handleUpdate : Submit} disabled={isLoading}>
                    {isStockExisting ? 'Update' : 'Create'}
                </button>
            <button className="btn btn-danger" onClick={resetbutton}>Reset</button>
        </div>
            </div>
        </div>
        <br/>
        <div style={{ flex: "1", margin: "0 10px", padding: "10px", border: "1px solid #ddd"}}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px" }}>
                <h5 style={{ color: 'blue' }}>All Stock Code</h5>
                <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    id="example-search-input"
                    style={{ width: "230px", borderRadius: "20px", border: "1px solid black" }}
                    value={searchTermAllstock}
                    onChange={handleSearchChangeAllstock}
                />
            </div>
        <div>
        <Table responsive="sm" hover>
            <thead>
                <tr>
                    <th>Stock Code</th>
                    <th>Qty on Hand</th>
                    <th>Qty Counted</th>
                    <th>Stock in Unit Value</th>
                    <th>Discripancy</th>
                    <th>Time</th>
                </tr>
                    </thead>
                    <tbody>
                        {filteredDataall.length > 0 ? (
                            filteredDataall.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.stock_code}</td>
                                    <td>{data.qty_in_hand}</td>
                                    <td>{data.qty_counted}</td>
                                    <td>{data.stock_in_unit_value}</td>
                                    <td>{data.qty_counted - data.qty_in_hand}</td>
                                    <td>{data.post_time}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Pagination
                    activePage={currentPageall}
                    itemsCountPerPage={itemsPerPageall}
                    totalItemsCount={totalItemsall}
                    pageRangeDisplayed={5}
                    onChange={handlePageChangeall}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-end"
                    activeClass="active-pagination"
                />
                </div>
</div>
        </>);
}
export default StockTakeDetails
