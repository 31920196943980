import React,{ useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './report.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Dropdown } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { var_api } from "../constant";
import axios from "axios";
import jsPDF from "jspdf";


const Report = () => {
    const history = useHistory();
    const shopname = localStorage.getItem('shopName');
    const today = new Date();
    const [viewMod, setViewMod] = useState(false);
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // Months are zero-based, so add 1
    const currentDay = today.getDate();
    const [todayIncome, setTodayIncome] = useState(null);
    const [monthlyIncome, setMonthlyIncome] = useState(null);
    const [ orders, setOrders ] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(true);

    //Navigate to home page
    const handleGoTOHome = () => {
        history.push('/home');
    };


   //to handle view the report
   function handleViewReport(){
    setViewMod(true);
   }

    //close the modal
    const handleCloseMod = () => setViewMod(false);

    useEffect(() => {
      getIncome();
      getOrders();
     }, []);


      // fetch the today income and monthly income
      function getIncome(){
      const shop_id = localStorage.getItem('shopId');
      axios.get(`${var_api}/api/food/income/${shop_id}`)
        .then((response) => {
          const { TodayIncome, MonthlyIncome } = response.data;
          setTodayIncome(TodayIncome);
          setMonthlyIncome(MonthlyIncome);
         
        })
        .catch((error) => {
          console.log('Error fetching data:', error);
        });
      }


      // fetch the today income and monthly income
      function getOrders(){
        const shop_id = localStorage.getItem('shopId');
        axios.get(`${var_api}/api/food/orderData/${shop_id}`)
          .then((response) => {
            const order = response.data.groupedResults;
          
            setOrders(order);
            console.log("orderarray:",orders)
           
           
          })
          .catch((error) => {
            console.log('Error fetching data:', error);
          });
        }


        const downloadPdf = async () => {
          setButtonVisible(false);
          // Create a new instance of jsPDF
          const doc = new jsPDF('l', 'mm', [1500, 1400]);
        
          // Get the HTML element with the ID 'financi'
          const pdfjs = document.querySelector('#financi');
        
          if (pdfjs) {
            // Calculate the dimensions and position to center the content
            const contentWidth = pdfjs.offsetWidth;
            const contentHeight = pdfjs.offsetHeight;
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const x = (pageWidth - contentWidth) / 2;
            const y = (pageHeight - contentHeight) / 2 -320;
        
            // Generate the PDF document from the HTML content
            doc.html(pdfjs, {
              x: x,
              y: y,
              callback: (generatedPdf) => {
                // Save the generated PDF as a Blob
                const pdfBlob = generatedPdf.output('blob');
        
                // Save the generated PDF as a file
                generatedPdf.save('newpdf.pdf');
              },
            });
        
            setButtonVisible(true);
          } else {
            console.error("Element with ID 'financi' not found.");
          }
        };
        
        






    return (
    <div id="firdiv" style={{ backgroundColor: "rgba(10, 241, 33, 0.133)"}}>
        <nav className="navbar navbar-expand navbar-light py-2 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"10px"}} >
            <h3 style={{textAlign:"center",color:"white"}} id="kifo">Reports<i class="bi bi-clipboard-data-fill"></i></h3>
            <button className="btn" id="hombu" onClick={handleGoTOHome}><i class="bi bi-house"></i> Go to home</button>
        </nav>
        <br/>
        <div>
            <h1 id="shopis"> {shopname} </h1>
            <br/>
            <div id="reportBox">
                <div class='finare'>
                    <h3 style={{fontFamily: "cursive",fontWeight: "bolder",marginLeft:"50px"}}>
                    Financial Report
                    </h3>
                    <div class='bagr'>
                    {/* <Dropdown>
                      <Dropdown.Toggle variant="none" id="dropdown-basic" className="no-caret">
                        <i class="bi bi-three-dots-vertical" style={{fontSize:"20px"}}></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className="butdrop" onClick={handleViewReport}><i style={{color:"rgb(24, 131, 224)",fontSize:"20px",padding:"5px"}} class="bi bi-eye-fill"></i>  view</Dropdown.Item>
                        <Dropdown.Item className="butdrop" onClick={downloadPdf}><i style={{color:"red",fontSize:"20px",padding:"5px"}} class="bi bi-file-earmark-arrow-down-fill"></i> Download</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <i style={{color:"black",fontSize:"30px",padding:"5px"}} onClick={handleViewReport} class="bi bi-eye-fill"></i>
                    </div>
                </div>

            </div>
            
            <Modal className="temp"
            size="lg"
            show={viewMod}
            onHide={handleCloseMod}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title> FINANCIAL REPORT</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'scroll' }}>
              <div id="financi" style={{textAlign: "center",margin:"auto",border:"1px solid black",padding:"5px"}}>
                <h1 style={{textAlign:"center",textDecoration:"underline"}}>Financial Report</h1>
              <div class="flcan">
               <div>
                <h4 style={{fontSize:"13px",fontWeight:"bolder",color:"black"}}>Date: 
                <span style={{color:"black",fontSize:"20px",textTransform:"capitalize",fontWeight:"bolder"}}> {currentDay}/{currentMonth}/{currentYear}</span>
               </h4>
                </div>
                <div>
                <h4 style={{fontSize:"13px",fontWeight:"bolder",color:"black",float:"right"}}>Today Income: 
                <span style={{color:"black",fontSize:"20px",textTransform:"capitalize",fontWeight:"bolder"}}> {todayIncome}</span>
               </h4>
                </div>
                <div>
                <h4 style={{fontSize:"13px",fontWeight:"bolder",color:"black",float:"left"}}>Monthly Income: <span style={{color:"black",fontSize:"20px",fontWeight:"bolder",marginLeft:"2px"}}>{monthlyIncome}</span></h4>
                </div>
               </div>
               <br/>
               <h2 style={{textAlign:"center"}}>Today Orders</h2>
               <table className='order' style={{margin:"auto"}}>
                    <thead>
                        <th className='hesty'>Name</th>
                        <th className='hesty'>Phone</th>
                        <th className='hesty'>Order No</th>
                        <th className='hesty'>Items</th>
                        <th className='hesty'>Total</th>
                       
                    </thead>
                    {
                    orders.map((order,index)=>(
                    <tbody>
                        <tr key={order.id} >
                            <td className='dasty'>{order.cust_name ? order.cust_name : "-"}</td>
                            <td className='dasty'>{order.phone_no ? order.phone_no : '-'}</td>
                            <td className='dasty'>{order.order_no}</td>
                            <td className='dasty'>
                                <table style={{margin:"10px"}}>
                                    <thead>
                                        <th className='hesty'>item name</th>
                                        <th className='hesty'>quantity</th>
                                        <th className='hesty'>price</th>
                                    </thead>
                                    {Object.values(order).map((fieldValue) => {
              if (Array.isArray(fieldValue)) {
                return fieldValue.map((item, index) => (
                                        <tbody>
                                          <tr key={index}>
                                            <td className='dasty'>{item.food_name}</td>
                                            <td className='dasty'>{item.quantity}</td>
                                            <td className='dasty'>{item.unit_price}</td>
                                          </tr>
                                        </tbody>
                                     ));
                                    }
                                    return null;
                                  })}
                                </table>
                            </td>
                            <td className='dasty'>{order.total}</td>
                          
                        </tr>
                    </tbody>
                                        
                ))}
                                
                </table>
                <br/>
                <hr/>
                <h5 style={{textAlign:"right",textTransform:"capitalize",fontWeight:"bolder"}}>{shopname}</h5>
                <h6 style={{textAlign:"right",textTransform:"capitalize",fontSize:"15px"}}>(Shop Name)</h6>
              </div>
                {buttonVisible &&
              <button className="btn btn-success" onClick={downloadPdf} ><i  class="bi bi-file-earmark-arrow-down-fill"></i> download</button>
                }
            </Modal.Body>
           
          </Modal>

          
        
            
        </div>




    </div>



  )
}





export default Report;