import React from 'react';
import './SubKitchenMaster.css';
import profile from '../../images/splash/user.png';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useRef } from 'react';
import Select from 'react-select';
import {Card} from 'react-bootstrap';
import { Tooltip } from 'reactstrap';


const SubKitchenMaster = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    device_name: '',
    description: '',
    shop_id: parseInt(localStorage.getItem('shopId')),
    user_id: parseInt(localStorage.getItem('userId'))
  });
  const [ isLoading, setIsLoading] = useState(false);
  const [ isEditLoading, setIsEditLoading] = useState(false);
   const [tables, setTables] = useState([]);
   const [ searchQuery,setSearchQuery] = useState('');
   const [ searchFoodQuery, setSearchFoodQuery] = useState('');
   const [show, setShow] = useState(false);
   const [tableD, setTableD] = useState({});
   const [ isEdit,setIsEdit ] = useState(false);
   const [ loading, setLoading ] = useState(false);
   const [switches, setSwitches] = useState([]);
   const [isOpenDeviceModal, setIsOpenDeviceModal] = useState(false);
   const [deviceId, setDeviceId] = useState(null);
   const shopName = localStorage.getItem('shopName');
   const [selectedItem, setSelectedItem] = useState(null)
   const [updateFormData, setUpdateFormData] = useState({
    device_name: '',
    description: ''
  });
  const [updatedId, setUpdatedId] = useState(null);
  const [ foodOptions, setFoodOptions] = useState(null);
  const [ deFoodLoading, setDeFoodLoading ] = useState(false);
  const [ deviceFoods, setDeviceFoods ] = useState([]);
  const [puttooltipOpen, setPutToolTipOpen] = useState([]);
  const [actiontooltipOpen, setActionToolTipOpen] = useState([]);
  const fileInputRef = useRef(null);

    useEffect(() => {
        fetchDevices();
        fetchAllFoods();
      }, []);

      const togglePutTooltip = (index) => {
        const putTooltipOpen = [...puttooltipOpen];
        putTooltipOpen[index] = !putTooltipOpen[index];
        setPutToolTipOpen(putTooltipOpen);
      };

      const toggleActionTooltip = (index) => {
        const actionTooltipOpen = [...actiontooltipOpen];
        actionTooltipOpen[index] = !actionTooltipOpen[index];
        setActionToolTipOpen(actionTooltipOpen);
      };


      const fetchDevices = async () => {
        setLoading(true);
        try {
           const shop = localStorage.getItem('shopId')
           const response = await axios.get(`${var_api}/api/sub-kitchen-master/get/all-device/${shop}`);
           console.log("response",response)
          const user =response.data;
          setLoading(false);
          const initialSwitches = user.map((location) => location.is_active === 1);
    console.log("initialSwitches",initialSwitches)
    setSwitches(initialSwitches)
          setTables(user);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };


      const fetchAllFoods = async () => {
        try {
           const shop = localStorage.getItem('shopId')
           const response = await axios.get(`${var_api}/api/foodMaster/api/allFood/${shop}`);
           console.log("response",response)
          const user =response.data.result;
          const options = user.map(food => ({
            value: food.id,
            label: food.food_name,
            image: food.image
          }));
          setFoodOptions(options);
           
        } catch (error) {
          console.log(error);
        }
      };
      
      

     //search the transaction data
     const filteredSuppliers = tables.filter((order) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (order.device_name && order.device_name.toString().toLowerCase().includes(searchLower)) ||
        (order.description && order.description.toString().toLowerCase().includes(searchLower)) 
      );
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };


      const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
       };

      //post the form data
    const Submit = async (e) => {
        e.preventDefault();  
        setIsLoading(true);
       
         try {
          const capitalizedFormData = { ...formData, device_name: capitalize(formData.device_name) };
          const response = await axios.post(`${var_api}/api/sub-kitchen-master/post`, capitalizedFormData);
          if(response.status == 200){
           
            console.log('Registration successful!',response);
             // Clear the form fields
      setFormData({
        device_name: '',
        description: '',
        shop_id: '',
        user_id: ''
      });
            
              Swal.fire({
                icon:"success",
                text:'Device Created Successfully',
                confirmButtonColor:"#007500"
              })
              fetchDevices();
              setIsLoading(false); 
         
          }else {
            console.error('Failed to create Device:', response.status);
            // Handle error, e.g., show an error message to the user
           
              setIsLoading(false);
          }

          
          // Handle success scenario as needed
        } catch (error) {
            console.error('Error:', error.response);
            setIsLoading(false);
            
            if(error.response.status === 400){
                Swal.fire({
                  icon:"error",
                  text:'Device Already Exist',
                  confirmButtonColor:"#007500"
                })
              }else{
                Swal.fire({
                  icon:"error",
                  text:'Failed to Create Device',
                  confirmButtonColor:"#007500"
                })
              }
            // Handle error scenario as needed
          }
    };


    //reset the values
    const handleToReset = () => {
         // Reset the form fields individually
       // Reset the form fields by setting them to empty strings
       setFormData({
        ...formData,
        device_name: '',
        description: ''
    });
    }

    //Go to home page
    const handleButtonClick = () => {
      history.push('/home');
    }

    //go to table order page
    const handleGOtoTableOrder = () => {
      history.push('/tableOrders');
    }


    //go to sub kitchen
    const handleButtonSubKitchenClick = () => {
      // Navigate to a specific route using history.push
      history.push('/sub-kitchen');
    };


    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };


      //get the value in foods search and set the setstate
    const handleSearchFoodsInput = (e) => {
      setSearchFoodQuery(e.target.value)
      };


      //search the transaction data
     const filteredFoods = deviceFoods.filter((order) => {
      const searchLower = searchFoodQuery.toLowerCase();
      return (
        (order.food_name && order.food_name.toString().toLowerCase().includes(searchLower)) ||
        (order.category_name && order.category_name.toString().toLowerCase().includes(searchLower)) ||
        (order.item_code && order.item_code.toString().toLowerCase().includes(searchLower)) ||
        (order.text && order.text.toString().toLowerCase().includes(searchLower)) ||
        (order.uom && order.uom.toString().toLowerCase().includes(searchLower))
      );
    });


       //to open the form for slot updation 
    const handleEditTable = async (user) => {
      console.log(user);
      setIsEdit(true);
      setUpdateFormData({
        device_name: user.device_name,
        description: user.description
      })
      setUpdatedId(user.id)
      console.log("updateFormData",updateFormData);
          // const { value: formValues } = await Swal.fire({
          //   title: 'Update Table',
          //   html: `
          //    <label class="swalLa">Table No</label>
          //    <br/>
          //     <input id="swal-input1" class="swal2-input fin" name="table_no" placeholder="Table No" value="${data.table_no || ''}">
          //     <br/>
          //     <br/>
          //     <label class="swalLa">No.Of.Seats</label>
          //     <input id="swal-input2" class="swal2-input fin" name="no_of_seats" placeholder="No of Seats" value="${data.no_of_seats || ''}">
          //     <br/>
          //     <br/>
          //     <label class="swalLa">Table Image</label>
          //     <input id="swal-input3" class="swal2-input fin" name="table" type="file" accept="image/*">
          //   `,
          //   focusConfirm: false,
          //   showCancelButton: true,
          //   confirmButtonColor: '#007500',
          //   confirmButtonText: 'Submit',
          //   preConfirm: () => {
          //     return new Promise(async (resolve) => {
          //       const input1 = document.getElementById('swal-input1').value;
          //       const input2 = document.getElementById('swal-input2').value;
          //       const input3 = document.getElementById('swal-input3').files[0];
        
          //       if (!input1 || !input2) {
          //         Swal.showValidationMessage('Please fill in all the required fields');
          //         resolve(null);
          //       } else {

          //         console.log("categories",data);
          //         console.log("categories",data.image);
          //         // Create a FormData object to handle the file upload
          //         const formData = new FormData();
          //         formData.append('table_no', input1 || data.table_no);
          //         formData.append('no_of_seats', input2 || data.no_of_seats);
          //         formData.append('table_image', input3 || data.table_image);
          //         formData.append('is_active', data.is_active);
          //         formData.append('shop_id',data.shop_id);
        
          //         try {
          //           // Make a PUT request to update the category
          //           const categoryId = data.id; // Replace with the actual category ID
          //           const response = await axios.put(${var_api}/api/table-details/table-put/${categoryId}, formData, {
          //             headers: {
          //               'Content-Type': 'multipart/form-data', // Set the content type for file upload
          //             },
          //           });
        
          //           if (response.status === 200) {
          //             fetchTables();
          //             // Category updated successfully
          //             Swal.fire({
          //               icon: 'success',
          //               text: 'Table updated successfully',
          //               confirmButtonColor: '#007500',
          //             });
          //           } else {
          //             Swal.fire({
          //               icon: 'error',
          //               text: 'Failed to update Table',
          //               confirmButtonColor: '#007500',
          //             });
          //           }
          //         } catch (error) {
          //           console.error('Error updating Table:', error);
          //           Swal.fire('Error', 'Failed to update Table. Please try again.', 'error');
          //         }
        
          //         resolve(formData);
          //       }
          //     });
          //   },
          // });
    };


     //delete method for remove UOM
   const handleDeleteTable = (item) => {
    setShow(true);
    setTableD(item)
     
    }

    //close the modal
    const handleCloseConfirmation = () => {
      setShow(false)
    }


     //close the modal
     const handleCloseDeviceModal= () => {
        setIsOpenDeviceModal(false)
      }


    // delete the item
    const handleDelete = () => {
      axios
      .delete(`${var_api}/api/sub-kitchen-foods/item-delete/${tableD.id}`)
      .then((response) => {
        if (response.status === 200) {
          fetchDevicesFoods(deviceId.id);
          setShow(false);
          Swal.fire("success"," Item Removed Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Item not found');
          Swal.fire("error","Item not found!","error")
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove item!","error")
        // Handle the error as needed
      });
    }



    //close the update modal
  const handleEditClose = () =>{
    setIsEdit(false);
  }


  //post method for creating the shop
  const handleUpdateSubmit = async (e) => {
    console.log("update data before put",updateFormData)
    e.preventDefault(); 
   
    
     try {
      const response = await fetch(`${var_api}/api/sub-kitchen-master/put/${updatedId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateFormData)
    });
      if(response.status == 200){
        setIsEdit(false);
        Swal.fire({
          title: 'Success',
          text: 'Device Updated Successfully.',
          icon: 'success',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
        setUpdateFormData({
            device_name: '',
            description: '' 
        });
         // Clear the value of the file input element
   
    fetchDevices();
        const data = await response.json();
        console.log('Registration successful!',data);
     
      }else {
        console.error('Failed to update shop:', response.statusText);
        // Handle error, e.g., show an error message to the user
        Swal.fire({  
          title: 'error',
          text: 'Failed to update Device...Please Try Again!',
          icon: 'error',
          confirmButtonColor: '#007500', // Set the custom color for the Confirm button
        });
      }
      
      // Handle success scenario as needed
    } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    }

    //input onchange for update
  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({ ...updateFormData, [name]: value });
  };


    //onchange function for file for update
  const handleImageChangeUpdate = (e) => {
    const file = e.target.files[0];
    setUpdateFormData({ ...updateFormData, table_image: file || updateFormData.shop_image});
  };

   //reset form for update
   const handleResetUpdateShop = () => {
    setUpdateFormData({
      id:null,
      table_image:null,
      table_no:'',
      no_of_seats:''
    });
    
  if (fileInputRef.current) {
    fileInputRef.current.value = '';
  }
  }

  //toggle switch for is_active updation
  const handleToggle = async (index,id) => {
    const newSwitches = [...switches];
    console.log('newSwitches', newSwitches);
    console.log('newSwitches', !newSwitches[index]);
    newSwitches[index] = !newSwitches[index];
    console.log('newSwitches', newSwitches[index]);
   
    setSwitches(newSwitches);
    console.log('newSwitches', newSwitches);
    

// Make an API request to update the location_status
try {
const response = await axios.put(`${var_api}/api/sub-kitchen-master/put/${id}`, { is_active: newSwitches[index] ? 1 : 0 });
if (response.status === 200) {
  console.log('Device status updated successfully');
  fetchDevices();
}
} catch (error) {
console.log('Error updating Device status:', error);
// Revert the switch state in case of an error
newSwitches[index] = !newSwitches[index];
setSwitches(newSwitches);
}
  };


  //to open device modal
  const handleOpenDevice = (device) => {
    setIsOpenDeviceModal(true);
    setDeviceId(device);
    fetchDevicesFoods(device.id);
    setSelectedItem(null);
  }

//onchange function for select
  const handleItemSelectChange = (selectedOption) => {
    setSelectedItem(selectedOption)
  }

  const handleAddDeviceItem = async()=>{
    const data = {
        shop_id: parseInt(localStorage.getItem('shopId')), 
        user_id: parseInt(localStorage.getItem("userId")), 
        sub_kitchen_id: deviceId.id, 
        food_id:selectedItem.value , 
        food_name: selectedItem.label, 
        food_image : selectedItem.image
    }
    try {
        const response = await axios.post(`${var_api}/api/sub-kitchen-foods/post`, data);
        if(response.status == 200){
         
          console.log('Registration successful!',response);
          setSelectedItem(null);
          
            Swal.fire({
              icon:"success",
              text:'Item Created Successfully',
              confirmButtonColor:"#007500"
            })
            fetchDevicesFoods(deviceId.id);
            setIsLoading(false);
       
        }else {
          console.error('Failed to create Item:', response.status);
          // Handle error, e.g., show an error message to the user
         
            setIsLoading(false);
        }

        
        // Handle success scenario as needed
      } catch (error) {
          console.error('Error:', error.response);
          setIsLoading(false);
          
          if(error.response.status === 400){
              Swal.fire({
                icon:"error",
                text:'Item Already Exist in Another Device!',
                confirmButtonColor:"#007500"
              })
            }else{
              Swal.fire({
                icon:"error",
                text:'Failed to Create Item',
                confirmButtonColor:"#007500"
              })
            }
          // Handle error scenario as needed
        }

  }


  const fetchDevicesFoods = async (id) => {
    setDeFoodLoading(true);
    try {
       const shop = localStorage.getItem('shopId')
       const response = await axios.get(`${var_api}/api/sub-kitchen-foods/get/device-items/${shop}/${id}`);
       console.log("response",response)
      const user =response.data;
      setDeFoodLoading(false);
      setDeviceFoods(user);
    } catch (error) {
      setDeFoodLoading(false);
      console.log(error);
    }
  };




    return(
        <div className='whole' style={{backgroundColor:"white"}}>
            <div class="split left">
                <div class="centered">
                    <h3 className='ushe'>SUB KITCHEN MASTER</h3>
                    <br/>
                    <div style={{marginLeft:"12px",padding:"12px"}}>

                    <form onSubmit={Submit}>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Device Name</lable>
            <br/>
            <input  className="form-input" type='text' name='device_name' placeholder='enter device name'
             required
             value={formData.device_name}
             onChange={handleChange}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Description</lable>
            <input  className="form-input" type='text' name='description' placeholder='enter description'
            required
             value={formData.description}
             onChange={handleChange}/>
            </div>
            <br/>
            
            <br/>
            {
                isLoading ? (
                    <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) :  <button className='btn btn-success' style={{float:"right"}}>Create Device</button>
            }
           
            </form>
            
            <button className='btn btn-secondary' style={{float:"right",marginRight:"3px"}} onClick={handleToReset} >Reset</button> {' '}
                       
                        
                    </div>
                   
                </div>
            </div>

            <div class="split right">
            <nav className="navbar" 
            style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"5px",height:"50px",width:"1065px",position:"relative",top:"-10px"}} >
              <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
              
                <h4 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "50px", color: "white", whiteSpace:"nowrap" }}>{ shopName }</h4>
                <button className="btn" style={{backgroundColor:"white",color:"green",marginLeft:"70px",marginRight:"5px"}} onClick={handleButtonSubKitchenClick}><i class="bi bi-cup"></i> Sub Kitchen</button>{" "}
                <button className="btn" onClick={handleGOtoTableOrder} style={{backgroundColor:"white",color:"green",marginRight:"5px"}}>
                <i class="bi bi-easel"></i> Table Order</button>{" "}
                  <button className="btn" onClick={handleButtonClick} style={{backgroundColor:"white",color:"green"}}>
                  <i class="bi bi-house"></i> Go to Home</button>
              </div>
      </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"640px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      
      <div>
        
      <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={handleSearchInput}/>
      {/* <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder"}}>Current Tables</h3> */}
      <br/>
      <br/>
      <br/>
     <div className="table-container" style={{ height:"500px", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",borderColor:"grey",width:"90%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col">S.No</th>
      <th scope="col">Device Name</th>
      <th scope="col">Description</th>
      <th scope="col">Edit</th>
      <th scope="col">Active Status</th>
      <th scope='col'>Action</th>
    </tr>
  </thead>
  {loading ? (
        <div className="loader-containeru">
      	  <div className="spinneru"></div>
        </div>
  ) : (
  <tbody class="body-half-screen">
   {filteredSuppliers.length > 0 ? (
   filteredSuppliers.map((data,index) =>(

    <tr style={{padding:"5px"}}>
      <th scope="row">{index+1}</th>
      <td>{data.device_name}</td>
      <td>{data.description}</td>
      <td>
      <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='left'
      >
          Edit Sub Kitchen
        </Tooltip>
      <i class="bi bi-pencil-square"  id={`putButton${index}`}  onClick={()=>{handleEditTable(data); togglePutTooltip(index);}}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      </td>
      <td>
      <div className="form-check form-switch">
      <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id={`flexSwitchCheckChecked_${index}`} checked={switches[index]}  onChange={() => handleToggle(index,data.id)}/>
</div>
</div>

      </td>
      <td>
      <Tooltip
        isOpen={actiontooltipOpen[index]} // Use tooltip state based on index
        target={`actionButton${index}`} // Specify the target ID of the button
        toggle={() => toggleActionTooltip(index)}
        placement='right'
      >
          Add Items
        </Tooltip>
      <i class="bi bi-arrow-right-circle-fill" id={`actionButton${index}`} onClick={()=>{handleOpenDevice(data); toggleActionTooltip(index);}} style={{fontSize:"25px",color:"red"}}></i>
      </td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="6" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )}
 

   </tbody>
)}
</table>

      </div>
<br/>
<br/>


        </div>
      
      </div>
            </div>

            <Modal
        show={isEdit}
        onHide={handleEditClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Device </Modal.Title>
        </Modal.Header>
        <Modal.Body>
       {
       updateFormData && 
       <form  className="py-2" onSubmit={handleUpdateSubmit}>
       <div class="row">
         <div class="col">
            <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Device Name</label>
           <input type="text" class="form-control" placeholder="enter device name" id="input1"  name="device_name"  value={updateFormData.device_name}  onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
         <div class="col">
           <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Description</label>
           <input type="text" class="form-control" placeholder="enter description" id="input2" name="description" value={updateFormData.description} onChange={handleUpdateInputChange} style={{borderRadius:"12px"}} required/>
         </div>
       </div>
       <br/>
       
       <br/>
       {
        isEditLoading ? (
            <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
       }
       
       </form> 
       }
          <button className="btn btn-danger" onClick={handleEditClose} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Close</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>


            <Modal
        show={show}
        onHide={handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM !</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Remove 
          <span style={{color:"red"}}> {tableD.food_name} Item</span> ?</h6> 
         </div>
         {' '} 
          
        </Modal.Body>
        <Modal.Footer>
        <Button variant="danger" style={{float:"right"}} onClick={handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal> 

      <Modal
        show={isOpenDeviceModal}
        onHide={handleCloseDeviceModal}
        backdrop="static"
        keyboard={false}
        size="xl" // Set size to large
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px", textTransform:"Capitalize", fontFamily:"serif", fontSize:"15px"}}>
          <Modal.Title>Sub Kitchen - <b>{deviceId && deviceId.device_name}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{padding: "20px"}}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                        options={foodOptions}
                        onChange={handleItemSelectChange}
                        isSearchable={true}
                        className="customSelect"
                        value={selectedItem}
                        styles={{ menu: (provided, state) => ({
                          ...provided,
                          zIndex: 9999 // Increase the z-index to ensure the dropdown is displayed above other elements
                      }) }}
                         /> {" "}
                         {" "}<button className='btn btn-success' style={{marginLeft:"10px"}} onClick={handleAddDeviceItem}>Add Food</button>
                         </div>
                         <div style={{ display: 'flex', alignItems: 'center' }}>
                         <h5 className='text-dark' style={{fontFamily:"serif", fontWeight:"bolder", marginRight:"20px"}}>No.Of.Items: <b style={{color:"red"}}>{deviceFoods.length}</b></h5>
                         <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={handleSearchFoodsInput}/>
      </div>
                    </div>
                  
                    {
                      deFoodLoading ? (
                        <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
                      ) : (

                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between',zIndex:"-1" }}>
   
    {filteredFoods.map((item, index) => (
     <div className="card p-2" key={index} style={{border: "1px solid green",borderRadius: "30px",padding: "5px",boxShadow:"2px 2px 2px black",height:"370px",width:"16rem",backgroundColor:"rgba(4, 234, 27, 0.073)"}}>
    {
      deviceFoods.length === 0 ? (
        <h4 className="card-title" style={{color:"green",textShadow:"1px 1px 2px black",textTransform:"uppercase"}}><b>Foods Not Available
       </b></h4>
      ) : (
        <>
        <img className="card-img-left" src={`${var_api}/private/${item.food_image}`} alt={`Card ${index + 1}`}  style={{height:"10rem",border: "1px solid green",borderRadius: "30px",padding: "5px",backgroundColor:"white"}}/>
        <div   style={{textAlign:"center",height:"120px",padding:"5px"}}>
          <h4 className="card-title" style={{color:"green",textShadow:"1px 1px 2px black",textTransform:"uppercase",lineHeight:"100%", fontSize:"23px"}}><b>{item.food_name} {" "}
          <span style={{fontSize:"13px"}}>({item.item_code})</span>
          </b></h4>
          {/* <p style={{textAlign:"left",fontSize:"16px",margin:"3px 8px"}}><b>Item Code:</b> {item.item_code}</p> */}
          <p className="card-text" style={{textAlign:"left",fontSize:"14px",margin:"3px 8px"}}><b>Category Name:</b> {item.category_name}</p>
          <p className="card-text" style={{textAlign:"left",fontSize:"14px",margin:"3px 8px"}}><b>Description:</b> {item.text}</p>
          <p className="card-text" style={{textAlign:"left",fontSize:"14px",margin:"3px 8px"}}><b>UOM:</b> {item.uom}</p>
          <button className='btn btn-danger' style={{padding:"0px 5px 0px 5px", float:"right"}} onClick={()=> handleDeleteTable(item)}><i class="bi bi-trash" style={{fontSize:"20px"}}></i></button>
        </div>
        </>
      )
    }
   </div>
    ))}

            </div>
                      )
                    }
            
            </div>
        </Modal.Body>
      
      </Modal> 
        </div>

        
    )
}

export default SubKitchenMaster;