import React, { useState, useEffect } from "react";
import { Link, useHistory} from "react-router-dom";
import { var_api } from "../constant";
import shop_logo from "../../images/splash/shop_logo.jpeg";
import "./SignUp.css";
import axios from "axios";
import Swal from 'sweetalert2';
import { Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';

const ChangePassword = () => {
    const history = useHistory();
    const [password, setPassword] = useState("");

    const submit = async (event) => {
        
        event.preventDefault();
       
        const email =  localStorage.getItem('forgotEmail');
        try {
          let response;
          const person = localStorage.getItem('passwordFor')
          console.log("person",person);
          if(person === "Employee"){
           // Make an API call with the OTP payload
            response = await axios.put(`${var_api}/api/employees/change-new-password`, { email:email, newPassword:password });

          }else{
           // Make an API call with the OTP payload
            response = await axios.put(`${var_api}/api/user/change-password`, { email:email, newPassword:password });

          }
          
            
      
            console.log(response.data); // Log response from the API
      
            // Check if the status code is 200
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    text: response.data.message,
                    confirmButtonColor: '#007500',
                  });
              // Redirect to the change password page upon successful response
              history.push('/signin');
            } else {
              console.error('Unexpected status code:', response.status);
              // Handle unexpected status codes appropriately
            }
          } catch (error) {
            console.error('Error:', error.response);
             // Show the Swal alert for a Bad Request (status code 400)
             Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
                confirmButtonColor: '#007500',
              });
          }
    }

return(
    <div className="sign-up">
        <br/> 
       {/* <Link to="/" className="navbar-brand">
          <img src={shop_logo} alt="logo" style={{marginLeft:"720px", width:"120px",height:"110px",marginTop:"5px"}} />
        </Link>
        <br/> 
        <br/>  */}
          <Card style={{width:"27rem",height:"25rem",padding:"10px",borderRadius:"25px",border:"none", display: "flex", justifyContent: "center", alignItems: "center",margin:"auto" }}>
        <CardBody>
          <CardTitle style={{textAlign:"center"}}> 
          <Link to="/" className="navbar-brand">
          <img src={shop_logo} alt="logo" style={{margin:"auto", width:"100px",height:"100px",borderRadius:"50px", marginTop:"20px"}} />
        </Link>
          </CardTitle>
          <CardSubtitle>
      <div className="container" style={{width:"370px",margin:"auto"}}>
        
       
          <form onSubmit={submit}  className="py-2">
            <h1 className="lead text-center py-2" style={{color:"rgb(248, 113, 50)", fontSize:"15px", fontWeight: "bolder"}}>Change Password ?</h1>
            <h1 className="lead text-center " style={{color:"grey", fontSize:"15px", fontWeight: "bolder"}}>Change password with SG-Biller</h1>
           <br/>
            <div className="form-group">
              <input
              type="text"
                name="text"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter New Password"
                style={{height:"7px", borderRadius:"20px"}}
                required
              />
              
            </div>
            <br/>
            <div className="d-grid">
              <button className="btn btn-success p-2" type="submit" style={{borderRadius:'10px', borderRadius:"15px"}}>
                Change Password
              </button>
            </div>

           
          </form>
        
      </div>
      </CardSubtitle>
         
         </CardBody>
       </Card>
      
    </div>
)
};

export default ChangePassword;