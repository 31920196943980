import React,{useState, useEffect} from 'react';
import { Table, Row, Col, Card} from 'react-bootstrap';
import '../kitchen/kitchen2.css';
import { collection, onSnapshot, where, query, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase.js';
import axios from 'axios';
import { var_api } from '../constant';


const DChildCard1 = () => {
  
  
  const [ readyDelOrders, setReadyDelOrders ] = useState([]);
  

  useEffect(() => {

    fetchData();
   
   }, [])

//fetch data when parcel status is 1
   const fetchData = async () => {
     const shopName = localStorage.getItem('shopName');
    const ordersRef = collection(db, `shops/${shopName}/orders`);
    // Query to get orders with process_code equal to 3
    const ordersquery = query(ordersRef, where('process_code', '==', 4));
    const unsub = onSnapshot(ordersquery, snapshot => {
        setReadyDelOrders(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
    })

    return () => {
        unsub()
    }

    // const shopId=localStorage.getItem('shopId');
    // try {
     
    //   const response = await axios.get(`${var_api}/api/food/api/foodparcel/${shopId}/1`);
    //   const responseData = response.data;
      
    //   if (Array.isArray(responseData)) {
    //     setReadyDelOrders(responseData);
    //   } else {
    //     // Handle the case when responseData is not an array
    //     console.error('API response is not an array:', responseData);
    //     setReadyDelOrders([responseData]); // Set users to an empty array or handle the error appropriately
    //   }
    // } catch (error) {
    //   console.log('Error fetching data:',error);
    // }
  };


   const handleReadyDelButtonClick = (orderId) => {

    // const shopID = localStorage.getItem('shopId');
   

    // const updatedData = {
    //  parcel_status:2,
    // }

    // axios
    // .put(`${var_api}/api/food/parcelput/${shopID}/${phone}`, updatedData)
    // .then((response) => {
    //   console.log('Data updated successfully:', response.data.message);
    //   fetchData();
    //   // Handle success, e.g., show a success message or update the UI
    // })
    // .catch((error) => {
    //   console.error('Error updating data:', error);
    //   // Handle error, e.g., show an error message or update the UI accordingly
    // });

    const shopName = localStorage.getItem('shopName');
    const orderRef = doc(db, `shops/${shopName}/orders`, orderId);
  
    // Update the process_code field to 4
    updateDoc(orderRef, {
      process_code: 5
    })
    .then(() => {
      console.log('process_code updated successfully');
    })
    .catch(error => {
      console.error('Error updating process_code:', error);
    });
  };


  return (
    <div style={{margin:"10px"}}>
     
     <Row>
        {readyDelOrders.map((order,index) => (
          <Col key={index}>
            <Card className="cssa" border="success" style={{borderRadius:"20px",width:"300px",marginBottom:"0px",marginTop:"22px"}}> 
            <Card.Header className="bird" style={{fontWeight:"bold",padding:"7px",textTransform:"capitalize"}}>{order.data.customer_name} <span style={{fontSize:"10px"}}>{order.data.customer_phone}</span></Card.Header> 
          
            <Card.Body className="p-2">
            {Object.values(order.data).map((fieldValue,index) => {
            if (Array.isArray(fieldValue)) {
            return (
          
               <div>
                 <Card.Title style={{fontSize:"13px"}}>order no .{order.data.order_no}</Card.Title>
               <div  className="duck" style={{overflowY:"scroll"}}>
                
               <Table striped="columns" className="scrollable-table" bg="warning"  >
                
            
                <thead>
                <th style={styles.tableHeader}>Item Name</th>
                <th style={styles.tableHeader}>Quantity</th>
                <th style={styles.tableHeader}>Price(INR)</th>
              </thead>
              {fieldValue.map((item, index) => (
                  
                    <tr key={index}>
                    <td style={styles.tableCell}>{item.food_name}</td>
                    <td style={styles.tableCell}>{item.quantity}</td>
                    <td style={styles.tableCell}>{item.unit_price}</td>
                    </tr>
                     ))}
    </Table>
               
               </div>
               </div>
            )}
          })}

               
               <button type="button" class="btn btn-outline-success" onClick={()=>handleReadyDelButtonClick(order.id)}  style={{marginTop:"10px"}}>Ready to Parcel</button>
              </Card.Body>
          
            </Card>
          </Col>
        ))}
      </Row>
   </div>
 );
};


const styles = {
  table: {
    width: '50%',
    borderCollapse: 'collapse',
    margin: 'auto',
    overflow:"scroll",
    
  },
  tableHeader: {
    border:'1px solid black',
    padding: '2px',
    textAlign: 'center',
    color:"black",
  },
  tableCell: {
    border: '1px solid black',
    padding: '2px',
    color:"black",
  },
 
}

export default DChildCard1;
