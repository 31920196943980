import React, { useState,useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import {var_api} from '../constant.js';

function SearchableDropdown({ onSelect,selectedValue }) {
    const [options, setOptions] = useState([]);
  
    useEffect(() => {
        // Fetch data from the API when the component mounts
  
        fetchCreditUser();
       
      }, []);


      const fetchCreditUser = async () => {
        const shop_id = localStorage.getItem('shopId');
        try {
          const response = await axios.get(`${var_api}/api/credituser/data/${shop_id}`);
          const data = response.data;
    
          // Transform the data from the API into the format expected by react-select
          const formattedOptions = data.map(item => ({
            value: item.phone_no, // Change this to the actual property name from your API response
            label: item.name,
            mail:item.email,
            iden:item.id, // Change this to the actual property name from your API response
            maxCredit: item.maxCredit
          }));
    
          setOptions(formattedOptions);
        } catch (error) {
          console.log('Error fetching data:', error);
          setOptions([]);
        }
      };

    
    const handleSelectChange = (selectedOption) => {
      onSelect(selectedOption);
    };
  
    return (
      <div>
        <Select
          value={selectedValue}
          onChange={handleSelectChange}
          options={options}
          isSearchable={true} // Enable the search functionality
        />
        {/* {selectedOption && (
          <div>
            You selected: <strong>{selectedOption.label}</strong> ({selectedOption.value}) ({selectedOption.mail})
          </div>
        )} */}
      </div>
    );
  }
  
  export default SearchableDropdown;
  