import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from "../constant";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()




  class AdminHome extends React.Component {


    constructor(props) {
      
      super(props);
      this.routeChange = this.routeChange.bind(this);
      this.routeRaw=this.routeRaw.bind(this);
      this.routeDay=this.routeDay.bind(this);
      this.routeShop=this.routeShop.bind(this);
      this.routeDaily=this.routeDaily.bind(this);
      this.routeTable =  this.routeTable.bind(this);
      this.routeCredit =  this.routeCredit.bind(this);
      this.routeSupplier = this.routeSupplier.bind(this);
      this.routeUom = this.routeUom.bind(this);
      this.toggle = this.toggle.bind(this);
      this.toggleNav = this.toggleNav.bind(this);
      this.toggleChange = this.toggleChange.bind(this);
      this.handleOnclick = this.handleOnclick.bind(this);
      this.routeReport = this.routeReport.bind(this);
      this.routeContract = this.routeContract.bind(this);
      this.routeGroupOrders =  this.routeGroupOrders.bind(this);
     
      
      this.state = {
        dropdownOpen: false
      };
      this.state = {
        shadropdownOpen: false
      };
      const shopNameFromLocalStorage = localStorage.getItem('shop_name');
      this.state = {
        activeTab: '1'
      };
      this.state = {
        isChecked:true,
        activeTab: 2,
        selectedList: 'Breakfast',
        customers:[], // Set the default selected list to 'Breakfast'
        orderNo: 100,
        showTable: false,
        shopName: '',
        users:[],
        categories: [],
        foods: [],
        cartItems: [],
        orders: [],
        mobileInput:[],
        searchQuery:'',
        checkedCategories:false,
        loading:false,
        selectedCreditCustomer: null,
        creditName:'',
        creditMobile:'',
        selectedData: '',
        firstCategoryId:0,
        isPrinted:false,
        ItemCodeOptions:[],
        selectedItemCode:null,
      };

      
      // this.contextType = OrderContext; 
    }

    componentDidMount() {

      const userId = localStorage.getItem('userId');
      console.log("userId",userId);
      // localStorage.removeItem('shopName');
      localStorage.removeItem('shopId');
      console.log("Before checking userID",localStorage.getItem('shopName'));
      console.log("Before checking userID",localStorage.getItem('shopId'));
      // this.getShopName(userId);
      
      if (userId) {
       
        const shopName = localStorage.getItem('shopName');
        console.log("abc",shopName);
        // Retrieve the shop name from localStorage
        if (shopName) {
          console.log("s abc",shopName);
         this.setState({shopName});
          // localStorage.removeItem('shopName');
        } else {
          // If shop name is not available in localStorage, fetch it from the server
          this.getShopName(userId);
          console.log("n abc",shopName);
           
        }
      } else {
        console.error('User ID not found in local storage.');
        this.props.history.push("/signin");
        // Handle the case where the user ID is not available in local storage
      }
     

      this.fetchData();
      // this.getShopName(userId);
      this.fetchCategories();
      this.fetchCreditUser();
      this.fetchItemCode();

      // this.handleListClick(this.defaultCategoryId)


      // Simulate a 3-second delay before checking categories (replace with your data fetching logic)
    setTimeout(() => {
      this.fetchData();
      this.fetchCategories();
  this.setState({ checkedCategories: true})
    }, 11000); // Delay for 3 seconds

    }

    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
    }


   
  //show the shop name in center
  getShopName(userId) {
      axios.get(`${var_api}/api/shop/shopData/${userId}`)
        .then((response) => {
          const data = response.data;
          this.setState({ shopName:data.shopName });
          localStorage.setItem('shopName', data.shopName);
          localStorage.setItem('shopId', data.shopID);
          console.log(data.shopName,data.shopId)
          
        })
        .catch((error) => {
          console.error('Error fetching shop name:', error);
          // Handle the error if needed
        });
}

    
fetchCategories = async () => {
          
         const shopId = localStorage.getItem('shopId')
         try {
          const response = await axios.get(`${var_api}/api/categories/api/categories/${shopId}` );
          const data = response.data;
          if (data.length > 0) {
            // If there are categories in the response, set the ID of the first category in the state
            this.setState({ categories: data, firstCategoryId: data[0].id });
          } else {
            // Handle the case where there are no categories
            console.log('No categories found.');
          }
        }catch (error) {
          console.log('Error fetching data:', error);
          
          }
        };
  


 
  
    toggle(tab) {
     
      const { activeTab } = this.state;
      this.fetchCategories();
  
      if (activeTab === tab) {
    
        // Close the active tab if it's clicked again
        this.setState({ activeTab: null });
      } else {
      
        this.setState({ activeTab: tab });
        
      }
    }

   

    routeChange() {
      let path = `/Admintable-master`;
      this.props.history.push(path);
    }

    routeRaw() {
      let path = `/Adminraw-material`;
      this.props.history.push(path);
    }

    routeShop() {
      let path = `/AdminShopMaster`;
      this.props.history.push(path);
    }

    routeDay() {
      let path = `/Adminemployee-master`;
      this.props.history.push(path);
    }

    routeDaily() {
      let path = `/daily`;
      this.props.history.push(path);
    }

    routeTable() {
      let path = `/tableOrders`;
      this.props.history.push(path);
    }

    routeCredit() {
      let path = `/creditUser`;
      this.props.history.push(path);
    }

    routeContract() {
      let path = `/contract`;
      this.props.history.push(path);
    }

    routeReport() {
      let path = `/AdminCategoryMaster`;
      this.props.history.push(path);
    }

    routeUom() {
      let path = `/AdminUomMaster`;
      this.props.history.push(path);
    }

    routeGroupOrders() {
      let path = `/groupOrders`;
      this.props.history.push(path);
    }


    routeSupplier() {
      let path = `/Adminsupplier-master`;
      this.props.history.push(path);
    }


    toggleNav() {
      
      this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen

      }));
     
    }

      toggleChange() {
        this.setState(prevState => ({
          shadropdownOpen: !prevState.shadropdownOpen
        }));

    }

 
    handleNameSelection = (name) => {
      this.setState({
        shopName: name.shop_name,
      });
      localStorage.removeItem('shopName')
      const shopName=localStorage.setItem('shopName',name.shop_name)
      localStorage.setItem('shopId',name.id)
      this.fetchCategories();
      this.setState({foods:[]})
      this.setState({orders:[]})
    };

    fetchData = async () => {
      try {
         // const response = await axios.get('https://api.spbiller.com/api/shop/data');
         const create = localStorage.getItem('userId')
         const response = await axios.get(`${var_api}/api/shop/api/shops/${create}`);
        const user =response.data;

         if (Array.isArray(user)) {
          this.setState({ 
            users: user
           });
 
       
        } else {
          // Handle the case when responseData is not an array
          console.error('API response is not an array:', user);
          this.setState({ 
            users: [user]
           });; // Set users to an empty array or handle the error appropriately
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleOnclick(){
      localStorage.removeItem('shopName');
        this.props.history.push('/AdminDashboard')
     
    }


    //fetch all the item code
    fetchItemCode= async()=> {
      const shopId = localStorage.getItem('shopId')
        try {
          const response = await axios.get(`${var_api}/api/foodMaster/api/allFood/${shopId}`);
          const data = response.data.result;
    
          // Transform the data from the API into the format expected by react-select
          const formattedOptions = data.map(item => ({
            label: item.item_code, // Change this to the actual property name from your API response
          }));
    
          this.setState({ItemCodeOptions:formattedOptions});
        } catch (error) {
          console.log('Error fetching data:', error);
          
        }
}


  //set the selected item code
   handleCodeSelectChange = (data) => {
    this.fetchItemCode();
 
    this.setState({selectedItemCode:data}, () => {
      console.log('selected item code:', this.state.selectedItemCode);
    });
   }


    handleSearchInput = (e) => {
     this.setState({
      searchQuery : e.target.value,
     });
    };


    //fetch the item details filter by item code
    handleAddByItemCode = async () => {
      const { selectedItemCode } = this.state
      if(selectedItemCode && selectedItemCode.label){
        const item_code = selectedItemCode.label
      const shopId = localStorage.getItem('shopId')
      try {
        const response = await axios.get(`${var_api}/api/foodMaster/api/filterItem/${shopId}/${item_code}`);
        const data = response.data.result;

        const food = data[0];
       // Add the selected food to the cart
        this.addToCart(food);
        console.log("selected food details",food)
  
       
      } catch (error) {
        console.log('Error fetching data:', error);
        
      }
        
      }else{
        Swal.fire({
          text:"Select Item Code Before Add",
          confirmButtonColor: '#007500',
        })
      }
      
    }


    incrementQuantity = (itemId) => {
      const { cartItems } = this.state;
      const updatedCartItems = cartItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      this.setState({ cartItems: updatedCartItems });
    };
    
    decrementQuantity = (itemId) => {
      const { cartItems } = this.state;
      const updatedCartItems = cartItems.map((item) => {
        if (item.id === itemId && item.quantity > 0) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });
      this.setState({ cartItems: updatedCartItems });
    };
    


    addToCart = (food) => {
      const { cartItems } = this.state;
  const existingItemIndex = cartItems.findIndex((item) => item.food.id === food.id);

  if (existingItemIndex !== -1) {
    const updatedCartItems = [...cartItems];
    updatedCartItems[existingItemIndex].quantity += 1;
    this.setState({ cartItems: updatedCartItems });
  } else {
    const cartItemId = Date.now();
    this.setState((prevState) => ({
      cartItems: [...prevState.cartItems, {  id: cartItemId,food: food, quantity: 1 }],
    }));
  }
    };

    removeFromCart = (cartItemId) => {
      const { cartItems } = this.state;
      const updatedCartItems = cartItems.filter((item) => item.id !== cartItemId);
      this.setState({ cartItems: updatedCartItems });
    };

    fetchCreditUser = async () => {
      const shopId = localStorage.getItem('shopId');
     try {
      const response = await axios.get(`${var_api}/api/credituser/data/${shopId}` );
      const data = response.data;
      this.setState({ mobileInput: data });
    }catch (error) {
      console.log('Error fetching data:', error);
      this.setState({ mobileInput: [] });
      }
    };



    handleAddCard = () => {
      // Navigate to a specific route using history.push
      this.props.history.push('/cate');
    };

    handleDropdownChange = (selectedOption) => {
      this.setState({ selectedData: selectedOption });
    };
  

    render() {
      const { activeTab } = this.state;
      const { customers } = this.state;
      const { selectedList } = this.state;
      const { orderNo,showTable } = this.state;
      const { shopName } = this.state;
      const { users } = this.state;
      const { categories } = this.state;
      const { foods } = this.state;
      const { orders } = this.state;
      const { searchQuery } = this.state;
      const { checkedCategories } = this.state;
      const { loading } = this.state;
      const { isPrinted,ItemCodeOptions,selectedItemCode } = this.state;

      console.log("shop Id:",localStorage.getItem('shopId')); 
      console.log("email Id:",this.state.selectedData.mail); 
    

  return (
    
    <>
       <div id="fix" style={{position:"fixed",left:"650px"}}>
    <h1 class="banner" style={{textTransform:"uppercase"}}><div class="glow">&nbsp;</div>{ shopName }</h1>
   </div>
  <div style={{padding:"5px",marginLeft:"10px",marginTop:"5px",borderRadius:"5px",position:"fixed",zIndex:"2"}}>
  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleNav} className="custom-dropdown">
  <DropdownToggle className='wine'> <i class="bi bi-list-stars" style={{fontSize:"23px"}}></i> </DropdownToggle>
  <DropdownMenu>
          <DropdownItem header className="dropItem active">MASTERS</DropdownItem>
          <DropdownItem className="dropItem"  onClick={this.routeShop}> 
             SHOP MASTER
          </DropdownItem>
          <DropdownItem className="dropItem"  onClick={this.routeReport}> 
             CATEGORY MASTER
          </DropdownItem>
          <DropdownItem className="dropItem"  onClick={this.routeUom}>
             UOM MASTER
          </DropdownItem>
          <DropdownItem className="dropItem"  onClick={this.routeChange}> 
             TABLE MASTER
          </DropdownItem>
          <DropdownItem className="dropItem"  onClick={this.routeSupplier}>
             SUPPLIER MASTER
          </DropdownItem>
          <DropdownItem className="dropItem"  onClick={this.routeRaw}> 
             RAW MATERIAL MASTER
          </DropdownItem>
          <DropdownItem className="dropItem"  onClick={this.routeDay}> 
             EMPLOYEE MASTER
          </DropdownItem>
          {/* <DropdownItem className="dropItem" onClick={this.routeGroupOrders}>
          GROUP LOCATION ORDERS
          </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
 
  </div>

  <Dropdown isOpen={this.state.shadropdownOpen} toggle={this.toggleChange} className="custom-dropdown">
  <DropdownToggle className='wine' style={{marginLeft:"1200px",backgroundColor:"white",border:"none",position:"fixed"}}><i class="bi bi-shop-window" style={{fontSize:"35px",color:"green"}}></i></DropdownToggle>
  
  <DropdownMenu >
  {users.map(user=>(
    <DropdownItem key={user.id} className="dropItem" onClick={() => this.handleNameSelection(user)}>{user.shop_name}</DropdownItem>
    ))}
  </DropdownMenu>
 
  </Dropdown>

  <button className="btn btn-outline-success" style={{position:"fixed",marginLeft:"1400px",marginTop:"20px"}} onClick={this.handleOnclick}>Go to Dashboard</button>

   
   
    </>
    );
  }
}


export default withRouter(AdminHome);

