import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from '../constant.js';
import "./currency_master.css";

function AdminRawMaterial(){
    const history = useHistory();
    const shopName= localStorage.getItem('shopName');
    const [item_name,setitem_name] = useState("");
    const [stock_code,setstock_code] =  useState("");
    const [rawItems, setRawItems] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ searchQuery,setSearchQuery] = useState('');

    //redirect to home page
    const handleHome = () =>{
      history.push('/AdminHome')
    }

    //list the catgories in select
    useEffect(() => {
        fetchItems();
    
    }, []);


//fetch the uom details
    const fetchItems = async () => {
        setLoading(true);

        const shopId = localStorage.getItem('shopId');
       try {
        const response = await axios.get(`${var_api}/api/raw-items/get-items/${shopId}` );
        const use = response.data
      setRawItems(use);
      
      console.log("categories",rawItems)
      setLoading(false);
      }catch (error) {
        console.log('Error fetching data:', error);
        
        }
      };

 

   //post the form data
    const Submit = async (e) => {
      e.preventDefault();
    
      try {
        const response = await axios.post(`${var_api}/api/raw-items/post`, {
            item_name: item_name, 
            stock_code: stock_code,
            shop_id: localStorage.getItem('shopId')
        });
        console.log("response",response)
    
        if (response.status === 200) {
          Swal.fire({
            icon:"success",
            text:`Raw Items Created Successfully`,
            confirmButtonColor:"#007500"
          })
          setstock_code(" ");
          setitem_name(" ");
          fetchItems();
          console.log('Data inserted successfully.')

      }

      } catch (error) {
        
        Swal.fire({
            icon:"error",
            text:`${error.response.data.error}`.toUpperCase(),
            confirmButtonColor:"#007500"
          })
        console.error('Error:',error);
        // Handle error scenario as needed
      }
    };

    const handleToReset = () => {
        setstock_code(" ");
        setitem_name(" "); 
    }



      //get the value in search and set the setstate
      const handleSearchInput = (e) => {
        setSearchQuery(e.target.value)
        };


         //search the transaction data
      const filteredItems = rawItems.filter((order) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          (order.item_name && order.item_name.toString().toLowerCase().includes(searchLower)) ||
          (order.stock_code && order.stock_code.toString().toLowerCase().includes(searchLower))
          
        );
      });

      //to open the form for slot updation 
    const handleEditItem = async (data) => {
        const { value: formValues } = await Swal.fire({
            title: 'Update Currrency',
            html: `
             <label class="swalLa">Item Name</label>
             <br/>
              <input id="swal-input1" class="swal2-input fin" name="item_name" placeholder="Item Name" value="${data.item_name || ''}">
              <br/>
              <br/>
              <label class="swalLa">Stock Code</label>
              <input id="swal-input2" class="swal2-input fin" name="stock_code" placeholder="Stock Code" value="${data.stock_code || ''}">
             
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#007500',
            confirmButtonText: 'Submit',
            preConfirm: () => {
              return new Promise(async (resolve) => {
                const input1 = document.getElementById('swal-input1').value;
                const input2 = document.getElementById('swal-input2').value;
        
                if (!input1 || !input2) {
                  Swal.showValidationMessage('Please fill in all the required fields');
                  resolve(null);
                } else {

                  const requestData = {
                    item_name: input1|| data.item_name,
                    stock_code: input2 || data.stock_code
                  };
        
                  try {
                    // Make a PUT request to update the UOM
                    const response = await axios.put(`${var_api}/api/raw-items/put/${data.id}`, requestData, {
                      headers: {
                        'Content-Type': ' application/json', 
                      },
                    });
        
                    if (response.status === 200) {
                        fetchItems();
                      // Category updated successfully
                      Swal.fire({
                        icon: 'success',
                        text: 'Raw Items updated successfully',
                        confirmButtonColor: '#007500',
                      });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: 'Failed to update Raw Items',
                        confirmButtonColor: '#007500',
                      });
                    }
                  } catch (error) {
                    console.error('Error updating Raw Items:', error);
                    Swal.fire('Error', 'Failed to update Raw Items. Please try again.', 'error');
                  }
        
                  resolve(requestData);
                }
              });
            },
          });
      };


       //delete method for remove UOM
     const handleDeleteItem = (id) => {
        axios
        .delete(`${var_api}/api/raw-items/item-delete/${id}`)
        .then((response) => {
          if (response.status === 200) {
            console.log(id)
            fetchItems();
            Swal.fire("success","Raw Items Removed Successfully!","success")
            // Perform any other actions you need on success
          } else if (response.status === 404) {
            console.log('Raw Items not found');
            Swal.fire("error","Raw Items not found!","error")
          }
        })
        .catch((error) => {
          console.error('Error executing the request:', error);
          Swal.fire("error","Failed to Remove Raw Items!","error")
          // Handle the error as needed
        });
      }


    return(
        <>
        <nav className="navbar navbar-expand navbar-light py-2 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none"}} >
          <h5 className="hefou" style={{textAlign:"center",color:"white",paddingLeft:"5px"}}><i class="bi bi-arrow-left-circle" onClick={handleHome} style={{fontSize:"21px"}}></i>{' '}RAW MATERIALS</h5>
        </nav>
        <div id='dailybacku'>
          <br/>
          <br/>
          <div id='fiboxu'>
            <form onSubmit={Submit}>
            <div class="labelsu">
            <lable style={{color:"green",fontWeight:"bold",fontSize:"18px",letterSpacing:"2px"}}>Item Name  </lable>
            <input  className="form-inputu" type='text' name='item_name'
             required
             value={item_name}
             onChange={(e) => setitem_name(e.target.value)}/>
            </div>
            <br/>
            <div class="labelsu">
            <lable style={{color:"green",fontWeight:"bold",fontSize:"18px",letterSpacing:"2px"}}>Stock Code</lable>
            <input  className="form-input1u" type='text' name='stock_code'
            required
             value={stock_code}
             onChange={(e) => setstock_code(e.target.value)}/>
            </div>
            <br/>
            <br/>
            <button className='btn btn-success' id='dasubu'>Submit</button> {' '}
            
            </form>
            <button className='btn btn-danger' style={{borderRadius: "11px", marginLeft:"540px", marginTop:"-63px"}} onClick={handleToReset}> Reset</button>
          </div>
          <br/>
          <div id='taboxu'>
          <table class="table table-responsive-sm table-hover" style={{maxWidth:"90%",backgroundColor:"rgba(10, 241, 33, 0.133)"}}>
            <caption className="hefou" style={{captionSide:"top",textAlign:"right",fontSize:"25px",color:"black",fontWeight:"bolder"}}>
            <input type='Search' name='search' className='listSearchu' placeholder='Search Currency' onChange={handleSearchInput}  value={searchQuery}/>
            </caption>
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Item Name</th>
      <th scope="col">Stock Code</th>
      <th scope="col">Action</th>
      <th></th>
    </tr>
  </thead>
  {loading ? (
        <div className="loader-containeru">
      	  <div className="spinneru"></div>
        </div>
  ) : (
  <tbody >
   {filteredItems.length > 0 ? (
   filteredItems.map((data, index) => (
  
    <tr key={index}>
      <th scope="row">{index + 1}</th>
      <td style={{textTransform:"uppercase", fontSize:"15px"}}>{data.item_name}</td>
      <td style={{textTransform:"capitalize",fontSize:"15px"}}>{data.stock_code}</td>
      <td>
      <i class="bi bi-pencil-square" onClick={() => handleEditItem(data)}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      <i class="bi bi-trash" onClick={() => handleDeleteItem(data.id)}  style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i>
      </td>
    </tr>
  
)) 
): (
    <tr>
      <td colSpan="7" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )
}

   </tbody>
  )}
</table>
          </div>
        </div>
        </>
    )
}

export default AdminRawMaterial;