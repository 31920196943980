import React, { useState, useEffect} from "react";
import "./Shop.css";
import { useHistory , Link, useLocation} from "react-router-dom";
import Splash from "../../images/splash/shop_logo.jpeg";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import axios from "axios";
import { var_api } from "../constant";
import Select from 'react-select';


function Shop(){
    const location = useLocation();
    const history = useHistory();
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState({ value: 'SGD', label: 'Singapore Dollar' });
    const [formData, setFormData] = useState({
      shop_name:'',
      owner_name:'',
      address:'',
      state:'',
      country_name:'',
      created_by:'',
      shop_image:null,  
      shop_currency:selectedCurrency.value,
      shop_type:null  
    });
   
   
    // const handleClick = () => {
    //   history.push('/home', { isdbcon: true }); // Replace with the path of the component you want to navigate to
    //   };

    // useEffect(() => {
    //   const hideAlert = localStorage.getItem('hideAlert');
  
    //   if (hideAlert) {
    //     // Remove the flag from local storage
    //     localStorage.removeItem('hideAlert');
    //   } else {
    //     Swal.fire({
    //       title: 'Error!',
    //       text: 'Please buy a subscription for create shop',
    //       icon: 'error',
    //       confirmButtonColor: '#d33',
    //       confirmButtonText: 'ok'
    //     }).then(okay => {
    //       if (okay) {
    //         window.location.href = "/sub";
    //       }
    //     });
    //   }
    // }, []);
  
    

    const handleCurrencyChange = (selectedOption) => {
      setSelectedCurrency(selectedOption);
      console.log(selectedCurrency)
      setFormData({ ...formData, shop_currency: selectedOption.value });
    };
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      setFormData({ ...formData, image: file });
    };


     //fetch currency
  const fetchcurrecny = async () => {
    try {
      const response = await axios.get(`${var_api}/api/currency-master/get-currency`);
      const responseData = response.data;
      
      if (Array.isArray(responseData)) {
      
         // Map currency names to currencyOptions
      const options = responseData.map(currency => ({
        value: currency.currency_name,
        label: currency.currency_name
      }));

      setCurrencyOptions(options);
      } else {
        // Handle the case when responseData is not an array
        console.error('API response is not an array:', responseData);
        
      }
    } catch (error) {
      console.log('Error fetching data:',error);
    }
  };

  useEffect(() => {
    fetchcurrecny();
  }, []);


    const handleSubmit = async (e) => {
      e.preventDefault();  
      
      const created_by = localStorage.getItem('userId') || "";
      const formDataToSend = new FormData();
      formDataToSend.append('shop_name', formData.shop_name);
      formDataToSend.append('owner_name', formData.owner_name);
      formDataToSend.append('address', formData.address);
      formDataToSend.append('state', formData.state);
      formDataToSend.append('country_name', formData.country_name);
      formDataToSend.append('created_by', created_by); 
      formDataToSend.append('shop_image', formData.image);
      formDataToSend.append('shop_currency', formData.shop_currency);
      formDataToSend.append('shop_type',formData.shop_type);
    
       try {
        const response = await fetch(`${var_api}/api/shop/api/post`, {
          method: 'POST',
        body: formDataToSend,
      });
        if(response.status == 200){

          const data = await response.json();
          const shop_id = data.insertedId;
          console.log("shopid",shop_id);

          try {
            // Make POST request to your API endpoint
            const response = await axios.post(`${var_api}/api/shop-paymode/post`, {
              user_id: parseInt(created_by),
              shop_id: shop_id
            });
            console.log("dd",formData)
          if(formData.shop_type == 2){
            localStorage.setItem('shopType',formData.shop_type);
            history.push('/sb-home')
          }else{
            history.push('/home')
          }
          
      
            console.log(response.data); // Log the response data
            // Handle success or update UI accordingly
          } catch (error) {
            console.error('Error:', error); // Log any errors
            // Handle error or show error message to the user
          }
          console.log('Registration successful!',data);

          
          // localStorage.setItem('shop_name', shop_name);
          // console.log(shop_name)
        
       
        }else {
          console.error('Failed to create shop:', response.statusText);
          // Handle error, e.g., show an error message to the user
        }
        
        // Handle success scenario as needed
      } catch (error) {
          console.error('Error:', error);
          // Handle error scenario as needed
        }
      };

      const handleRadioChange = (event) => {
        const selectedValue = event.target.value;
        setFormData({
          ...formData,
          shop_type: selectedValue === 'foodShop' ? 1 : selectedValue === 'serviceBooking' ? 2 : null
        });
    
        if (selectedValue === 'foodShop') {
          // Save logic for Food Shop
          console.log('Selected Food Shop');
        } else if (selectedValue === 'serviceBooking') {
          // Save logic for Service Booking
          console.log('Selected Service Booking');
        }
      };

    return(

      
        <div className="sign-up">
<br/>
          
            <Link to="/" className="navbar-brand">
          <img src={Splash} alt="logo" style={{marginLeft:"720px", width:"100px",height:"100px",borderRadius:"50px"}} />
        </Link>
        <div className="container" style={{width:"950px",margin:"auto"}}>
        <h5 style={{textAlign:"center",fontWeight:"bolder",fontFamily:'sans-serif',color:"rgb(248, 113, 50)"}}>Create Shop</h5>
        <br/>
          <form onSubmit={handleSubmit} style={{margin:"auto",maxWidth:"70%"}}>
          <div className="row">
            <div className="col">
            <label class="form-label text-success fw-bolder m-1" style={{fontFamily:"serif"}}>Shop Name</label>
              <input
               type="text"
                name="shop_name"
                className="form-control"
                placeholder="Shop Name"
                value={formData.shop_name}
                onChange={handleInputChange}
                style={{height:"7px",marginBottom:"10px",border:"1px solid green",borderRadius:"12px"}}
                required
              />
            </div>
            <div className="col">
            <label class="form-label text-success fw-bolder m-1" style={{fontFamily:"serif"}}>Shop Image</label>
              <input
               type="file"
                name="image"
                className="form-control"
                placeholder="Shop Image"
                onChange={handleImageChange}
                style={{height:"7px",marginBottom:"10px",border:"1px solid green",borderRadius:"12px"}}
              />
            </div>
            </div>

            <div className="row">
            <div className="col">
            <label class="form-label text-success fw-bolder m-1" style={{fontFamily:"serif"}}>Owner Name</label>
              <input
              type="text"
                name="owner_name"
                className="form-control"
                placeholder="Owner Name"
                value={formData.owner_name}
                onChange={handleInputChange}
                 style={{height:"7px",marginBottom:"10px",border:"1px solid green",borderRadius:"12px"}}
                 required
              />
            </div>
            <div className="col">
            <label class="form-label text-success fw-bolder m-1" style={{fontFamily:"serif"}}>Address</label>
              <input
                type="text"
                name="address"
                className="form-control"
                placeholder="Address"
                value={formData.address} 
                 onChange={handleInputChange} 
                 style={{height:"7px",marginBottom:"10px",border:"1px solid green",borderRadius:"12px"}}
                 required
              />
            </div>
            </div>


            <div className="row">
            <div className="col">
            <label class="form-label text-success fw-bolder m-1" style={{fontFamily:"serif"}}>State</label>
              <input
                type="text"
                name="state"
                className="form-control"
                placeholder="State"
                pattern="[A-Za-z\s]+"
                title="Please enter only letters (no numbers)"
                value={formData.state}
                onChange={handleInputChange} 
                style={{height:"7px",marginBottom:"10px",border:"1px solid green",borderRadius:"12px"}}
                required

              />
            </div>
            <div className="col">
            <label class="form-label text-success fw-bolder m-1" style={{fontFamily:"serif"}}>Country</label>
              <input
                type="text"
                name="country_name"
                className="form-control"
                pattern="[A-Za-z\s]+"
                title="Please enter only letters (no numbers)"
                placeholder="Country"
                value={formData.country_name}
                onChange={handleInputChange}
                style={{height:"7px",marginBottom:"10px",border:"1px solid green",borderRadius:"12px"}}
                required
              />
            </div>
            </div>
            <div className="row">
            <div className="col">
            <label class="form-label text-success fw-bolder m-1" style={{fontFamily:"serif"}}>Currency</label>
            <Select
    options={currencyOptions}
      placeholder="Select Currency"
      isSearchable={true}
      value={selectedCurrency}
      onChange={handleCurrencyChange}
      styles={{
        // Styles for increasing width and height
        control: (provided, state) => ({
          ...provided,
          maxHeight: '7px',
          borderRadius: '12px', 
          border: '1px solid green'
        }),
      }}
  />
            </div>
            <div className="col" style={{marginTop:"15px"}}>
            <label for="formFile" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Shop Type</label>
            <br/>
              <div class="form-check" style={{display:"inline-block"}}>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="foodShop"
          checked={formData.shop_type === 1}
          onChange={handleRadioChange} required/>
                <label class="form-check-label" for="flexRadioDefault1">
                  Food Shop
                </label>
              </div>
              <div class="form-check" style={{display:"inline-block", marginLeft:"70px"}}>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  value="serviceBooking"
          checked={formData.shop_type === 2}
          onChange={handleRadioChange} required/>
                <label class="form-check-label" for="flexRadioDefault2">
                  Sevice Booking
                </label>
              </div>
           
            </div>
            </div>
            <br/>
        <br/>
            <div className="form-group">
              <button className="btn btn-success" type="submit" style={{float:"right"}}>
                Create Shop
              </button>
            </div>
            </form>
          </div>
       
        </div>
    );
}

export default Shop;