import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from '../constant';
import "./admin_dashboard.css";
import { Link, useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {toast} from 'react-toastify';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import "./admin_employeemaster.css"
import Pagination from 'react-js-pagination';

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ searchQuery,setSearchQuery] = useState('');
    const [ cashId,setCashId] = useState(null);
    const [ payment_Status,setPayment_Status] = useState(null);
    const [ historyId,setHistoryId] = useState(null);
    const [showPaymentModel, setShowPaymentModel] =  useState(false);
    const [showConfirmationModel, setShowConfirmationModel] =  useState(false);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [ paymentHistories, setPaymentHistories] = useState([]);
    const [selected, setSelected] = useState(null);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [totalItems, setTotalItems] = useState(0);


    useEffect(() => {
      const superadminID = localStorage.getItem('superAdminId')
     if(superadminID){
          fetchData();
     } else{
      history.push('/admin');
     }
        }, [currentPage, searchQuery]);

    //get order details for particular group Location order
    const fetchData = async () => {
        setLoading(true);
        try {
         const response = await axios.get(`${var_api}/api/user/get-admin-users` );
         const details = response.data;

         setUsers(details);
         console.log("order details",details);
         setLoading(false);
       }catch (error) {
         console.log('Error fetching data:', error);
         setUsers([]);
         }
       };


        //get order details for particular group Location order
    const fetchPaymentDetails = async (id) => {
      try {
       const response = await axios.get(`${var_api}/api/cash-subscription-details/payment-history-data/${id}` );
       const details = response.data;

       setPaymentHistories(details);
       console.log("order details",details);
      
     }catch (error) {
       console.log('Error fetching data:', error);
       setPaymentHistories([]);
       }
     };
    

     const handleSearchInput = (e) => {
      setSearchQuery(e.target.value);
      setCurrentPage(1); // Reset currentPage when search query changes
    };


         //search the transaction data
      const filteredUsers = users.filter((user) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          (user.user.name && user.user.name.toString().toLowerCase().includes(searchLower)) ||
          (user.user.email && user.user.email.toString().toLowerCase().includes(searchLower)) ||
          (user.user.mobile && user.user.mobile.toString().toLowerCase().includes(searchLower)) ||
          (user.user.country && user.user.country.toString().toLowerCase().includes(searchLower))
          
        );
      });

      
     const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredUsers.length);
    const displayedUsers = filteredUsers.slice(startIndex, endIndex);


      //redirect to admin dashboard page
      const handleAdminHome = (id) =>{
        history.push('/adminHome');
        localStorage.setItem('userId', id);
        console.log("admiinc", localStorage.getItem('userId'),id);
      }


      //open the payment modal
      const handlePaymentModel = (payment,id) => {
        setPaymentDetails(payment);
        setShowPaymentModel(true);
        setHistoryId(id)
        fetchPaymentDetails(id)
      }

      //close the modal
      const handleClose = () =>{
        setShowPaymentModel(false);
      }

        

      const changeFormat = (dateString) =>{
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
const year = date.getFullYear();

const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
      }

      //open the confirmation modal
      const handleOpenConfirmation = (id,status)=>{
        setShowConfirmationModel(true);
        setCashId(id);
        setPayment_Status(status);
      }

      //close the confirmation modal
      const handleCloseConfirmation = () =>{
        setShowConfirmationModel(false);
      }
      

      //update the manual verification status
      const handleUpdate = () =>{
        const status = payment_Status === 0 ? 1 : 0
        const updatedData = {
          manual_payment_verification : status
        }
        axios
        .put(`${var_api}/api/cash-subscription-details/edit-payment-verification/${cashId}`, updatedData)
        .then((response) => {
          toast('Data updated successfully',{autoClose:2000})
          console.log('Data updated successfully:', response.data.message);
          fetchPaymentDetails(historyId)
          setShowConfirmationModel(false);
          
          // Handle success, e.g., show a success message or update the UI
        })
        .catch((error) => {
          console.error('Error updating data:', error);
          // Handle error, e.g., show an error message or update the UI accordingly
        });
      }

      const handleCloselogoutConfirmation = () => setShow(false);
      const handleShowConfirmation = () => setShow(true);
      const handleModLogout = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('shopId');
        localStorage.removeItem('shopName');
        localStorage.removeItem('superAdminId');
        const place = localStorage.getItem('userId');
        console.log("id", place)
        if (place === null) {
          history.push('/signin');
        }
      };
      

    const handleSelect = (selectedItem) => {
        setSelected(selectedItem);
        switch (selectedItem) {
            case 'currency':
                // Navigate to the home page
                history.push('/currency-master');
                break;
            case 'credential':
                // Navigate to the payment credential page
                history.push('/payment-credentials');
                break;
            case 'location':
                // Navigate to the payment location page
                  history.push('/location-credentials');
                  break;
            case 'subscription':
                history.push('/Subscription-plans');
                break;
            case 'paymode':
                history.push('/pay-mode');
                break;
            default:
                break;
        }
    };


   
    return(
        <>
        <div style={{backgroundColor:"rgb(251, 251, 251)",height:"50rem"}}>

        <SideNav
    onSelect={handleSelect}

    style={{backgroundColor:"#000"}}
>
    <SideNav.Toggle />
    <SideNav.Nav >
        <NavItem eventKey="currency">
            <NavIcon>
                <i className="bi bi-currency-exchange" style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText style={{ fontSize: '1.10em',fontFamily:"serif",fontWeight:"bolder" }}>
                CURRENCY MASTER
            </NavText>
        </NavItem>
        <NavItem eventKey="credential">
            <NavIcon>
                <i className="bi bi-cash-coin" style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText style={{ fontSize: '1.05em',fontFamily:"serif",fontWeight:"bolder" }}>
                PAYMENT CREDENTIAL
            </NavText>
            {/* <NavItem eventKey="charts/linechart">
                <NavText>
                    Line Chart
                </NavText>
            </NavItem>
            <NavItem eventKey="charts/barchart">
                <NavText>
                    Bar Chart
                </NavText>
            </NavItem> */}
        </NavItem>
        <NavItem eventKey="location">
            <NavIcon>
            <i class="bi bi-geo-alt-fill" style={{ fontSize: '1.75em' }}></i>
            </NavIcon>
            <NavText style={{ fontSize:'1.03em',fontFamily:"serif",fontWeight:"bolder" }}>
              LOCATION CREDENTIAL
            </NavText>
        </NavItem>
        <NavItem eventKey="subscription">
            <NavIcon>
            <i class="bi bi-collection-play" style={{ fontSize: '1.75em' }}></i>
            </NavIcon>
            <NavText style={{ fontSize:'1.03em',fontFamily:"serif",fontWeight:"bolder" }}>
              SUBSCRIPTION PLANS
            </NavText>
        </NavItem>
        <NavItem eventKey="paymode">
            <NavIcon>
            <i class="bi bi-credit-card" style={{ fontSize: '1.75em' }}></i>
            </NavIcon>
            <NavText style={{ fontSize:'1.03em',fontFamily:"serif",fontWeight:"bolder" }}>
              PAYMODE MASTER
            </NavText>
        </NavItem>
    </SideNav.Nav>
</SideNav>


        <div class="card" style={{margin: "0px 20px 20px 20px",position: "fixed",left:"30px",zIndex:"2"}}>
  <div class="card-body">
    <h4 class="card-title" style={{margin:"10px",fontFamily:"initial",fontWeight:"bolder"}}>SG BILLER SUPER ADMIN - DASHBOARD</h4>
    
  </div>
  <button className="btn btn-outline-danger" style={{position:"fixed",marginLeft:"1350px", marginTop:"15px",}}  onClick={handleShowConfirmation}>Logout</button>
</div>
<br/>
<br/>
<div class="card" style={{width:"89rem",height:"38rem",left:"40px",zIndex:"1"}}>
  <div class="card-body" style={{ overflowX: 'auto' }}>
  <input class="form-control m-3" type="Search" placeholder="Search" id="example-search-input" onChange={handleSearchInput}  value={searchQuery} 
            style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} />
            <br/>
            <br/>
           
                <table class="table  table-hover table-light table-responsive">
                    <thead>
                        <tr class="table-light text-secondary">
                            <th scope="col">S.No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Country</th>
                            <th scope='col'>Subscription Status</th>
                            <th scope='col'>Action</th>
                        </tr>
                    </thead>
                    {loading ? (
        <div className="loader-containeru">
      	  <div className="spinneru"></div>
        </div>
  ) : (
                    <tbody>
                        {displayedUsers.length > 0 ? (
                            displayedUsers.map((user,index)=>(
                        <tr tr key={index} style={{backgroundColor:"white"}}>
                            <th scope="row" style={{backgroundColor:"white"}}>{startIndex + index + 1}</th>
                            <td style={{backgroundColor:"white"}}>{user.user.name}</td>
                            <td style={{backgroundColor:"white"}}>{user.user.email}</td>
                            <td style={{backgroundColor:"white"}}>{user.user.mobile}</td>
                            <td style={{backgroundColor:"white"}}>{user.user.country}</td>
                            <td style={{backgroundColor:"white"}}>{user.subscriptions[0] && user.subscriptions[0].active_status === 1 ? (
                                <span style={{color:"green",fontWeight:"bolder"}} onClick={()=>handlePaymentModel(user.subscriptions,user.user.id)}>Paid</span>
                            ) :  (
                                <span style={{color:"red",fontWeight:"bolder"}}>Not Paid</span>
                            )}
                            </td>
                            <td style={{backgroundColor:"white"}}> <i class="bi bi-arrow-right-circle-fill p-1" style={{fontSize:"23px",color:"grey"}} onClick={()=>handleAdminHome(user.user.id)}></i></td>
                        </tr>
                         ))
                        ):(
                            <tr>
                            <td colSpan="12" style={{ textAlign: "center" }}>
                              No Data Available
                            </td>
                          </tr>   
                        )
                        }
                    </tbody>
  )}
                </table>
                {/* Pagination component */}
                <div className="pagination-container">
                <Pagination
  activePage={currentPage}
  itemsCountPerPage={itemsPerPage}
  totalItemsCount={filteredUsers.length}
  pageRangeDisplayed={2}
  onChange={handlePageChange}
  itemClass="page-item"
  linkClass="page-link"
  prevPageText={<i className="bi bi-chevron-left"></i>}
  nextPageText={<i className="bi bi-chevron-right"></i>}
  innerClass="pagination justify-content-center"
  activeClass="active-pagination" // Custom class for the active pagination item
/>

</div>





               
  </div>
  </div> 

   <Modal
        show={showPaymentModel}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl" // Set size to large
      >
        <Modal.Header closeButton>
          <Modal.Title> Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="card text-success border-success m-auto" style={{maxWidth: "35rem",height:"15rem",opacity:"0.9",borderWidth:"5px",borderRadius:"18px",boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
  <div class="card-header text-light bg-success">ACTIVE PLAN DETAILS</div>
  <div class="card-body">
    <h5 class="card-title" style={{textTransform:"capitalize",fontFamily:"-moz-initial",fontSize:"27px",textDecoration:"underline"}}>{paymentDetails[0] && paymentDetails[0].subscription_plan_name}</h5>
    <div class="row">
        <div class="col">
            <p class="mb-0 text-dark">Transaction Id: <span className='text-success' style={{fontSize:"18px",fontWeight:"bolder",fontFamily:"monospace"}}>{paymentDetails[0] && paymentDetails[0].transaction_id}</span></p>
        </div>
        <div class="col">
        <p class="mb-0 text-dark">Transaction Person Name: <span className='text-success' style={{fontSize:"18px",fontWeight:"bolder",fontFamily:"monospace"}}>{paymentDetails[0] && paymentDetails[0].transaction_person_name}</span></p>
        </div>
    </div>
    <div class="row">
        <div class="col">
        <p class="mb-0 text-dark">Start Date: <span className='text-success' style={{fontSize:"18px",fontWeight:"bolder",fontFamily:"monospace"}}>{changeFormat(paymentDetails[0] && paymentDetails[0].start_date)}</span></p>
        </div>
        <div class="col">
        <p class="mb-0 text-dark">Ends Date: <span className='text-success' style={{fontSize:"18px",fontWeight:"bolder",fontFamily:"monospace"}}>{changeFormat(paymentDetails[0] && paymentDetails[0].ends_date)}</span></p> 
        </div>
    </div>
    <div class="row">
        <div class="col">
        <p class="mb-0 text-dark">Days to Expire: <span className='text-success' style={{fontSize:"18px",fontWeight:"bolder",fontFamily:"monospace"}}>{paymentDetails[0] && paymentDetails[0].date_count} days</span></p>
        </div>
        <div class="col">
            <p class="mb-0 text-dark">Active Status: {paymentDetails[0] && paymentDetails[0].active_status === 0 ? (
                <span className="badge bg-danger" style={{textTransform:"uppercase"}}>Inactive</span>
            ) : (
                <span className="badge bg-success" style={{textTransform:"uppercase"}}>Active</span>
            )}</p>
        </div>
    </div>
    
  </div>
</div>
<br/>
<br/>

        <table class="table">
          <caption style={{captionSide:"top"}}>Payment History</caption>
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Payment Id</th>
      <th scope="col">Person Name</th>
      <th scope="col">Plan Name</th>
      <th scope="col">Plan Price</th>
      <th scope="col">Start Date</th>
      <th scope="col">Ends Date</th>
      <th scope="col">Days to Expire</th>
      <th scope="col">Active Status</th>
      <th scope="col">Manual Verify</th>
    </tr>
  </thead>
  <tbody>
    {paymentHistories.map((detail,index)=>(
    <tr>
      <th scope="row">{index+1}</th>
      <td>{detail.transaction_id}</td>
      <td style={{textTransform:"capitalize"}}>{detail.transaction_person_name}</td>
      <td style={{textTransform:"capitalize"}}>{detail.subscription_plan_name}({detail.plan_duration_days})</td>
      <td style={{textTransform:"capitalize"}}>{detail.final_price}</td>
      <td>{changeFormat(detail.start_date)}</td>
      <td>{changeFormat(detail.ends_date)}</td>
      <td>{detail.date_count} days</td>
      <td>{detail.active_status === 0 ? (<span className='text-danger' style={{fontWeight:"bolder"}}>Not Active</span>) : (<span className='text-success' style={{fontWeight:"bolder"}}>Active</span>)}</td>
      <td>{detail.manual_payment_verification === 1 ?
      (<Button variant="success" onClick={()=>handleOpenConfirmation(detail.id,detail.manual_payment_verification)} disabled={detail.date_count<=0 && detail.active_status === 0}>Approved</Button>):
      (<Button variant="danger" onClick={()=>handleOpenConfirmation(detail.id,detail.manual_payment_verification)}>Rejected</Button>)}</td>
    </tr>
    ))}
    </tbody>
    </table>
       
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> 


       <Modal
        show={showConfirmationModel}
        onHide={handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="sm" // Set size to large
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

          <h4 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to {payment_Status === 0 ? "Approve" : "Reject"} ?</h4> 
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right"}} onClick={handleUpdate}>
            Confirm
          </Button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>  

      <Modal
      show={show}
      onHide={handleCloselogoutConfirmation}
      backdrop="static"
      keyboard={false}
      size="md" // Set size to large
      style={{ borderRadius: '20px' }} // Add border radius style
    >
      <Modal.Header closeButton style={{ backgroundColor: "rgba(6, 105, 16, 0.367)", padding: "22px" }}>
        {/* <Modal.Title>confirmation</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px", backgroundColor: "white" }}>
          <h4 style={{ fontFamily: "monospace", fontWeight: "bolder", textAlign: "center" }}>CONFIRM!</h4>
          <h6 className='text-dark' style={{ textAlign: "center", fontWeight: "bolder" }}>Are You Sure You want to Logout ?</h6>
        </div>
        {' '}
        <Button variant="danger" style={{ float: "right" }} onClick={handleCloselogoutConfirmation}>
          Close
        </Button>{' '}
        <Button variant="success" style={{ float: "right", marginRight: "5px" }} onClick={handleModLogout}>
          Confirm
        </Button>
      </Modal.Body>
    </Modal>
        </div>
        </>
    )

}


export default AdminDashboard