import React, {useState, useEffect}  from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Loader from "../Loader/Loader";
import { var_api } from "../constant";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter } from "reactstrap";
import Pagination from 'react-js-pagination';
import Swal from 'sweetalert2';


toast.configure()


const DayClosing = () => {
    const history = useHistory();
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [isLoading, setIsLoading] = useState(false);
    const shop_id = localStorage.getItem("shopId");
    const shopCurrency = localStorage.getItem('shopCurrency');
    const empID = localStorage.getItem('admin_employee');
    const [previousDate, setPreviousDate] = useState("");
    const [previousDetails, setPreviousDetails] = useState(null);
    const [totalPayout, setTotalPayout ] = useState(0);
    const [paymode, setPaymode] = useState(null);
    const [cashSales, setCashSales] = useState(null);
    const [totalSales, setTotalSales] = useState(null);
    const [totalCash, setTotalCash] = useState(null);
    const [closingAdjustment, setClosingAdjustment] = useState(null);
    const [closingAdjustmentRemark, setClosingAdjustmentRemark] = useState('');
    const [DailyHistory, setDailyHistory] = useState([]);
    const [previousClosingAmount, setPreviousClosingAmount] = useState(0);
    const [isEditClosingAmount, setIsEditClosingAmount] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [todayClosingDetails, setTodayClosingDetails] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [existingStatus, setExistingStatus] = useState(null);
    const itemsPerPage = 25;
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({ account_no: '', account_name: '' });

      //Go to home page
      const handleButtonClick = () => {
        history.push('/home')
      }


      useEffect(() => {
        // Calculate the previous date and set it to previousDate state
        const currentDate = new Date(date);
        currentDate.setDate(currentDate.getDate() - 1);
        const prevDay = currentDate.getDate().toString().padStart(2, '0');
        const prevMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const prevYear = currentDate.getFullYear();
        const previousDateString = `${prevDay}-${prevMonth}-${prevYear}`;
        setPreviousDate(previousDateString);
    
        // Fetch data for the previous date
        FetchClosingAmount(previousDateString);
        fetchPayOut();
        fetchPaymode();
        checkPreviousData(previousDateString);
        FetchDailyHistory();
        
      }, [date]); 


      useEffect(() => {
        FetchDailyHistory();
        fetchAccounts();
      }, []); 

      const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${var_api}/api/gl-accounts-master/get-accounts/${shop_id}`);
            setAccounts(response.data);
        } catch (error) {
            console.error('Error fetching stock adjustments:', error);
        }
    };



      const checkPreviousData = async (closing_date) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`${var_api}/api/day-closing/get-previous/${shop_id}/${closing_date}`);
          const status = response.data.existing;
          console.log('existing', status, response.data.result);
          setExistingStatus(status);
          if (status){
            console.log("exist", status);
            return null;
          }else{
            console.log("exist", status)
            toast(`You did not close ${closing_date} !`)
          }
        } catch (error) {
          console.error('Error fetching suppliers:', error);
        }
        finally{
          setIsLoading(false);
        }
      };


      const FetchClosingAmount = async (closing_date) => {
        setIsLoading(true);
        try {
          const response = await axios.get(`${var_api}/api/day-closing/get/${shop_id}/${closing_date}`);
          const Details = response.data;
          if (Details.length !=0){
            setPreviousDetails(Details)
            const PreviousClosing = Details.final_cash_amount + Details.cash_opening_balance + Details.closing_adjustment_amount;
            setPreviousClosingAmount(PreviousClosing);

            console.log("po",PreviousClosing, Details);
          }
          else{
            setPreviousClosingAmount(0);
          }
        } catch (error) {
          console.error('Error fetching suppliers:', error);
        }
        finally{
          setIsLoading(false);
        }
      };


      const FetchDailyHistory = async () => {
        setIsLoading(true);
        const startIndexCalc = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndexCalc + itemsPerPage;

         // Function to format date as dd-mm-yyyy
  // const formatDate = (date) => {
  //   const d = new Date(date);
  //   let day = d.getDate();
  //   let month = d.getMonth() + 1; // Months are zero indexed
  //   const year = d.getFullYear();

  //   if (day < 10) day = '0' + day;
  //   if (month < 10) month = '0' + month;

  //   return `${day}-${month}-${year}`;
  // };

  // const todayFormatted = formatDate(new Date());
        try {
          const response = await axios.get(`${var_api}/api/day-closing/getAll/${shop_id}`);
          const Details = response.data;
          setDailyHistory(Details.slice(startIndexCalc, endIndex));
          setTotalItems(Details.length);
          setStartIndex(startIndexCalc);
          // Filter the details to only include today's date
    const todayDetails = Details.filter(item => item.closing_date === changeFormat(date));
    setTodayClosingDetails(todayDetails);
          console.log("po",Details, todayDetails);
        } catch (error) {
          console.error('Error fetching daily histroy:', error);
          setDailyHistory([]);
        setTotalItems(0);
        setStartIndex(0);
        }
        finally{
          setIsLoading(false);
        }
      };

        //onchange for pagination
        const handlePageChange = (pageNumber) => {
          setCurrentPage(pageNumber);
      };
  

      const changeFormat = (dateString) =>{
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
const year = date.getFullYear();

const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
      }


      const fetchPayOut = async () => {
        const fromattedDate= changeFormat(date)
        try {
            const response = await axios.get(`${var_api}/api/settlements/get-total-settlement/${shop_id}/${fromattedDate}`);
            console.log("response", response);
            const user = response.data.amount;
            console.log("response u", user);
            setTotalPayout(user);
        } catch (error) {
            console.log(error);
        }
    };
    


      const handleDateChange = (e) => {
        const newDate = e.target.value;
        setDate(newDate);
        console.log("date", e.target.value);
      };



      const fetchPaymode = async () => {
        setIsLoading(true); // Set isLoading to true when fetching data
        const fromattedDate= changeFormat(date)
        try {
            const shopid = localStorage.getItem('shopId');
            const response = await axios.get(`${var_api}/api/food/get-paymode-summary/${shopid}/${fromattedDate}`);
            console.log("responsettoal", response);
            const paymodes = response.data;
            setPaymode(paymodes);

            const filteredPayModes = paymodes.filter(payMode => payMode.payMode.toUpperCase() === 'CASH');
            console.log("ff",filteredPayModes);
           setCashSales(filteredPayModes[0].total_Amount)

       // Calculate total amount including all payments
    const totalAmount = paymodes.reduce((total, payment) => {
        return total + payment.total_Amount;
      }, 0);
        setTotalSales(totalAmount); 
        const totalCash =  filteredPayModes[0].total_Amount - totalPayout;
        setTotalCash(totalCash);
            setIsLoading(false); // Set isLoading to false after fetching data
        } catch (error) {
            console.log(error);
            setIsLoading(false); // Set isLoading to false if there's an error
        }
    };


     // Calculate total cash value only if previousDetails is available
  const finalCashAmount = previousDetails ? parseFloat(previousDetails.final_cash_amount) || 0 : 0;
  const cashOpeningBalance = previousDetails ? parseFloat(previousDetails.cash_opening_balance) || 0 : 0;
  const closingAdjustmentAmount = parseFloat(closingAdjustment) || 0;

  const totalClosingAmount = finalCashAmount + cashOpeningBalance + closingAdjustmentAmount;


  const handleClosingToday = async(e) => {
    e.preventDefault();
    setIsLoading(true);

    const cash_opening_balances = previousClosingAmount ? previousClosingAmount  : 0;

      // Validation for closing adjustment remark
      if (closingAdjustment > 0 && !closingAdjustmentRemark.trim()) {
        setIsLoading(false);
        toast.error('Closing Adjustment Remark is required when Closing Adjustment is greater than 0.');
        return;
    }


    if (!existingStatus && cash_opening_balances == 0){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `You did not closing date ${previousDate}!`,
      });
    }

     // Validation for closing adjustment remark
     if (cash_opening_balances == 0) {
      setIsLoading(false);
      toast.error('Today OPening Amount is should be greater than 0');
      return;
  }
  
    try {
      // Make the API request
      const response = await axios.post(`${var_api}/api/day-closing/post`, {
        shop_id: shop_id,
        closing_date: changeFormat(date),
        cash_opening_balance: cash_opening_balances,
        cash_total: cashSales ? parseFloat(cashSales) : 0,
        total_payout: totalPayout ? parseFloat(totalPayout) : 0,
        final_cash_amount: totalCash ? parseFloat(totalCash) : 0,
        overall_total: totalSales ? parseFloat(totalSales) : 0,
        closing_adjustment_amount: parseFloat(closingAdjustment) || 0,
        closing_adjustment_remark: closingAdjustmentRemark,
        is_closed: 1,
      });

      // Handle successful response
      console.log(response.data);
      toast('Day closing entry created successfully!');
      await handleGlSubmit();
      await FetchDailyHistory();
      setIsClosing(false);
      setIsEditClosingAmount(false);
      setClosingAdjustmentRemark("");
      setClosingAdjustment('');
    } catch (error) {
      // Handle error response
      console.error('Error creating day closing entry:', error);
    } finally {
        setIsLoading(false);
    }
  };


  const handleClosingTodayUpdate = async(e) => {
    e.preventDefault();
    setIsLoading(true);

    const cash_opening_balances = previousClosingAmount ? previousClosingAmount  : 0;

      // Validation for closing adjustment remark
      if (closingAdjustment > 0 && !closingAdjustmentRemark.trim()) {
        setIsLoading(false);
        toast.error('Closing Adjustment Remark is required when Closing Adjustment is greater than 0.');
        return;
    }

    // Validation for closing adjustment remark
    if (cash_opening_balances == 0) {
      setIsLoading(false);
      toast.error('Today OPening Amount is should be greater than 0');
      return;
  }
  
    try {
      // Make the API request
      const response = await axios.put(`${var_api}/api/day-closing/put/${todayClosingDetails[0].id}`, {
        shop_id: shop_id,
        closing_date: changeFormat(date),
        cash_opening_balance: cash_opening_balances,
        cash_total: cashSales ? parseFloat(cashSales) : 0,
        total_payout: totalPayout ? parseFloat(totalPayout) : 0,
        final_cash_amount: totalCash ? parseFloat(totalCash) : 0,
        overall_total: totalSales ? parseFloat(totalSales) : 0,
        closing_adjustment_amount: parseFloat(closingAdjustment) || 0,
        closing_adjustment_remark: closingAdjustmentRemark,
        is_closed: 1,
      });

      // Handle successful response
      console.log(response.data);
      toast('Day closing Updated successfully!');
      FetchDailyHistory();
      setIsClosing(false);
      setIsEditClosingAmount(false);
      setClosingAdjustmentRemark("");
      setClosingAdjustment('');
    } catch (error) {
      // Handle error response
      console.error('Error creating day closing entry:', error);
    } finally {
        setIsLoading(false);
    }
  };


  const filteredHistory = DailyHistory.filter((list) =>
    list.closing_date.includes(searchTerm) ||
    list.cash_opening_balance.toString().includes(searchTerm) ||
    list.cash_total.toString().includes(searchTerm) ||
    list.total_payout.toString().includes(searchTerm) ||
    list.final_cash_amount.toString().includes(searchTerm) ||
    list.overall_total.toString().includes(searchTerm) ||
    list.closing_adjustment_amount.toString().includes(searchTerm) ||
    (list.closing_adjustment_remark && list.closing_adjustment_remark.toLowerCase().includes(searchTerm.toLowerCase()))
  );


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };



  const formatpDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const formatPostingPeriod = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    return `${year}-${month}`;
  };
  


  const handleGlSubmit = async () => { 
     // Find the selected account from the accounts list
  const selectedIncomeAccount = accounts.find(account => account.account_name.includes("day_closing_income"));
  if (!selectedIncomeAccount) {
    alert('Please select a valid income GL account.');
    return;
  }
  const { account_no: incomeAccountNo, account_name: incomeAccountName } = selectedIncomeAccount; 

  const selectedSettlementAccount = accounts.find(account => account.account_name.includes("day_closing_settlement"));
  if (!selectedSettlementAccount) {
    alert('Please select a valid settlement GL account.');
    return;
  }
  const { account_no: settlementAccountNo, account_name: settlementAccountName } = selectedSettlementAccount; 
    // Get the current date formatted as dd-mm-yyyy
    const currentDate = formatpDate(new Date());
    const postingPeriod = formatPostingPeriod(new Date());
    // Extract relevant fields from adjustmentDetails array and perform calculations
  const extractAndCalculateFields = ( totalPayout, totalSales) => {
    let creditData = {
      gl_account_no: incomeAccountNo,
      account_description: incomeAccountName,
      posting_date: currentDate,
      credit_transaction_amount: totalSales,
      debit_transaction_amount: 0,
      indicator: 'credit',
      document_no: `day_closing_income_${currentDate}`,
      document_type: 'day closing income',
      shop_id: parseInt(shop_id),
      lost_center: 0,
      profit_center: 0,
      currency: shopCurrency,
      reference_no: ' ',
      posting_period: postingPeriod,
      user_id: parseInt(empID),
      remarks: '  ',
      tax_code: '  ',
      business_area: '  '
    };

    let debitData = {
      gl_account_no: settlementAccountNo,
      account_description: settlementAccountName,
      posting_date: currentDate,
      credit_transaction_amount: 0,
      debit_transaction_amount: totalPayout,
      indicator: 'debit',
      document_no: `day_closing_settlement_${currentDate}`,
      document_type: 'day closing settlement',
      shop_id: parseInt(shop_id),
      lost_center: 0,
      profit_center: 0,
      currency: shopCurrency,
      reference_no: ' ',
      posting_period: postingPeriod,
      user_id: parseInt(empID),
      remarks: '  ',
      tax_code: '  ',
      business_area: '  '
    };

    if (totalSales > 0) {
      creditData.credit_transaction_amount = totalSales;
    }
    
    if (totalPayout > 0) {
      debitData.debit_transaction_amount = totalPayout;
    }
    
    // Prepare the final data to be posted
    let dataToPost = [];
    if (creditData.credit_transaction_amount > 0) {
      dataToPost.push(creditData);
    }
    if (debitData.debit_transaction_amount > 0) {
      dataToPost.push(debitData);
    }

    return dataToPost;
  };


  const relevantData = extractAndCalculateFields(totalPayout, totalSales);

  // If relevantData is empty, return without posting
  if (relevantData.length === 0) {
    toast('No valid transactions to post.');
    return;
  } 
    try {
      const response = await axios.post(`${var_api}/api/gl-accounts-posting/post`, relevantData);
      console.log('Accounts posting successful:', response.data);
      toast("Added in GL Acccount")
      // Handle success (e.g., show a success message, reset form, etc.)
    } catch (error) {
      console.error('Error posting accounts:', error);
      // Handle error (e.g., show an error message)
    }
  };



    return(
        <>
        { isLoading && <Loader />}
         <Navbar expand="sm" className="bg-body-tertiary bg-success p-0" style={{padding:"0px"}}>
        <Container className="p-0">
            <Navbar.Brand className="p-0 text-light fw-bolder">DAY CLOSING</Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                 <button className="btn btn-outline-success" onClick={handleButtonClick} style={{backgroundColor:"white",color:"green"}}>
                  <i class="bi bi-house"></i> Go to Home</button>
            </Navbar.Collapse>
       </Container>
    </Navbar>
    <br/>
    <Tabs
      defaultActiveKey="Day Closing"
      id="fill-tab-example"
      className="mb-3 tabclrs"
      fill
    >
      <Tab eventKey="Day Closing" title={<span className="grpushe">DAY CLOSING</span>}>
      <Container className="mt-3">
        <Card style={{maxWidth:"40rem", height:"auto", color:"black"}}>
          <Card.Body>
          <Form className="d-flex align-items-center">
              <Form.Label className="me-2 mb-0">Today</Form.Label>
              <Form.Control
                type="date"
                value={date}
                onChange={handleDateChange}
              />
            </Form>
            <div className="d-flex align-items-center mt-3">
                <h6>Previous Date:</h6>
                <h6>{previousDate}</h6>
            </div>
            <div className="d-flex align-items-center mt-3">
                <h6>Today Opening Balance:{" "} </h6>
                {
                  isEditClosingAmount ? (
                    <Form.Control
                    type="number"
                    value={previousClosingAmount}
                    onChange={(e)=>setPreviousClosingAmount(e.target.value)}
                    style={{maxWidth:"200px", marginLeft:"20px"}}
                  />
                  ) :(
                    <h6>{previousClosingAmount} {" "} 
                    <i class="bi bi-pencil-fill" style={{fontSize:"15px", marginLeft:"10px", color:"blue"}} onClick={()=>setIsEditClosingAmount(true)}></i>
                    </h6>
                  )
                }
               
            </div>
            <hr/>
            <div style={{ width: '450px', height:"auto", border:"none",margin:"auto"}}>
            <div >
              
                <h6 style={{fontFamily:"serif",color:"black",fontWeight:"normal"}}> Total Only Cash: <span style={{float:"right",fontFamily:"monospace"}}>{cashSales ? cashSales.toFixed(2) : 0}</span></h6>
                <h6 style={{fontFamily:"serif",color:"black",fontWeight:"normal"}}> Overall Order Total: <span style={{float:"right",fontFamily:"monospace"}}>{totalSales ? totalSales.toFixed(2) : 0}</span></h6>
                <h6 style={{fontFamily:"serif",color:"black",fontWeight:"normal"}}> Total Payout: <span style={{float:"right",fontFamily:"monospace"}}>{totalPayout ? totalPayout.toFixed(2) : 0}</span></h6>
                <h6 style={{fontFamily:"serif",color:"black",fontWeight:"normal"}}> Total Cash In Hand <span style={{fontSize:"13px", color:"red"}}>(Total Only Cash - Total Payout)</span>: <span style={{float:"right",fontFamily:"monospace"}}>{totalCash ? totalCash.toFixed(2) : 0}</span></h6>
                <hr/>
                <h6 style={{fontFamily:"serif",color:"black",fontWeight:"bolder"}}> Today Closing <span style={{fontSize:"13px", color:"red", fontWeight:"normal"}}>(Total Cash In Hand + Today Opening Balance)</span>: <span style={{float:"right",fontFamily:"monospace"}}>{(totalCash + parseFloat(previousClosingAmount)).toFixed(2)}</span></h6>
                <Form className="d-flex align-items-center g-2">
              <Form.Label className="me-2 mb-0">Closing Adjustment </Form.Label>
              <Form.Control
                type="number"
                value={closingAdjustment}
                onChange={(e)=>setClosingAdjustment(e.target.value)}
              />
              {
                closingAdjustment > 0 ? (
                    <>
                    <Form.Label className="me-2 mb-0 ml-1">Remark</Form.Label>
              <Form.Control
                type="text"
                value={closingAdjustmentRemark}
                onChange={(e)=>setClosingAdjustmentRemark(e.target.value)}
              /></>
                ) : null
              }
            </Form>
                
                <h6 style={{fontFamily:"serif",color:"green",marginTop:"20px", fontSize:"20px"}}> Final Closing Amount <span style={{fontSize:"13px", color:"red"}}>(Today Closing + Closing Adjustment)</span>:{" "} <span style={{float:"right",fontFamily:"monospace"}}>{(totalCash  + parseInt(previousClosingAmount) + parseInt(closingAdjustment ? closingAdjustment : 0)).toFixed(2)}</span></h6>
            </div>
            {
              todayClosingDetails && todayClosingDetails[0] && todayClosingDetails[0].is_closed == 1 ?
              <button className="btn btn-warning" style={{float:"right", marginTop:"10px", marginLeft:"10px"}} onClick={handleClosingTodayUpdate}>Update</button> : <button className="btn btn-primary" style={{float:"right", marginTop:"10px"}} onClick={()=>setIsClosing(true)}>Close Today</button>
            }
           
            
        </div>
          </Card.Body>
        </Card>
      </Container>
      </Tab>
      <Tab eventKey="DAY CLOSING HISTORY" title={<span className="grpushe">DAY CLOSING HISTORY</span>}>
        <br/>
      <Card style={{ Width: '100%', height:"auto", margin:"15px", borderRadius:"5px", border:"none", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding:"10px" }}>
      <Card.Body>
      <Form.Group controlId="search">
        <Form.Control
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{maxWidth:"200px", float:"right"}}
        />
      </Form.Group>
      <br/>
      <br/>
      <Table hover variant="light">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Closing Date</th>
              <th>Cash Opening Balance</th>
              <th>Cash Total</th>
              <th>Total Payout</th>
              <th>Final Cash Amount</th>
              <th>Overall Total</th>
              <th>Closing Adjustment Amount</th>
              <th>Closing Adjustment Remark</th>
              <th>Final Closing Amount <br/><span style={{fontSize:"12px", color:"red"}}>(closing adjustment amount + final cash amount)</span></th>
            </tr>
          </thead>
          <tbody>
          {
            filteredHistory.length == 0 ? (
              <tr>
                <td colSpan="11" style={{textAlign:"center", color:"red"}}>No data Available</td>
              </tr>
            ) : (
            filteredHistory.map((list, index) => (
              <tr>
              <td>{index+1}</td>
              <td>{list.closing_date}</td>
              <td>{list.cash_opening_balance}</td>
              <td>{list.cash_total}</td>
              <td>{list.total_payout}</td>
              <td>{list.final_cash_amount}</td>
              <td>{list.overall_total}</td>
              <td>{list.closing_adjustment_amount}</td>
              <td>{list.closing_adjustment_remark ? list.closing_adjustment_remark : "-"}</td>
              <td>{list.cash_opening_balance + list.closing_adjustment_amount + list.final_cash_amount}</td>
            </tr>
            )
            ))
           }
           
            
          </tbody>
        </Table>
        <Pagination
    activePage={currentPage}
    itemsCountPerPage={itemsPerPage}
    totalItemsCount={totalItems} // Update to reflect total items for pagination
    pageRangeDisplayed={5}
    onChange={handlePageChange}
    itemClass="page-item"
    linkClass="page-link"
    innerClass="pagination justify-content-end"
    activeClass="active-pagination" // Custom class for the active pagination item
/>
        </Card.Body>
        </Card>
      </Tab>
    </Tabs>


    <Modal show={isClosing} onHide={()=>setIsClosing(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <h6>Are you sure you want to closing the day <span style={{fontSize:"20px", color:"red"}}>{changeFormat(date)}</span></h6>
      </Modal.Body>
      <Modal.Footer>
        
      <Button variant="danger" style={{float:"right", marginRight:"5px"}} onClick={()=>setIsClosing(false)}>Cancel</Button>
      <Button variant="success" style={{float:"right", marginRight:"5px"}} onClick={handleClosingToday}>Confirm</Button>
      </Modal.Footer>
    </Modal>

   
        </>
    )
}


export default DayClosing;