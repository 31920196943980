import React,{useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import "./admin_signin.css";
import pic1 from '../../images/admin_login1.avif';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from '../constant';
import Spinner from 'react-bootstrap/Spinner';
import shop_logo from "../../images/splash/shop_logo.jpeg";
import { Card, CardImg, CardTitle, CardText, CardColumns,
  CardSubtitle, CardBody, Tooltip } from 'reactstrap';


const AdminSignin = () => {
    const [email, setEmail] = useState(" ");
    const [password, setPassword] = useState("");
    const history = useHistory(); 
  const [message, setMessage] = useState('');
  const [ postloading,setPostLoading ] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);


  const handleSignIn = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setPostLoading(true); 

    try {
      const response = await axios.post(`${var_api}/api/sg-admin/signin`, { email, password }); // Make a POST request to the /signin endpoint
      setMessage(response.data.message); // Update message state with the response message
      console.log(response.data.status,response)
      if(response.status === 200){
        const id = response.data.id; 
        localStorage.setItem('superAdminId', id);
        history.push('/AdminDashboard');
        setPostLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        Swal.fire({
          icon:"error",
          title:"Oops...",
          text:"Invalid Email",
          confirmButtonColor:"#dc3545"
        })
        console.error('Error signing in:', error.response.data.message); // Log any errors
        setMessage(error.response.data.message); // Update message state with the error message
        setPostLoading(false);
      } else {
        console.error('Error signing in:', error); // Log any errors
        setMessage('An error occurred while signing in.'); // Update message state with a generic error message
        setPostLoading(false);
      }
    }
  };


    // const Submit = async (e) => {
    //     e.preventDefault();
      
    //     try {
    //       const response = await axios.post(`${var_api}/api/sg-admin/signin`, {
    //         email: email,
    //         password: password,
    //       });
         
         
         
    //       if (response.status === 200) {
    //         Swal.fire({
    //             icon: 'success',
    //             title: 'success...',
    //             text: 'login successful',
    //             confirmButtonColor: 'rgb(9, 242, 199)',
    //           });
    //           history.push('/AdminDashboard');
    
    //     }
        
    //       console.log('Login successful!');
    //       // Handle success scenario as needed
    //     } catch (error) {
    //       console.error('Error:', error);
    //       if (error.response && error.response.status === 401) {
    //         // Show the Swal alert for a Bad Request (status code 400)
    //         Swal.fire({
    //           icon: 'error',
    //           title: 'Oops...',
    //           text: 'Invalid username or password...',
    //           confirmButtonColor: 'rgb(9, 242, 199)',
    //         });
    //       }
    //       // Handle error scenario as needed
    //     }
    //   };
    const handleGoSuperAdmin = () => {
      history.push('/signin');
    }

    const toggleTooltip = () => {
      setTooltipOpen(!tooltipOpen);
    };
      


   return(
    <div className="superadminsign-up">
       <Tooltip
        isOpen={tooltipOpen}
        target="adminButton" // Specify the target ID of the button
        toggle={toggleTooltip}
      >
        Admin
      </Tooltip>
    <button className="btn btn-danger m-2" id="adminButton" onClick={()=>{handleGoSuperAdmin(); toggleTooltip();}}><i class="bi bi-person"></i></button>
      <br/>
      <Card style={{width:"27rem",height:"35rem",padding:"10px",borderRadius:"25px",border:"none", display: "flex", justifyContent: "center", alignItems: "center",margin:"auto" }}>
    <CardBody>
      <CardTitle style={{textAlign:"center"}}> 
      <Link to="/" className="navbar-brand">
      <img src={shop_logo} alt="logo" style={{margin:"auto", width:"100px",height:"100px",borderRadius:"50px"}} />
    </Link>
      </CardTitle>
      <CardSubtitle>
   
  <div className="container" style={{width:"350px",margin:"auto"}}>
  <div className="option text-center my-3">
              <p>
      <span className="badge rounded-pill bg-danger p-2" style ={{fontWeight:"normal", fontWeight:"bold",fontSize:"18px",width:"300px", height:"35px"}}>
      SG BILLER SUPER ADMIN
      </span></p>
    </div>
      <form  className="py-2"  onSubmit={handleSignIn}>
        <h6 className="lead text-left py-2" style={{fontSize:"14px", fontWeight:"bold", fontFamily:"sans-serif"}}>Login</h6>
       
        <div className="form-group">
        <label class="form-label text-danger fw-bold m-0" style={{fontFamily:"serif"}}>Email</label>
          <input
          type="email"
            name="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            style={{height:"10px",borderRadius:"20px"}}
            required
          />
          
        </div>
         <br/>

         <div className="form-group">
        <label class="form-label text-danger fw-bold m-0" style={{fontFamily:"serif"}}>Password</label>
         <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                style={{height:"7px",borderRadius:"20px 0px 0px 20px"}}
                required
              />
                <button
          className="btn btn-outline-secondary"
          type="button"  style={{ zIndex: 100, border:"none",backgroundColor:"whitesmoke",borderRadius:"0px 20px 20px 0px", height:"50px"}}
          onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state on button click
        >
          {showPassword ? <i class="bi bi-eye-slash-fill" style={{fontSize:"18px", color:"black"}}></i> : <i class="bi bi-eye-fill" style={{fontSize:"18px", color:"black"}}></i>  } {/* Show eye icon based on showPassword state */}
        </button>
        </div></div>
        {/* <a href="/forgot-password" class="float-right text-danger m-1" style={{ textDecoration:"none", float:"right", fontSize:"13px", fontWeight:"bolder"}}>Forgot Password?</a> */}
        <br/>
        <br/>
        <div className="d-grid">
              
                {postloading ? (
                  <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) : <button className="btn btn-danger btn-block" type="submit">Login </button>}
                
             
            </div>
       
      </form>
    
  </div>
  </CardSubtitle>
     
     </CardBody>
   </Card>
</div>
    
    // <div className="signin-container">
    //     <div className='signinbox'>
    //         <div className="left-section">
            
    //         </div>
    //         <div className="right-section">
    //         <div style={{width:"350px",margin:"auto",marginTop:"100px"}}>
    //             <h2 style={{textAlign:"center",fontFamily:"serif",fontWeight:"bolder"}}>Login</h2>
    //             <form className="py-2" onSubmit={handleSignIn} >
    //                 <div className="form-group">
    //                     <label htmlFor="username" style={{color:"rgb(9, 242, 199)",fontWeight:"bold",padding:"5px",fontSize:"20px"}}>Email</label>
    //                     <input
    //           type="email"
    //             name="email"
    //             className="form-control"
    //             value={email}
    //             onChange={(e) => setEmail(e.target.value)}
    //             placeholder="Email"
    //             style={{height:"10px"}}
    //             required
    //           />
    //                 </div>
    //                 <br/>
    //                 <div className="form-group">
    //                     <label htmlFor="password" style={{color:"rgb(9, 242, 199)",fontWeight:"bold",padding:"5px",fontSize:"20px"}}>Password</label>
    //                     <input
    //             type="password"
    //             name="password"
    //             className="form-control"
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //             placeholder="Password"
    //             style={{height:"10px"}}
    //             required
    //           />
    //                 </div>
    //                 <br/>
    //                 <div className="d-grid">
    //           <button className="btn btn-success btn-block" style={{backgroundColor:"rgb(9, 242, 199)",borderColor:"rgb(9, 242, 199)"}} type="submit" disabled={postloading}>
    //           {postloading ? (
    //       <Spinner
    //         as="span"
    //         animation="border"
    //         size="sm"
    //         role="status"
    //         aria-hidden="true"
    //       />
    //     ) : (
    //       'Login'
    //     )}
    //           </button>
    //         </div>
    //             </form>
    //             {message && <p>{message}</p>}
    //         </div>
    //         </div>

    //     </div>
   
    // </div>

    )

    }

 export default AdminSignin;
