import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import { db } from '../../firebase';
import { updateDoc, doc, getDoc ,setDoc} from "firebase/firestore";
import axios from 'axios';
import logo from '../../images/splash/shop_logo.jpeg';
import { var_api } from '../constant';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()


class SbBill extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      updatedQuantities: {}, // State to hold updated quantities for food items
      total: null,
      showAdd:false,
      categoryName:[],
      food_name:[],
      SelectedCate:"",
      selectedItem:"",
      cateCodeSelect:"",
      unitPrice:null,
      foodCodeSelect:"",
      shopDetails:null,
      transId:null,
      totalItems:0,

    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

   // Handle input change for food quantities and update the state
   handleQuantityChange = (foodItemId, quantity,transId) => {
    this.setState((prevState) => ({
      updatedQuantities: {
        ...prevState.updatedQuantities,
        [foodItemId]: quantity,
      },

    }));

    this.setState({ transId : transId})
    console.log("customer setstate trans id", this.state.transId)
  };

  
  updateFood = (orderId,cust_id) => {
    const { updatedQuantities,transId } = this.state;
   
    console.log("updatedQuantities",updatedQuantities)
    const shopName = localStorage.getItem('shopName');
    const orderRef = doc(db, `shops/${shopName}/orders`, orderId);
    let itemsToDelete = []; // Declare itemsToDelete at a higher scope
  
    // Fetch the order document from Firestore
    getDoc(orderRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const orderData = docSnapshot.data();
          console.log("orderData",orderData);

  
          // Calculate the updated quantities and totals
          const updatedOrderItems = orderData.order_items.map((item, index) => {
            const updatedQuantity = updatedQuantities[index] 
          // Use updatedQuantity if it's defined, otherwise, fall back to item.quantity
         const quantityToUse = updatedQuantity !== undefined ? updatedQuantity : item.quantity;
            const updatedSubtotal = quantityToUse * item.unit_price;
    
 

            console.log("updatedQuantity",updatedQuantity);
            console.log("updatedSubtotal",updatedSubtotal);
  
            return {
              ...item,
              quantity: quantityToUse,
              subtotal: updatedSubtotal,
            };
          });

          // Create a new array without elements with quantity 0
      const updatedOrderItemsWithoutZeroQuantity = updatedOrderItems.filter(
        (item) => item.quantity !== 0
      );


      // Filter items with quantity 0
       itemsToDelete = updatedOrderItems.filter(
        (item) => item.quantity === 0);

      

        console.log("itemsToDelete",itemsToDelete);

       

      console.log(
        "updatedOrderItemsWithoutZeroQuantity",
        updatedOrderItemsWithoutZeroQuantity
      );



  
          // Calculate the updated sub-total and total
          const updatedSubTotal = updatedOrderItemsWithoutZeroQuantity.reduce(
            (total, item) => total + item.subtotal,
            0
          );

          

          console.log("updatedSubTotal",updatedSubTotal);
          const updatedTotal = updatedSubTotal + 5; // Assuming service charge of ₹15
          console.log("updatedTotal",updatedTotal);
           this.setState({total:updatedTotal});

          const totalItems = updatedOrderItemsWithoutZeroQuantity.length;
          this.setState({ totalItems: totalItems })
          console.log("updated Order Items Without Zero Quantity",updatedOrderItemsWithoutZeroQuantity);
      


          // Update the entire `order_items`, `sub_total`, and `total` in the Firestore document
          return updateDoc(orderRef, {
            order_items: updatedOrderItemsWithoutZeroQuantity,
            sub_total: updatedSubTotal,
            total: updatedTotal,
            total_items: totalItems,
          })
          .then(() => {
            console.log('Order items updated successfully');
            // Now, you can set the `foodItemsToUpdate` array based on `updatedOrderItems`.
            
            const foodItemsToUpdate = updatedOrderItems.map((item) => ({
              item_code: item.item_code,
              quantity: item.quantity,
            }));
            return foodItemsToUpdate;
          })
          .catch((error) => {
            console.error('Error updating Firestore:', error);
            throw error; // Rethrow the error to handle it below
          });
      } else {
        throw new Error('Order document does not exist');
      }
    })
    .then((foodItemsToUpdate) => {
      const shopID = localStorage.getItem('shopId');
      const { total,totalItems } =  this.state;


   
      // Now you have the `foodItemsToUpdate` array based on Firestore data
      // Make the Axios request with this data
      return axios.put(`${var_api}/api/food/update-food-and-total/${cust_id}/${transId}`, {
        foodItemsToUpdate,
        total:total,
        total_items: totalItems,
      });
    })
    .then((response) => {
      toast('updated successfully',{autoClose:2000})
      this.setState({updatedQuantities: {}})
      console.log('Update successful', response);

      const shopID = localStorage.getItem('shopId');
      console.log("customer id", cust_id)
      console.log("customer trans id", transId)

      if(itemsToDelete.length > 0){
        itemsToDelete.forEach((item) => {
          const item_code = item.item_code; // Access the item_code property
          console.log("item_code:", item_code);
            
 
            
    // Make the Axios DELETE request to delete items with quantity 0
   axios
   .delete(`${var_api}/api/food/delete-items/${cust_id}/${transId}/${item_code}`)
   .then((response) => {
    // Calling toast method by passing string
    toast('Item removed successfully',{autoClose:2000})
     console.log('Items with quantity 0 deleted successfully from the API', response);
     // Handle any further actions after deletion from the API
   })
   .catch((error) => {
     console.error('Error deleting items with quantity 0 from the API:', error);
     // Handle the error as needed
   });

          });
      }
      // Handle any further actions after the update is successful
      this.toggle(); // Close the modal after updating
    })
    .catch((error) => {
      console.error('Error updating food and total:', error);
    });
};


//update and delete for credit order
handleCreditUpdate= (orderId,cust_id,creditId) => {
  const { updatedQuantities,transId } = this.state;
 
  console.log("updatedQuantities",updatedQuantities)
  const shopName = localStorage.getItem('shopName');
  const orderRef = doc(db, `shops/${shopName}/orders`, orderId);
  let itemsToDelete = []; // Declare itemsToDelete at a higher scope

  // Fetch the order document from Firestore
  getDoc(orderRef)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        const orderData = docSnapshot.data();
        console.log("orderData",orderData);
        
        // Calculate the updated quantities and totals
        const updatedOrderItems = orderData.order_items.map((item, index) => {
          const updatedQuantity = updatedQuantities[index] 
        // Use updatedQuantity if it's defined, otherwise, fall back to item.quantity
       const quantityToUse = updatedQuantity !== undefined ? updatedQuantity : item.quantity;
          const updatedSubtotal = quantityToUse * item.unit_price;
  
          console.log("updatedQuantity",updatedQuantity);
          console.log("updatedSubtotal",updatedSubtotal);

          return {
            ...item,
            quantity: quantityToUse,
            subtotal: updatedSubtotal,
          };
        });

        // Create a new array without elements with quantity 0
    const updatedOrderItemsWithoutZeroQuantity = updatedOrderItems.filter(
      (item) => item.quantity !== 0
    );

 // Filter items with quantity 0
     itemsToDelete = updatedOrderItems.filter(
      (item) => item.quantity === 0);
   console.log("itemsToDelete",itemsToDelete);

 console.log(
      "updatedOrderItemsWithoutZeroQuantity",
      updatedOrderItemsWithoutZeroQuantity
    );

// Calculate the updated sub-total and total
        const updatedSubTotal = updatedOrderItemsWithoutZeroQuantity.reduce(
          (total, item) => total + item.subtotal,0);
          console.log("updatedSubTotal",updatedSubTotal);
        const updatedTotal = updatedSubTotal + 5; // Assuming service charge of ₹15
        console.log("updatedTotal",updatedTotal);
         this.setState({total:updatedTotal});

        const totalItems = updatedOrderItemsWithoutZeroQuantity.length;
        this.setState({ totalItems: totalItems })
        console.log("updated Order Items Without Zero Quantity",updatedOrderItemsWithoutZeroQuantity);
    
      // Update the entire `order_items`, `sub_total`, and `total` in the Firestore document
        return updateDoc(orderRef, {
          order_items: updatedOrderItemsWithoutZeroQuantity,
          sub_total: updatedSubTotal,
          total: updatedTotal,
          total_items: totalItems,
        })
        .then(() => {
          console.log('Order items updated successfully');
          // Now, you can set the `foodItemsToUpdate` array based on `updatedOrderItems`.
          
          const foodItemsToUpdate = updatedOrderItems.map((item) => ({
            item_code: item.item_code,
            quantity: item.quantity,
          }));
          return foodItemsToUpdate;
        })
        .catch((error) => {
          console.error('Error updating Firestore:', error);
          throw error; // Rethrow the error to handle it below
        });
    } else {
      throw new Error('Order document does not exist');
    }
  })
  .then((foodItemsToUpdate) => {
    const shopID = localStorage.getItem('shopId');
    const { total,totalItems } =  this.state;

// Now you have the `foodItemsToUpdate` array based on Firestore data
    // Make the Axios request with this data
    return axios.put(`${var_api}/api/creditorder/updateFood-CreditOrder/${cust_id}/${transId}/${creditId}`, {
      foodItemsToUpdate,
      total:total,
      total_items: totalItems,
    });
  })
  .then((response) => {
    toast('updated successfully',{autoClose:2000})
    this.setState({updatedQuantities: {}})
    console.log('Update successful', response);

    const shopID = localStorage.getItem('shopId');
    console.log("customer id", cust_id)
    console.log("customer trans id", transId)

    if(itemsToDelete.length > 0){
      itemsToDelete.forEach((item) => {
        const item_code = item.item_code; // Access the item_code property
        console.log("item_code:", item_code);
          
// Make the Axios DELETE request to delete items with quantity 0
 axios
 .delete(`${var_api}/api/creditorder/delete-creditOrderitems/${cust_id}/${transId}/${item_code}/${creditId}`)
 .then((response) => {
  // Calling toast method by passing string
  toast('Item removed successfully',{autoClose:2000})
   console.log('Items with quantity 0 deleted successfully from the API', response);
   // Handle any further actions after deletion from the API
 })
 .catch((error) => {
   console.error('Error deleting items with quantity 0 from the API:', error);
   // Handle the error as needed
 });
});
    }
    // Handle any further actions after the update is successful
    this.toggle(); // Close the modal after updating
  })
  .catch((error) => {
    console.error('Error updating food and total:', error);
  });
};



//add food Item icon
handleAddItem=()=>{
 this.setState({ showAdd : true })
 this.fetchCategory();
}

//fetch category name
fetchCategory= async () =>{
  const shopId = localStorage.getItem('shopId');
  try {
   const response = await axios.get(`${var_api}/api/categories/api/categories/${shopId}` );
   const cateNames = response.data

 console.log("categories",cateNames)
 this.setState({ categoryName : cateNames})
 }catch (error) {
   console.log('Error fetching data:', error);
   }
}

//select handler for category
 categoryhandler = (e) => {
  const selectedIndex = e.target.selectedIndex;
  const selectedOption = e.target.options[selectedIndex];
  const categoryId = selectedOption.getAttribute('data-id'); // Get the category ID from the "data-id" attribute
 
  this.setState({ SelectedCate: selectedOption.value });
  console.log('Selected Option ID:', categoryId);
  
  const CategoryCode = selectedOption.getAttribute('data-cateCode');
  this.setState({
    cateCodeSelect : CategoryCode
  },
  () => {
    // This callback function will run after the state has been updated
    console.log('Selected code:', this.state.cateCodeSelect);
  })
  
  this.fetchFoodItems(categoryId);

} 


//get the value for select food
ChangeFoodHandler = (e) => {
  const selectedIndex = e.target.selectedIndex;
  console.log("selectedIndex",selectedIndex)
  const selectedOption = e.target.options[selectedIndex]
  console.log("selectedOption",selectedOption)
  this.setState({ selectedItem: selectedOption.value });

  const unitPrice = selectedOption.getAttribute('data-price');
  const ItemCode = selectedOption.getAttribute('data-codeItem');
   this.setState({
    unitPrice : unitPrice,
    foodCodeSelect : ItemCode,
   },
   () => {
    console.log("selectedPrice and code",this.state.unitPrice,this.state.foodCodeSelect);
   })
}


 //list the food items in select
 fetchFoodItems=async(optionId)=>{

  try {
    const response = await axios.get(`${var_api}/api/foodMaster/api/subFood/${optionId}` );
    const use = response.data

  console.log("Items",use)
  this.setState({food_name:use});
   
  }catch (error) {
    console.log('Error fetching data:', error);
    
    }
 
}

//update => add item to the db
handlemoreItem = async (orderId,custID) =>{
  try {
  const {selectedItem, unitPrice, foodCodeSelect, cateCodeSelect} = this.state
  console.log("order id",orderId)
  const shopID = localStorage.getItem('shopId');
  var quantity = document.querySelector('#newQuan').value;
  const shopName = localStorage.getItem('shopName');

  const orderRef = doc(db, `shops/${shopName}/orders`, orderId);

  // Wrap your code in an async function
  const updateOrder = async () => {
    // Retrieve the existing order_items array from Firestore
    const orderDoc = await getDoc(orderRef);

    const orderItems = 
    {
       food_name:selectedItem,
       quantity: parseInt(quantity),
       unit_price: parseFloat(unitPrice),
       item_code: foodCodeSelect,
       category_code:cateCodeSelect,
       trans_id: 0,
       // Add more properties as needed
     };

     console.log("ordered items:",orderItems)

     const existingOrderItems = orderDoc.data().order_items || [];
     const existingItemsLength = existingOrderItems.length;

     // Append the new orderItems to the existing order_items array
     const updatedOrderItems = [...existingOrderItems, orderItems];

    
    // Calculate the total based on unit_price and quantity for each item
    const total = updatedOrderItems.reduce((accumulator, item) => {
      return accumulator + item.quantity * item.unit_price;
    }, 0);

    const grandTOtal = total + 5;

    const totalItems =  existingItemsLength + 1;

       // Update the Firestore document with the modified order_items array and the calculated total
       await updateDoc(orderRef, {
        order_items: updatedOrderItems,
        sub_total: total,
        total: grandTOtal, // Update the total field with the calculated total
        total_items: totalItems, 
      });
  

    const noorderItems = 
    {
       food_name:selectedItem,
       quantity: parseInt(quantity),
       unit_price: parseFloat(unitPrice),
       item_code: foodCodeSelect,
       category_code:cateCodeSelect,
       shop_name:shopName,
      total:grandTOtal,
      total_items:totalItems,
     };

   //to make the POST request
  try {
    const response = await axios.post(`${var_api}/api/food/api/newpost/${custID}`, noorderItems)
   
      if (response.status === 200) {
          const trans_id =  response.data.addTrid;

       // Update the trans_id in the orderItems object
      orderItems.trans_id = trans_id;

       // Update the Firestore document again with the modified order_items array (including the updated trans_id)
       await updateDoc(orderRef, {
        order_items: updatedOrderItems,
        sub_total: total,
        total: grandTOtal,
        total_items: totalItems,
      });
       

        // Clear the selected option by setting selectedItem to an empty string
        this.setState( { SelectedCate: " "})
        this.setState({ selectedItem: ' ' });
        
        // Clear the input field with id 'newQuan' if needed
        document.querySelector('#newQuan').value = '';

  
        // Display a success message (you may want to use a toast or other UI feedback)
        toast('Item added successfully', { autoClose: 2000 });
  
        console.log('POST request successful', response);
        this.toggle(); // Close the modal after updating
      }
      // toast('Item added successfully',{autoClose:2000})
      // this.setState({ selectedItem: '' });
      // document.querySelector('#newQuan').value='';
      // console.log('POST request successful', response);
      // // Handle any further actions after a successful request
    }catch(error) {
      console.error('Error making POST request:', error);
      // Handle the error as needed
    };
    console.log('Order items updated successfully');
  };

  // Call the async function
  await updateOrder();
} catch (error) {
  console.error('Error adding order item:', error);
}
};


//update => add item for credit user
handleCreditAddItem = async (orderId,custId,creditId) =>{
  try {
  const {selectedItem, unitPrice, foodCodeSelect, cateCodeSelect} = this.state
  console.log("order id",orderId)
  const shopID = localStorage.getItem('shopId');
  var quantity = document.querySelector('#newQuan').value;
  const shopName = localStorage.getItem('shopName');

  const orderRef = doc(db, `shops/${shopName}/orders`, orderId);

  // Wrap your code in an async function
  const updateOrder = async () => {
    // Retrieve the existing order_items array from Firestore
    const orderDoc = await getDoc(orderRef);

    const orderItems = 
    {
       food_name:selectedItem,
       quantity: parseInt(quantity),
       unit_price: parseFloat(unitPrice),
       item_code: foodCodeSelect,
       category_code:cateCodeSelect,
       trans_id: 0,
       // Add more properties as needed
     };

     console.log("ordered items:",orderItems)

     const existingOrderItems = orderDoc.data().order_items || [];
     const existingItemsLength = existingOrderItems.length;

     // Append the new orderItems to the existing order_items array
     const updatedOrderItems = [...existingOrderItems, orderItems];

    
    // Calculate the total based on unit_price and quantity for each item
    const total = updatedOrderItems.reduce((accumulator, item) => {
      return accumulator + item.quantity * item.unit_price;
    }, 0);

    const grandTOtal = total + 0;

    const totalItems =  existingItemsLength + 1;

       // Update the Firestore document with the modified order_items array and the calculated total
       await updateDoc(orderRef, {
        order_items: updatedOrderItems,
        sub_total: total,
        total: grandTOtal, // Update the total field with the calculated total
        total_items: totalItems, 
      });
  

    const noorderItems = 
    {
       food_name:selectedItem,
       quantity: parseInt(quantity),
       unit_price: parseFloat(unitPrice),
       item_code: foodCodeSelect,
       category_code:cateCodeSelect,
       shop_name:shopName,
      total:grandTOtal,
      total_items:totalItems,
     };

   //to make the POST request
  try {
    const response = await axios.post(`${var_api}/api/creditorder/api/newCreditpost/${custId}/${creditId}`, noorderItems)
   
      if (response.status === 200) {
          const trans_id =  response.data.addTrid;

       // Update the trans_id in the orderItems object
      orderItems.trans_id = trans_id;

       // Update the Firestore document again with the modified order_items array (including the updated trans_id)
       await updateDoc(orderRef, {
        order_items: updatedOrderItems,
        sub_total: total,
        total: grandTOtal,
        total_items: totalItems,
      });
       

        // Clear the selected option by setting selectedItem to an empty string
        this.setState( { SelectedCate: " "})
        this.setState({ selectedItem: ' ' });
        
        // Clear the input field with id 'newQuan' if needed
        document.querySelector('#newQuan').value = '';

  
        // Display a success message (you may want to use a toast or other UI feedback)
        toast('Item added successfully', { autoClose: 2000 });
  
        console.log('POST request successful', response);
        this.toggle(); // Close the modal after updating
      }
      // toast('Item added successfully',{autoClose:2000})
      // this.setState({ selectedItem: '' });
      // document.querySelector('#newQuan').value='';
      // console.log('POST request successful', response);
      // // Handle any further actions after a successful request
    }catch(error) {
      console.error('Error making POST request:', error);
      // Handle the error as needed
    };
    console.log('Order items updated successfully');
  };

  // Call the async function
  await updateOrder();
} catch (error) {
  console.error('Error adding order item:', error);
}
};


//get shop details
componentDidMount() {
  const shopID = localStorage.getItem('shopId');
  // Make the API request
  axios
    .get(`${var_api}/api/shop/shopDetails/${shopID}`)
    .then((response) => {
      this.setState({
        shopDetails: response.data
      });
      console.log("shop details:",this.state.shopDetails)
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
}
  
  
  
  
  


  render() {
    const { order } = this.props;
    const currentDate = new Date();
    const currentDateFormatted = currentDate.toLocaleDateString();
    const currentTime = currentDate.toLocaleTimeString();
    const { categoryName,food_name,shopDetails } = this.state;

    return (
        
      <>
      <div style={{backgroundColor:"rgba(234, 195, 223, 0.367)",padding:"25px",width:"300px",border:"3px ridge rgb(236, 0, 173)"}}>
        <div>
        <p style={{textAlign:"center",fontWeight:"bolder",textTransform:"uppercase",color:"rgb(236, 0, 173)",fontFamily:"sans-serif"}}>{localStorage.getItem('shopName')}</p>
       
        {shopDetails ? (
        <div className="m-0" style={{lineHeight: "15%",fontFamily:"sans-serif",margin:"0px"}}>
          <p  style={{textAlign:"center", fontWeight:"bold",fontSize:"12px"}}>#{shopDetails.address}</p>
          <p  style={{textAlign:"center", fontWeight:"normal",fontSize:"12px",marginBottom:"10px"}}>{shopDetails.state}, {shopDetails.country_name}</p>
        </div>
      ) : (
        <div>Loading...</div>
      )}
       
        
      </div>
      <hr  style={{border:"1px dashed black",padding:"0px",margin:"0px"}}/>

     
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"14px"}} >Booking No:{" "}<b>1</b>
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{" "}27-04-2024</span>
      
        </h6>
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px",lineHeight:"2px"}} >Beautician: Reena
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>10:30</span>
        </h6>
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}}>Sitting Type: Room
        <span style={{fontSize:"13px", float:"right"}}>staus: <span className="badge rounded-pill bg-primary p-1">Upcoming</span></span>
        </h6>
 <table style={{borderCollapse:"collapse",width:"100%",marginBottom:"10px"}}>

 <thead style={{borderTop:"1px solid rgb(236, 0, 173)",borderBottom:"1px solid rgb(236, 0, 173)",maxWidth:"100%"}}>
   <th style={{padding:"2px",color:"black"}}>Services</th>
   <th style={{padding:"2px",color:"black"}}>Price</th>
 </thead>

 <tbody>
   
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>Basic Facial</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>20 SGD</td>
   </tr>
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>Delux Facial</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>40 SGD</td>
   </tr>
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>Skin1</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>10 SGD</td>
   </tr>
 
 </tbody>

</table>
<hr style={{border:"1px dashed black",position:"relative",top:"-15px",padding:"0px",marginBottom:"10px"}}/>
        <h6 style={{padding:"1px",color:"rgb(236, 0, 173)",textAlign:"center",fontWeight:"bolder",marginTop:"-20px",fontFamily:"monospace",marginBottom:"-2px"}}><b>Total Amount: {" "}
          70 </b> <span style={{fontSize:"17px"}}>SGD</span></h6>
          <hr style={{border:"1px dashed black",position:"relative",top:"-5px",padding:"0px",marginBottom:"10px"}}/>
          <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px",lineHeight:"2px"}} >Customer: vidya
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>Phone:896445645</span>
        </h6>
      </div>
    
      <br/>


      <div style={{backgroundColor:"rgba(234, 195, 223, 0.367)",padding:"25px",width:"300px",border:"3px ridge rgb(236, 0, 173)"}}>
        <div>
        <p style={{textAlign:"center",fontWeight:"bolder",textTransform:"uppercase",color:"rgb(236, 0, 173)",fontFamily:"sans-serif"}}>{localStorage.getItem('shopName')}</p>
       
        {shopDetails ? (
        <div className="m-0" style={{lineHeight: "15%",fontFamily:"sans-serif",margin:"0px"}}>
          <p  style={{textAlign:"center", fontWeight:"bold",fontSize:"12px"}}>#{shopDetails.address}</p>
          <p  style={{textAlign:"center", fontWeight:"normal",fontSize:"12px",marginBottom:"10px"}}>{shopDetails.state}, {shopDetails.country_name}</p>
        </div>
      ) : (
        <div>Loading...</div>
      )}
       
        
      </div>
      <hr  style={{border:"1px dashed black",padding:"0px",margin:"0px"}}/>

     
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"14px"}} >Booking No:{" "}<b>2</b>
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{" "}27-04-2024</span>
      
        </h6>
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px",lineHeight:"2px"}} >Beautician: Angel 
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>11:30</span>
        </h6>
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}}>Sitting Type: Seat
        <span style={{fontSize:"13px", float:"right"}}>staus: <span className="badge rounded-pill bg-warning p-1">Inprogress</span></span>
        </h6>
 <table style={{borderCollapse:"collapse",width:"100%",marginBottom:"10px"}}>

 <thead style={{borderTop:"1px solid rgb(236, 0, 173)",borderBottom:"1px solid rgb(236, 0, 173)",maxWidth:"100%"}}>
   <th style={{padding:"2px",color:"black"}}>Services</th>
   <th style={{padding:"2px",color:"black"}}>Price</th>
 </thead>

 <tbody>
   
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>pampering Facial</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>60 SGD</td>
   </tr>
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>Skin3</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>30 SGD</td>
   </tr>
 
 </tbody>

</table>
<hr style={{border:"1px dashed black",position:"relative",top:"-15px",padding:"0px",marginBottom:"10px"}}/>
        <h6 style={{padding:"1px",color:"rgb(236, 0, 173)",textAlign:"center",fontWeight:"bolder",marginTop:"-20px",fontFamily:"monospace",marginBottom:"-2px"}}><b>Total Amount: {" "}
          90 </b> <span style={{fontSize:"17px"}}>SGD</span></h6>
          <hr style={{border:"1px dashed black",position:"relative",top:"-5px",padding:"0px",marginBottom:"10px"}}/>
          <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px",lineHeight:"2px"}} >Customer:  rani
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>Phone: 9765645645</span>
        </h6>   
      
      </div>
     



      <br/>



      <div style={{backgroundColor:"rgba(234, 195, 223, 0.367)",padding:"25px",width:"300px",border:"3px ridge rgb(236, 0, 173)"}}>
        <div>
        <p style={{textAlign:"center",fontWeight:"bolder",textTransform:"uppercase",color:"rgb(236, 0, 173)",fontFamily:"sans-serif"}}>{localStorage.getItem('shopName')}</p>
       
        {shopDetails ? (
        <div className="m-0" style={{lineHeight: "15%",fontFamily:"sans-serif",margin:"0px"}}>
          <p  style={{textAlign:"center", fontWeight:"bold",fontSize:"12px"}}>#{shopDetails.address}</p>
          <p  style={{textAlign:"center", fontWeight:"normal",fontSize:"12px",marginBottom:"10px"}}>{shopDetails.state}, {shopDetails.country_name}</p>
        </div>
      ) : (
        <div>Loading...</div>
      )}
       
        
      </div>
      <hr  style={{border:"1px dashed black",padding:"0px",margin:"0px"}}/>

     
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"14px"}} >Booking No:{" "}<b>3</b>
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{" "}27-04-2024</span>
      
        </h6>
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px",lineHeight:"2px"}} >Beautician: beaula
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>12:30</span>
        </h6>
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}}>Sitting Type: Room
        <span style={{fontSize:"13px", float:"right"}}>staus: <span className="badge rounded-pill bg-primary p-1">Upcoming</span></span>
        </h6>
 <table style={{borderCollapse:"collapse",width:"100%",marginBottom:"10px"}}>

 <thead style={{borderTop:"1px solid rgb(236, 0, 173)",borderBottom:"1px solid rgb(236, 0, 173)",maxWidth:"100%"}}>
   <th style={{padding:"2px",color:"black"}}>Services</th>
   <th style={{padding:"2px",color:"black"}}>Price</th>
 </thead>

 <tbody>
  
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>Delux Facial</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>40 SGD</td>
   </tr>
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>Skin2</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>20 SGD</td>
   </tr>
 
 </tbody>

</table>
<hr style={{border:"1px dashed black",position:"relative",top:"-15px",padding:"0px",marginBottom:"10px"}}/>
        <h6 style={{padding:"1px",color:"rgb(236, 0, 173)",textAlign:"center",fontWeight:"bolder",marginTop:"-20px",fontFamily:"monospace",marginBottom:"-2px"}}><b>Total Amount: {" "}
          60 </b> <span style={{fontSize:"17px"}}>SGD</span></h6>
          <hr style={{border:"1px dashed black",position:"relative",top:"-5px",padding:"0px",marginBottom:"10px"}}/>
          <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px",lineHeight:"2px"}} >Customer: reshu
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>Phone:896445645</span>
        </h6>
      </div>
     


      <br/>


      <div style={{backgroundColor:"rgba(234, 195, 223, 0.367)",padding:"25px",width:"300px",border:"3px ridge rgb(236, 0, 173)"}}>
        <div>
        <p style={{textAlign:"center",fontWeight:"bolder",textTransform:"uppercase",color:"rgb(236, 0, 173)",fontFamily:"sans-serif"}}>{localStorage.getItem('shopName')}</p>
       
        {shopDetails ? (
        <div className="m-0" style={{lineHeight: "15%",fontFamily:"sans-serif",margin:"0px"}}>
          <p  style={{textAlign:"center", fontWeight:"bold",fontSize:"12px"}}>#{shopDetails.address}</p>
          <p  style={{textAlign:"center", fontWeight:"normal",fontSize:"12px",marginBottom:"10px"}}>{shopDetails.state}, {shopDetails.country_name}</p>
        </div>
      ) : (
        <div>Loading...</div>
      )}
       
        
      </div>
      <hr  style={{border:"1px dashed black",padding:"0px",margin:"0px"}}/>

     
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"14px"}} >Booking No:{" "}<b>4</b>
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>{" "}27-04-2024</span>
      
        </h6>
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px",lineHeight:"2px"}} >Beautician: Reena
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>01:30</span>
        </h6>
        <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px"}}>Sitting Type: Room
        <span style={{fontSize:"13px", float:"right"}}>staus: <span className="badge rounded-pill bg-warning p-1">Inprogress</span></span>
        </h6>
 <table style={{borderCollapse:"collapse",width:"100%",marginBottom:"10px"}}>

 <thead style={{borderTop:"1px solid rgb(236, 0, 173)",borderBottom:"1px solid rgb(236, 0, 173)",maxWidth:"100%"}}>
   <th style={{padding:"2px",color:"black"}}>Services</th>
   <th style={{padding:"2px",color:"black"}}>Price</th>
 </thead>

 <tbody>
   
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>Pampering Facial</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>60 SGD</td>
   </tr>
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>skin3</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>30 SGD</td>
   </tr>
   <tr>
     <td style={{padding:"2px",color:"black",textAlign:"left",textTransform:"capitalize"}}>Skin1</td>
     <td style={{padding:"2px",color:"black",textAlign:"center"}}>10 SGD</td>
   </tr>
 
 </tbody>

</table>
<hr style={{border:"1px dashed black",position:"relative",top:"-15px",padding:"0px",marginBottom:"10px"}}/>
        <h6 style={{padding:"1px",color:"rgb(236, 0, 173)",textAlign:"center",fontWeight:"bolder",marginTop:"-20px",fontFamily:"monospace",marginBottom:"-2px"}}><b>Total Amount: {" "}
          100 </b> <span style={{fontSize:"17px"}}>SGD</span></h6>
          <hr style={{border:"1px dashed black",position:"relative",top:"-5px",padding:"0px",marginBottom:"10px"}}/>
          <h6 style={{fontWeight:"lighter",color:"black", fontSize:"13px",lineHeight:"2px"}} >Customer: shemla
        <span style={{fontSize:"13px", float:"right",letterSpacing:"-1px"}}>Phone:7896445645</span>
        </h6>
      
      </div>
     
      </>
      
    );
  }
}

export default SbBill;