import React from "react";
import './Mysubscriptionhistory.css';
import axios from 'axios';
import { var_api } from '../constant';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table} from 'react-bootstrap';

const Mysubscriptionhistory = () => {
    
    const [subscriptions, setSubscriptions] = useState([]);
    const [isHistory, setIsHistory] = useState(false);
    const history = useHistory();
    const shopName = localStorage.getItem('shopName');
    
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array to run only once on component mount

  const fetchData = async () => {
    const userid = localStorage.getItem('userId');
    try {
      const response = await axios.get(`${var_api}/api/user/user-subscription-detail/${userid}`);
      setSubscriptions(response.data);
    } catch (error) {
      console.log('Error fetching data:', error);
      setSubscriptions([]);
    }
  };

   const [tables, setTables] = useState([]);
   const [ searchQuery,setSearchQuery] = useState('');
 
    useEffect(() => {
        fetchTables();
      }, []);


      const fetchTables = async () => {
        setIsHistory(true);
        try {
           const userid = localStorage.getItem('userId')
           const response = await axios.get(`${var_api}/api/cash-subscription-details/payment-history-data/${userid}`);
           console.log("response",response)
           setIsHistory(false);
          const user =response.data;
          setTables(user);
        } catch (error) {
          console.log(error);
          setIsHistory(false);
        }
      };
      
       // Function to determine the status based on conditions
    const getStatus = (active_status, manual_payment_verification, date_count) => {
        if (active_status === 1 && manual_payment_verification === 1 && date_count > 0) {
            return "Active";
        } else if (active_status === 1 && manual_payment_verification === 0 && date_count > 0) {
            return "Rejected";
        } else if (active_status === 0 && date_count <= 0) {
            return "Not Active";
        } 
    };
    const getActiveStatusColor = (active_status, manual_payment_verification, date_count) => {
        if (active_status === 1 && manual_payment_verification === 1 && date_count > 0) {
            return 'green'; 
        } else if (active_status === 0 && manual_payment_verification === 1 && date_count > 0) {
            return 'orange'; 
        } else if (active_status === 0 && date_count <= 0) {
            return 'red';
        }
    };
    
      
  //search the transaction data
  const filteredSuppliers = tables.filter((order) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (order.transaction_person_name.toString().toLowerCase().includes(searchLower) || order.transaction_id.toString().toLowerCase().includes(searchLower)) ||
      (order.start_date.toString().toLowerCase().includes(searchLower) || order.ends_date.toString().toLowerCase().includes(searchLower)) 
    );
  });

//navigate to another page
const handleButtonClick = () => {
    // Navigate to a specific route using history.push
    history.push('/Renewsubscription');
  };

  const handleButtonClickhome = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };
    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };

       // Function to format date as dd-mm-yyyy
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); // 'en-GB' for dd-mm-yyyy format
    };

    const renderRenewButton = (noofdays, advancedrenewal, hasadvancedrenewal) => {
        if (noofdays < 60 && advancedrenewal === 0 && hasadvancedrenewal === "false") {
            return <button className='btn btn-danger' onClick={handleButtonClick} >Renew Now</button>;
        } else {
            return null; // or you can return an empty string, or any other component if needed
        }
    };


  return(
    <div className='wholem'>
        <div class="splitm leftm">
            <div>
                <h3 className='ssname'>ACTIVE PLAN</h3>
                <br/>
                <div style={{marginLeft:"12px",padding:"12px"}}>
                    <p><b>Plan Name:</b> {subscriptions.subscription_plan_name} ({subscriptions.plan_duration_days} days)</p>
                    <p><b>Cost:</b> {subscriptions.total_price} {subscriptions.currency_name}</p>
                    <p><b>Start Date:</b> {formatDate(subscriptions.start_date)}</p>
                    <p><b>End Date:</b> {formatDate(subscriptions.ends_date)}</p> 
                    <p><b>Expire In:</b> {subscriptions.date_count} days</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '10px' }}><b>Status:</b>{" "}
                        {subscriptions.is_tableorder && subscriptions.is_tableorder === 1 && <span className="badge rounded-pill bg-danger p-2 text-center">Free Plan</span>} 
                        </p>
                        <div style={{ backgroundColor: getActiveStatusColor(subscriptions.active_status, subscriptions.manual_payment_verification, subscriptions.date_count), padding: '5px', borderRadius: '5px' }}>
                            <p style={{ color: 'white', margin: 0 }}>{getStatus(subscriptions.active_status, subscriptions.manual_payment_verification, subscriptions.date_count)}</p>
                        </div>
                    </div>
                    <br/>
                    {renderRenewButton(subscriptions.date_count, subscriptions.advanced_renewal, subscriptions.has_advanced_renewal)}
                </div>
               
            </div>
        </div>

        <div class="splitm rightm">
        <div style={{ textAlign: "center" }}>
    <nav className="navbar" id="subscripnav">
        {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 10px" }}> */}
            <h4 style={{ fontFamily: "cursive", fontWeight: "bolder", color: "white", margin: 0, padding:"2px 5px" }}>{shopName}</h4>
            <button className="btn" onClick={handleButtonClickhome} id="subscripgohome">
                <i className="bi bi-house"></i> Go to Home
            </button>
        {/* </div> */}
    </nav>
</div>

  <div id="supsciprightdiv">
  
  <div>
    
  <div id="beforetablediv">
  <h3 id="historyheading">Subscription History</h3>
  <input class="form-control" type="Search" placeholder="Search" id="subsripsearch"  onChange={handleSearchInput}/></div>
  <br/>
  <br/>
  <br/>
 <div  id="tableDiv" >
<Table striped="columns" className="scrollable-table" bg="warning" >
<thead>
<tr style={{fontWeight:"bolder"}}>
  <th scope="col">S.No</th>
  <th scope="col">Name</th>
  <th scope="col">Trans.ID</th>
  <th scope="col">Start Date</th>
  <th scope="col">End Date</th>
  <th scope="col">Plan Name</th>
  <th scope="col">Renewal</th>
</tr>
</thead>
    <tbody class="body-half-screen">
{
  isHistory ? (
    <tr>
  <td colSpan="7" style={{ textAlign: "center" }}>
  <div class="text-center m-5">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  </td>
</tr>
  ) : (
filteredSuppliers.length > 0 ? (
filteredSuppliers.reverse().map((data, index) =>(

<tr style={{padding:"5px"}}>
  <th scope="row">{index+1}</th>
  <td>{data.transaction_person_name}</td>
  <td>{data.transaction_id}</td>
  <td>{formatDate(data.start_date)}</td>
  <td>{formatDate(data.ends_date)}</td>
  <td>{data.subscription_plan_name} ({data.plan_duration_days} days)</td>
  <td>
    <div style={{ backgroundColor: data.advanced_renewal === 1 ? 'orange' : 'green', color: 'white', padding: '5px', borderRadius: '5px', maxWidth: '100px' , textAlign: 'center'}}>
        {data.advanced_renewal === 1 ? "Advance" : "Completed"}
    </div>
</td>
</tr>
))
) : (
<tr>
  <td colSpan="7" style={{ textAlign: "center" }}>
    No Data Available
  </td>
</tr>
))}




</tbody>
  
</Table>

  </div>
<br/>
<br/>


    </div>
  
  </div>
        </div> 
    </div>

    
)
};


export default Mysubscriptionhistory;
