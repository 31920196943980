import React, { useRef } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import {  Input, Tooltip } from 'reactstrap';
import Select from 'react-select';
import { var_api } from "../constant";
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import ReactToPrint from 'react-to-print';
import Bill from '../Foods/bill';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './paymodeStatusMaster.css';
toast.configure()

const PaymodeStatusMaster = () => {
    const history = useHistory();
    const [ shopPaymodes, setShopPaymodes ] = useState([]);
    const shop_id = localStorage.getItem('shopId');
    const user_id = localStorage.getItem('userId');
    const [isLoading, setIsLoading] = useState([]);
    const shopName = localStorage.getItem('shopName');
    const [switches, setSwitches] = useState([]);
    const [balanceSwitches, setBalanceSwitches] = useState([]);
    const [ searchQuery,setSearchQuery] = useState('');


      //go to home page
  const handleButtonClickhome = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };

//go to table order page
  const handleToRedirectTableOrder = () => {
    // Navigate to a specific route using history.push
    history.push('/tableOrders');
  };

  useEffect(() => {
    fetchAllShopPaymodes();
   
}, []);


const fetchAllShopPaymodes = async () => {
    setIsLoading(true);
    try {
        const response = await axios.get(`${var_api}/api/shop-paymode/getAll-paymode-list/${shop_id}/${user_id}`);
        const shopPaymodeData = response.data;
        if (shopPaymodeData.length > 0) {
            setShopPaymodes(shopPaymodeData);
            setIsLoading(false);
            const initialSwitches = shopPaymodeData.map((location) => location.is_active === 1);
            console.log("initialSwitches",initialSwitches)
            setSwitches(initialSwitches)
            const initialBalanceSwitches = shopPaymodeData.map((location) => location.show_balance === 1);
            console.log("initialBalanceSwitches",initialBalanceSwitches)
            setBalanceSwitches(initialBalanceSwitches)
        } else {
            console.log('No data found.');
            setShopPaymodes([]);
            setIsLoading(false);
        }
    } catch (error) {
        setShopPaymodes([]);
        setIsLoading(false);
    }
};


//toggle switch for is_active updation
const handleToggle = async (index,id) => {
    const newSwitches = [...switches];
    console.log('newSwitches', newSwitches);
    console.log('newSwitches', !newSwitches[index]);
    newSwitches[index] = !newSwitches[index];
    console.log('newSwitches', newSwitches[index]);
   
    setSwitches(newSwitches);
    console.log('newSwitches', newSwitches);
    

// Make an API request to update the location_status
try {
const response = await axios.put(`${var_api}/api/shop-paymode/put/${id}`, { is_active: newSwitches[index] ? 1 : 0 });
if (response.status === 200) {
  console.log('status updated successfully');
  fetchAllShopPaymodes();
}
} catch (error) {
console.log('Error updating status:', error);
// Revert the switch state in case of an error
newSwitches[index] = !newSwitches[index];
setSwitches(newSwitches);
}
  };




  //toggle switch for is_active updation
const handleBalanceToggle = async (index,id) => {
    const newSwitches = [...balanceSwitches];
    console.log('newSwitches', newSwitches);
    console.log('newSwitches', !newSwitches[index]);
    newSwitches[index] = !newSwitches[index];
    console.log('newSwitches', newSwitches[index]);
   
    setBalanceSwitches(newSwitches);
    console.log('newSwitches', newSwitches);
    

// Make an API request to update the location_status
try {
const response = await axios.put(`${var_api}/api/shop-paymode/put/${id}`, { show_balance: newSwitches[index] ? 1 : 0 });
if (response.status === 200) {
  console.log('status updated successfully');
  fetchAllShopPaymodes();
}
} catch (error) {
console.log('Error updating status:', error);
// Revert the switch state in case of an error
newSwitches[index] = !newSwitches[index];
setBalanceSwitches(newSwitches);
}
  };


   //get the value in search and set the setstate
   const handleSearchInput = (e) => {
    setSearchQuery(e.target.value)
    };


    //search the transaction data
    const filteredPaymodes = shopPaymodes.filter((order) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          (order.pay_mode_name && order.pay_mode_name.toString().toLowerCase().includes(searchLower)) ||
          (order.description && order.description.toString().toLowerCase().includes(searchLower))
          
        );
      });




  return(
    <div class="whole"  style={{ textAlign: "center" ,height:"100%"}}>
    <div className="container-fluid">
        <nav className="navbar" style={{ boxShadow: "0px 0px 1px 0px black", backgroundColor: "green", border: "none", padding: "5px", height: "50px", width: "100%", position: "relative"}}>
                <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "40px", color: "white" }}>{ shopName }</h3>
                <button className="btn" onClick={handleToRedirectTableOrder} style={{  backgroundColor: "white", color: "green", marginLeft:"auto", marginRight:"5px" }}>
                <i class="bi bi-easel"></i> Go to Table Order
                </button>
                <button className="btn" onClick={handleButtonClickhome} style={{ backgroundColor: "white", color: "green" , float:"right"}}>
                    <i class="bi bi-house"></i> Go to Home
                </button> 
        </nav>
    </div>
      <div>
            <br />
        <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",textAlign: "center", color: "black", textDecoration:"underline" }}>Shop Paymode Status</h3>
          
        </div>
        <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"15px",border:"1px solid black",float:"right",marginRight:"30px"}} />
<br />
            <br />
            
            <div className="table-container" style={{ height: "auto", overflowX: 'auto', overflowY: 'scroll' }}>
                <table class="table table-hover small-first-col" style={{ border: "none", borderColor: "grey", width: "95%", margin: "auto" ,height:"50%"}}>
                    <thead>
                        <tr style={{ fontWeight: "bolder" }}>
                            <th scope="col">S.No</th>
                            <th scope="col">Paymode Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Show Balance Status</th>
                            <th scope="col">Active Status</th>
                        </tr>
                    </thead>
                    <tbody class="body-half-screen"> 
                    {
                        isLoading ? (
                            <tr>
                                <td colSpan={4} style={{fontWeight:"bolder", textAlign:"center", color:"red"}}>
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                </td>
                                </tr>
                        ) : (
                            filteredPaymodes.length === 0 ? (
                                <tr>
                                    <td colSpan={4} style={{fontWeight:"bolder", textAlign:"center", color:"red"}}>No Data Available</td>
                                </tr>
                            ) : (
                                filteredPaymodes
                                .filter(paymode => paymode.pay_mode_name !== "CREDIT ORDER" && paymode.pay_mode_name !== "GROUP LOCATION CREDIT ORDER")
                                .map((paymode, index)=>(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{paymode.pay_mode_name}</td>
                                        <td>{paymode.description}</td>
                                        <td>
                                        <div className="form-check form-switch custom-switch-input">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id={`flexSwitchCheckbalanceChecked_${index}`} checked={balanceSwitches[index]}  onChange={() => handleBalanceToggle(index,paymode.shop_paymode_id)}/>
                                            </div>
                                        </div>
                                        </td>
                                        <td>
                                        <div className="form-check form-switch">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id={`flexSwitchCheckChecked_${index}`} checked={switches[index]}  onChange={() => handleToggle(index,paymode.shop_paymode_id)}/>
                                            </div>
                                        </div>
                                        </td>
                                    </tr>
                                ))
                            )
                        )
                    } 
                    
                    </tbody>
                </table>
                <br />
                
            </div>
            
</div>


    
)
};


export default PaymodeStatusMaster;