import React from 'react';
import './adminSupplierMaster.css';
import profile from '../../images/splash/user.png';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';


const AdminSupplierMaster = () => {
  const history = useHistory();
   const [supplier_name, setSupplier_name] = useState("");
   const [supplier_code, setSupplier_code] = useState("");
   const [address, setAddress] = useState("");
   const [mobile_no, setMobile_no] = useState("");
   const [gst_no, setGst_no] = useState("");
   const [suppliers, setSuppliers] = useState([]);
   const [ searchQuery,setSearchQuery] = useState('');
   const shopName= localStorage.getItem('shopName');

    useEffect(() => {
      fetchSuppliers();
      }, []);


      const fetchSuppliers = async () => {
        try {
           const shop = localStorage.getItem('shopId')
           const response = await axios.get(`${var_api}/api/supplier-master/get-supplier/${shop}`);
           console.log("response",response)
          const user =response.data;
          setSuppliers(user);
        } catch (error) {
          console.log(error);
        }
      };
      
      

     //search the transaction data
     const filteredSuppliers = suppliers.filter((order) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (order.supplier_name && order.supplier_name.toString().toLowerCase().includes(searchLower)) ||
        (order.supplier_code && order.supplier_code.toString().toLowerCase().includes(searchLower)) ||
        (order.supplier_address && order.supplier_address.toString().toLowerCase().includes(searchLower)) ||
        (order.mobile_no && order.mobile_no.toString().toLowerCase().includes(searchLower)) ||
        (order.gst_code && order.gst_code.toString().toLowerCase().includes(searchLower))
        
      );
    });


      //post the form data
    const Submit = async (e) => {
      e.preventDefault();
    
      try {
        const response = await axios.post(`${var_api}/api/supplier-master/post`, {
          supplier_code: supplier_code, 
          supplier_name: supplier_name, 
          supplier_mobile: mobile_no, 
          supplier_address: address, 
          gst_code:gst_no,
          shop_id: localStorage.getItem('shopId') || ""
        });
    
        if (response.status === 200) {
          Swal.fire({
            icon:"success",
            text:`Supplier Created Successfully`,
            confirmButtonColor:"#007500"
          })
          setAddress(" ");
          setGst_no(" ");
          setSupplier_code(" ");
          setMobile_no(" ");
          setSupplier_name(" ");
          fetchSuppliers();
          console.log('Data inserted successfully.')

      }
      } catch (error) {
        Swal.fire({
            icon:"error",
            text:`Failed to Create Supplier profile`,
            confirmButtonColor:"#007500"
          })
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    };


    //reset the values
    const handleToReset = () => {
        setSupplier_code(" ");
        setSupplier_name(" "); 
        setAddress(" ");
        setGst_no(" ");
        setMobile_no(" ");
    }

    //Go to home page
    const handleButtonClick = () => {
      history.push('/AdminHome')
    }


    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };


       //to open the form for slot updation 
    const handleEditSuppliers = async (data) => {
      const { value: formValues } = await Swal.fire({
          title: 'Update Supplier',
          html: `
           <label class="swalLa">Name</label>
           <br/>
            <input id="swal-input1" class="swal2-input fin" name="supplier_name" placeholder="Supplier Name" value="${data.supplier_name || ''}">
            <br/>
            <br/>
            <label class="swalLa">Code</label>
            <input id="swal-input2" class="swal2-input fin" name="supplier_code" placeholder="Supplier Code" value="${data.supplier_code || ''}">
            <br/>
            <br/>
            <label class="swalLa">Address</label>
            <input id="swal-input3" class="swal2-input fin" name="address" placeholder="Address" value="${data.supplier_address || ''}">
            <br/>
            <br/>
            <label class="swalLa">Mobile No</label>
            <input id="swal-input4" class="swal2-input fin" name="mobile" placeholder="Mobile" value="${data.supplier_mobile || ''}">
            <br/>
            <br/>
            <label class="swalLa">GST No</label>
            <input id="swal-input5" class="swal2-input fin" name="gst" placeholder="GST No" value="${data.gst_code || ''}">
          `,
          focusConfirm: false,
          showCancelButton: true,
          confirmButtonColor: '#007500',
          confirmButtonText: 'Submit',
          preConfirm: () => {
            return new Promise(async (resolve) => {
              const input1 = document.getElementById('swal-input1').value;
              const input2 = document.getElementById('swal-input2').value;
              const input3 = document.getElementById('swal-input3').value;
              const input4 = document.getElementById('swal-input4').value;
              const input5 = document.getElementById('swal-input5').value;
      
              if (!input1 || !input2 || !input3 || !input4 || !input5) {
                Swal.showValidationMessage('Please fill in all the required fields');
                resolve(null);
              } else {

                const requestData = {
                  supplier_name: input1 || data.supplier_name,
                  supplier_code: input2 || data.supplier_code,
                  supplier_address: input3 || data.supplier_address,
                  supplier_mobile: input4 || data.supplier_mobile,
                  gst_code: input5 || data.gst_code,
                };
      
                try {
                  // Make a PUT request to update the UOM
                  const response = await axios.put(`${var_api}/api/supplier-master/put/${data.id}`, requestData, {
                    headers: {
                      'Content-Type': ' application/json', 
                    },
                  });
      
                  if (response.status === 200) {
                    fetchSuppliers();
                    // Category updated successfully
                    Swal.fire({
                      icon: 'success',
                      text: 'Supplier Details updated successfully',
                      confirmButtonColor: '#007500',
                    });
                  } else {
                    Swal.fire({
                      icon: 'error',
                      text: 'Failed to update Supplier Details',
                      confirmButtonColor: '#007500',
                    });
                  }
                } catch (error) {
                  console.error('Error updating Supplier Details:', error);
                  Swal.fire('Error', 'Failed to update Supplier Details. Please try again.', 'error');
                }
      
                resolve(requestData);
              }
            });
          },
        });
    };


     //delete method for remove UOM
   const handleDeleteSupplier = (id) => {
      axios
      .delete(`${var_api}/api/supplier-master/supplier-delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          console.log(id)
          fetchSuppliers();
          Swal.fire("success"," Supplier Removed Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Supplier not found');
          Swal.fire("error","Supplier not found!","error")
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Supplier!","error")
        // Handle the error as needed
      });
    }



    return(
        <div className='whole' style={{backgroundColor:"white"}}>
            <div class="split left">
                <div class="centered">
                    <h3 className='ushe'>SUPPLIER MASTER</h3>
                    <br/>
                    <div style={{marginLeft:"12px",padding:"12px"}}>

                    <form onSubmit={Submit}>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Supplier Name</lable>
            <br/>
            <input  className="form-input" type='text' name='supplier_name' placeholder='Enter Name'
             required
             value={supplier_name}
             onChange={(e) => setSupplier_name(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Supplier Code</lable>
            <input  className="form-input" type='text' name='supplier_code' placeholder='Enter Code'
            required
             value={supplier_code}
             onChange={(e) => setSupplier_code(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Address</lable>
            <input  className="form-input" type='text' name='address' placeholder='Enter Address'
            required
             value={address}
             onChange={(e) => setAddress(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Mobile No</lable>
            <input  className="form-input" type='text' name='mobile' placeholder='Enter Mobile'
            required
             value={mobile_no}
             onChange={(e) => setMobile_no(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>GST No</lable>
            <input  className="form-input" type='text' name='gst' placeholder='Enter GST'
            required
             value={gst_no}
             onChange={(e) => setGst_no(e.target.value)}/>
            </div>
            <br/>
            <br/>
            <button className='btn btn-success' style={{float:"right"}}>Create Supplier</button> {' '}
            </form>
            
            <button className='btn btn-danger' style={{float:"right",marginRight:"3px"}} onClick={handleToReset} >Reset</button> {' '}
                       
                        
                    </div>
                   
                </div>
            </div>

            <div class="split right">
            <nav className="navbar" 
            style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"5px",height:"50px",width:"1065px",position:"relative",top:"-10px"}} >
<div style={{ display: "flex"}}>
{shopName && <span style={{ fontSize:"21px",fontWeight: "bold",fontFamily:"serif", color: "white",marginLeft:"350px" }}>{shopName}</span>}
        <button className="btn" onClick={handleButtonClick} style={{marginLeft:"200px",backgroundColor:"white",color:"green"}}
         ><i class="bi bi-house"></i> Go to Home</button></div>
      </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"640px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      
      <div>
        
      <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={handleSearchInput}/>
      <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder"}}>Current Suppliers</h3>
      <br/>
     <div>
     <table class="table table-hover table-scroll small-first-col" style={{border:"none"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col">S.No</th>
      <th scope="col"></th>
      <th scope="col">Name</th>
      <th scope="col">Code</th>
      <th scope="col">Address</th>
      <th scope="col">Mobile No</th>
      <th scope="col">GSt No</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {filteredSuppliers.length > 0 ? (
   filteredSuppliers.map((data,index) =>(

    <tr style={{padding:"16px"}}>
      <th scope="row">{index+1}</th>
      <td style={{textTransform:"capitalize",textAlign:"center"}}><img src={profile}  alt='profile' width="55px" height="50px" /></td>
      <td>{data.supplier_name}</td>
      <td>{data.supplier_code}</td>
      <td style={{textTransform:"capitalize",textAlign:"center"}}>{data.supplier_address}</td>
      <td>{data.supplier_mobile}</td>
      <td>{data.gst_code}</td>
      <td>
      <i class="bi bi-pencil-square" onClick={()=>handleEditSuppliers(data)}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      <i class="bi bi-trash" onClick={()=>handleDeleteSupplier(data.id)}   style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i>
      </td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )}
 

   </tbody>

</table>

      </div>
<br/>
<br/>


        </div>
      
      </div>
            </div>
        </div>

        
    )
}

export default AdminSupplierMaster;