import React,{ useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './report.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Dropdown } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { var_api } from "../../constant";
import axios from "axios";
import jsPDF from "jspdf";
import Select from 'react-select';


const SbReport = () => {
    const history = useHistory();
    const shopname = localStorage.getItem('shopName');
    const today = new Date();
    const [viewMod, setViewMod] = useState(false);
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // Months are zero-based, so add 1
    const currentDay = today.getDate();
    const [todayIncome, setTodayIncome] = useState(null);
    const [monthlyIncome, setMonthlyIncome] = useState(null);
    const [ orders, setOrders ] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(true);

    const labelCodeOptions = [
        { value: 'Kalpana', label: 'Kalpana' },
        { value: 'Jenie', label: 'Jenie' }
    ];

    //Navigate to home page
    const handleGoTOHome = () => {
        history.push('/sb-home');
    };


   //to handle view the report
   function handleViewReport(){
    setViewMod(true);
   }

    //close the modal
    const handleCloseMod = () => setViewMod(false);

    useEffect(() => {
      getIncome();
      getOrders();
     }, []);


      // fetch the today income and monthly income
      function getIncome(){
      const shop_id = localStorage.getItem('shopId');
      axios.get(`${var_api}/api/food/income/${shop_id}`)
        .then((response) => {
          const { TodayIncome, MonthlyIncome } = response.data;
          setTodayIncome(TodayIncome);
          setMonthlyIncome(MonthlyIncome);
         
        })
        .catch((error) => {
          console.log('Error fetching data:', error);
        });
      }


      // fetch the today income and monthly income
      function getOrders(){
        const shop_id = localStorage.getItem('shopId');
        axios.get(`${var_api}/api/food/orderData/${shop_id}`)
          .then((response) => {
            const order = response.data.groupedResults;
          
            setOrders(order);
            console.log("orderarray:",orders)
           
           
          })
          .catch((error) => {
            console.log('Error fetching data:', error);
          });
        }


       


    return (
    <div id="firdiv" style={{ backgroundColor: "rgba(234, 195, 223, 0.367)"}}>
        <nav className="navbar navbar-expand navbar-light py-2 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"rgb(236, 0, 173)",border:"none",padding:"10px"}} >
            <h3 style={{textAlign:"center",color:"white"}} id="kifo"><i class="bi bi-clipboard-data-fill"></i>{" "}Reports</h3>
            <button className="btn" id="sbhombu" onClick={handleGoTOHome}><i class="bi bi-house"></i> Go to home</button>
        </nav>
        <br/>
        <div style={{padding:"15px"}}>


            <div style={{display: "flex", justifyContent: "space-between",width:"1100px",margin:"auto"}}>
            <div style={{ margin: "auto", width: "60%" }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: "5px" }}>
    <label style={{fontWeight:"bolder",fontFamily:"serif",color: "rgb(236, 0, 173)"}}>Beautician: </label>
    <Select
      options={labelCodeOptions}
      placeholder="Kalpana"
      // onChange={handleOrderLabelSelectChange}
      isSearchable={true}
      styles={{ menu: (provided, state) => ({
        ...provided,
        zIndex: 9999, // Increase the z-index to ensure the dropdown is displayed above other elements
        width:"300px"
    }) }}
    />
    {/* <i className="bi bi-calendar text-primary" style={{ marginLeft: '5px', fontSize: "20px" }} ></i> */}
    
    <div className="card" style={{width:"auto",margin:"0px",height:"auto", borderRadius:"10px"}}>
        <div className="card-body">
            <div className="row">
                <div className="col">
                <label style={{color: "rgb(236, 0, 173)"}}>From Date:</label>
                <input type="date" placeholder="From time" />{" "}
                </div>
                <div className="col">
                <label style={{color: "rgb(236, 0, 173)"}}>To Date:</label><br/>
                <input type="date" placeholder="To time" />
                </div>
            </div>
        </div>
    </div>
  </div>
</div>

<div className="card " style={{ width: '350px', height:'auto',margin:"0px auto",backgroundColor:"white !important"}}>
    <div className="card-header" style={{textAlign:"center",fontWeight:"bolder",color: "rgb(236, 0, 173)"}}>Report Summary<br/><span style={{fontSize:"14px",color: "black"}}>01-04-2024 to 05-04-2024</span></div>
    <div className="card-body" style={{padding:"10px",backgroundColor:"white", lineHeight:"8px"}}>
        <h6 style={{color: "rgb(236, 0, 173)",fontFamily:"serif",fontWeight:"bolder"}}>Beautician: <b style={{color:"black"}}>Kalpana</b> </h6>
        <p style={{color: "rgb(236, 0, 173)",fontWeight:"bolder",fontFamily:"serif"}}>No.Of Tasks Completed: <b style={{color:"black"}}>20</b></p>
        <p style={{color: "rgb(236, 0, 173)",fontWeight:"bolder",fontFamily:"serif"}}>Total Tasks Duration: <b style={{color:"black"}}>10hrs 30mins</b></p> 
        <p style={{color: "rgb(236, 0, 173)",fontWeight:"bolder",fontFamily:"serif"}}>Total Room Sittings: <b style={{color:"black"}}>4</b></p>  
        <p style={{color: "rgb(236, 0, 173)",fontWeight:"bolder",fontFamily:"serif"}}>Total Seat Sittings: <b style={{color:"black"}}>3</b></p>   
    </div>
</div>
            </div>
        
<div className="card" style={{height:"350px",borderRadius:"20px"}}>
    <div className="card-body">
    <div className="table-container" style={{ height: "400px", overflowX: 'auto', overflowY: 'scroll' }}>
                <table class="table table-hover small-first-col" style={{ border: "none", borderColor: "grey", width: "95%", margin: "auto" ,height:"50%"}}>
                    <thead>
                        <tr style={{ fontWeight: "bolder" }}>
                            <th scope="col">S.No</th>
                            <th scope="col">Date</th>
                            <th scope="col">Slot Time</th>
                            <th scope="col">Customer</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Sitting Type</th>
                            <th scope="col">Start Time-End Time</th>
                            <th scope="col">Time Taken</th>
                        </tr>
                    </thead>
                    <tbody class="body-half-screen">
                       
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">1</td>
                                    <td>01-04-2024</td>
                                    <td>11:00 AM</td>
                                    <td>reena</td>
                                    <td>978945678</td>
                                    <td>Seat</td>
                                    <td>11:00 AM - 01:00 PM</td>
                                    <td>
                                    2 hrs
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">2</td>
                                    <td>01-02-2024</td>
                                    <td>10:30 AM</td>
                                    <td>vidhya</td>
                                    <td>95648945</td>
                                    <td>Room</td>
                                    <td>10:30 AM - 12:00 PM</td>
                                    <td>
                                    1hrs 30mins
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">3</td>
                                    <td>02-04-2024</td>
                                    <td>11:00 AM</td>
                                    <td>priya</td>
                                    <td>89765678</td>
                                    <td>Seat</td>
                                    <td>11:00 AM - 12:00 PM</td>
                                    <td>
                                    1 hr
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">4</td>
                                    <td>02-04-2024</td>
                                    <td>01:00 PM</td>
                                    <td>priya</td>
                                    <td>954356678</td>
                                    <td>Room</td>
                                    <td>01:00 PM - 02:00 PM</td>
                                    <td>
                                    1 hr
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">5</td>
                                    <td>03-04-2024</td>
                                    <td>03:00 PM</td>
                                    <td>mahima</td>
                                    <td>939765678</td>
                                    <td>Room</td>
                                    <td>03:00 AM - 04:00 PM</td>
                                    <td>
                                    1 hr
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">6</td>
                                    <td>03-04-2024</td>
                                    <td>11:00 AM</td>
                                    <td>zoya</td>
                                    <td>916765678</td>
                                    <td>Room</td>
                                    <td>11:00 AM - 01:00 PM</td>
                                    <td>
                                    2 hrs
                                    </td>
                                  
                                </tr>
                                <tr style={{ padding: "5px" }}>
                                    <td scope="row">7</td>
                                    <td>03-04-2024</td>
                                    <td>05:00 PM</td>
                                    <td>shivin</td>
                                    <td>99765678</td>
                                    <td>Seat</td>
                                    <td>05:00 PM - 07:00 PM</td>
                                    <td>
                                    2 hrs
                                    </td>
                                  
                                </tr>
                                
                                
                              
                    </tbody>
                </table>
                <br />
               
            </div>
    </div>
</div>


        </div>
            <Modal className="temp"
            size="lg"
            show={viewMod}
            onHide={handleCloseMod}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title> FINANCIAL REPORT</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'scroll' }}>
              
            </Modal.Body>
           
          </Modal>

          
        
            
        </div>







  )
}





export default SbReport;