import React from 'react';
// import './supplier_master.css';
import profile from '../../images/splash/user.png';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Tooltip } from 'reactstrap';
import Loader from "../Loader/Loader";
import Pagination from 'react-js-pagination';

const GLAccountsMaster = () => {
  const history = useHistory();
  const shopId = localStorage.getItem('shopId')
  const [isLoading, setIsLoading] = useState(false);
   const [accountNumber, setAccountNumber] = useState("");
   const [accountName, setAccountName] = useState("");
   const [accountType, setAccount_type] = useState("");
   const [glaccounts, setAccounts] = useState([]);
   const [ searchQuery,setSearchQuery] = useState('');
   const [show, setShow] = useState(false);
   const [isEdit, setisedit] = useState(false);
   const [supplierD, setSupplierD] = useState({});
   const shopName = localStorage.getItem('shopName');
   const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15; // Number of items per page
    const [totalItems, setTotalItems] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
   const [ updatedaccount, setUpdatedAccount] = useState({
    id:null,
    account_name:"",
    account_no:"",
    account_type:"",
   });
  const [puttooltipOpen, setPutToolTipOpen] = useState([]);
  const [deletetooltipOpen, setDeleteToolTipOpen] = useState([]);

    useEffect(() => {
        getAccounts();
      }, [currentPage]);


      const getAccounts = async () => {
        setIsLoading(true);
        const startIndexCalc = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndexCalc + itemsPerPage;
        try {
           const response = await axios.get(`${var_api}/api/gl-accounts-master/get-accounts/${shopId}`);
           console.log("response",response)
          const user =response.data;
          const total = user.length;
          setAccounts(user.slice(startIndexCalc, endIndex));
          setTotalItems(total); 
          setStartIndex(startIndexCalc); 
          setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
          console.log(error);
        }
      };
    
     //search the transaction data
     const filteredSuppliers = glaccounts.filter((order) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        (order.account_no && order.account_no.toString().toLowerCase().includes(searchLower)) ||
        (order.account_name && order.account_name.toString().toLowerCase().includes(searchLower)) ||
        (order.account_type && order.account_type.toString().toLowerCase().includes(searchLower))
      );
    });


    // Function to convert string to camelCase
  const capitalizeEachWord = (str) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, function (match) {
      return match.toUpperCase();
    });
  };

  // Function to capitalize the first letter of a string
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
 };

 const togglePutTooltip = (index) => {
  const putTooltipOpen = [...puttooltipOpen];
  putTooltipOpen[index] = !putTooltipOpen[index];
  setPutToolTipOpen(putTooltipOpen);
};


const toggleDeleteTooltip = (index) => {
  const deleteTooltipOpen = [...deletetooltipOpen];
  deleteTooltipOpen[index] = !deleteTooltipOpen[index];
  setDeleteToolTipOpen(deleteTooltipOpen);
};



      //post the form data
    const Submit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
        // Trim whitespace from the input values
  const trimmedSupplierName = accountNumber.trim();
  const trimmedAddress = accountName.trim();
  const trimmedGstNo = accountType.trim();


  // Check if the trimmed values are empty
  if (trimmedSupplierName === '' || trimmedAddress === '' || trimmedGstNo === '') {
    // Display an error message to the user
    Swal.fire({
      icon: "error",
      text: "All fields are required",
      confirmButtonColor: "#dc3545"
    });
    return; // Exit the function early if validation fails
  }

      try {
        const response = await axios.post(`${var_api}/api/gl-accounts-master/post`, {
            shop_id: parseInt(shopId),
            account_no: accountNumber,
            account_name: accountName,
            account_type: accountType,
            is_active: 1
        });
    
        if (response.status === 200) {
          Swal.fire({
            icon:"success",
            text:`GL Account Created Successfully`,
            confirmButtonColor:"#007500"
          })
          setIsLoading(false);
          setAccount_type(" ");
          setAccountName(" ");
          setAccountNumber(" ");
          getAccounts();
          console.log('Data inserted successfully.')
      }
      } catch (error) {
        setIsLoading(false);
        Swal.fire({
            icon:"error",
            text:`Account Number or Account Name already exists for the given shop ID`,
            confirmButtonColor:"#007500"
          })
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    //reset the values
    const handleToReset = () => {
        setAccountNumber(" "); 
        setAccountName(" ");
        setAccount_type(" ");
    }

    //Go to home page
    const handleButtonClick = () => {
      history.push('/home')
    }


    //get the value in search and set the setstate
    const handleSearchInput = (e) => {
      setSearchQuery(e.target.value)
      };


       //to open the form for slot updation 
    const handleEditSuppliers = async (data) => {
      setisedit(true);
      setUpdatedAccount({
        id: data.id,
        account_name: data.account_name,
        account_no: data.account_no,
        account_type: data.account_type,
      })
    };


     //delete method for remove UOM
   const handleDeleteSupplier = (id,name) => {
    setShow(true);
    setSupplierD({id:id,account_name:name})
     
    }

    //close the modal
    const handleCloseConfirmation = () => {
      setShow(false)
    }


    const handleDelete = () => {
        setIsLoading(true);
      axios
      .delete(`${var_api}/api/gl-accounts-master/delete/${supplierD.id}`)
      .then((response) => {
        if (response.status === 200) {
            getAccounts();
          setShow(false);
          setIsLoading(false);
          Swal.fire("success"," Account deleted successfully","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Account not deleted');
          Swal.fire("error","Account not found!","error")
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Account!","error")
        // Handle the error as needed
      });
    }

    const handleResetShop = () => {
      setUpdatedAccount({
        id:null,
        account_name:"",
        account_no:"",
        account_type:"",
      })
    }

    const handleUpdateSuppliers = async(e) => {
        setIsLoading(true);
      e.preventDefault()
       // Trim whitespace from the input values
  const trimmedSupplierCode = updatedaccount.account_name.trim();
  const trimmedSupplierName = updatedaccount.account_type.trim();
  const trimmedAddress = updatedaccount.account_no.trim();


  // Check if the trimmed values are empty
  if (trimmedSupplierCode === '' || trimmedSupplierName === '' || trimmedAddress === '') {
    // Display an error message to the user
    Swal.fire({
      icon: "error",
      text: "All fields are required",
      confirmButtonColor: "#dc3545"
    });
    return; // Exit the function early if validation fails
  }

      const requestData = {
        shop_id: parseInt(shopId),
        account_no: updatedaccount.account_no,
        account_name: updatedaccount.account_name,
        account_type: updatedaccount.account_type,
        is_active: 1
      };
        
       try {
        // Make a PUT request to update the UOM
        const response = await axios.put(`${var_api}/api/gl-accounts-master/put/${updatedaccount.id}`, requestData, {
          headers: {
            'Content-Type': ' application/json', 
           },
        });
        
        if (response.status === 200) {
            getAccounts();
          setisedit(false);
          setIsLoading(false);
        // Category updated successfully
          Swal.fire({
            icon: 'success',
            text: 'Account Details updated successfully',
            confirmButtonColor: '#007500',
          });
        } else {
            setIsLoading(false);
           Swal.fire({
            icon: 'error',
            text: 'Failed to update Account Details',
            confirmButtonColor: '#007500',
          });
        }
      } catch (error) {
        console.error('Error updating Account Details:', error);
        Swal.fire('Error', 'Failed to update Account Details. Please try again.', 'error');
      }
        
    } 

    const handleUpdatetoggle = async (e, data) => {
        setIsLoading(true);
        e.preventDefault();
        // Prepare request data with updated is_active status
        const requestData = {
          shop_id: parseInt(shopId),
          account_no: data.account_no,
          account_name: data.account_name,
          account_type: data.account_type,
          is_active: data.is_active ? 0 : 1 // Toggle is_active status
        };
      
        try {
          // Make a PUT request to update the UOM
          const response = await axios.put(`${var_api}/api/gl-accounts-master/put/${data.id}`, requestData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
      
          if (response.status === 200) {
            getAccounts();
            setisedit(false);
            setIsLoading(false);
            // Category updated successfully
            Swal.fire({
              icon: 'success',
              text: 'Account Details updated successfully',
              confirmButtonColor: '#007500',
            });
          } else {
            setIsLoading(false);
            Swal.fire({
              icon: 'error',
              text: 'Failed to update Account Details',
              confirmButtonColor: '#007500',
            });
          }
        } catch (error) {
          console.error('Error updating Account Details:', error);
          Swal.fire('Error', 'Failed to update Account Details. Please try again.', 'error');
        }
      };



    return(
        <>
        {isLoading && <Loader />}
        <div className='whole' style={{backgroundColor:"white"}}>
            <div class="split left">
                <div class="centered">
                    <h3 className='ushe'>GL ACCOUNTS MASTER</h3>
                    <div style={{marginLeft:"12px",padding:"12px"}}>

                    <form onSubmit={Submit}>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Account Number</lable>
            <br/>
            <input  className="form-input" type='text' name='account_number' placeholder='Enter Account Number'
             required
             value={accountNumber}
             onChange={(e) => setAccountNumber(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Account Name</lable>
            <input  className="form-input" type='text' name='account_name' placeholder='Enter Account Name'
            required
             value={accountName}
             onChange={(e) => setAccountName(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"30px"}}>Account Type</lable>
            <input  className="form-input" type='text' name='address' placeholder='Enter Account Type'
            required
             value={accountType}
             onChange={(e) => setAccount_type(e.target.value)}/>
            </div>
            <br/>
            <br/>
            <button className='btn btn-success' style={{float:"right"}}>Create</button> {' '}
            </form>
            <button className='btn btn-secondary' style={{float:"right",marginRight:"3px"}} onClick={handleToReset} >Reset</button> {' '}
            </div>
                   
                </div>
            </div>

            <div class="split right">
            <nav className="navbar" 
            style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"5px",height:"50px",width:"1065px",position:"relative",top:"-10px"}} >
              <div style={{ display: "flex"}}>
                <h4 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "250px", color: "white" }}>{ shopName }</h4>
                <button className="btn" onClick={handleButtonClick} style={{marginLeft:"200px",backgroundColor:"white",color:"green"}}>
                  <i class="bi bi-house"></i> Go to Home</button>
              </div>
      </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"640px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      
      <div>
        
      <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={handleSearchInput}/>
      {/* <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder"}}>Current Suppliers</h3> */}
      <br/>
      <br/>
      <br/>
     <div className="table-container" style={{ height:"500px", overflowX: 'auto', overflowY: 'scroll' }}>
     <table class="table table-hover small-first-col" style={{border:"none",borderColor:"grey",width:"90%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col">S.No</th>
      <th scope="col">Account Number</th>
      <th scope="col">Account Name</th>
      <th scope="col">Account Type</th>
      <th scope="col">Is Active</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {filteredSuppliers.length > 0 ? (
   filteredSuppliers.map((data,index) =>(

    <tr style={{padding:"5px"}}>
     <th scope="row">{(currentPage - 1) * itemsPerPage + index + 1}</th>
      <td>{data.account_no}</td>
      <td>{data.account_name}</td>
      <td>{data.account_type}</td>
      <td>
        <div className="form-check form-switch custom-switch-input">
            <div class="form-check form-switch">
            <input
            className="form-check-input"
            type="checkbox"
            id={`flexSwitchCheckbalanceChecked_${index}`}
            checked={data.is_active === 1}
            onChange={(e) => handleUpdatetoggle(e, data)}
            />
            </div>
        </div>
      </td>
      <td>
      <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='left'
      >
          Edit 
        </Tooltip>
      <i class="bi bi-pencil-square" id={`putButton${index}`} onClick={()=>{handleEditSuppliers(data); togglePutTooltip(index);}}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      <Tooltip
        isOpen={deletetooltipOpen[index]} // Use tooltip state based on index
        target={`deleteButton${index}`} // Specify the target ID of the button
        toggle={() => toggleDeleteTooltip(index)}
        placement='right'
      >
          Delete 
        </Tooltip>
      <i class="bi bi-trash" id={`deleteButton${index}`} onClick={()=>{handleDeleteSupplier(data.id,data.account_name); toggleDeleteTooltip(index);}}   style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i>
      </td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )}
    </tbody>
</table>
<br/>
<Pagination
                    activePage={currentPage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-end"
                    activeClass="active-pagination"
                />
      </div>
<br/>
<br/>
        </div>
      </div>
            </div>
            <Modal
        show={show}
        onHide={handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM !</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete Account 
          <span style={{color:"red",textTransform:"uppercase"}}> {supplierD.account_name}</span>?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>


      <Modal
        show={isEdit}
        onHide={()=>setisedit(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Account Detail </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleUpdateSuppliers}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Account Name</label>
              <input type="text" class="form-control" placeholder="enter supplier name" id="input1"  name="supplier_name"  value={updatedaccount.account_name}  onChange={(e) => setUpdatedAccount({...updatedaccount, account_name:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Account Number</label>
              <input type="text" class="form-control" placeholder="enter supplier code" id="input2" name="supplier_code" value={updatedaccount.account_no} onChange={(e) => setUpdatedAccount({...updatedaccount, account_no:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Account Type</label>
              <input type="text" class="form-control" placeholder="enter address" id="input3"  name="address"  value={updatedaccount.account_type}  onChange={(e) => setUpdatedAccount({...updatedaccount, account_type:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
          </form>
          <button className="btn btn-danger" onClick={()=>setisedit(false)} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
      </Modal> 
        </div>
        </>
    )
}

export default GLAccountsMaster;