import React, { useState, useEffect } from "react";
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { var_api } from "../constant";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import Pagination from 'react-js-pagination';

const GLFinanceSummary = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [getstocktake, setstocktake] = useState([]);
    const shopId = localStorage.getItem('shopId');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15; // Number of items per page
    const [totalItems, setTotalItems] = useState(0);
    const [startIndex, setStartIndex] = useState(0);

    const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
        setFromDate(startOfMonth.toISOString().split('T')[0]);
        setToDate(currentDate.toISOString().split('T')[0]);
        fetchstocktake(startOfMonth, currentDate);
    }, [currentPage]);

    const handleButtonClick = () => {
        history.push('/home')
    }

    const handleFromDateChange = (e) => {
        const date = new Date(e.target.value);
        setFromDate(date.toISOString().split('T')[0]);
        fetchstocktake(date, new Date(toDate));
    };

    const handleToDateChange = (e) => {
        const date = new Date(e.target.value);
        setToDate(date.toISOString().split('T')[0]);
        fetchstocktake(new Date(fromDate), date);
    };

    const fetchstocktake = async (fromDateParam, toDateParam) => {
        const startIndexCalc = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndexCalc + itemsPerPage;

        setIsLoading(true);
        try {
            const formattedFromDate = formatDate(fromDateParam.toISOString().split('T')[0]);
            const formattedToDate = formatDate(toDateParam.toISOString().split('T')[0]);
            const response = await axios.get(`${var_api}/api/gl-accounts-posting/get-postings/${shopId}/${formattedFromDate}/${formattedToDate}`);
            const poDetails = response.data; // Adjust based on your API response structure
            const total = poDetails.length; // Adjust based on your API response structure

            setstocktake(poDetails.slice(startIndexCalc, endIndex));
            setTotalItems(total); // Update total items for pagination
            setStartIndex(startIndexCalc); // Update total items for pagination
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching stock takes:', error);
            setIsLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = getstocktake.filter(item =>
        item.gl_account_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.account_description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.total_credit_amount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.total_debit_amount.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            {isLoading && <Loader />}
            <Navbar expand="sm" className="bg-body-tertiary bg-success p-0" style={{ padding: "0px" }}>
                <Container className="p-0">
                    <Navbar.Brand className="p-0 text-light fw-bolder">GL FINANCE SUMMARY</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <button className="btn btn-outline-success" onClick={handleButtonClick} style={{ backgroundColor: "white", color: "green" }}>
                            <i className="bi bi-house"></i> Go to Home
                        </button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: '10px' }}>
                        <label>From Date</label>
                        <input
                            type="date"
                            className="form-control"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            style={{ borderRadius: '20px', padding: '10px 20px', width: '200px' }}
                        />
                    </div>
                    <div>
                        <label>To Date</label>
                        <input
                            type="date"
                            className="form-control"
                            value={toDate}
                            onChange={handleToDateChange}
                            style={{ borderRadius: '20px', padding: '10px 20px', width: '200px' }}
                        />
                    </div>
                </div>
                <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    id="example-search-input"
                    style={{ width: "280px", borderRadius: "20px", border: "1px solid black" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <br />
            <div>
                <Table responsive="sm" hover>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Account Number</th>
                            <th>Account Description</th>
                            <th>Total Credit Amount</th>
                            <th>Total Debit Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.length > 0 ? (
                            filteredData.map((data, index) => (
                                <tr key={index}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>{data.gl_account_no}</td>
                                    <td>{data.account_description}</td>
                                    <td>{data.total_credit_amount}</td>
                                    <td>{data.total_debit_amount}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <br />
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-end"
                    activeClass="active-pagination"
                />
            </div>
        </>
    );
}

export default GLFinanceSummary;
