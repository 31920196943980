import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from '../constant.js';
import "../uomMaster/uom_master.css";

function AdminUomMaster(){
    const history = useHistory();
    const shopName= localStorage.getItem('shopName');
    const [uom_name,setUom_name] = useState("");
    const [uom_description,setUom_description] =  useState("");
    const [uoms, setUoms] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ searchQuery,setSearchQuery] = useState('');
    const [ postloading,setPostLoading ] = useState(false);
    const [ updateloading, setUpdateLoading ] = useState(false);

    //redirect to home page
    const handleHome = () =>{
      history.push('/AdminHome')
    }

    //list the catgories in select
    useEffect(() => {
        fetchUOMs();
    
    }, []);


//fetch the uom details
    const fetchUOMs = async () => {
        setLoading(true);

        const shopId = localStorage.getItem('shopId');
       try {
        const response = await axios.get(`${var_api}/api/uom-master/get-uom/${shopId}`);
        const use = response.data
      setUoms(use);
      
      console.log("categories",uoms)
      setLoading(false);
      }catch (error) {
        console.log('Error fetching data:', error);
        
        }
      };

 

   //post the form data
    const Submit = async (e) => {
      e.preventDefault();
      setPostLoading(true);
    
      try {
        const response = await axios.post(`${var_api}/api/uom-master/post`, {
            uom_name : uom_name, 
            uom_description: uom_description, 
          shop_id: localStorage.getItem('shopId') || ""
        });
    
        if (response.status === 200) {
          setPostLoading(false);
          Swal.fire({
            icon:"success",
            text:'UOM Created Successfully',
            confirmButtonColor:"#007500"
          })
          setUom_description(" ");
          setUom_name(" ");
          fetchUOMs();
          console.log('Data inserted successfully.')

      }
      } catch (error) {
        setPostLoading(false);
        Swal.fire({
            icon:"success",
            text:'Failed to Create UOM',
            confirmButtonColor:"#007500"
          })
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    };

    const handleToReset = () => {
        setUom_description(" ");
        setUom_name(" "); 
    }



      //get the value in search and set the setstate
      const handleSearchInput = (e) => {
        setSearchQuery(e.target.value)
        };


         //search the transaction data
      const filteredUoms = uoms.filter((order) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          (order.uom_name && order.uom_name.toString().toLowerCase().includes(searchLower)) ||
          (order.uom_description && order.uom_description.toString().toLowerCase().includes(searchLower))
          
        );
      });

      //to open the form for slot updation 
    const handleEditUOm = async (data) => {
        const { value: formValues } = await Swal.fire({
            title: 'Update UOM',
            html: `
             <label class="swalLa">Name</label>
             <br/>
              <input id="swal-input1" class="swal2-input fin" name="uom_name" placeholder="Category Name" value="${data.uom_name || ''}">
              <br/>
              <br/>
              <label class="swalLa">Description</label>
              <input id="swal-input2" class="swal2-input fin" name="uom_description" placeholder="Category Code" value="${data.uom_description || ''}">
             
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#007500',
            confirmButtonText: 'Submit',
            preConfirm: () => {
              return new Promise(async (resolve) => {
                const input1 = document.getElementById('swal-input1').value;
                const input2 = document.getElementById('swal-input2').value;
        
                if (!input1 || !input2) {
                  Swal.showValidationMessage('Please fill in all the required fields');
                  resolve(null);
                } else {

                  const requestData = {
                    uom_name: input1 || data.uom_name,
                    uom_description: input2 || data.uom_description,
                    shop_id: data.shop_id
                  };
        
                  try {
                    setUpdateLoading(true)
                    // Make a PUT request to update the UOM
                    const response = await axios.put(`${var_api}/api/uom-master/put/${data.id}`, requestData, {
                      headers: {
                        'Content-Type': ' application/json', 
                      },
                    });
        
                    if (response.status === 200) {
                      setPostLoading(false);
                      fetchUOMs();
                      // Category updated successfully
                      Swal.fire({
                        icon: 'success',
                        text: 'UOM updated successfully',
                        confirmButtonColor: '#007500',
                      });
                    } else {
                      setPostLoading(false);
                      Swal.fire({
                        icon: 'error',
                        text: 'Failed to update UOM',
                        confirmButtonColor: '#007500',
                      });
                    }
                  } catch (error) {
                    setPostLoading(false);
                    console.error('Error updating category:', error);
                    Swal.fire('Error', 'Failed to update category. Please try again.', 'error');
                  }
        
                  resolve(requestData);
                }
              });
            },
          });
      };


       //delete method for remove UOM
     const handleDeleteUOm = (id) => {
        axios
        .delete(`${var_api}/api/uom-master/uom-delete/${id}`)
        .then((response) => {
          if (response.status === 200) {
            console.log(id)
            fetchUOMs();
            Swal.fire("success"," UOM Removed Successfully!","success")
            // Perform any other actions you need on success
          } else if (response.status === 404) {
            console.log('Slot not found');
            Swal.fire("error","UOM not found!","error")
          }
        })
        .catch((error) => {
          console.error('Error executing the request:', error);
          Swal.fire("error","Failed to Remove UOM!","error")
          // Handle the error as needed
        });
      }


    return(
        <>
        <nav className="navbar navbar-expand navbar-light py-2 sticky-top" style={{ boxShadow: "0px 0px 1px 0px black", backgroundColor: "green", border: "none" }}>
  <div className="container-fluid">
    <div className="navbar-brand">
    <h5 className="hefou" style={{textAlign:"center",color:"white",paddingLeft:"5px"}}><i class="bi bi-arrow-left-circle" onClick={handleHome} style={{fontSize:"21px"}}></i>{' '}UOM MASTER</h5>
    </div>
    <div className="text-center flex-grow-1">
      {shopName && <span style={{ fontSize:"21px",fontWeight: "bold",fontFamily:"serif", color: "white" }}>{shopName}</span>}
    </div>
  </div>
</nav>
        <div id='dailybacku'>
          <br/>
          <br/>
          <div id='fiboxu'>
            <form onSubmit={Submit}>
            <div class="labelsu">
            <lable style={{color:"green",fontWeight:"bold",fontSize:"18px",letterSpacing:"2px"}}>UOM Name  </lable>
            <input  className="form-inputu" type='text' name='uom_name'
             required
             value={uom_name}
             onChange={(e) => setUom_name(e.target.value)}/>
            </div>
            <br/>
            <div class="labelsu">
            <lable style={{color:"green",fontWeight:"bold",fontSize:"18px",letterSpacing:"2px"}}>UOM Description</lable>
            <input  className="form-input1u" type='text' name='uom_description'
            required
             value={uom_description}
             onChange={(e) => setUom_description(e.target.value)}/>
            </div>
            <br/>
            <br/>
            {postloading ? (
                  <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) :  <button className='btn btn-success' id='dasubu'>Submit</button> }{' '}
           
            
            </form>
            <button className='btn btn-danger' style={{borderRadius: "11px", marginLeft:"540px", marginTop:"-63px"}} onClick={handleToReset}> Reset</button>
          </div>
          <br/>
          <div id='taboxu'>
          <table class="table table-success table-striped table-hover">
            <caption className="hefou" style={{captionSide:"top",textAlign:"right",fontSize:"25px",color:"black",fontWeight:"bolder"}}>
            <input type='Search' name='search' className='listSearchu' placeholder='Search UOM' onChange={handleSearchInput}  value={searchQuery}/>
            </caption>
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">UOM Name</th>
      <th scope="col">Description</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  {loading ? (
        <div className="loader-containeru">
      	  <div className="spinneru"></div>
        </div>
  ) : (
  <tbody >
   {filteredUoms.length > 0 ? (
   filteredUoms.map((data, index) => (
  
    <tr key={index}>
      <th scope="row">{index + 1}</th>
      <td style={{textTransform:"uppercase", fontSize:"15px"}}>{data.uom_name}</td>
      <td style={{textTransform:"capitalize",fontSize:"15px"}}>{data.uom_description}</td>
      <td>
      <i class="bi bi-pencil-square" onClick={() => handleEditUOm(data)}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      {/* <i class="bi bi-trash" onClick={() => handleDeleteUOm(data.id)}  style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i> */}
      </td>
    </tr>
  
)) 
): (
    <tr>
      <td colSpan="5" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )
}

   </tbody>
  )}
</table>
          </div>
        </div>
        </>
    )
}

export default AdminUomMaster;