import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import "./SignUp.css";
import shop_image from "../../images/splash/shop_logo.jpeg"
import axios from 'axios'; 
import Swal from 'sweetalert2';
import { var_api } from "../constant";
import { Card, CardImg, CardTitle, CardText, CardColumns,
  CardSubtitle, CardBody } from 'reactstrap';

const SignUp = () => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [Country, setCountry] = useState('');
  const [mobile, setMobile] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);


  const handleMobileChange = (e) => {
    const value = e.target.value;

    // Validate mobile number (only 10 digits)
    // if (/^\d{0,10}$/.test(value)) {
      setMobile(value);
    // }
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (mobile.length !== 6) {
    //   Swal.fire({
    //     icon: 'error',
    //     text: 'Please enter a valid 6-digit mobile number',
    //     confirmButtonColor: '#007500'
    //   });
    //   return;
    // }
    setIsCompleted(true);
  
    try {
      const response = await axios.post(`${var_api}/api/user/api/post`, {
        name: name,
        email: email,
        password: password,
        country: Country,
        mobile: mobile,
      }, {
        headers: {
          'Accept': 'application/json, text/plain, /',
          'Content-Type': 'application/json',
        }
      });
  
      if (response.status === 200) {
        history.push('/');
      
      }
      setIsCompleted(false);
  
      console.log('Registration successful!');
      // Handle success scenario as needed
    } catch (error) {
      console.error('Error:', error);
      // Handle error scenario as needed
  
      if (error.response && error.response.status === 400) {
        // Show the Swal alert for a Bad Request (status code 400)
        console.log("error",error.response.data.message);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${error.response.data.message}`,
          confirmButtonColor: '#007500',
        });
      }
      setIsCompleted(false);
    }
  };
  return (
    <div className="sign">
<br/>
       <Card style={{width:"47rem",height:"35rem",padding:"10px",borderRadius:"25px",border:"none",margin:"auto"}}>
        <CardBody>
          <CardTitle style={{textAlign:"center"}}>
          <Link to="/" className="navbar-brand">
          <img src={shop_image} alt="logo" style={{ width:"100px",height:"100px", borderRadius:"50px"}} />
        </Link>
          </CardTitle>
          <CardSubtitle>
       
      <div className="container" style={{width:"750px",margin:"auto"}}>
        
       
        
          <form onSubmit={handleSubmit} style={{margin:"auto",maxWidth:"70%"}} >
           

          <div className="row">
            <div className="col">
            <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>UserName</label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="UserName"
                pattern="[A-Za-z\s]+"
                title="Please enter only letters (no numbers)"
                style={{height:"7px",borderRadius:"20px"}}
                value={name}
                 onChange={(e) => setName(e.target.value)}
                 required
              />
            </div>
            {/* <div className="col">
           
            </div> */}
            </div>
            <br/>
            <div className="row">
              <div className="col">
              <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Email</label>
              <input
              type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                style={{height:"7px",borderRadius:"20px"}}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              
              </div>
              <div className="col">
              <div className="form-group">
            <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Password</label>
            <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                style={{height:"7px",borderRadius:"20px 0px 0px 20px"}}
                required
              />
                <button
          className="btn btn-outline-secondary"
          type="button"  style={{ zIndex: 100, border:"none",backgroundColor:"whitesmoke",borderRadius:"0px 20px 20px 0px", height:"50px"}}
          onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state on button click
        >
          {showPassword ? <i class="bi bi-eye-slash-fill" style={{fontSize:"18px", color:"black"}}></i> :  <i class="bi bi-eye-fill" style={{fontSize:"18px", color:"black"}}></i>} {/* Show eye icon based on showPassword state */}
        </button>
        </div>
            </div>
             </div>
            </div>

           
            <br/>

            <div className="row">
              <div className="col">
              <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Country</label>
              <input
                type="text"
                name="country"
                className="form-control"
                placeholder="Country"
                pattern="[A-Za-z\s]+"
                title="Please enter only letters (no numbers)"
                style={{height:"7px",borderRadius:"20px"}}
                value={Country}
                onChange={(e) => setCountry(e.target.value)}
                required
              />
              </div>
              <div className="col">
              <label class="form-label text-success fw-bold m-0" style={{fontFamily:"serif"}}>Mobile</label>
              <input
                type="number"
                name="Mobile"
                className="form-control"
                placeholder="Mobile"
                style={{height:"7px",borderRadius:"20px"}}
                value={mobile}
                onChange={handleMobileChange}
                required
              />
              </div>
            </div>

           
            <br/>

            <div className="d-grid">
              {
                isCompleted ? (
                  <div class="text-center" >
                  <div class="spinner-border" role="status" >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) : (
                  <button className="btn btn-success btn-block" type="submit">
                  Sign Up
                </button>
                )
              }
             
            </div>

            <div className="option text-center my-3">
            <Link to='/signin' className="text-success fw-2" style={{fontWeight:"bolder"}} ><label>
                Already a User ?
              </label>{' '}
              Sign In
              </Link>
            </div>
          </form>
       
      </div>
      </CardSubtitle>
         
        </CardBody>
      </Card>
    </div>
  );
};

export default SignUp;