import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./signin.css";
import shop_logo from "../../images/splash/shop_logo.jpeg";
import axios from "axios";
import Swal from 'sweetalert2';
import { var_api } from "../constant";
import { Card, CardTitle, CardSubtitle, CardBody,Tooltip } from 'reactstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const SignIn = () => {
  const history = useHistory();
  const handleSignupClick = () => {
    history.push('/signup');
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState('');
  const [isLogin, setIsLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [tooltipOpen, setTooltipOpen] = useState(false);


  // // Check if userId is available in localStorage when the component mounts
  // useEffect(() => {
  //   const storedUserId = localStorage.getItem('userId');
  //   if (storedUserId) {
  //     setUserId(storedUserId);
  //     history.push('/home');
      
  //   }
  // }, [history]);

  const Submit = async (e) => {
    e.preventDefault();
    setIsLogin(true);
  
    try {
      const response = await axios.post(`${var_api}/api/user/signin`, {
        email: email,
        password: password,
      });
     
      setUserId(response.data.id); 
     
      if (response.status === 200) {
        // Assuming 'id' is the variable containing the ID you want to save
        const id = response.data.id; // Replace 'data.id' with the actual property path containing the ID in the response
        const mailid =  response.data.mailId //Replace 'data.mailId with the actual property path containing the Email in the response
        const admin_employee = response.data.admin_employee;
    
        // Save the ID in local storage
        localStorage.setItem('userId', id);
    
         // Save the EMAILID in local storage
         localStorage.setItem('emailId', mailid);

          // Save the admin employee id in local storage
          localStorage.setItem('admin_employee', admin_employee);

        
        // Redirect the user to the '/home' page
        history.push('/');
        setIsLogin(false);
    }
    
      console.log('Login successful!');
      // Handle success scenario as needed
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        // Show the Swal alert for a Bad Request (status code 400)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid username or password...',
          confirmButtonColor: '#007500',
        });
        setIsLogin(false);
      }
      setIsLogin(false);
      // Handle error scenario as needed
    }
  };

  const handleGoSuperAdmin = () => {
    history.push('/admin');
  }

  const handleGoToForgotPassword = () => {
    history.push('/forgot-password');
    localStorage.setItem("passwordFor","Admin");
  }


  const handleGoToEmpForgotPassword = () => {
    history.push('/forgot-password');
    localStorage.setItem("passwordFor","Employee");
  }

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };


  const EmpSubmit = async (e) => {
    e.preventDefault();
    setIsLogin(true);
  
    try {
      const response = await axios.post(`${var_api}/api/employees/signin`, {
        e_email: email,
        e_password: password,
      });
     
      setUserId(response.data.id); 
     
      if (response.status === 200) {
        //Assuming 'id' is the variable containing the ID you want to save
        const id = response.data.employeeDetails.user_id; // Replace 'data.id' with the actual property path containing the ID in the response
        const mailid =  response.data.employeeDetails.e_email; //Replace 'data.mailId with the actual property path containing the Email in the response
        const admin_employee = response.data.employeeDetails.id;
        const sub_status = response.data.sub_status;
        const verification_status = response.data.verification_status;
        const e_role = response.data.employeeDetails.e_role;
        const e_name = response.data.employeeDetails.e_name;
    
        // Save the ID in local storage
        localStorage.setItem('userId', id);
    
         // Save the EMAILID in local storage
         localStorage.setItem('empEmailId', mailid);

         localStorage.setItem('empId', admin_employee);

          // Save the admin employee id in local storage
          // localStorage.setItem('admin_employee', admin_employee);
          localStorage.setItem('sub_status', sub_status);
          localStorage.setItem('verification_status',verification_status)
          localStorage.setItem('e_role',e_role);
          localStorage.setItem('empName',e_name);
          const sub_statusl = localStorage.getItem("sub_status");
          const verification_statusl = localStorage.getItem("verification_status");
          console.log("s,v",sub_statusl,verification_statusl);

        
        // Redirect the user to the '/home' page
        history.push('/');
        setIsLogin(false);
    }
    
      console.log('Login successful!');
      // Handle success scenario as needed
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        // Show the Swal alert for a Bad Request (status code 400)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Invalid username or password...',
          confirmButtonColor: '#007500',
        });
        setIsLogin(false);
      }
      setIsLogin(false);
      // Handle error scenario as needed
    }
  }
  
  return (
    
    <div className="sign-up">
      <Tooltip
        isOpen={tooltipOpen}
        target="saButton" // Specify the target ID of the button
        toggle={toggleTooltip}
        placement="right"
      >
        Super Admin
      </Tooltip>
        <button className="btn btn-success m-2" id="saButton"  onClick={()=>{handleGoSuperAdmin(); toggleTooltip();}}><i class="bi bi-person"></i></button>
      <br/> 
       
        <Card id="signupcard">
        <CardBody>
          <CardTitle style={{textAlign:"center"}}> 
          <Link to="/" className="navbar-brand">
          <img src={shop_logo} alt="logo" id="signinLogo"/>
        </Link>
          </CardTitle>
          <CardSubtitle>
            <br/>
          <Tabs
            defaultActiveKey="Admin"
            id="fill-tab-example"
            className="mb-1 ml-1 tabclrsignin"
            fill
          >
            <Tab eventKey="Admin" title={<span className="signintab">Admin</span>}>
       
      <div className="container" id="containdiv">
     
       
          <form onSubmit={Submit} className="py-2">
            <h6 className="lead text-left py-2" style={{fontSize:"14px", fontWeight:"bold", fontFamily:"sans-serif"}}>Please Login to Your Account</h6>
           
            <div className="form-group">
            <label class="form-label  fw-bold m-0" style={{fontFamily:"serif",color:"green"}}>Email</label>
              <input
              type="email"
                name="email"
                className="form-control"
                value={email}
                id="signinemail"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              
            </div>
             <br/>

            <div className="form-group">
            <label class="form-label fw-bold m-0" style={{fontFamily:"serif",color:"green"}}>Password</label>
            <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                id="signinpassword"
                required
              />
                <button
          className="btn btn-outline-secondary"
          type="button"  id="signinshow"
          onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state on button click
        >
          {showPassword ? <i class="bi bi-eye-slash-fill" id="signineye"></i> :  <i class="bi bi-eye-fill" id="signineye"></i>} {/* Show eye icon based on showPassword state */}
        </button>
        </div>
            </div>
            <h5 className="m-1" style={{ textDecoration:"none", float:"right", fontSize:"13px", fontWeight:"bolder",color:"green"}} onClick={handleGoToForgotPassword}>Forgot Password?</h5>
            {/* <a href="/forgot-password" class="float-right text-success m-1" style={{ textDecoration:"none", float:"right", fontSize:"13px", fontWeight:"bolder"}}>Forgot Password?</a> */}
            <br/>
            <br/>
            <div className="d-grid">
              
                {isLogin ? (
                  <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) : <button className="btn btn-success btn-block"  type="submit">Login </button>}
                
             
            </div>
           
 

            <br/>
            <div className=" text-center">
             
      <span className="badge rounded-pill bg-danger" style ={{fontWeight:"normal",fontSize:"15px", marginBottom:"15px"}} onClick={handleSignupClick}>
        Sign up as New Admin
      </span>
    </div>
          </form>
        
      </div>
      </Tab>
      <Tab eventKey="employee" title={<span className="signintab">Employee</span>}>
      <div className="container" id="containdiv">
     
       
     <form onSubmit={EmpSubmit} className="py-2">
       <h6 className="lead text-left py-2" style={{fontSize:"14px", fontWeight:"bold", fontFamily:"sans-serif"}}>Please Login to Your Account</h6>
      
       <div className="form-group">
       <label class="form-label  fw-bold m-0" style={{fontFamily:"serif",color:"rgb(250, 106, 28)"}}>Email</label>
         <input
         type="email"
           name="email"
           className="form-control"
           value={email}
           id="signinemail"
           onChange={(e) => setEmail(e.target.value)}
           placeholder="Email"
           required
         />
         
       </div>
        <br/>

       <div className="form-group">
       <label class="form-label fw-bold m-0" style={{fontFamily:"serif",color:"rgb(250, 106, 28)"}}>Password</label>
       <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
         <input
           type={showPassword ? 'text' : 'password'}
           name="password"
           className="form-control"
           value={password}
           onChange={(e) => setPassword(e.target.value)}
           placeholder="Password"
           id="signinpassword"
           required
         />
           <button
     className="btn btn-outline-secondary"
     type="button"  id="signinshow"
     onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state on button click
   >
     {showPassword ? <i class="bi bi-eye-slash-fill" id="signineye"></i> :  <i class="bi bi-eye-fill" id="signineye"></i>} {/* Show eye icon based on showPassword state */}
   </button>
   </div>
       </div>
       <h5 className="m-1" style={{ textDecoration:"none", float:"right", fontSize:"13px", fontWeight:"bolder",color:"rgb(250, 106, 28)"}} onClick={handleGoToEmpForgotPassword}>Forgot Password?</h5>
       {/* <a href="/forgot-password" class="float-right text-success m-1" style={{ textDecoration:"none", float:"right", fontSize:"13px", fontWeight:"bolder"}}>Forgot Password?</a> */}
       <br/>
       <br/>
       <div className="d-grid">
         
           {isLogin ? (
             <div class="text-center">
             <div class="spinner-border" role="status">
               <span class="visually-hidden">Loading...</span>
             </div>
           </div>
           ) : <button className="btn btn-success btn-block"  type="submit" style={{color:"white", backgroundColor:"rgb(250, 106, 28)",borderColor:"rgb(250, 106, 28)"}}>Login </button>}
           
        
       </div>
      
     </form>
   
 </div>

      </Tab>
      </Tabs>
      </CardSubtitle>
         
         </CardBody>
       </Card>
    </div>
  );
};

export default SignIn;
