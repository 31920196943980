import React, { useState, useEffect } from "react";
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { var_api } from "../constant";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import Pagination from 'react-js-pagination';

const PandLReport = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const shopId = localStorage.getItem('shopId');
    const [pnlsummary, setpnlsummary] = useState([]);
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalPOandGRN, setTotalPOandGRN] = useState(0);
    const [totalOther, setTotalOther] = useState(0);
    const [grossprofit, setgrossprofit] = useState(0);
    const [netprofit, setnetprofit] = useState(0);
    //income
    const [showIncomeModal, setShowIncomeModal] = useState(false);
    const [searchTermIncome, setSearchTermIncome] = useState('');
    const [currentPageIncome, setCurrentPageIncome] = useState(1);
    const itemsPerPageIncome = 10; // Number of items per page
    const [totalItemsIncome, setTotalItemsIncome] = useState(0);
    const [startIndexIncome, setStartIndexIncome] = useState(0);
    const [income, setIncome] = useState([]);

    //poand grn
    const [poandgrn, setpoandgrn] = useState([]);
    const [showPOandGRNModal, setShowPOandGRNModal] = useState(false);
    const [searchTermPOandGRN, setSearchTermPOandGRN] = useState('');
    const [currentPagePOandGRN, setCurrentPagePOandGRN] = useState(1);
    const itemsPerPagePOandGRN = 10; // Number of items per page
    const [totalItemsPOandGRN, setTotalItemsPOandGRN] = useState(0);
    const [startIndexPOandGRN, setStartIndexPOandGRN] = useState(0);

    //other expenses
    const [otherexpenses, setotherexpenses] = useState([]);
    const [showOtherModal, setShowOtherModal] = useState(false);
    const [searchTermOther, setSearchTermOther] = useState('');
    const [currentPageOther, setCurrentPageOther] = useState(1);
    const itemsPerPageOther = 10; // Number of items per page
    const [totalItemsOther, setTotalItemsOther] = useState(0);
    const [startIndexOther, setStartIndexOther] = useState(0);
    
   // /:shop_id

    useEffect(() => {
        fetchpnlsummary();
        incomeDetails();
        poandgrnDetails();
        otherExpense();
    }, []);

    const fetchpnlsummary = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(`${var_api}/api/pnl/get-pnl-summary/${shopId}`);
          const pnlsummary = response.data;
          setpnlsummary(pnlsummary);
          setTotalIncome(pnlsummary.total_income || 0);
          setTotalPOandGRN(pnlsummary.grn_total || 0);
          setTotalOther(pnlsummary.other_expenses || 0);
          let grossvalue = pnlsummary.total_income - pnlsummary.grn_total;
          let netPnL = grossvalue - pnlsummary.other_expenses;
          setgrossprofit(grossvalue || 0);
          setnetprofit(netPnL || 0);
        } catch (error) {
          console.error('Error fetching grns:', error);
        }
        finally{
          setIsLoading(false);
        }
      };

      const incomeDetails = async () => {
        setIsLoading(true);
        // const startIndexCalcIncome = (currentPageIncome - 1) * itemsPerPageIncome;
        // const endIndexIncome = startIndexCalcIncome + itemsPerPageIncome;
        try {
           const response = await axios.get(`${var_api}/api/pnl/get-income-details/${shopId}`);
           console.log("response",response)
          const user =response.data;
          const total = user.length;
          setIncome(user)
        //     .slice(startIndexCalcIncome, endIndexIncome));
        //   setTotalItemsIncome(total); 
        //   setStartIndexIncome(startIndexCalcIncome); 
          setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
          console.log(error);
        }
      };

      const poandgrnDetails = async () => {
        setIsLoading(true);
        // const startIndexCalcPOandGRN = (currentPagePOandGRN - 1) * itemsPerPagePOandGRN;
        // const endIndexPOandGRN = startIndexCalcPOandGRN + itemsPerPagePOandGRN;
        try {
           const response = await axios.get(`${var_api}/api/pnl/get-PoGrn-details/${shopId}`);
           console.log("response",response)
          const user =response.data;
          const total = user.length;
          setpoandgrn(user)
        //     .slice(startIndexCalcPOandGRN, endIndexPOandGRN));
        //   setTotalItemsPOandGRN(total); 
        //   setStartIndexPOandGRN(startIndexCalcPOandGRN); 
          setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
          console.log(error);
        }
      };

      const otherExpense = async () => {
        setIsLoading(true);
        
        try {
           const response = await axios.get(`${var_api}/api/pnl/get-settlements-details/${shopId}`);
           console.log("response",response)
          const user =response.data;
          const total = user.length;
          setotherexpenses(user)
        //     .slice(startIndexCalcOther, endIndexOther));
        //   setTotalItemsOther(total); 
        //   setStartIndexOther(startIndexCalcOther); 
          setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
          console.log(error);
        }
      };

    const handleButtonClick = () => {
        history.push('/home')
    }

    const handleIncomeSearchChange = (e) => {
        setSearchTermIncome(e.target.value);
    };

    const handlePoandGrnSearchChange = (e) => {
        setSearchTermPOandGRN(e.target.value);
    };

    const handleOtherSearchChange = (e) => {
        setSearchTermOther(e.target.value);
    };

    const filteredDataIncome = income.filter(item =>
        (item.ordered_at?.toLowerCase() ?? '').includes(searchTermIncome.toLowerCase()) ||
        (item.order_no?.toString().toLowerCase() ?? '').includes(searchTermIncome.toLowerCase()) ||
        (item.quantity?.toString().toLowerCase() ?? '').includes(searchTermIncome.toLowerCase()) ||
        (item.item_code?.toLowerCase() ?? '').includes(searchTermIncome.toLowerCase()) ||
        (item.food_name?.toLowerCase() ?? '').includes(searchTermIncome.toLowerCase()) ||
        (item.unit_price?.toString().toLowerCase() ?? '').includes(searchTermIncome.toLowerCase()) ||
        (item.total_income?.toString().toLowerCase() ?? '').includes(searchTermIncome.toLowerCase())
    );
    
    const filteredDatapoandgrn = poandgrn.filter(item =>
        (item.postdate?.toString().toLowerCase() ?? '').includes(searchTermPOandGRN.toLowerCase()) ||
        (item.total_purchase_price?.toString().toLowerCase() ?? '').includes(searchTermPOandGRN.toLowerCase()) ||
        (item.service_amount_per_unit?.toString().toLowerCase() ?? '').includes(searchTermPOandGRN.toLowerCase()) ||
        (item.po_number?.toString().toLowerCase() ?? '').includes(searchTermPOandGRN.toLowerCase()) ||
        (item.po_detail_stock_code?.toString().toLowerCase() ?? '').includes(searchTermPOandGRN.toLowerCase()) ||
        (item.description?.toString().toLowerCase() ?? '').includes(searchTermPOandGRN.toLowerCase()) ||
        (item.grn_number?.toString().toLowerCase() ?? '').includes(searchTermPOandGRN.toLowerCase()) || 
        (item.item_name?.toString().toLowerCase() ?? '').includes(searchTermPOandGRN.toLowerCase()) || 
        (item.grn_quantity_received?.toString().toLowerCase() ?? '').includes(searchTermPOandGRN.toLowerCase())
    );

    const filteredDataOther = otherexpenses.filter(item =>
        (item.paid_date?.toString().toLowerCase() ?? '').includes(searchTermOther.toLowerCase()) ||
        (item.remarks?.toString().toLowerCase() ?? '').includes(searchTermOther.toLowerCase()) ||
        (item.paid_amount?.toString().toLowerCase() ?? '').includes(searchTermOther.toLowerCase()) 
    );
    //income
    const startIndexCalcIncome = (currentPageIncome - 1) * itemsPerPageIncome;
    const endIndexIncome = startIndexCalcIncome + itemsPerPageIncome;

    const paginatedDataIncome = filteredDataIncome.slice(startIndexCalcIncome, endIndexIncome);

    const handlePageChangeIncome = (pageNumber) => {
        setCurrentPageIncome(pageNumber);
    };

    //po and grn
    const startIndexCalcPOandGRN = (currentPagePOandGRN - 1) * itemsPerPagePOandGRN;
    const endIndexPOandGRN = startIndexCalcPOandGRN + itemsPerPagePOandGRN;

    const paginatedDataPOandGRN = filteredDatapoandgrn.slice(startIndexCalcPOandGRN, endIndexPOandGRN);

    const handlePageChangePOandGRN = (pageNumber) => {
        setCurrentPagePOandGRN(pageNumber);
    };
    
    //other expenses
    const startIndexCalcOther = (currentPageOther - 1) * itemsPerPageOther;
        const endIndexOther = startIndexCalcOther + itemsPerPageOther;

    const paginatedDataOther = filteredDataOther.slice(startIndexCalcOther, endIndexOther);

    const handlePageChangeOther = (pageNumber) => {
        setCurrentPageOther(pageNumber);
    };


    const handleShowIncomeModal = () => setShowIncomeModal(true);
    const handleCloseIncomeModal = () => setShowIncomeModal(false);

    const handleShowPOandGRNModal = () => setShowPOandGRNModal(true);
    const handleClosePOandGRNModal = () => setShowPOandGRNModal(false);

    const handleShowOtherModal = () => setShowOtherModal(true);
    const handleCloseOtherModal = () => setShowOtherModal(false);


    return (
        <>
            {isLoading && <Loader />}
            <Navbar expand="sm" className="bg-body-tertiary bg-success p-0" style={{ padding: "0px" }}>
                <Container className="p-0">
                    <Navbar.Brand className="p-0 text-light fw-bolder">P & L REPORT</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <button className="btn btn-outline-success" onClick={handleButtonClick} style={{ backgroundColor: "white", color: "green" }}>
                            <i className="bi bi-house"></i> Go to Home
                        </button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                <Card style={{ width: '800px', height: 'auto', padding: '20px' }}>
                    <Card.Body>
                        <Card.Title className="text-center">P & L Report Summary</Card.Title>
                        <br/>
                        <Card.Text>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: '10px', marginBottom: '10px' }}>
                                <div><strong>Description</strong></div>
                                <div><strong>Amount</strong></div>
                                <div></div> {/* Empty cell for the button column header */}
                            </div>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: '10px', marginBottom: '10px' }}>
                                <div>Total Income</div>
                                <div>${totalIncome.toFixed(2)}</div>
                                <div><button className="btn btn-primary btn-sm" onClick={handleShowIncomeModal}>View Detail</button></div>
                            </div>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: '10px', marginBottom: '10px' }}>
                                <div>Total PO & GRN</div>
                                <div>${totalPOandGRN.toFixed(2)}</div>
                                <div><button className="btn btn-primary btn-sm" onClick={handleShowPOandGRNModal}>View Detail</button></div>
                            </div>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: '10px', marginBottom: '10px' }}>
                                <div>Total Other Expenses</div>
                                <div>${totalOther.toFixed(2)}</div>
                                <div><button className="btn btn-primary btn-sm" onClick={handleShowOtherModal}>View Detail</button></div>
                            </div>
                            <hr/>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: '10px', marginBottom: '10px' }}>
                                <div style={{fontWeight:"bolder", color:'blue'}}>Gross Profit</div>
                                <div style={{fontWeight:"bolder", color:'blue'}}>${grossprofit.toFixed(2)}</div>
                            </div>
                            <hr/>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: '10px', marginBottom: '10px' }}>
                                <div style={{fontWeight:"bolder", color:'red'}}>Net Profit/Loss</div>
                                <div style={{fontWeight:"bolder", color:'red'}}>${netprofit.toFixed(2)}</div>
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

             {/* Modal Income */}
             <Modal show={showIncomeModal} onHide={handleCloseIncomeModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Income Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "end", padding: "5px" }}>
                <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    id="example-search-input"
                    style={{ width: "280px", borderRadius: "20px", border: "1px solid black" }}
                    value={searchTermIncome}
                    onChange={handleIncomeSearchChange}
                />
            </div>
                    <div className="table-responsive">
                        <Table responsive="sm" hover>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Order No</th>
                                    <th>Item Code</th>
                                    <th>Item Description</th>
                                    <th>Quantity</th>
                                    <th>Unit Price</th>
                                    <th>Total Sales</th>
                                </tr>
                            </thead>
                            <tbody>
                            {paginatedDataIncome.length > 0 ? (
                            paginatedDataIncome.map((data, index) => (
                                <tr key={index}>
                                    <td>{(currentPageIncome - 1) * itemsPerPageIncome + index + 1}</td>
                                    <td>{data.ordered_at}</td>
                                    <td>{data.order_no}</td>
                                    <td>{data.item_code}</td>
                                    <td>{data.food_name}</td>
                                    <td>{data.quantity}</td>
                                    <td>{data.unit_price}</td>
                                    <td>{data.total_income}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                            </tbody>
                        </Table>
                        <Pagination
                    activePage={currentPageIncome}
                    itemsCountPerPage={itemsPerPageIncome}
                    totalItemsCount={filteredDataIncome.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChangeIncome}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-end"
                    activeClass="active-pagination"
                />
                    </div>
                </Modal.Body>
            </Modal>


            {/* Modal PO and GRN */}
            <Modal show={showPOandGRNModal} onHide={handleClosePOandGRNModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>PO and GRN Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "end", padding: "5px" }}>
                <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    id="example-search-input"
                    style={{ width: "280px", borderRadius: "20px", border: "1px solid black" }}
                    value={searchTermPOandGRN}
                    onChange={handlePoandGrnSearchChange}
                />
            </div>
                    <div className="table-responsive">
                        <Table responsive="sm" hover>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>PO Number</th>
                                    <th>GRN Number</th>
                                    <th>Item Code</th>
                                    <th>Item Description</th>
                                    <th>Quantity</th>
                                    <th>Unit Price</th>
                                    <th>Total Purchase Value</th>
                                </tr>
                            </thead>
                            <tbody>
                            {paginatedDataPOandGRN.length > 0 ? (
                            paginatedDataPOandGRN.map((data, index) => (
                                <tr key={index}>
                                    <td>{(currentPagePOandGRN - 1) * itemsPerPagePOandGRN + index + 1}</td>
                                    <td>{data.postdate}</td>
                                    <td>{data.po_number}</td>
                                    <td>{data.grn_number}</td>
                                    <td>{data.po_detail_stock_code}</td>
                                    <td>{data.item_name}</td>
                                    <td>{data.grn_quantity_received}</td>
                                    <td>{data.service_amount_per_unit}</td>
                                    <td>{data.total_purchase_price}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                            </tbody>
                        </Table>
                        <Pagination
                    activePage={currentPagePOandGRN}
                    itemsCountPerPage={itemsPerPagePOandGRN}
                    totalItemsCount={filteredDatapoandgrn.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChangePOandGRN}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-end"
                    activeClass="active-pagination"
                />
                    </div>
                </Modal.Body>
            </Modal>


            {/* Modal Other Expenses */}
            <Modal show={showOtherModal} onHide={handleCloseOtherModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Other Expenses Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "end", padding: "5px" }}>
                <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    id="example-search-input"
                    style={{ width: "280px", borderRadius: "20px", border: "1px solid black" }}
                    value={searchTermOther}
                    onChange={handleOtherSearchChange}
                />
            </div>
                    <div className="table-responsive">
                        <Table responsive="sm" hover>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Expense Description</th>
                                    <th>Expense Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {paginatedDataOther.length > 0 ? (
                            paginatedDataOther.map((data, index) => (
                                <tr>
                                    <td>{(currentPageOther - 1) * itemsPerPageOther + index + 1}</td>
                                    <td>{data.paid_date}</td>
                                    <td>{data.remarks}</td>
                                    <td>{data.paid_amount}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                            </tbody>
                        </Table>
                        <Pagination
                    activePage={currentPageOther}
                    itemsCountPerPage={itemsPerPageOther}
                    totalItemsCount={filteredDataOther.length}
                    pageRangeDisplayed={5}
                    onChange={handlePageChangeOther}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-end"
                    activeClass="active-pagination"
                />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PandLReport;
