
   // const var_api = 'http://localhost:4500';
    const var_api = 'http://posapi.suyaptech.com';
   //  const var_api = 'http://postestapi.suyaptech.com';
    //  const var_api = "https://api.spbiller.com";
   //  const var_api = 'http://139.59.61.131:4501';



    export { var_api }


