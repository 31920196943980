import React, {useEffect, useState} from 'react';
import { Table, Row, Col, Card} from 'react-bootstrap';
import { collection, onSnapshot, where, query, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase.js';
import './kitchen2.css';
// import { useOrderContext } from "./orderContext";

const ChildCard2 = ({ orders }) => {

  const [inProgressOrders, setInProgressOrders] = useState([])

  // const { inProgressOrders, setInProgressOrders, setReadyOrders } = useOrderContext();
    // const cards2 = [
    //     {
    //         name:'Justin',
    //         title: 'Order No ',
    //         mobile: '9067852134',
    //         text:'Inprogress',
    //         id:2,
           
    //     },
    //     {
    //         name:'Selena',
    //         title: 'Order No ',
    //         mobile: '9067852134',
    //         text:'Inprogress',
    //         id:2,
    //     },
    //     {
    //         name:'Comez',
    //         title: 'Order No ',
    //         mobile: '9067852134',
    //         text:'Inprogress',
    //         id:2,
    //     },
        
    //   ];

    // Function to move an order from In Progress to Ready
  // const moveOrderToReady = (orderId) => {
  //   const orderToMove = inProgressOrders.find((order) => order.id === orderId);

  //   // Update the state to remove the order from "In Progress" orders
  //   setInProgressOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));

  //   // Add the order to "Ready" orders
  //   setReadyOrders((prevOrders) => [...prevOrders, orderToMove]);
  // };

  useEffect(() => {
    const shopName = localStorage.getItem('shopName');
    console.log("kitchen page:",shopName)
    const ordersRef = collection(db, `shops/${shopName}/orders`);
    // Query to get orders with process_code equal to 1
    const ordersquery = query(ordersRef, where('process_code', '==', 1));
    const unsub = onSnapshot(ordersquery, snapshot => {
      setInProgressOrders(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
    })

    return () => {
        unsub()
    }
   }, [setInProgressOrders])


   const handleProgressButtonClick = (orderId) => {
    const shopName = localStorage.getItem('shopName');
    const orderRef = doc(db, `shops/${shopName}/orders`, orderId);
  
    // Update the process_code field to 2
    updateDoc(orderRef, {
      process_code: 2
    })
    .then(() => {
      console.log('process_code updated successfully');
    })
    .catch(error => {
      console.error('Error updating process_code:', error);
    });
  };



  return (
    <div>
     
     <Row>
     {inProgressOrders.map((order) => (
          <Col key=''>
            <Card className="cssa" border="success" style={{borderRadius:"20px",width:"300px",marginBottom:"0px",marginTop:"22px",height:"300px"}}> 
            <Card.Header className="bird" style={{fontWeight:"bold",padding:"7px",textTransform:"capitalize"}}>{order.data.customer_name}<span style={{fontSize:"10px"}}>{order.data.customer_phone}</span></Card.Header> 
          
            <Card.Body className="p-2">
           {Object.values(order.data).map((fieldValue,index) => {
            if (Array.isArray(fieldValue)) {
            return (
               <div>
                 <Card.Title style={{fontSize:"13px"}}>order no .{order.data.order_no}</Card.Title>
               <div key={fieldValue} className="duck" style={{overflowY:"scroll"}}>
                
               <Table striped="columns" className="scrollable-table" bg="warning"  >
                
            
                <thead>
                <th style={styles.tableHeader}>Item Name</th>
                <th style={styles.tableHeader}>Quantity</th>
                <th style={styles.tableHeader}>Price(INR)</th>
              </thead>
              {fieldValue.map((item, index) => (
                  
                    <tr key={index}>
                    <td style={styles.tableCell}>{item.food_name}</td>
                    <td style={styles.tableCell}>{item.quantity}</td>
                    <td style={styles.tableCell}>{item.unit_price}</td>
                    </tr>
                     ))}
    </Table>
               
               </div>
               </div>
            )}
                })}
               
               <button type="button" onClick={() => handleProgressButtonClick(order.id)}  class="btn btn-outline-success" style={{marginTop:"10px"}}>Inprogress</button>
              </Card.Body>
            </Card>
           </Col>
      ))} 
      </Row>
    </div>
  );
};


const styles = {
  table: {
    width: '50%',
    borderCollapse: 'collapse',
    margin: 'auto',
    overflow:"scroll",
    
  },
  tableHeader: {
    border:'1px solid black',
    padding: '2px',
    textAlign: 'center',
    color:"black",
  },
  tableCell: {
    border: '1px solid black',
    padding: '2px',
    color:"black",
  },
 
}

export default ChildCard2;
