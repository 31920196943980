import React,{ createContext, useContext, useState, useEffect } from 'react';

// Create the context
export const OrderContext = createContext();

// Provider component to wrap the application and provide the state and dispatch functions
export const OrderProvider = ({ children }) => {
  const [newOrders, setNewOrders] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  // const [customers, setCustomers] = useState([]);

  // const updateNewOrders = (newOrder) => {
  //   setNewOrders((prevOrders) => [...prevOrders, newOrder]);
  // };

  useEffect(() => {
    const newOrdersData = localStorage.getItem('newOrders');
    if (newOrdersData) {
      setNewOrders(JSON.parse(newOrdersData));
    }

    const inProgressOrdersData = localStorage.getItem('inProgressOrders');
    if (inProgressOrdersData) {
      setInProgressOrders(JSON.parse(inProgressOrdersData));
    }

    const readyOrdersData = localStorage.getItem('readyOrders');
    if (readyOrdersData) {
      setReadyOrders(JSON.parse(readyOrdersData));
    }

    const deliveredOrdersData = localStorage.getItem('deliveredOrders');
    if (deliveredOrdersData) {
      setDeliveredOrders(JSON.parse(deliveredOrdersData));
    }

     // Set the flag to indicate that data has been loaded
     
  }, []);

  // Update localStorage whenever the orders state changes
  useEffect(() => {
    localStorage.setItem('newOrders', JSON.stringify(newOrders));
  }, [newOrders]);

  useEffect(() => {
    localStorage.setItem('inProgressOrders', JSON.stringify(inProgressOrders));
  }, [inProgressOrders]);

  useEffect(() => {
    localStorage.setItem('readyOrders', JSON.stringify(readyOrders));
  }, [readyOrders]);

  useEffect(() => {
    localStorage.setItem('deliveredOrders', JSON.stringify(deliveredOrders));
  }, [deliveredOrders]);

  


  return (
    <OrderContext.Provider value={{newOrders,setNewOrders, inProgressOrders, setInProgressOrders, readyOrders, setReadyOrders,deliveredOrders, setDeliveredOrders }}>
      {children}
    </OrderContext.Provider>
  );
};

// Custom hook to access the order context
export const useOrderContext = () => useContext(OrderContext);
