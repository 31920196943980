import React, { useState, useEffect} from "react";
import Header from "../Header/Header";
import Banner from "../Banner/Banner";
import Foods from "../Foods/Foods";
import Blog from "../Blog/Blog";
import Footer from "../Footer/Footer";
import { useHistory , useLocation} from "react-router-dom";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { OrderProvider } from "../kitchen/orderContext";

function Home(){
  const [cart, setCart] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const location = useLocation();
  const history = useHistory();



  useEffect(() => {
    // Fetch data from Firestore and set it to the 'customers' state
    const colRef = collection(db, 'jaya'); // Replace 'your_collection_name' with the actual name of your Firestore collection
    const q = query(colRef);
    const unsub = onSnapshot(q, (snapshot) => {
      setCustomers(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
    });

    return () => {
      unsub();
    };
  }, []);


    // useEffect(() => {
    //   const { isdbcon } = location.state || {};
  
    //   if (isdbcon) {
    //     Swal.fire({
    //       title: 'Error!',
    //       text: 'Your firebase is not configured',
    //       icon: 'error',
    //       confirmButtonColor: '#007500',
    //       confirmButtonText: 'ok'
    //     }).then(okay => {
    //       if (okay) {
    //         Swal.fire({
    //           title: 'Error!',
    //           text: 'Allow to access your firestore',
    //           icon: 'warning',
    //           confirmButtonColor: '#007500',
    //           confirmButtonText: 'allow'
    //         })
    //      }
    //    });
    //   }
    // }, [location.state, history]);

  // function paymentHandler(amount) {
  //   setGrandTotal(amount);
  // }

  // const cartHandler = (currentFood) => {
  //   const alreadyAdded = cart.find((item) => item.id === currentFood.id);

  //   if (alreadyAdded) {
  //     const reamingCarts = cart.filter((item) => cart.id !== currentFood);
  //     setCart(reamingCarts);
  //   } else {
  //     const newCart = [...cart, currentFood];
  //     setCart(newCart);
  //   }
  // };

  // const [deliveryDetails, setDeliveryDetails] = useState({
  //   toDoor: "Delivery To Door",
  //   read: null,
  //   businessName: null,
  //   address: null,
  // });

  // const [orderDetails, setorderDetails] = useState({
  //   deliveryDetails: deliveryDetails,
  //   orderID: null,
  //   timestamp: null,
  // });

  // const setorderDetailsHandler = (data) => {
  //   setorderDetails(data);
  // };

  // const deliveryDetailsHandler = (data) => {
  //   setDeliveryDetails(data);
  // };

  // const checkOutItemHandler = (foodID, foodQuantity) => {
  //   const newCart = cart.map((item) => {
  //     if (item.id === foodID) {
  //       item.quantity = foodQuantity;
  //     }
  //     return item;
  //   });

  //   const filteredCart = newCart.filter((item) => item.quantity > 0);
  //   setCart(filteredCart);
  // };

  // const clearCart = () => {
  //   setCart([]);
  // };



    return(
        <>
        <OrderProvider>
        {/* <Header cart={cart} />
        <Banner /> */}
           <Foods customers={customers}/>
        {/* <Blog />
        <Footer /> */}
        </OrderProvider>
        </>
    )
} 


export default Home;