import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from '../constant.js';
import "./location_credentials.css";

function LocationCredentials(){
    const history = useHistory();
    const shopName= localStorage.getItem('shopName');
    const [credentials, setCredentials] = useState("");

    //redirect to home page
    const handleHome = () =>{
      history.push('/AdminDashboard')
    }

    //list the catgories in select
    useEffect(() => {
        fetchCredentails();
    
    }, []);


//fetch the uom details
    const fetchCredentails = async () => {
       
       try {
        const response = await axios.get(`${var_api}/api/map-credentials/get-map-credential` );
        const use = response.data
      setCredentials(use);
      
      console.log("categories",credentials)
     
      }catch (error) {
        console.log('Error fetching data:', error);
        
        }
      };

 




      

      //to open the form for slot updation 
    const handleEditCredentials = async () => {
        const { value: formValues } = await Swal.fire({
            title: 'Update Credential',
            html: `
             <label class="swalLa">Map Key</label>
             <br/>
              <input id="swal-input1" class="swal2-input fin" name="key_id" placeholder="Map key" value="${credentials.map_key || ''}">
             
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#007500',
            confirmButtonText: 'Submit',
            preConfirm: () => {
              return new Promise(async (resolve) => {
                const input1 = document.getElementById('swal-input1').value;
        
                if (!input1) {
                  Swal.showValidationMessage('Please fill in the required fields');
                  resolve(null);
                } else {

                  const requestData = {
                    map_key: input1 || credentials.map_key
                  };
        
                  try {
                    // Make a PUT request to update the UOM
                    const response = await axios.put(`${var_api}/api/map-credentials/put/${credentials.id}`, requestData, {
                      headers: {
                        'Content-Type': ' application/json', 
                      },
                    });
        
                    if (response.status === 200) {
                        fetchCredentails();
                      // Category updated successfully
                      Swal.fire({
                        icon: 'success',
                        text: 'Credentials updated successfully',
                        confirmButtonColor: '#007500',
                      });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: 'Failed to update Credentials',
                        confirmButtonColor: '#007500',
                      });
                    }
                  } catch (error) {
                    console.error('Error updating Credentials:', error);
                    Swal.fire('Error', 'Failed to update Credentials. Please try again.', 'error');
                  }
        
                  resolve(requestData);
                }
              });
            },
          });
      };




    return(
        <>
        <nav className="navbar navbar-expand navbar-light py-2 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none"}} >
          <h5 className="hefou" style={{textAlign:"center",color:"white",paddingLeft:"5px"}}><i class="bi bi-arrow-left-circle" onClick={handleHome} style={{fontSize:"21px"}}></i>{' '}Location Map Credentials</h5>
          
        </nav>
        <br/>
        <br/>
        <br/>
        <br/>
        <div id='dailybacku'>
          <br/>
          <br/>
          <div id='fiboxu'>
          
            <div class="labelsu">
            <i class="bi bi-pencil-square" onClick={handleEditCredentials}  style={{fontSize:"20px",color:"rgb(9, 97, 9)",float:"right",margin:"0px 15px 0px 15px"}}></i>
            <br/>
            <br/>
            <br/>
            <lable style={{color:"green",fontWeight:"bold",fontSize:"18px",letterSpacing:"2px",padding:"5px"}}><i class="bi bi-geo-alt-fill"></i>Map Key: </lable>{' '}
            <input  className="form-inputul" type='text' name='map_key'
             value={credentials.map_key}
            />
            </div>
           
           
          </div>
          
        </div>
        </>
    )
}

export default LocationCredentials;