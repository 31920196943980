import React, { useState, useEffect } from "react";
import { Link, useHistory} from "react-router-dom";
import { var_api } from "../constant";
import shop_logo from "../../images/splash/shop_logo.jpeg";
import "./SignUp.css";
import Swal from 'sweetalert2';
import axios from "axios";
import { Card, CardImg, CardTitle, CardText, CardColumns,
  CardSubtitle, CardBody } from 'reactstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()


const ForgotPassword = () => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [isSent, setIsSent] = useState(false);

    const submit = async(e) => {
        e.preventDefault();
  
        setIsSent(true);
        try {
          let response;
          const person = localStorage.getItem('passwordFor')
          console.log("person",person);
          if(person === "Employee"){
            response = await axios.post(`${var_api}/api/employees/generateAndSendOTP`, {
              e_email: email
            });

          }else{
            response = await axios.post(`${var_api}/api/user/forgot-password`, {
              email: email
            });

          }
          
          if (response.status === 200) {
               // Save the EMAILID in local storage
         localStorage.setItem('forgotEmail', email);
         toast('OTP sent your email successfully',{autoClose:3000})
         setIsSent(false);
         console.log("email is there", localStorage.getItem('forgotEmail'))
             history.push('/check-otp');
        }
        
        } catch (error) {
          console.error('Error:', error);
          console.error('Error:', error.response, error.response.status);
          if (error.response && error.response.status === 404) {
            // Show the Swal alert for a Bad Request (status code 400)
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              confirmButtonColor: '#007500',
            });
          }
          setIsSent(false);
          // Handle error scenario as needed
        }
        
    }
    

    const handleGoToSignin = () => {
      history.push('/signin');
    }



return(
    <div className="sign-up">
        <br/> 
       {/* <Link to="/" className="navbar-brand">
          <img src={shop_logo} alt="logo" style={{marginLeft:"720px", width:"120px",height:"110px",marginTop:"5px"}} />
        </Link>
        <br/> 
        <br/>  */}
        <Card style={{width:"27rem",height:"30rem",padding:"10px",borderRadius:"25px",border:"none", display: "flex", justifyContent: "center", alignItems: "center",margin:"auto" }}>
          <CardBody>
          <CardTitle style={{textAlign:"center"}}> 
          <Link to="/" className="navbar-brand">
          <img src={shop_logo} alt="logo" style={{margin:"auto", width:"100px",height:"100px",borderRadius:"50px", marginTop:"50px"}} />
        </Link>
          </CardTitle>
          <CardSubtitle>
      <div className="container" style={{width:"370px",margin:"auto"}}>
        
       
          <form onSubmit={submit}  className="py-2">
            <h1 className="lead text-center py-2" style={{color:"rgb(248, 113, 50)", fontSize:"15px", fontWeight: "bolder"}}>Forgot Password ?</h1>
            <h1 className="lead text-center py-1" style={{color:"grey", fontSize:"15px", fontWeight: "bolder"}}>Reset password with SG-Biller</h1>
            <h1 className="lead text-center" style={{fontSize:"15px", fontWeight: "bolder"}}>Enter Your Email and OTP Will Be Sent to You!</h1>
           <br/>
            <div className="form-group">
              <input
              type="email"
                name="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
                style={{height:"7px", borderRadius:"20px"}}
                required
              />
              
            </div>
            <br/>
            <div className="d-grid">
              {
                isSent ? (
                  <div class="text-center" >
                  <div class="spinner-border" role="status" >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) : (
                  <button className="btn btn-success" type="submit" style={{borderRadius:'10px'}}>
                    Send OTP
                  </button>
                )
              }
            </div>

           
            <div className="option text-center my-3">
            <h5  class="m-1 fw-4" style={{ textDecoration:"none", color:"rgb(248, 113, 50)", fontSize:"15px", fontWeight:"bolder", fontFamily:"sans-serif"}} onClick={handleGoToSignin}>
                <span style={{ color:"grey",  fontSize:"13px"}}>Wait I remember My Password..</span>
                Click Here</h5>
    </div>
   
          </form>
        
      </div>
      </CardSubtitle>
         
         </CardBody>
       </Card>
    </div>
)
};

export default ForgotPassword;