import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './daily.css';
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from '../../firebase.js';
import Swal from 'sweetalert2';
import { var_api } from '../constant';
import Modal from 'react-bootstrap/Modal';
import { Tooltip } from 'reactstrap';

function DailyInventory(){
    const history = useHistory();
    const [categories, setcategories] = useState([]);
    const [foods, setFoods] = useState([]);
    const shopName= localStorage.getItem('shopName');
    const [quantity,setQunatity] = useState(0);
    const [target,setTarget] =  useState(0);
    const [selectedcate,setSelectedCate] = useState("");
    const [selectedfood,setSelectedFood] = useState("");
    const [trOrders, setTrOrders] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [ loading, setLoading ] = useState(false);
    const [targetData, setTargetData] = useState(null);
    const [foodId, setFoodId] = useState(null);
    const [isOpen, setIsopen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [puttooltipOpen, setPutToolTipOpen] = useState([]);
    

    //redirect to home page
    const handleHome = () =>{
      history.push('/home')
    }

    //list the catgories in select
    useEffect(() => {
      fetchCate();
      // const colRef = collection(db, `shops/${shopName}/categories`);
      // const q = query(colRef);
      // const unsub = onSnapshot(q, (snapshot) => {
      //   setcategories(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
       
      // });
  
      // return () => {
      //   unsub();
      // };
    }, []);

    const fetchCate = async () => {

      const shopId = localStorage.getItem('shopId');
     try {
      const response = await axios.get(`${var_api}/api/categories/api/categories/${shopId}` );
      const use = response.data

    console.log("categories",use)
    setcategories(use);
    }catch (error) {
      console.log('Error fetching data:', error);
      
      }
    };

    const togglePutTooltip = (index) => {
      const putTooltipOpenCopy = [...puttooltipOpen];
      putTooltipOpenCopy[index] = !putTooltipOpenCopy[index];
      setPutToolTipOpen(putTooltipOpenCopy);
    };
  
   
   //list the food items in select
    const fetchFood=async(optionId)=>{

      try {
        const response = await axios.get(`${var_api}/api/foodMaster/api/subFood/${optionId}` );
        const use = response.data
  
      console.log("categories",use)
      setFoods(use);
       
      }catch (error) {
        console.log('Error fetching data:', error);
        
        }
      // const cOLRef = collection(db, `shops/${shopName}/categories/${optionId}/foodMaster`);
      // const q = query(cOLRef);
      // const unsub = onSnapshot(q,(snapshot) => {
      //   setFoods(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
      // })
    }

   
//get the selected category's id
   const onChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    setSelectedCate(selectedOption.value)
    const optionId = selectedOption.id
    // console.log('Selected Option ID:', optionId);
    fetchFood(optionId);
    }

    //get the value for select food
    const onChangeFoodHandler = (e) => {
      const selectedIndex = e.target.selectedIndex;
      const selectedOption = e.target.options[selectedIndex]
      console.log("foodid",selectedOption);
      setSelectedFood(selectedOption.value)
      setFoodId(selectedOption.id);
    }

   //post the form data
    const Submit = async (e) => {
      e.preventDefault();

      if(parseInt(target)===0 || parseInt(quantity)===0){
        Swal.fire({
          icon:"error",
          text:`All Fields required`,
          confirmButtonColor:"#007500"
        })
        return
      }
    
      try {
        const response = await axios.post(`${var_api}/api/target/api/post`, {
          category_name:selectedcate,
          food_name:selectedfood,
          today_quantity: parseInt(quantity),
          target: parseInt(target),
          shop_name:shopName,
          set_by:parseInt(localStorage.getItem('userId')),
          shop_id: parseInt(localStorage.getItem('shopId')),
          food_id: parseInt(foodId)
        });
    
        if (response.status === 200) {
          Swal.fire({
            icon:"success",
            text:`target has been set for ${selectedfood}`,
            confirmButtonColor:"#007500"
          })
          checkTargetsAndSendAlerts();
          // Reset the select fields to their initial state
          document.getElementById('categorySelect').selectedIndex = 0;
          document.getElementById('foodSelect').selectedIndex = 0;
          setSelectedFood('');
          setSelectedCate('');
          setQunatity(0);
          setTarget(0);
          console.log('Data inserted successfully.')

      }
      } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    };

    //  //get the transaction table data  
    //  useEffect(() => {
    //   const fetchData = async () => {
    //     setLoading(true);
    //     try {
    //       const response = await axios.get(`http://localhost:4300/api/transaction/data/${shopName}/${selectedDate}`);
    //       setTrOrders(response.data);
    //       setLoading(false);
    //     } catch (error) {
    //       setLoading(false);
    //       if (error.response && error.response.status === 404){
    //         //Handle the 404 error using Swal
    //         Swal.fire({
    //           icon:'error',
    //           title: 'Ooops',
    //           text:`No Order items found for ${selectedDate}`,
    //           confirmButtonColor:"#007500"
    //         })
    //         setTrOrders([]);
    //       }
    //       else{
    //         console.log(error);
    //       }
    //     }
    //   };
  
    //   fetchData();
    // }, [selectedDate]);


// handle the date change input
const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    }
  
//check the condition
  useEffect(() => {
    const checkTargetsAndSendAlerts = async () => {
      setLoading(true);
      const shop_id = parseInt(localStorage.getItem('shopId'));
      try {
        // Fetch targets from an API or database
        // const targetResponse = await fetch(`${var_api}/api/target/get/${shopName}/${selectedDate}`);
        // const targetData = await targetResponse.json();
       
        // Fetch transaction data from an API or database
        const transactionResponse = await fetch(`${var_api}/api/transaction/data/${shop_id}/${selectedDate}`);
        const transactionData = await transactionResponse.json();
        // setTargetData(targetData);
        setTrOrders(transactionData);
        setLoading(false);
      } catch (error) {
        console.error('Error checking targets and sending alerts:', error);
      }
    };

    // Initial call when the component mounts
    checkTargetsAndSendAlerts();

   
  }, [ shopName, selectedDate ]);

  const checkTargetsAndSendAlerts = async () => {
    setLoading(true);
    const shop_id = parseInt(localStorage.getItem('shopId'));
      try {
        // Fetch targets from an API or database
        // const targetResponse = await fetch(`${var_api}/api/target/get/${shopName}/${selectedDate}`);
        // const targetData = await targetResponse.json();
       
        // Fetch transaction data from an API or database
        const transactionResponse = await fetch(`${var_api}/api/transaction/data/${shop_id}/${selectedDate}`);
        const transactionData = await transactionResponse.json();
        // setTargetData(targetData);
        setTrOrders(transactionData);
        setLoading(false);
      } catch (error) {
        console.error('Error checking targets and sending alerts:', error);
      }
  };


  //opent the edit modal
  const handleToOpenEdit= (target) => {
    setIsopen(true);
    setTargetData(target);
  }

  //input onchange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTargetData({ ...targetData, [name]: parseInt(value) });
  };

  const handleUpdateTarget = async (e) => {
    e.preventDefault()
    setIsLoading(true);
    const shop_id = parseInt(localStorage.getItem('shopId'))
    const food_id = targetData.food_id;
    try {
      const response = await axios.put(`${var_api}/api/target/target-put/${shop_id}/${food_id}/${selectedDate}`, 
      { target: targetData.target, today_quantity: targetData.today_quantity});
      if (response.status === 200) {
        console.log('Target updated successfully');
        checkTargetsAndSendAlerts();
        setIsopen(false);
        setIsLoading(false);
      }
      } catch (error) {
      console.log('Error updating target:', error);
      setIsopen(false);
      setIsLoading(false);
      }
  }




    return(
        <>
        <nav className="navbar navbar-expand navbar-light py-2 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none"}} >
          <h5 className="hefo" style={{textAlign:"center",color:"white"}}><i class="bi bi-arrow-left-circle" onClick={handleHome} style={{fontSize:"21px"}}></i>{' '}DAILY INVENTORY</h5>
        </nav>
        <div id='dailybackd'>
          <br/>
          <br/>
          <div id='fiboxd'>
            <form onSubmit={Submit}>
                <select class="form-select" aria-label="Default select example" id='categorySelect' onChange={onChangeHandler} required>
                <option value="" disabled selected>select Category</option>
                {categories.map((category) =>(
                <option value={category.category_name} id={category.id} key={category.id} >{category.category_name}</option>
                ))}
              </select>
              <select class="form-select" aria-label="Default select example" id='foodSelect' style={{marginTop:"-83px",marginLeft:"370px"}} onChange={onChangeFoodHandler} required>
              <option value="" disabled selected>select food</option>
              {foods.map((food) =>(
                <option value={food.food_name} id={food.id} key={food.id}>{food.food_name}</option>
                ))}
            </select>
            
            <div class="labelsd">
            <lable style={{color:"green",fontWeight:"bold",fontSize:"18px",letterSpacing:"2px"}}>Today Quantity:</lable>
            <input  className="form-inputd" type='number' name='quantity' pattern='[1-9]'
            min="1"  // Set minimum value to 1
             required
             value={quantity}
             onChange={(e) => setQunatity(e.target.value)}/>
            </div>
            <br/>
            <div class="labelsd">
            <lable style={{color:"green",fontWeight:"bold",fontSize:"18px",letterSpacing:"2px"}}>Target:</lable>
            <input  className="form-inputd1" type='number' name='target' pattern='[1-9]'
            min="1"  // Set minimum value to 1
            required
             value={target}
             onChange={(e) => setTarget(e.target.value)}/>
            </div>
            <br/>
            <br/>
            <button className='btn btn-outline-success' id='dasub'>Submit</button>
            </form>
          </div>
          <br/>
          <br/>
          <div id='tabox'>
          <table class="table table-success table-striped table-hover" >
            <caption className="hefo" style={{captionSide:"top",textAlign:"center",fontSize:"25px",color:"black",fontWeight:"bolder"}}>SUMMARY for
            {' '}
            <input type='date' name='date' value={selectedDate} onChange={handleDateChange} style={{border:"none",borderBottom:"2px solid green"}} placeholder='choose date' />
            </caption>
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Food Name</th>
      <th scope="col">Ordered At</th>
      <th scope="col">Quantity</th>
      <th scope='col'>Target</th>
      <th scope='col'>Action</th>
      <th></th>
    </tr>
  </thead>
  {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
  ) : (
  <tbody>
   { Array.isArray(trOrders) && trOrders.map((trOrder, index) => (
  
    <tr key={trOrder.id}>
      <th scope="row">{index + 1}</th>
      <td>{trOrder.food_name}</td>
      <td>{new Date(trOrder.ordered_at).toLocaleDateString()}</td>
      <td>{trOrder.total_quantity}</td>
      <td>{trOrder.target}</td>
      <td>
         { trOrder.target == 0 ? "-" :
         <>
         <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='left'
      >
          Edit Target
        </Tooltip>
         <i class="bi bi-pencil-square"  id={`putButton${index}`} style={{fontSize:"20px",color:"blue"}} onClick={()=>{handleToOpenEdit(trOrder); togglePutTooltip(index);}}></i>
         </>
        }</td>
      <td>
        {
          trOrder.target == 0 ? <span class="badge rounded-pill bg-warning p-2" style={{color:"white"}}><i class="bi bi-exclamation-circle-fill" style={{color:"white",fontSize:"20px"}}>{' '}</i>No Target Found</span>
          : trOrder.total_quantity < trOrder.target ? <span class="badge rounded-pill bg-danger p-2" style={{color:"white"}}><i class="bi bi-x-circle-fill" style={{color:"white",fontSize:"20px"}}>{' '}</i>Not Reached Target</span> :
          trOrder.total_quantity >= trOrder.target ? <span class="badge rounded-pill bg-success p-2" style={{color:"white"}}><i class="bi bi-check-circle-fill" style={{color:"white",fontSize:"20px"}}>{' '}</i>Reached Target</span>
           : null
        }
      
      </td>
    </tr>
   ))}
   </tbody>
  )}
</table>
          </div>
        </div>
        <Modal
  show={isOpen}
  backdrop="static"
  keyboard={false}
  onHide={()=>setIsopen(false)}
  size="lg"
>
  <Modal.Header closeButton  style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
    <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}>Update {targetData && targetData.food_name} Target</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <h5 style={{textAlign:"left", fontFamily:"serif", color:"red"}}>Food Name: <span style={{fontWeight:"bolder",color:"black"}}>{targetData && targetData.food_name}</span></h5>
    <br/>
 <form>
 <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Today Quantity</label>
              <input type="number" class="form-control" placeholder="today_quantity" id="input1" name="today_quantity" value={targetData && targetData.today_quantity} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Target</label>
              <input type="number" class="form-control" placeholder="enter target" id="input4" name="target" value={targetData && targetData.target} onChange={handleInputChange} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <button className='btn btn-danger' style={{marginTop:"10px",float:"right"}} onClick={()=>setIsopen(false)}>Cancel</button>
          {isLoading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (
          <button className='btn btn-success' style={{marginTop:"10px",float:"right",marginRight:"5px"}} onClick={handleUpdateTarget}>Update</button>
      )}
 </form>
      
  </Modal.Body>
</Modal>
      
        </>
    )
}

export default DailyInventory;