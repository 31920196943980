import React,{useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';
import { var_api } from "../constant";
import axios from "axios";
import Select from 'react-select';
import Bill from "../Foods/bill";
import { Modal,Button } from "react-bootstrap";
import Pagination from 'react-js-pagination';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()



const VoidTransaction = () => {
    const history = useHistory();
    const [labelCodeOptions] = useState([
        { value: '0', label: 'All' },
        { value: '1', label: 'Normal Order' },
        { value: '2', label: 'Credit Order' },
        { value: '3', label: 'Group Location Order' },
        { value: '4', label: 'Normal Table Order' },
        { value: '5', label: 'Credit Table Order' },
    ]);
    const [selectedOrderLabel, setSelectedOrderLabel] = useState({ value: '0', label: 'All' });
    const [orderHistory, setOrderhistory] = useState([]);
    const [searchQuery, setSearchQuery] = useState(" ");
    const [isOpenSelectDate, setIsOpenSelectDate] = useState(false);
    const [isOpenBill, setIsOpenBill] = useState(false);
    const [ selectedOrderBill,setSelectedOrderBill] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [startIndex, setStartIndex] = useState(0);   
    const itemsPerPage = 25; 
    const [isVoidConfirmation, setIsVoidConfirmation] = useState(false);


    // Function to get formatted date (dd-mm-yyyy) from a Date object
    const getFormattedDate = (date) => {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return `${dd}-${mm}-${yyyy}`;
    };

    const [startDate, setStartDate] = useState(getFormattedDate(new Date()));
    const [endDate, setEndDate] = useState(getFormattedDate(new Date()));


    useEffect(() => {
        // Fetch orders history and summary when selectedOrderLabel, startDate, or endDate changes
        if (selectedOrderLabel.value !== '' && startDate !== '' && endDate !== '') {
            fetchAllOrdersHistory(selectedOrderLabel.value);
        }
        fetchAllOrdersHistory();
    }, [selectedOrderLabel, startDate, endDate]);


    const handleOrderLabelSelectChange = (selectedOption) => {
        setSelectedOrderLabel(selectedOption);
        fetchAllOrdersHistory(selectedOption.value);
      };


      const handleStartDateChange = (event) => {
        const startDate = event.target.value;
        setStartDate(formatDate(startDate));
      };
    
      const handleEndDateChange = (event) => {
        const endDate = event.target.value;
        setEndDate(formatDate(endDate));
      };


      const formatDate =(dateString) =>{
        const [day, month, year] = dateString.split('-');
        return `${year}-${month}-${day}`;
      }
    
    


    //go to home page
  const handleButtonClickhome = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
};


  //void transaction list
  const fetchAllOrdersHistory = async (order_label) => {
    const startIndexCalc = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndexCalc + itemsPerPage;
    const shopId = localStorage.getItem('shopId');
    try {
        const response = await axios.get(`${var_api}/api/food/void-transaction-data/${shopId}/${order_label}/${startDate}/${endDate}`);
        const orderHistoryData = response.data;
        if (orderHistoryData.length > 0) {
            setOrderhistory(orderHistoryData.slice(startIndexCalc, endIndex));
            setTotalItems(orderHistoryData.length);
            setStartIndex(startIndexCalc);
        } else {
            console.log('No data found.');
            setOrderhistory([]);
            setTotalItems(0);
            setStartIndex(0); 
        }
    } catch (error) {
        console.log('Error fetching data:', error);
    }
};


const shopName = localStorage.getItem('shopName');
const role = localStorage.getItem('e_role');


  //get the value in search and set the setstate
  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value)
    };


    const handleOpenSelectDate = () => {
        setIsOpenSelectDate(true);
      };


      //open the bill modal
    const  handleOpenPrintBill = (data) => {
        setIsOpenBill(true);
        setSelectedOrderBill(data);
    }

    const handleSelectDateRange = () => {
        fetchAllOrdersHistory(selectedOrderLabel.value);
        setIsOpenSelectDate(false);
      };


      const handleCloseDateConfirmation = () => {
        setIsOpenSelectDate(false);
      };


      const filteredOrders = Array.isArray(orderHistory) && orderHistory.filter((order)=> {
        const searchLower = searchQuery.toLowerCase();
        return(
            (order.order_no && order.order_no.toString().toLowerCase().includes(searchLower)) ||
            (order.ordered_at && order.ordered_at.toString().toLowerCase().includes(searchLower)) || 
            (order.total && order.total.toString().toLowerCase().includes(searchLower)) ||
            ((order.order_type === 0 && order.order_label === 0 && order.is_group_location_order === 0) && "normal order".includes(searchLower)) ||
            ((order.order_type === 0 && order.order_label === 1 && order.is_group_location_order === 0) && "credit order".includes(searchLower)) ||
            ((order.order_type === 0 && order.order_label === 1 && order.is_group_location_order === 1) && "group location order".includes(searchLower)) ||
            ((order.order_type === 1 && order.order_label === 0 && order.is_group_location_order === 0) && "table normal order".includes(searchLower)) ||
            ((order.order_type === 1 && order.order_label === 1 && order.is_group_location_order === 0) && "table credit order".includes(searchLower))
        )
    });


    const handleToOpenVoidConfirmation = (data) => {
        setIsVoidConfirmation(true);
        setSelectedOrderBill(data);
    }


    const handleVoidPut = () => {
        const shopID = localStorage.getItem('shopId');
        const orderId = selectedOrderBill.id;
         
        const updatedStatus = {
          is_void:0,
         }
      
         axios
         .put(`${var_api}/api/food/put/is-void/${shopID}/${orderId}`, updatedStatus)
         .then((response) => {
          fetchAllOrdersHistory(selectedOrderLabel.value);
          toast('void status updated',{autoClose:2000})
           console.log('Data updated successfully:', response.data.message);
           setIsVoidConfirmation(false);
         })
         .catch((error) => {
           console.error('Error updating data:', error);
         });
      };
      

    return(
        <div class="whole"  style={{ textAlign: "center" ,height:"100%"}}>
        <div className="container-fluid">
            <nav className="navbar" style={{ boxShadow: "0px 0px 1px 0px black", backgroundColor: "green", border: "none", padding: "5px", height: "50px", width: "100%", position: "relative"}}>
                <div style={{ display: "flex"}}>
                    <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "15px", color: "white" }}>{ shopName }</h3>
                  
                </div>
                {role === "Chef" || role === "Captain" || role === "Waiter" ? null : (
                    <button className="btn" onClick={handleButtonClickhome} style={{ backgroundColor: "white", color: "green" , float:"right"}}>
                        <i class="bi bi-house"></i> Go to Home
                    </button> )}
            </nav>
        </div>
          <div>
                <br />
            <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",textAlign: "center", color: "black" }}>Void Transactions</h3>
                <div className="card" style={{ maxWidth: '40%', height:'auto',margin:"auto"}}>
        <div className="card-body d-flex flex-column justify-content-center align-items-center">
            <div style={{ display: 'flex', justifyContent: 'center', gap: "35px" }}>
            <Select
                    options={labelCodeOptions}
                    placeholder="Select Order"
                    value={selectedOrderLabel}
                    onChange={handleOrderLabelSelectChange}
                    isSearchable={true}
                    menuPlacement="top"
                    className="customSelectFood"
                />
                <label style={{ fontFamily: "serif", fontWeight: "bolder" }}>Date Range: <span className="text-primary" onClick={handleOpenSelectDate}>{startDate} to {endDate}</span>
                {/* <Tooltip
            isOpen={tooltipOpen}
            target="DateIcon" // Specify the target ID of the button
            toggle={toggleTooltip}
            placement="bottom"
          >
            Change Date
          </Tooltip> */}
                <i className="bi bi-calendar text-primary"  id="DateIcon" style={{ marginLeft: '5px', fontSize: "20px" }} onClick={()=>handleOpenSelectDate()}></i> </label>
                
    
            </div>
    
            {/* <div style={{ display: 'flex', alignItems: 'center', gap: "10px", marginTop: "10px" }}>
                <Select
                    options={labelCodeOptions}
                    placeholder="Select Order"
                    value={selectedOrderLabel}
                    onChange={handleOrderLabelSelectChange}
                    isSearchable={true}
                    menuPlacement="top"
                    className="customSelectFood"
                />
                <button className="btn btn-danger text-white p-1" onClick={handleOpenOrderSumModal}>Order Summary</button>
            </div> */}
        </div>
    </div>
    
            </div>
            <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
          style={{width:"230px",borderRadius:"15px",border:"1px solid black",float:"right",marginRight:"30px"}} onChange={handleSearchInput}/>
    <br />
                <br />
                
                <div className="table-container" style={{ height: "auto", overflowX: 'auto', overflowY: 'scroll' }}>
                    <table class="table table-hover small-first-col" style={{ border: "none", borderColor: "grey", width: "95%", margin: "auto" ,height:"50%"}}>
                        <thead>
                            <tr style={{ fontWeight: "bolder" }}>
                                <th scope="col">S.No</th>
                                <th scope="col">Order Date</th>
                                <th scope="col">Order No</th>
                                <th scope="col">Order Type</th>
                                <th scope="col">No.of Items</th>
                                <th scope="col">Total Amount</th>
                                <th scope='col'>Action</th>
                            </tr>
                        </thead>
                        <tbody class="body-half-screen">
                            {filteredOrders.length > 0 ? (
                                filteredOrders.map((data, index) => (
                                    <tr style={{ padding: "5px" }}>
                                        <td scope="row">{ index + 1}</td>
                                        <td>{data.ordered_at}</td>
                                        <td>{data.order_no}</td>
                                        <td>
                                        {data.order_type === 0 && data.order_label === 0 && data.is_group_location_order ===0 && "Normal Order"}
                                        {data.order_type === 0 && data.order_label === 1 && data.is_group_location_order ===0 && "Credit Order"}
                                        {data.order_type === 0 && data.order_label === 1 && data.is_group_location_order ===1 && "Group Location Order"}
                                        {data.order_type === 1 && data.order_label === 0 && data.is_group_location_order ===0 && "Table Normal Order"}
                                        {data.order_type === 1 && data.order_label === 1 && data.is_group_location_order ===0 && "Table Credit Order"}</td>
                                        <td>{data.items? data.items.length : 0}</td>
                                        <td>{data.total}</td>
                                        <td>
                                            <button className="btn btn-primary" onClick={() => handleOpenPrintBill(data)}><i class="bi bi-eye-fill"></i></button>
                                            <button className="btn btn-warning" style={{marginLeft:"10px", color:"white",padding:"5px 9px"}} onClick={() => handleToOpenVoidConfirmation(data)}><i class="bi bi-arrow-counterclockwise" style={{fontSize:"20px"}}></i></button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: "center" }}>
                                        No Data Available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <br />
                    <Pagination
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={totalItems} // Update to reflect total items for pagination
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
        innerClass="pagination justify-content-end"
        activeClass="active-pagination" // Custom class for the active pagination item
    />
                </div>


                <Modal
    show={isOpenBill}
    onHide={()=>setIsOpenBill(false)}
    backdrop="static"
    keyboard={false}
    size="md"
    style={{ borderRadius: '20px' }}
>
    <Modal.Header closeButton style={{ backgroundColor: "rgba(6, 105, 16, 0.367)", padding: "12px" }}>
        <Modal.Title style={{ fontSize: "19px", fontWeight: "bold" }}>Order Bill</Modal.Title>
    </Modal.Header>
    <Modal.Body >
    
        <br />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '10px' }}>
            <Bill order={selectedOrderBill}/>
        </div>
       
    </div>
    </Modal.Body>
</Modal>



<Modal
    show={isVoidConfirmation}
    onHide={()=>setIsVoidConfirmation(false)}
    backdrop="static"
    keyboard={false}
    size="md"
    style={{ borderRadius: '20px' }}
>
    <Modal.Header closeButton>
        <Modal.Title >Confirm!</Modal.Title>
    </Modal.Header>
    <Modal.Body >
      
        <div style={{ padding:"15px" }}>
            <h6>Are You Sure You Want to UnVoid The  <span style={{fontWeight:"bold", color:"red", fontSize:"20px"}}>Order No: {selectedOrderBill.order_no}</span> ?</h6>
        </div>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="danger" onClick={()=>setIsVoidConfirmation(false)}>Cancel</Button>
        <Button variant="success" onClick={handleVoidPut}>Confirm</Button>
    </Modal.Footer>
</Modal>



<Modal
                show={isOpenSelectDate}
                onHide={handleCloseDateConfirmation}
                backdrop="static"
                keyboard={false}
                size="md"
                style={{ borderRadius: '20px' }}
            >
                <Modal.Header closeButton style={{ backgroundColor: "rgba(6, 105, 16, 0.367)", padding: "12px" }}>
                    <Modal.Title style={{ fontSize: "19px", fontWeight: "bold" }}>Select Date Range</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                    <div className="col">
                            <label style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Start Date:</label>
                            <input
                                type='date'
                                name='startDate'
                                style={{ border: "2px ridge green", borderRadius: "15px", height: "35px", padding: "10px" }}
                                placeholder='choose date'
                                onChange={handleStartDateChange}
                                value={formatDate(startDate)}
                            />
                        </div>
                        <div className="col">
                            <label style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>End Date:</label>
                            <input
                                type="date"
                                name='date'
                                placeholder="choose date"
                                onChange={handleEndDateChange}
                                style={{ border: "2px ridge green", borderRadius: "15px", height: "35px", padding: "10px" }}
                                value={formatDate(endDate)}
                                min={formatDate(startDate)}
                            />
                        </div>
                    </div>
                    <br />
                    <div style={{ textAlign: "center" }}>
                        <Button variant="danger" onClick={handleCloseDateConfirmation}>Close</Button>{' '}
                        <Button variant="success" style={{ marginRight: "5px" }} onClick={handleSelectDateRange}>Ok</Button>
                    </div>
                </Modal.Body>
            </Modal>
            
</div>
    )
}


export default VoidTransaction;