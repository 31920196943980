import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { AuthProvider, PrivateRoute } from "./components/SignUp/useAuth";
// import Header from "./components/Header/Header";
// import Banner from "./components/Banner/Banner";
// import Foods from "./components/Foods/Foods";
// import FoodDetails from "./components/FoodDetails/FoodDetails";
// import Blog from "./components/Blog/Blog";
// import Footer from "./components/Footer/Footer";
import NotFound from "./components/NotFound/NotFound";
import SignUp from "./components/SignUp/SignUp";
import Splash from "./components/splash/splash";
import Home from "./components/Home/home";
import Shop from "./components/ShopCreation/shop";
import 'react-toastify/dist/ReactToastify.css';
import Subscrip from "./components/subscription/subscription";
import Category from "./components/categories/category";
import FoodMaster from "./components/foodMaster/foodm";
import ShopMaster from "./components/shop master/shopm";
import SignIn from "./components/SignUp/signin";
import Kitchen from "./components/kitchen/kitchen";
import Deliver from "./components/Delivery/deliver";
import ChildCard2 from "./components/kitchen/childCard2";
import AddCart from "./components/cart/cart";
import DownloadPdf from "./components/downloadfile/downloadpdf";
import DailyInventory from "./components/Daily_Invention/daily";
import TableOrders from "./components/tableOrders/tableOrder";
import CreditCustomer from "./components/creditCustomers/creditCustomer";
import Report from "./components/report/report";
import ContractFood from "./components/contract/contract";
import GroupOrders from "./components/GroupOrders/groupOrders";
import ForgotPassword from "./components/SignUp/forgotpassword";
import CheckOTP from "./components/SignUp/checkotp";
import ChangePassword from "./components/SignUp/changepassword";
import UomMaster from "./components/uomMaster/uom_master";
import SupplierMaster from "./components/supplierMaster/supplier_master";
import TableMaster from "./components/tablemaster/table_master";
import RawMaterial from "./components/rawmaterial_master/rawMaterial-master";
import EmployeeMaster from "./components/employeemaster/employee-master";
import AdminSignin from "./components/super_admin/admin_signin";
import AdminDashboard from "./components/super_admin/admin_dashboard";
import AdminHome from "./components/super_admin/admin_home";
import AdminShopMaster from "./components/super_admin/admin_shopmaster";
import AdminCategoryMaster from "./components/super_admin/admin_categorymaster";
import AdminFoodMaster from "./components/super_admin/admin_foodmaster";
import AdminUomMaster from "./components/super_admin/admin_uommaster";
import CurrencyMaster from "./components/super_admin/currecny_master";
import PaymentCredentials from "./components/super_admin/payment_credentials";
import AdminRawMaterial from "./components/super_admin/admin_raw_material";
import AdminSupplierMaster from "./components/super_admin/admin_suppliermaster";
import AdminTableMaster from "./components/super_admin/admin_tablemaster";
import AdminEmployeeMaster from "./components/super_admin/admin_employeemaster";
import LocationCredentials from "./components/super_admin/location_credentials";
import SubscriptionPlans from "./components/super_admin/subscription_plans";
import Mysubscriptionhistory from "./components/Mysubscriptionhistory/Mysubscriptionhistory";
import Renewsubscription from "./components/Renewsubscription/Renewsubscription";
import Outstandingpayment from "./components/Outstandingpayment/Outstandingpayment";
import Itemsummary from "./components/Itemsummary/Itemsummary";
import Orderhistory from "./components/Orderhistory/Orderhistory";
import Settlements from "./components/Settlements/Settlements";
import ParcelStatus from "./components/ParcelStatus/ParcelStatus";
import NewSettlement from "./components/NewSettlement/NewSettlement";
import SubKitchenMaster from "./components/subKitchenMaster/SubKitchenMaster";
import SubKitchen from "./components/SubKitchen/SubKitchen";
import SbHOme from "./components/ServiceBooking/home";
import PaymodeMaster from "./components/super_admin/paymode_master";
import SbReport from "./components/ServiceBooking/reports/reports";
import AttendanceBreaks from "./components/ServiceBooking/attendanceBreaks/attendanceBreaks";
import MyProfile from "./components/MyProfile/myprofile";
import PaymodeStatusMaster from "./components/paymodeStatusMaster/paymodeStatusMaster";
import VoidTransaction from "./components/VoidTransaction/VoidTransaction";
import PurchaseOrders from "./components/purchaseOrders/purchaseOrders";
import StoreGrn from "./components/storeGRN/storeGrn";
import DayClosing from "./components/DayClosing/DayClosing";
import StockAdjustments from "./components/stockAdjustments/stockAdjustments";
import StockTake from "./components/stocktake/stocktake";
import StockTakeDetails from "./components/stocktake/stocktakeDetails";
import StockTransaction from "./components/stocktransaction/stocktransaction";
import GLAccountsMaster from "./components/glaccountMaster/glaccountsMaster";
import GLFinanceSummary from "./components/glaccountMaster/glfinanceSummary";
import StockBalanceReport from "./components/stockbalancereport/stockbalancereport";
import PandLReport from "./components/pnlreport/pnlreport";

function App() {
  const [cart, setCart] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  function paymentHandler(amount) {
    setGrandTotal(amount);
  }

  const cartHandler = (currentFood) => {
    const alreadyAdded = cart.find((item) => item.id === currentFood.id);

    if (alreadyAdded) {
      const reamingCarts = cart.filter((item) => cart.id !== currentFood);
      setCart(reamingCarts);
    } else {
      const newCart = [...cart, currentFood];
      setCart(newCart);
    }
  };

  const [deliveryDetails, setDeliveryDetails] = useState({
    toDoor: "Delivery To Door",
    read: null,
    businessName: null,
    address: null,
  });

  const [orderDetails, setorderDetails] = useState({
    deliveryDetails: deliveryDetails,
    orderID: null,
    timestamp: null,
  });

  const setorderDetailsHandler = (data) => {
    setorderDetails(data);
  };

  const deliveryDetailsHandler = (data) => {
    setDeliveryDetails(data);
  };

  const checkOutItemHandler = (foodID, foodQuantity) => {
    const newCart = cart.map((item) => {
      if (item.id === foodID) {
        item.quantity = foodQuantity;
      }
      return item;
    });

    const filteredCart = newCart.filter((item) => item.quantity > 0);
    setCart(filteredCart);
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    
      <Router basename="/">
        <Switch>
          <Route exact path="/">
          <Splash />
          </Route>

          <Route path='/home'>
            <Home />
          </Route>

          <Route path='/signin'>
            <SignIn />
          </Route>

          <Route path='/shopmas'>
            <ShopMaster />
          </Route>

          <Route path='/deliver'>
            <Deliver />
          </Route>

          <Route path='/sub'>
            <Subscrip />
          </Route>

          <Route path='/shop'>
            <Shop />
          </Route>

          
          {/* <Route path='/cate/:shopName'> */}
          <Route path='/cate'>
            <Category />
          </Route>

          <Route path='/kitchen'>
            <Kitchen />
          </Route>

          <Route path='/child1'>
            <ChildCard2 />
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>

          <Route path="/download">
            <DownloadPdf />
          </Route>

          <Route path="/daily">
            <DailyInventory />
          </Route>

          <Route path="/tableOrders">
            <TableOrders />
          </Route>

          <Route path="/creditUser">
            <CreditCustomer />
          </Route>


          <Route path="/foodm/:id" component={FoodMaster}>
            <FoodMaster />
          </Route>

          <Route path="/cart" >
            <AddCart />
          </Route>

          <Route path="/report" >
            <Report />
          </Route>
          <Route path="/contract" >
            <ContractFood />
          </Route>
          <Route path="/groupOrders" >
            <GroupOrders />
          </Route>

          <Route path="/forgot-password" >
            <ForgotPassword />
          </Route>

          <Route path="/check-otp" >
            <CheckOTP />
          </Route>

          <Route path="/change-password" >
            <ChangePassword />
          </Route>

          <Route path="/uom-master" >
            <UomMaster />
          </Route>

          <Route path="/supplier-master" >
            <SupplierMaster />
          </Route>

          <Route path="/RawMaterial-master" >
            <RawMaterial />
          </Route>

          <Route path="/table-master" >
            <TableMaster />
          </Route>

          <Route path="/employee-master" >
            <EmployeeMaster />
          </Route>

          <Route path="/admin" >
            <AdminSignin />
          </Route>

          <Route path="/AdminDashboard" >
            <AdminDashboard />
          </Route>

          <Route path="/AdminHome" >
            <AdminHome />
          </Route>

          <Route path="/AdminShopMaster" >
            <AdminShopMaster />
          </Route>

          <Route path="/AdminCategoryMaster" >
            <AdminCategoryMaster />
          </Route>

          <Route path="/adminfoodm/:id" component={AdminFoodMaster} >
            <AdminFoodMaster />
          </Route>

          <Route path="/AdminUomMaster" >
            <AdminUomMaster />
          </Route>

          <Route path="/currency-master" >
            <CurrencyMaster />
          </Route>

          <Route path="/payment-credentials" >
            <PaymentCredentials />
          </Route>

          <Route path="/Adminraw-material" >
            <AdminRawMaterial />
          </Route>

          <Route path="/Adminsupplier-master" >
            <AdminSupplierMaster />
          </Route>

          <Route path="/Admintable-master" >
            <AdminTableMaster />
          </Route>

          <Route path="/Adminemployee-master" >
            <AdminEmployeeMaster />
          </Route>

          <Route path="/location-credentials" >
            <LocationCredentials />
          </Route>

          <Route path="/subscription-plans" >
            <SubscriptionPlans />
          </Route>

          <Route path="/Mysubscriptionhistory" >
            <Mysubscriptionhistory />
          </Route>

          <Route path="/Renewsubscription" >
            <Renewsubscription />
          </Route>

          <Route path="/Outstandingpayment" >
            <Outstandingpayment />
          </Route>

          <Route path="/item-summary" >
            <Itemsummary />
          </Route>

          <Route path="/order-history" >
            <Orderhistory />
          </Route>

          <Route path="/expense-log" >
            <Settlements />
          </Route>

          <Route path="/parcel-kitchen" >
            <ParcelStatus />
          </Route>

          <Route path="/new-settlement" >
            <NewSettlement />
          </Route>

          <Route path="/sub-kitchen-master" >
            <SubKitchenMaster />
          </Route>

          <Route path="/sub-kitchen" >
            <SubKitchen />
          </Route>

          <Route path="/pay-mode" >
            <PaymodeMaster />
          </Route>

          <Route path="/my-profile" >
            <MyProfile />
          </Route>

          <Route path="/paymode-status-master">
            <PaymodeStatusMaster />
          </Route>

          <Route path="/void-transaction">
            <VoidTransaction />
          </Route>

          <Route path="/purchase-orders">
            <PurchaseOrders />
          </Route>

          <Route path="/store-grn">
            <StoreGrn />
          </Route>

          <Route path="/day-closing">
            <DayClosing />
          </Route>

          <Route path="/stock-adjustments">
            <StockAdjustments />
          </Route>

          <Route path="/stock-transaction">
            <StockTransaction />
          </Route>

          <Route path="/stock-take">
            <StockTake />
          </Route>

          <Route path="/stock-take-details">
            <StockTakeDetails />
          </Route>

          <Route path="/glaccounts-Master" >
            <GLAccountsMaster />
          </Route>

          <Route path="/glfinance-summary" >
            <GLFinanceSummary />
          </Route>

          <Route path="/stock-balance-report" >
            <StockBalanceReport />
          </Route>

          <Route path="/profit-and-loss-report" >
            <PandLReport />
          </Route>



          
          <Route path="/sb-home" >
            <SbHOme />
          </Route>

          <Route path="/sb-report">
            <SbReport />
          </Route>

          <Route path="/sb-attendance">
            <AttendanceBreaks />
          </Route>

        </Switch>
      </Router>
   
  );
}

export default App;
