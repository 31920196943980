import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Card, Form, Row, Col, Navbar, Table, Container, Button } from 'react-bootstrap';
import axios from 'axios';
import { var_api } from "../constant";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';

toast.configure()

const StockAdjustments = () => {
    const history = useHistory();
    const shop_id = localStorage.getItem("shopId");
    const shopCurrency = localStorage.getItem('shopCurrency');
    const empID = localStorage.getItem('admin_employee');
    const [formData, setFormData] = useState({
        reference_no: '',
        authorized_by: '',
        checked_by: '',
        adjustment_date: '',
        description: '',
        shop_id: parseInt(shop_id), // Example shop_id, replace with actual value
        status: 0, // Example status, replace with actual value
        created_at:  new Date().toISOString().slice(0, 19).replace('T', ' '),
        updated_at: new Date().toISOString().slice(0, 19).replace('T', ' ')
    });
    const [stockAdjustments, setStockAdjustments] = useState([]);
    const [selectedStockAdjustment, setSelectedStockAdjustment] = useState(null);
    const [isdelete, SetIsDelete]  = useState(false);
    const [deletedData, setDeletedData] = useState(null);
    const [isView, setIsView] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [adjustmentDetails, setAdjustmentDetails] = useState([]);
    const [items, setItems] = useState([]);
    const [formDataItems, setFormDataItems] = useState({
        st_ad_id: selectedStockAdjustment && selectedStockAdjustment.id,
        shop_id: parseInt(shop_id),
        stock_code: '',
        reason_code: '',
        stock_unit_in_value:null,
        stock_in_value: null,
        stock_in_qty: null,
        stock_out_value: null,
        stock_out_qty: null
      });
    const [employees, setEmployees] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({ account_no: '', account_name: '' });
    const [searchQuery, setSearchQuery] = useState('');


    useEffect(() => {
        fetchStockAdjustments();
        fetchItems();
        fetchEmployees();
        fetchAccounts();
    }, []);

    const fetchStockAdjustments = async () => {
        try {
            const response = await axios.get(`${var_api}/api/stock-adjustments/get-stock-adjustments/${shop_id}`);
            setStockAdjustments(response.data);
        } catch (error) {
            console.error('Error fetching stock adjustments:', error);
        }
    };


    const fetchEmployees = async () => {
      const userid = localStorage.getItem("userId");

        try {
            const response = await axios.get(`${var_api}/api/employees/get-emplyees/${userid}/${shop_id}`);
            setEmployees(response.data.results);
        } catch (error) {
            console.error('Error fetching stock adjustments:', error);
        }
    };


    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${var_api}/api/gl-accounts-master/get-accounts/${shop_id}`);
            setAccounts(response.data);
        } catch (error) {
            console.error('Error fetching stock adjustments:', error);
        }
    };


    const fetchItems = async () => {
        try {
          const response = await axios.get(`${var_api}/api/raw-items/get-items/${shop_id}`);
          console.log("stock Items",response.data)
          setItems(response.data);
        } catch (error) {
          console.error('Error fetching items:', error);
          toast('Error Fetching items');
        }
      };


    const fetchAdjustmentsDetails = async () => {
        try {
            const response = await axios.get(`${var_api}/api/stock-adjustments-details/get-stock-adjustments-details/${shop_id}/${selectedStockAdjustment.id}`);
            setAdjustmentDetails(response.data);
        } catch (error) {
            console.error('Error fetching stock adjustments:', error);
        }
    };

    const handleButtonClick = () => {
        history.push('/home');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: (name === 'authorized_by' || name === 'checked_by') ? parseInt(value) : value,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            if (selectedStockAdjustment) {
                // Update existing stock adjustment
                await axios.put(`${var_api}/api/stock-adjustments/put/${selectedStockAdjustment.id}`, formData);
            } else {
                // Create new stock adjustment
                await axios.post(`${var_api}/api/stock-adjustments/post`, formData);
            }
            fetchStockAdjustments();
            resetForm();
            toast("successfull!")
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const resetForm = () => {
        setFormData({
            reference_no: '',
            authorized_by: '',
            checked_by: '',
            adjustment_date: '',
            description: '',
        });
        setSelectedStockAdjustment(null);
    };

    const handleEditClick = (stockAdjustment) => {
        setSelectedStockAdjustment(stockAdjustment);
        setFormData({
          reference_no: stockAdjustment.reference_no,
          authorized_by: stockAdjustment.authorized_by,
          checked_by: stockAdjustment.checked_by,
          adjustment_date: stockAdjustment.adjustment_date,
          description: stockAdjustment.description,
          shop_id: stockAdjustment.shop_id, // Example shop_id, replace with actual value
          status: stockAdjustment.status,
          adjustment_date: stockAdjustment.adjustment_date.split('T')[0], // Adjust date format for input
        });
    };


    const handleDeleteClick = async (adjs) => {
      SetIsDelete(true);
      setDeletedData(adjs);
    };

    const handleDeleteAdjustment = async () => {
        try {
            await axios.delete(`${var_api}/api/stock-adjustments/delete/${deletedData.id}`);
            fetchStockAdjustments();
            toast("Deleted Successfully");
            SetIsDelete(false);
        } catch (error) {
            console.error('Error deleting stock adjustment:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const handleViewDetails = (stockAdjustment) => {
        setSelectedStockAdjustment(stockAdjustment);
        console.log("sta", stockAdjustment)
        setFormData({
            ...stockAdjustment,
            adjustment_date: stockAdjustment.adjustment_date.split('T')[0], // Adjust date format for input
        });
        setIsView(true);
    };


    const handleToDetails = () => {
        setIsDetail(true)
        fetchAdjustmentsDetails();
        console.log("st", selectedStockAdjustment)
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormDataItems((prevData) => ({
          ...prevData,
          [name]: (name === 'stock_code' || name === 'reason_code') ? value : parseInt(value),
          st_ad_id: selectedStockAdjustment.id
        }));
      };


      const handleSubmit = async (e) => {
        e.preventDefault();
        setFormDataItems({...formDataItems, st_ad_id: selectedStockAdjustment.id})
        try {
          const response = await axios.post(`${var_api}/api/stock-adjustments-details/post`,formDataItems);
          console.log('Stock adjustment detail created successfully', response.data);
          toast('stock adjustment detail created successfully');
          fetchAdjustmentsDetails();
         setFormDataItems({
            st_ad_id: selectedStockAdjustment && selectedStockAdjustment.id,
            shop_id: parseInt(shop_id),
            stock_code: '',
            reason_code: '',
            stock_unit_in_value:null,
            stock_in_value: null,
            stock_in_qty: null,
            stock_out_value: null,
            stock_out_qty: null
          });
          // Handle successful submission, e.g., clear the form, show a success message, etc.
        } catch (error) {
          console.error('Error creating stock adjustment detail:', error.response?.data || error.message);
          // Handle error, e.g., show an error message
        }
      };

      const handlePost = async() =>{
        // Check if selectedAccount is valid
        if (!selectedAccount.account_no || !selectedAccount.account_name) {
          alert('Please select a valid GL account.');
          return;
        }
        const forms = {
          status : 1
        }
        try {
          const response = await axios.put(`${var_api}/api/stock-adjustments/put/${selectedStockAdjustment.id}`, forms);
              if (response.status === 200) {
                await handlestocktransactionpost();
                await fetchStockAdjustments();
                await handleGlSubmit();
                setFormData({
                  reference_no: '',
                  authorized_by: '',
                  checked_by: '',
                  adjustment_date: '',
                  description: '',
              });
                setIsDetail(false);
                setIsView(false);
                toast("Successful!");
              }
      } catch (error) {
          console.error('Error submitting form:', error);
      }
      }


      const handlestocktransactionpost = async () => {
        const empID = localStorage.getItem("admin_employee")
        const payload = adjustmentDetails.flatMap(item => {
          const transactions = [];
      
          // For stock in (credit)
          if (item.stock_in_qty > 0) {
            transactions.push({
              shop_id: item.shop_id,
              stock_code: item.stock_code ? item.stock_code : item.non_stock_code,
              stock_type_id: 1,
              transaction_type_code: "Adjustment_to_Store",
              operation: "credit",
              qty: item.stock_in_qty,
              si_no: 1,
              grn_id: item.st_ad_id,
              grn_item_id: item.id,
              created_by: parseInt(empID),
              created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              updated_by: parseInt(empID),
              updated_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              post_date:  formatDate(new Date().toISOString()),
              stock_in_perunit: item.stock_unit_in_value,
              book_in_perunit: item.stock_unit_in_value,
              xrate: 0,
              currency_code: "INR",
              operation_code: item.reason_code
            });
          }
      
          // For stock out (debit)
          if (item.stock_out_qty > 0) {
            transactions.push({
              shop_id: item.shop_id,
              stock_code: item.stock_code ? item.stock_code : item.non_stock_code,
              stock_type_id: 1,
              transaction_type_code: "Adjustment_to_Store",
              operation: "debit",
              qty: item.stock_out_qty,
              si_no: 1,
              grn_id: item.st_ad_id,
              grn_item_id: item.id,
              created_by: parseInt(empID),
              created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              updated_by: parseInt(empID),
              updated_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              post_date: formatDate(new Date().toISOString()),
              stock_in_perunit: item.stock_unit_in_value,
              book_in_perunit: item.stock_unit_in_value,
              xrate: 0,
              currency_code: "INR",
              operation_code: item.reason_code
            });
          }
      
          return transactions;
        });
      
        // Log the payload to ensure it is structured correctly
        console.log('Data to be saved:', payload);
      
        try {
          const response = await axios.post(`${var_api}/api/stock-transaction/post`, payload);
          console.log('Post response:', response.data);
        } catch (error) {
          console.error('Error updating GRN items:', error);
        }
      };


      const formatpDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };


      const formatPostingPeriod = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        return `${year}-${month}`;
      };
      

      

      const handleAccountChange = (event) => {
        const selectedValue = event.target.value;
    
        // Find the selected account from accounts list
        const selectedAccount = accounts.find(account => account.account_no === selectedValue);
    
        console.log("gl", selectedAccount);
        // Check if the selected account name contains the word "adjustment"
        if (selectedAccount && selectedAccount.account_name.toLowerCase().includes('adjustment')) {
          setSelectedAccount({
            account_no: selectedAccount.account_no,
            account_name: selectedAccount.account_name
          });
        } else {
          // Optionally, you can show an alert or some message to the user
          alert('Please select an account with "adjustment" in its name.');
          setSelectedAccount({ account_no: '', account_name: '' }); // Reset selection
        }
      };




      const handleGlSubmit = async () => {
        
      
        // Get the current date formatted as dd-mm-yyyy
        const currentDate = formatpDate(new Date());
        const postingPeriod = formatPostingPeriod(new Date());
      
        // Extract relevant fields from adjustmentDetails array and perform calculations
        const extractAndCalculateFields = (details) => {
          let creditData = {
            gl_account_no: selectedAccount.account_no,
            account_description: selectedAccount.account_name,
            posting_date: currentDate,
            credit_transaction_amount: 0,
            debit_transaction_amount: 0,
            indicator: 'credit',
            document_no: `stock_adjustment_${currentDate}`,
            document_type: 'stock adjustment',
            shop_id: parseInt(shop_id),
            lost_center: 0,
            profit_center: 0,
            currency: shopCurrency,
            reference_no: ' ',
            posting_period: postingPeriod,
            user_id: parseInt(empID),
            remarks: '  ',
            tax_code: '  ',
            business_area: '  '
          };
      
          let debitData = {
            gl_account_no: selectedAccount.account_no,
            account_description: selectedAccount.account_name,
            posting_date: currentDate,
            credit_transaction_amount: 0,
            debit_transaction_amount: 0,
            indicator: 'debit',
            document_no: `stock_adjustment_${currentDate}`,
            document_type: 'stock adjustment',
            shop_id: parseInt(shop_id),
            lost_center: 0,
            profit_center: 0,
            currency: shopCurrency,
            reference_no: ' ',
            posting_period: postingPeriod,
            user_id: parseInt(empID),
            remarks: '  ',
            tax_code: '  ',
            business_area: '  '
          };
      
          details.forEach((transaction) => {
            const {
              stock_unit_in_value,
              stock_in_qty,
              stock_out_qty
            } = transaction;
      
            if (stock_in_qty > 0) {
              creditData.credit_transaction_amount += stock_in_qty * stock_unit_in_value;
            }
      
            if (stock_out_qty > 0) {
              debitData.debit_transaction_amount += stock_out_qty * stock_unit_in_value;
            }
          });
      
         // Prepare the final data to be posted
    let dataToPost = [];
    if (creditData.credit_transaction_amount > 0) {
      dataToPost.push(creditData);
    }
    if (debitData.debit_transaction_amount > 0) {
      dataToPost.push(debitData);
    }

    return dataToPost;
  };

      
        const relevantData = extractAndCalculateFields(adjustmentDetails);

         // If relevantData is empty, return without posting
  if (relevantData.length === 0) {
    alert('No valid transactions to post.');
    return;
  }
        
        try {
          const response = await axios.post(`${var_api}/api/gl-accounts-posting/post`, relevantData);
          console.log('Accounts posting successful:', response.data);
          toast("Added in GL Acccount")
          // Handle success (e.g., show a success message, reset form, etc.)
        } catch (error) {
          console.error('Error posting accounts:', error);
          // Handle error (e.g., show an error message)
        }
      };


      const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
      };
      

      const filteredAdjustments = stockAdjustments.filter((adjustment) => {
        const formattedDate = formatDate(adjustment.adjustment_date);
        return (
          adjustment.reference_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
          adjustment.authorized_by_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          adjustment.checked_by_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          adjustment.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
          formattedDate.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
       

    return (
        <>
            <Navbar expand="sm" className="bg-body-tertiary bg-success p-0" style={{ padding: "0px" }}>
                <Container className="p-0">
                    <Navbar.Brand className="p-0 text-light fw-bolder">STOCK ADJUSTMENTS</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <button className="btn btn-outline-success" onClick={handleButtonClick} style={{ backgroundColor: "white", color: "green" }}>
                            <i className="bi bi-house"></i> Go to Home
                        </button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
                {
                    isDetail ? (
                       <div>
                          <div style={{padding:"15px"}}>
      <Row className="my-4">
        <Col md={4}>
          <Card className="h-100" style={{maxWidth:"90%"}}>
            <Card.Body>
              <Card.Text>
                <div className="p-2">
                  <Row>
                    <Col>
                    <label htmlFor="authorized_by" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>GL Account Name</label>
                    <Form.Control
        as="select"
        style={{ borderRadius: "12px" }}
        value={selectedAccount.account_no}
        onChange={handleAccountChange}
      >
              <option value="">Enter Account Name</option>
              {accounts.map(item => (
                <option key={item.id} value={item.account_no}>
                  {item.account_name}
                </option>
              ))}
            </Form.Control>
                    </Col>
                    <Col>
                    <button className="btn btn-primary" onClick={handlePost} disabled={selectedStockAdjustment.status == 1} style={{float:"right"}}>Post</button>
                    </Col>
                  </Row>
               
              
                    <Table bordered hover style={{border:"1px solid black", borderCollapse:"collapse", marginTop:"10px"}}>
                        <tr>
                            <th>reference_no</th>
                            <td style={{border:"1px solid black", borderCollapse:"collapse"}}>{selectedStockAdjustment.reference_no}</td>
                        </tr>
                        <tr>
                            <th>Description</th>
                            <td style={{border:"1px solid black", borderCollapse:"collapse"}}>{selectedStockAdjustment.description}</td>
                        </tr>
                        <tr>
                            <th>Adjustment Date</th>
                            <td style={{border:"1px solid black", borderCollapse:"collapse"}}>{selectedStockAdjustment.adjustment_date}</td>
                        </tr>
                        <tr>
                            <th>Checked By</th>
                            <td style={{border:"1px solid black", borderCollapse:"collapse"}}>{selectedStockAdjustment.checked_by}</td>
                        </tr>
                    </Table>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card className="h-100" style={{maxWidth:"100%"}}>
            <Card.Body>
              <Card.Text>
              <Form className="py-2" onSubmit={handleSubmit}>
        <Row>
          <Col>
            <label htmlFor="stock_code" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Stock Code</label>
            <Form.Control as="select" name="stock_code" value={formDataItems.stock_code} onChange={handleChange} style={{ borderRadius: "12px" }}>
              <option value="">Enter Stock Code</option>
              {items.map(item => (
                <option key={item.id} value={item.stock_code}>
                  {item.stock_code}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <label htmlFor="reason_code" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Reason Code</label>
            <input type="text" className="form-control" id="reason_code" name="reason_code" value={formDataItems.reason_code} onChange={handleChange} style={{ borderRadius: "12px" }} required />
          </Col>
          <Col>
            <label htmlFor="stock_unit_in_value" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Stock-in Unit Value</label>
            <input type="number" className="form-control" id="stock_unit_in_value" name="stock_unit_in_value" value={formDataItems.stock_unit_in_value} onChange={handleChange} style={{ borderRadius: "12px" }} required />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <label htmlFor="stock_in_qty" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Stock-in Qty</label>
            <input type="number" className="form-control" id="stock_in_qty" name="stock_in_qty" value={formDataItems.stock_in_qty} onChange={handleChange} style={{ borderRadius: "12px" }} required />
          </Col>
          <Col>
            <label htmlFor="stock_in_value" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Stock-in Value</label>
            <input type="number" className="form-control" id="stock_in_value" name="stock_in_value" value={formDataItems.stock_in_value} onChange={handleChange} style={{ borderRadius: "12px" }} required />
          </Col>
          <Col>
            <label htmlFor="stock_out_qty" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Stock-out Qty</label>
            <input type="number" className="form-control" id="stock_out_qty" name="stock_out_qty" value={formDataItems.stock_out_qty} onChange={handleChange} style={{ borderRadius: "12px" }} required />
          </Col>
          <Col>
            <label htmlFor="stock_out_value" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Stock-out Value</label>
            <input type="number" className="form-control" id="stock_out_value" name="stock_out_value" value={formDataItems.stock_out_value} onChange={handleChange} style={{ borderRadius: "12px" }} required />
          </Col>
        </Row>
        <br />
        <Button type="submit" variant="success" style={{ float: "left", margin: "5px", borderRadius: "15px" }} disabled={selectedStockAdjustment.status == 1}>Save</Button>
      </Form>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
                          <div style={{padding:"20px"}}>
                          <Table hover variant="light">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Stock Code</th>
                                    <th>Reason code</th>
                                    <th>Stock Unit Value</th>
                                    <th>Stock In Value</th>
                                    <th>Stock In Qty</th>
                                    <th>Stock Out Value</th>
                                    <th>Stock Out Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {adjustmentDetails.map((detail, index) => (
                                    <tr key={detail.id}>
                                        <td>{index + 1}</td>
                                        <td>{detail.stock_code}</td>
                                        <td>{detail.reason_code}</td>
                                        <td>{detail.stock_unit_in_value}</td>
                                        <td>{detail.stock_in_value}</td>
                                        <td>{detail.stock_in_qty}</td>
                                        <td>{detail.stock_out_value}</td>
                                        <td>{detail.stock_out_qty}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                          </div>

                       </div>
                    ) : (
                        <Card style={{ maxWidth:"99%", height: "auto", margin: "20px", borderRadius: "15px", border: "none", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding: "10px" }}>
                        <Card.Body>
                        <div>
                            <h6>Stock Adjustments</h6>
                            <Form className="py-2" onSubmit={handleFormSubmit}>
                                <Row>
                                    <Col>
                                        <label htmlFor="reference_no" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Reference Number</label>
                                        <input type="text" className="form-control" id="reference_no" name="reference_no" value={formData.reference_no} onChange={handleInputChange} style={{ borderRadius: "12px" }} required />
                                    </Col>
                                    <Col>
                                        <label htmlFor="authorized_by" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Authorized By</label>
                                        <Form.Control as="select" name="authorized_by" value={formData.authorized_by} onChange={handleInputChange} style={{ borderRadius: "12px" }}>
              <option value="">Enter employees</option>
              {employees.map(item => (
                <option key={item.id} value={item.id}>
                  {item.e_name}
                </option>
              ))}
            </Form.Control>
                                    </Col>
                                    <Col>
                                        <label htmlFor="checked_by" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Checked By</label>
                                        <Form.Control as="select" name="checked_by" value={formData.checked_by} onChange={handleInputChange} style={{ borderRadius: "12px" }}>
              <option value="">Enter employees</option>
              {employees.map(item => (
                <option key={item.id} value={item.id}>
                  {item.e_name}
                </option>
              ))}
            </Form.Control>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                <Col>
                                        <label htmlFor="adjustment_date" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Adjustment Date</label>
                                        <input type="date" className="form-control" id="adjustment_date" name="adjustment_date" value={formData.adjustment_date} onChange={handleInputChange} style={{ borderRadius: "12px" }} required />
                                    </Col>
                                    <Col>
                                        <label htmlFor="description" style={{ color: "green", fontFamily: "serif", fontWeight: "bolder" }}>Description</label>
                                        <input type="text" className="form-control" id="description" name="description" value={formData.description} onChange={handleInputChange} style={{ borderRadius: "12px" }} required />
                                    </Col>
                                   {
                                    isView &&  <Col>
                                    <button className="btn btn-primary" style={{ borderRadius: "15px", marginTop:"20px"}} onClick={handleToDetails}>Details</button></Col>
                                   }
                                </Row>
                                <br />
                                <button type="submit" className="btn btn-success" style={{ float: "left", margin: "5px", borderRadius: "15px" }}>Submit</button>
                            </Form>
                        </div>
                        <button className="btn btn-danger" style={{ float: "left", margin: "5px", borderRadius: "15px", marginTop: "-3px" }} onClick={resetForm}>Reset</button>
                        <br /><br /><br /><br />
                        <input className="form-control" type="Search" placeholder="Search" id="uomsearch"  value={searchQuery} onChange={handleSearchChange}/>
                        <br /><br /><br />
                        <Table hover variant="light">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Reference NO</th>
                                    <th>Adjustment Date</th>
                                    <th>Authorized By</th>
                                    <th>Checked By</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredAdjustments.length > 0 ? (
            filteredAdjustments.map((adjustment, index) => (
                                    <tr key={adjustment.id}>
                                        <td>{index + 1}</td>
                                        <td>{adjustment.reference_no}</td>
                                        <td>{formatDate(adjustment.adjustment_date)}</td>
                                        <td>{adjustment.authorized_by_name}</td>
                                        <td>{adjustment.checked_by_name}</td>
                                        <td>{adjustment.description}</td>
                                        <td>{adjustment.status == 0 ?  <span class="badge rounded-pill bg-primary p-2" style={{fontWeight:"normal"}}>New</span> :
                                         <span class="badge rounded-pill bg-success p-2" style={{fontWeight:"normal"}}>Posted</span>}</td>
                                        <td>
                                            <button className="btn btn-primary" onClick={() => handleEditClick(adjustment)} disabled={adjustment.status == 1}><i className="bi bi-pencil-square"></i></button>{" "}
                                            <button className="btn btn-danger" onClick={() => handleDeleteClick(adjustment)} disabled={adjustment.status == 1}><i className="bi bi-trash"></i></button>{" "}
                                            <button className="btn btn-warning" onClick={()=>handleViewDetails(adjustment)}><i className="bi bi-eye-fill" style={{ color: "white" }}></i></button>
                                        </td>
                                    </tr>
                                ))  
                              ) : (
                                  <tr>
                                    <td colSpan="8" className="text-center text-danger">No data available</td>
                                  </tr>
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                    </Card>
                    )
                }
               
           
    <Modal
        show={isdelete}
        onHide={()=>SetIsDelete(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title> Confirm!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{margin:"10px"}}>
                <h5>Are You Sure You Want to Delete Reference No.
                    <b style={{color:"red", fontSize:"18px"}}>{deletedData && deletedData.reference_no}</b> ?
                </h5>
            </div>
        </Modal.Body>
         <Modal.Footer>
          <Button variant="secondary" onClick={()=>SetIsDelete(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteAdjustment}>
            Delete
          </Button>
        </Modal.Footer> 
      </Modal>
       </>
    )
}


export default StockAdjustments;