import React, { useRef } from 'react';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { var_api } from '../constant';
import { useHistory } from 'react-router-dom';
import './NewSettlement.css'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactToPrint from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


const ReportBillPrint = React.forwardRef(({ shopDetails, selectedDate, cashSales, totalPayout, otherTotal, totalSales,allCreditTotal }, ref) => (
    <div ref={ref} id='reportBill' style={{backgroundColor:"rgba(63, 68, 63, 0.082)",padding:"10px", maxWidth:"310px"}}>
      <div>
        <h3 style={{textAlign:"center",fontWeight:"lighter",textTransform:"uppercase",color:"black",fontFamily:"fantasy",letterSpacing: "3px"}}>{localStorage.getItem('shopName')}</h3>
        {/* Render shop details */}
        {shopDetails ? (
          <div style={{lineHeight: "15%",fontFamily:"cursive",marginTop:"15px"}}>
            <p style={{textAlign:"center", fontWeight:"bolder"}}>{shopDetails.address}</p>
            <p style={{textAlign:"center", fontWeight:"bolder"}}>{shopDetails.state}, {shopDetails.country_name}</p>
            <p style={{textAlign:"center", fontWeight:"bolder"}}>{shopDetails.user.mobile}</p>
          </div>
        ) : (
          <div>Loading...</div>
        )}
        <hr style={{border:"2px solid black"}}/>
        {/* Render report summary */}
        <p style={{textAlign:"center", fontWeight:"bolder", fontSize:"20px", fontFamily:"serif"}}>Report Summary</p>
        <p style={{textAlign:"left", fontWeight:"bolder",fontSize:"17px"}}>Date:<b>{selectedDate}</b> <span style={{float:"right"}}>Time:<b>{new Date().toLocaleTimeString()}</b></span></p>
        <br/>
        <h5 style={{fontFamily:"serif",color:"black",fontWeight:"bolder"}}> Cash Sales: <span style={{float:"right"}}>{cashSales ? cashSales.toFixed(3) : 0}</span></h5>
        <h5 style={{fontFamily:"serif",color:"black",fontWeight:"bolder"}}> Total Payout: <span style={{float:"right"}}>{totalPayout? totalPayout : 0}</span></h5>
        <h5 style={{fontFamily:"serif",color:"black",fontWeight:"bolder"}}> Other Total: <span style={{float:"right"}}>{otherTotal ? otherTotal.toFixed(3) : 0}</span></h5>
        <h5 style={{fontFamily:"serif",color:"black",fontWeight:"bolder"}}> All Credit Total: <span style={{float:"right",fontFamily:"monospace"}}>{allCreditTotal ? allCreditTotal.toFixed(3) : 0}</span></h5>
        <hr style={{border:"2px solid black"}}/>
        <h5 style={{fontFamily:"serif",color:"black",fontWeight:"bolder",fontSize:"22px"}}> Total Sales: <span style={{float:"right"}}>{totalSales ? totalSales.toFixed(3) : 0}</span></h5>
        <h5 style={{fontFamily:"serif",color:"black",marginTop:"20px", fontSize:"22px"}}> Balance Cash:{" "} <span style={{float:"right"}}>{( cashSales - totalPayout).toFixed(3)}</span></h5>
        <hr style={{border:"2px solid black"}}/>
        <div style={{marginTop:"-21px",lineHeight:"10px"}}>
          <br/>
          <h6 style={{textAlign:"center",fontFamily:"monospace",fontWeight:"bolder",color:"black",margin:"5px", fontSize:"19px"}}>Have a Nice Day...!</h6>
        </div>
      </div>
    </div>
  ));


const NewSettlement = () => {
    const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
    const [selectedDatepaymode, setSelectedDatepaymode] = useState(new Date().toISOString().split('T')[0]);
    const [tables, setTables] = useState([]);
    const [paymode, setPaymode] = useState([]);
    const [creditPaymode, setCreditPaymode] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false); // Add isLoading state
    const [cashSales, setCashSales] = useState(null);
    const [otherTotal, setOtherTotal] = useState(null);
    const [totalPayout, setTotalPayout] = useState(null);
    const [totalSales, setTotalSales] = useState(null);
    const [allCreditTotal, setAllCreditTotal] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [shopDetails, setShopDetails] = useState(null);
    const history = useHistory();
    const valtoday = new Date().toISOString().split('T')[0];
    const componentRef = useRef(null); 
    const printingType = localStorage.getItem("printingType");

    useEffect(() => {
        fetchTables();
        fetchPaymode();
        fetchShopDetails();
        fetchCreditPaymode();
    }, [selectedDate,selectedDatepaymode]); // Add selectedDate to the dependency array


    const fetchShopDetails = () => {
        const shopID = localStorage.getItem('shopId');
  // Make the API request
  axios
    .get(`${var_api}/api/shop/shopDetails/${shopID}`)
    .then((response) => {
      setShopDetails(response.data)
      console.log("shop details:",response.data, shopDetails)
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
    }

    const fetchTables = async () => {
        setIsLoading(true); // Set isLoading to true when fetching data
        try {
            const formattedDate = formatDate(selectedDatepaymode); 
            const shopid = localStorage.getItem('shopId');
            const response = await axios.get(`${var_api}/api/settlements/get-total-settlement/${shopid}/${formattedDate}`);
            console.log("response", response);
            const user = response.data.amount;
            console.log("response u", user);
            setTotalPayout(user);
            setIsLoading(false); // Set isLoading to false after fetching data
        } catch (error) {
            console.log(error);
            setIsLoading(false); // Set isLoading to false if there's an error
        }
    };


    const fetchPaymode = async () => {
        setIsLoading1(true); // Set isLoading to true when fetching data
        try {
            const shopid = localStorage.getItem('shopId');
            const formattedDate = formatDate(selectedDatepaymode); 
            const response = await axios.get(`${var_api}/api/food/get-paymode-summary/${shopid}/${formattedDate}`);
            console.log("response", response);
            const paymodes = response.data;
            setPaymode(paymodes);

            const filteredPayModes = paymodes.filter(payMode => payMode.payMode.toUpperCase() === 'CASH');
            console.log("ff",filteredPayModes);
           setCashSales(filteredPayModes[0].total_Amount)

           // Calculate total amount except CASH
           const totalExceptExcluded = paymodes.reduce((total, payment) => {
            const excludedModes = ["CASH", "CREDIT ORDER", "GROUP LOCATION CREDIT ORDER"];
            if (!excludedModes.includes(payment.payMode)) {
              return total + payment.total_Amount;
            }
            return total;
          }, 0);
      console.log("000",totalExceptExcluded);
      setOtherTotal(totalExceptExcluded)

       // Calculate total amount including all payments
    const totalAmount = paymodes.reduce((total, payment) => {
        return total + payment.total_Amount;
      }, 0);
        setTotalSales(totalAmount);  


        // Initialize variables to store the sum of total amounts
let creditOrderTotal = 0;
let groupLocationCreditTotal = 0;

// Iterate through the paymodes array and accumulate the total amounts
paymodes.forEach(paymode => {
    if (paymode.payMode === "CREDIT ORDER") {
        if (paymode.total_Amount) {
            creditOrderTotal += paymode.total_Amount;
        }
    } else if (paymode.payMode === "GROUP LOCATION CREDIT ORDER") {
        if (paymode.total_Amount) {
            groupLocationCreditTotal += paymode.total_Amount;
        }
    }
});

setAllCreditTotal(creditOrderTotal + groupLocationCreditTotal);

// Output the total amounts
console.log("Total amount for CREDIT ORDER:", creditOrderTotal);
console.log("Total amount for GROUP LOCATION CREDIT ORDER:", groupLocationCreditTotal);

            setIsLoading1(false); // Set isLoading to false after fetching data
        } catch (error) {
            console.log(error);
            setIsLoading1(false); // Set isLoading to false if there's an error
        }
    };



    const fetchCreditPaymode = async () => {
            setIsLoading1(true); // Set isLoading to true when fetching data
            try {
                const shopid = localStorage.getItem('shopId');
                const formattedDate = formatDate(selectedDatepaymode); 
                const response = await axios.get(`${var_api}/api/payment-paymode/get-paymode-summary/${shopid}/${formattedDate}`);
                console.log("response", response);
                const paymodes = response.data;
                setCreditPaymode(paymodes);
                setIsLoading1(false);
               
            } catch (error) {
                console.log(error);
                setIsLoading1(false); // Set isLoading to false if there's an error
            }
        };
    

    const totalAmount = paymode.reduce((total, item) => total + (item.total_Amount || 0), 0);

function getYesterdayDate() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const dd = String(yesterday.getDate()).padStart(2, '0');
    const mm = String(yesterday.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = yesterday.getFullYear();

    return `${dd}-${mm}-${yyyy}`;
}

const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    // Convert selectedDate to the desired format dd-mm-yyyy
    const [yyyy, mm, dd] = selectedDate.split('-');
    const formattedDate = `${dd}-${mm}-${yyyy}`;
    setSelectedDate(formattedDate);
    const today = new Date().toISOString().split('T')[0];
    if (selectedDate === today) {
        // Show toast message
        setTables([]);
        showToast("Only end-of-day summaries will be generated for tomorrow");
    }
};

const handleDateChangepaymode = (e) => {
    setSelectedDatepaymode(e.target.value);
    fetchTables();
    fetchPaymode();

};

const formatDate = (dateString) => {
    const [yyyy, mm, dd] = dateString.split('-');
    return `${dd}-${mm}-${yyyy}`;
};

const formatDateForInputField = (dateString) => {
    const [yyyy, mm, dd] = dateString.split('-');
    return `${dd}-${mm}-${yyyy}`; // Format as dd-mm-yyyy
};


const showToast = (message) => {
    // Implement toast message display logic here
    alert(message);
};


const handleButtonClickhome = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };


  const handlePrintOpenModal = () => {
   setIsOpen(true);
  };



  const handlePrints = async () => {
    try {
        // Get the reference to the ReportBillPrint component
        const component = componentRef.current;

        if (!component) {
            console.error('Component reference not found.');
            return;
        }

        // Ensure the component is fully rendered before capturing
        await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust timeout as needed

        // Capture the component as an image using html2canvas
        const canvas = await html2canvas(component, {
            scale: 2, // Adjust scale for better quality
            useCORS: true // Ensure cross-origin images are captured properly if needed
        });

        // Convert the canvas to an image (PNG format by default)
        const imgData = canvas.toDataURL('image/png');

        // Calculate dimensions for PDF creation
        const imgWidth = 1.98; // Width in inches
        const imgHeight = (canvas.height / canvas.width) * imgWidth; // Maintain aspect ratio

        // Create a new jsPDF instance for PDF generation
        const pdf = new jsPDF({
            orientation: 'portrait', // Set orientation (portrait or landscape)
            unit: 'in', // Set unit of measurement
            format: [imgWidth, imgHeight] // Set dimensions of the PDF
        });

        // Add the image captured from canvas to the PDF
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        // Convert the PDF to base64 data URI
        const pdfData = pdf.output('datauristring');
        const printerName = localStorage.getItem("selectedPrinter");

        // Send the base64 PDF data to the backend for printing
        await axios.post('http://localhost:3001/print', {
            data: pdfData.split(',')[1] ,// Extract base64 data from the URI
            printerName: printerName
        });

    } catch (err) {
        console.error('Error printing:', err);
    }
};


    return(
        <div>
            <div className="container-fluid" style={{ textAlign: "center" }}>
        <nav className="navbar" style={{ boxShadow: "0px 0px 1px 0px black", backgroundColor: "green", border: "none", padding: "5px", height: "50px", width: "100%", position: "relative"}}>
            <div style={{ display: "flex"}}>
                <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "500px", color: "white" }}>SETTLEMENT</h3>
                <button className="btn" onClick={handleButtonClickhome} style={{ marginLeft: "500px", backgroundColor: "white", color: "green" }}>
                    <i class="bi bi-house"></i> Go to Home
                </button>
            </div>
        </nav>
    </div>
    
    <br/>
    <div className="card" style={{ width: '300px',margin:"auto"}}>
            <div className="card-body" style={{ display: 'flex', flexDirection: 'row', alignItems: 'right' }}>
                <p className="card-text" style={{ marginRight: '10px',fontWeight:"bolder" }}>Date: </p>
                <i className="bi bi-search" style={{ marginRight: '5px', marginBottom: '10px'}}></i>
                <input 
                type="date" 
                id="yesterdayDateInput" 
                className="form-control" 
                style={{ marginRight: '5px', marginBottom: '15px', color:"blue"}}
                value={selectedDatepaymode}
                onChange={handleDateChangepaymode}
                max={valtoday} // Setting the max attribute to today's date
            />
            </div>
        </div>
        <br/>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
<div style={{ margin: 'auto' }}>
{isLoading1 ? (
    <div className="loader-container">
        <div className="loader"></div>
    </div>
) : (
    <>
     {/* <p className="card-text" style={{ marginRight: '10px', textAlign:'center', fontWeight:' bold', fontSize:'18px', color:"blue"}}> Summary</p> */}
        
        {/* <p style={{textAlign:"end", marginRight:"150px", fontWeight:"bold"}}>Total Amount ({selectedDatepaymode}) :  <span style={{color:"blue", fontWeight:"bold"}}>{totalAmount}</span></p> */}
        <h6 style={{fontWeight:"bolder", textAlign:"center",color:"green"}}>Normal Order Amount collection</h6>
        <div className="card" style={{ width: '600px', height:"auto", border:"2px solid green"}}>
            <div className="card-body">
                <div style={{ borderBottom: "2px solid black", paddingBottom: "10px", marginBottom: "10px" }}>
                    <div className="card-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                       
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>Paymode</strong></div>
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>Amount</strong></div>
                    </div>
                </div>
                {paymode.length === 0 ? (
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>No Paymode Summary</div>
                ) : (
                    paymode.map((table, index) => (
                       
                        <div className="content" key={index} style={{ borderBottom: "1px solid black", paddingBottom: "5px", marginBottom: "5px", display: 'flex', justifyContent: 'space-between', alignItems:"center" }}>
                            
                            <div style={{ flex: '1', textAlign: 'left',color: table.payMode === "CREDIT ORDER" || table.payMode === "GROUP LOCATION CREDIT ORDER" ? "blue" : "black" }}>{table.payMode}</div>
                            <div style={{ flex: '1', textAlign: 'left',color: table.payMode === "CREDIT ORDER" || table.payMode === "GROUP LOCATION CREDIT ORDER" ? "blue" : "black" }}>{table && table.total_Amount && table.total_Amount ? table.total_Amount.toFixed(3) : 0}</div>
                        </div>
                        
                    ))
                )}
            </div>
        </div>
    </>
)}
</div>

<div className="vertical-line"></div>

<div style={{ margin: 'auto'}}>
    <h6 style={{fontWeight:"bolder", textAlign:"center",color:"green"}}>Credit Order Balance Amount collection</h6>

<div className="card" style={{ width: '600px', height:"auto", border:"2px solid green"}}>
            <div className="card-body">
                <div style={{ borderBottom: "2px solid black", paddingBottom: "10px", marginBottom: "10px" }}>
                    <div className="card-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                       
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>Paymode</strong></div>
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>Amount</strong></div>
                    </div>
                </div>
                {creditPaymode.length === 0 ? (
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>No Paymode Summary</div>
                ) : (
                    creditPaymode.map((table, index) => (
                       
                        <div className="content" key={index} style={{ borderBottom: "1px solid black", paddingBottom: "5px", marginBottom: "5px", display: 'flex', justifyContent: 'space-between', alignItems:"center" }}>
                            
                            <div style={{ flex: '1', textAlign: 'left',color: table.pay_mode_name === "CREDIT ORDER" || table.pay_mode_name === "GROUP LOCATION CREDIT ORDER" ? "blue" : "black" }}>{table.pay_mode_name}</div>
                            <div style={{ flex: '1', textAlign: 'left',color: table.pay_mode_name === "CREDIT ORDER" || table.pay_mode_name === "GROUP LOCATION CREDIT ORDER" ? "blue" : "black" }}>{table && table.total_Amount && table.total_Amount ? table.total_Amount.toFixed(3) : 0}</div>
                        </div>
                        
                    ))
                )}
            </div>
        </div>
   
</div>



        </div>
        <br/>
        {isLoading ? (
    <div className="loader-container">
        <div className="loader"></div>
    </div>
) : (
    <>
     <p className="card-text" style={{ marginRight: '10px', textAlign:'center', fontWeight:' bolder', fontSize:'27px', color:"black",fontFamily:"serif"}}> Summary Report</p>
        {/* <div className="card" style={{ width: '300px'}}>
            <div className="card-body" style={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
                <p className="card-text" style={{ marginRight: '10px' }}>Date: </p>
                <i className="bi bi-search" style={{ marginRight: '5px', marginBottom: '10px'}}></i>
                <input type="date" id="yesterdayDateInput" className="form-control" style={{ marginRight: '5px', marginBottom: '15px', color:"red"}}
                    value={formatDateForInputField(selectedDate)} onChange={handleDateChange}/>
            </div>
        </div> */}
        <div className="card" style={{ width: '400px', height:"auto", border:"2px solid green",margin:"auto"}} id="card-content">
            <div className="card-body" >
              
                <h5 style={{fontFamily:"serif",color:"red",fontWeight:"normal"}}> Cash Sales: <span style={{float:"right",fontFamily:"monospace"}}>{cashSales ? cashSales.toFixed(3) : 0}</span></h5>
                <h5 style={{fontFamily:"serif",color:"black",fontWeight:"normal"}}> Total Payout: <span style={{float:"right",fontFamily:"monospace"}}>{totalPayout? totalPayout.toFixed(3) : 0}</span></h5>
                <h5 style={{fontFamily:"serif",color:"red",fontWeight:"normal"}}> Other Total: <span style={{float:"right",fontFamily:"monospace"}}>{otherTotal ? otherTotal.toFixed(3) : 0}</span></h5>
                <h5 style={{fontFamily:"serif",color:"blue",fontWeight:"normal"}}> All Credit Total: <span style={{float:"right",fontFamily:"monospace"}}>{allCreditTotal ? allCreditTotal.toFixed(3) : 0}</span></h5>
                <hr/>
                <h5 style={{fontFamily:"serif",color:"red",fontWeight:"bolder"}}> Total Sales: <span style={{float:"right",fontFamily:"monospace"}}>{totalSales ? totalSales.toFixed(3) : 0}</span></h5>
                
                <h5 style={{fontFamily:"serif",color:"green",marginTop:"20px"}}> Balance Cash:{" "} <span style={{float:"right",fontFamily:"monospace"}}>{( cashSales - totalPayout).toFixed(3)}</span></h5>
            </div>
            
        </div>
        <div className='text-center'>
        <button className='btn btn-primary' style={{margin:"auto"}} onClick={handlePrintOpenModal}><i class="bi bi-printer"></i> Report Summary</button>

        </div>
    </>
)}

        <Modal
        show={isOpen}
        onHide={()=>setIsOpen(false)}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
      >
        <Modal.Header closeButton >
          {/* <Modal.Title>confirmation</Modal.Title> */}Print Report Summary
        </Modal.Header>
        <Modal.Body>

        <ReportBillPrint
          ref={componentRef}
          shopDetails={shopDetails}
          selectedDate={selectedDate}
          cashSales={cashSales}
          totalPayout={totalPayout}
          otherTotal={otherTotal}
          totalSales={totalSales}
          allCreditTotal = {allCreditTotal}
        />

          <Button variant="danger" style={{float:"right"}} onClick={()=>setIsOpen(false)}>
            Close
          </Button>{' '} 

          {
            printingType == "directPrint" ?  (
                <button className='btn btn-success'  style={{ borderRadius: '5px', border: 'none', margin: '5px',float:"right" }} onClick={handlePrints}><i className='bi bi-printer-fill'></i> Print Directly</button>
            ) : (
          
          <ReactToPrint
          trigger={() => (
            <Button variant="primary" style={{float:"right",marginRight:"5px"}}>
              <i className="bi bi-printer-fill"></i> Print via Google
            </Button>
          )}
          content={() => componentRef.current}
        />
            )}
         
        </Modal.Body>
       
      </Modal>
        </div>

        

        
    )
}

export default NewSettlement;