import React from "react";
import "./tableBill.css";
import logo from '../../images/splash/shop_logo.jpeg';

class TableBill extends React.Component {
    render() {
      const { order } = this.props;
  
    return(
        <>
   
        <div style={{backgroundColor:"rgba(63, 68, 63, 0.082)",padding:"20px",width:"300px"}}>
            <div className="hidden-on-screen">
                {/* <img src={logo} alt="logo" style={{display: "block",marginLeft:" auto",marginRight: "auto",height:"50px",width:"50px"}} /> */}
                <h5 style={{textAlign:"center",fontWeight:"bolder",textTransform:"uppercase",color:"black",fontFamily:"Arial, Helvetica, sans-serif"}}>{localStorage.getItem('shopName')}</h5>
                <p style={{fontSize:"13px",textAlign:"center",fontFamily: "monospace",fontWeight:"bolder"}}>1234 Mani Street  <br/>Trichy, TamilNadu 620001<br/>9078908767</p>
                <hr style={{border:"1px dashed black",position:"relative",top:"-15px"}}/>
            </div>
           
            <div style={{marginTop:"-30px"}}>
          
                <h5 style={{color:"black",fontWeight:"bolder",textAlign:"center",marginTop:"12px"}} className="Nota">Table No: {order.table_no}</h5>
                <h6 style={{color:"black",fontWeight:"bolder",textAlign:"left"}} className="orderNo">Order No: 1</h6>

              
                <table key={order.order_no} style={{width:"100%"}}>
            
            {/* <thead>
                <th style={{padding:"2px",color:"black"}}>Item</th>
                <th style={{padding:"2px",color:"black"}}>Quantity</th>
                <th style={{padding:"2px",color:"black"}}>Price</th>
            </thead> */}

            <tbody>
                {order.items.map((item, itemIndex) => (
                <tr key={itemIndex}>
                    <td style={{color:"black",textAlign:"left",fontWeight:"bolder",padding:"1px"}}>{item.item_name}</td>
                    {/* <td style={{padding:"1px",color:"black",textAlign:"left",fontWeight:"bolder"}}>{item.quantity}</td> */}
                    <td style={{color:"black",textAlign:"right",fontWeight:"bolder",padding:"1px"}}>{item.unit_price}</td>

                   
                </tr>
                ))} 
                <br/>
                <tr>
                    
                <td style={{color:"black",textAlign:"left",fontWeight:"bolder",padding:"1px"}}>Sub Total</td>
                    <td style={{color:"black",textAlign:"right",fontWeight:"bolder",padding:"1px"}}>₹ 350.00</td>
                  
                </tr>
                <tr>
                    <td style={{color:"black",textAlign:"left",fontWeight:"bolder",padding:"1px"}}>Service Tax</td>
                    <td style={{color:"black",textAlign:"right",fontWeight:"bolder",padding:"1px"}}>₹ 50.00</td>
                </tr>
            </tbody>
            
        </table>
            </div>
       <hr style={{border:"1px dashed black",position:"relative",top:"-15px"}}/>
        <h5 style={{padding:"1px",color:"black",textAlign:"center",fontWeight:"bolder",marginTop:"-20px",fontFamily:"monospace"}}>Total Amount: ₹{order.total}.00</h5>
        <hr style={{border:"1px dashed black",position:"relative",top:"-15px"}} className="line"/>
      
      <h6 style={{textAlign:"left",fontFamily:"monospace",letterSpacing: "-2px",color:"black",marginTop:"-25px",fontSize:"15px"}} className="tableName">Table No: <span style={{color:"green",fontSize:"20px",color:"black"}}>{order.table_no}</span></h6>
      <div class="flex-container" style={{marginTop:"-5px"}}>
               <div>
               <span style={{color:"black",fontSize:"12px",textTransform:"capitalize",fontWeight:"bolder"}}>{' '} 2023/09/02 03:02:34</span>
                <h4 style={{captionSide:"top",fontSize:"13px",fontWeight:"bolder",color:"black",fontFamily: "monospace"}}>Customer: 
                <span style={{color:"black",fontSize:"13px",textTransform:"capitalize",fontWeight:"bolder"}}>{order.cust_name}</span>
                <br/>Phone: <span style={{color:"black",fontSize:"13px",fontWeight:"bolder",marginLeft:"5px"}}>{' '} {order.phone_no}</span></h4>
                </div>
                <div className="anode">
                <h4 style={{captionSide:"top",fontSize:"13px",fontWeight:"bolder",color:"black",marginTop:"25px",fontFamily: "monospace"}}>Attender: <span style={{color:"black",fontSize:"13px",fontWeight:"bolder",marginLeft:"2px"}}>{' '} Kaasi</span></h4>
                </div>
               </div>
 
               <div className="hidden-on-screen">
                <h6 style={{textAlign:"center",fontWeight:"bolder",color:"black",fontFamily:"monospace"}}>Thanks For Visiting...</h6>
            </div>
        </div>
        </>
    )
}
}

export default TableBill;