import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from '../constant.js';
import { event } from 'jquery';
import { icon } from '@fortawesome/fontawesome-svg-core';
import "./uom_master.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Tooltip } from 'reactstrap';

function UomMaster(){
    const history = useHistory();
    const shopName= localStorage.getItem('shopName');
    const [uom_name,setUom_name] = useState("");
    const [uom_description,setUom_description] =  useState("");
    const [uoms, setUoms] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ searchQuery,setSearchQuery] = useState('');
    const [show, setShow] = useState(false);
    const [selectedUom, setSelectedUom] = useState({});
    const [isEdit, setisedit] = useState(false);
    const [ postloading,setPostLoading ] = useState(false);
    const [ updateloading, setUpdateLoading ] = useState(false);
    const [puttooltipOpen, setPutToolTipOpen] = useState([]);
    const [updatedUOM, setUpdatdeUOM] = useState({
      id:null,
      uom_name:"",
      uom_description:"",
      shop_id:null
    });

    //redirect to home page
    const handleHome = () =>{
      history.push('/home')
    }

    //list the catgories in select
    useEffect(() => {
        fetchUOMs();
    
    }, []);


//fetch the uom details
    const fetchUOMs = async () => {
        setLoading(true);

        const shopId = localStorage.getItem('shopId');
       try {
        const response = await axios.get(`${var_api}/api/uom-master/get-uom/${shopId}`);
        const use = response.data
      setUoms(use);
      
      console.log("categories",uoms)
      setLoading(false);
      }catch (error) {
        console.log('Error fetching data:', error);
        
        }
      };

 
       // Function to capitalize the first letter of a string
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
 };

   //post the form data
    const Submit = async (e) => {
      e.preventDefault();

       // Trim whitespace from the input values
  const trimmedUOMName = uom_name.trim();
  const trimmedUOMDescription = uom_description.trim();

  // Check if the trimmed values are empty
  if (trimmedUOMName === '' || trimmedUOMDescription === '') {
    // Display an error message to the user
    Swal.fire({
      icon: "error",
      text: "UOM Name and Description are required",
      confirmButtonColor: "#dc3545"
    });
    return; // Exit the function early if validation fails
  }

      const uppercaseUOM = uom_name.toUpperCase();
      const capitalizedDesc = capitalize(uom_description);
      setPostLoading(true); 

      try {
        const response = await axios.post(`${var_api}/api/uom-master/post`, {
            uom_name : uppercaseUOM, 
            uom_description: capitalizedDesc, 
          shop_id: localStorage.getItem('shopId') || ""
        });
    
        if (response.status === 200) {
          setPostLoading(false); 
          Swal.fire({
            icon:"success",
            text:"UOM Created Successfully",
            confirmButtonColor:"#007500"
          })
          setUom_description(" ");
          setUom_name(" ");
          fetchUOMs();
          console.log('Data inserted successfully.')

      }
      } catch (error) {
        setPostLoading(false); 
        Swal.fire({
            icon:"error",
            text:"UOM Already Exist",
            confirmButtonColor:"#dc3545"
          })
        console.error('Error:', error);
        // Handle error scenario as needed
      }
    };

    //reset the create form
    const handleToReset = () => {
        setUom_description("");
        setUom_name(""); 
    }

    //handle to reset the update form
    const handleResetShop = () => {
      setUpdatdeUOM({
        uom_name:"",
        uom_description:""
      })
    }



      //get the value in search and set the setstate
      const handleSearchInput = (e) => {
        setSearchQuery(e.target.value)
        };


         //search the transaction data
      const filteredUoms = uoms.filter((order) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          (order.uom_name && order.uom_name.toString().toLowerCase().includes(searchLower)) ||
          (order.uom_description && order.uom_description.toString().toLowerCase().includes(searchLower))
          
        );
      });

      //to open the form for slot updation 
    const handleEditUOm = async (data) => {
      setisedit(true);
      setUpdatdeUOM({
        id: data.id,
        uom_name: data.uom_name,
        uom_description: data.uom_description,
        shop_id:data.shop_id
      })
        // const { value: formValues } = await Swal.fire({
        //     title: 'Update UOM',
        //     html: `
        //      <label class="swalLa">Name</label>
        //      <br/>
        //       <input id="swal-input1" class="swal2-input fin" name="uom_name" placeholder="Category Name" value="${data.uom_name || ''}">
        //       <br/>
        //       <br/>
        //       <label class="swalLa">Description</label>
        //       <input id="swal-input2" class="swal2-input fin" name="uom_description" placeholder="Category Code" value="${data.uom_description || ''}">
             
        //     `,
        //     focusConfirm: false,
        //     showCancelButton: true,
        //     confirmButtonColor: '#007500',
        //     confirmButtonText: 'Submit',
        //     preConfirm: () => {
        //       return new Promise(async (resolve) => {
        //         const input1 = document.getElementById('swal-input1').value;
        //         const input2 = document.getElementById('swal-input2').value;
        
        //         if (!input1 || !input2) {
        //           Swal.showValidationMessage('Please fill in all the required fields');
        //           resolve(null);
        //         } else {

        //           const requestData = {
        //             uom_name: input1 || data.uom_name,
        //             uom_description: input2 || data.uom_description,
        //             shop_id: data.shop_id
        //           };
        
        //           try {
        //             // Make a PUT request to update the UOM
        //             const response = await axios.put(${var_api}/api/uom-master/put/${data.id}, requestData, {
        //               headers: {
        //                 'Content-Type': ' application/json', 
        //               },
        //             });
        
        //             if (response.status === 200) {
        //               fetchUOMs();
        //               // Category updated successfully
        //               Swal.fire({
        //                 icon: 'success',
        //                 text: 'UOM updated successfully',
        //                 confirmButtonColor: '#007500',
        //               });
        //             } else {
        //               Swal.fire({
        //                 icon: 'error',
        //                 text: 'Failed to update UOM',
        //                 confirmButtonColor: '#007500',
        //               });
        //             }
        //           } catch (error) {
        //             console.error('Error updating category:', error);
        //             Swal.fire('Error', 'Failed to update category. Please try again.', 'error');
        //           }
        
        //           resolve(requestData);
        //         }
        //       });
        //     },
        //   });
      };

      const handleUpdateUOM = async(e) => {
        e.preventDefault(); 
        setUpdateLoading(true); 
         // Trim whitespace from the input values
  const trimmedUOMName = updatedUOM.uom_name.trim();
  const trimmedUOMDescription = updatedUOM.uom_description.trim();

  // Check if the trimmed values are empty
  if (trimmedUOMName === '' || trimmedUOMDescription === '') {
    // Display an error message to the user
    Swal.fire({
      icon: "error",
      text: "UOM Name and Description are required",
      confirmButtonColor: "#dc3545"
    });
    setUpdateLoading(false); 
    return; // Exit the function early if validation fails
  }

        const requestData = {
                      uom_name: updatedUOM.uom_name.toUpperCase(),
                      uom_description: capitalize(updatedUOM.uom_description),
                      shop_id: updatedUOM.shop_id
                    };

                    console.log("re",requestData)
          
                    try {
                      // Make a PUT request to update the UOM
                      const response = await axios.put(`${var_api}/api/uom-master/put/${updatedUOM.id}`, requestData, {
                        headers: {
                          'Content-Type': ' application/json', 
                        },
                      });
          
                      if (response.status === 200) {
                        setUpdateLoading(false); 
                        fetchUOMs();
                        setisedit(false);
                        // Category updated successfully
                        Swal.fire({
                          icon: 'success',
                          text: 'UOM updated successfully',
                          confirmButtonColor: '#007500',
                        });
                      } else {
                        setUpdateLoading(false); 
                        Swal.fire({
                          icon: 'error',
                          text: 'UOM Already Exist',
                          confirmButtonColor: '#007500',
                        });
                      }
                    } catch (error) {
                      setUpdateLoading(false); 
                      console.error('Error updating category:', error);
                      Swal.fire({ 
                        icon:"error",
                      text:"UOM Already Exist",
                      confirmButtonColor:"#dc3545"});
                    }

      }


       //delete method for remove UOM
       const handleDeleteUOM = () => {
        axios
        .delete(`${var_api}/api/uom-master/uom-delete/${selectedUom.uom_id}`)
        .then((response) => {
          if (response.status === 200) {
            fetchUOMs();
            setShow(false);
            Swal.fire("success"," UOM Removed Successfully!","success")
            // Perform any other actions you need on success
          } else if (response.status === 404) {
            console.log('Slot not found');
            Swal.fire("error","UOM not found!","error")
          }
        })
        .catch((error) => {
          console.error('Error executing the request:', error);
          Swal.fire("error","Failed to Remove UOM!","error")
          // Handle the error as needed
        });

       }


       //open the delete confirmation modal
     const handleDeleteUOm = (id, uom) => {
      setSelectedUom({uom_id:id,uom_name:uom});
      setShow(true);
       
      }


      //close the modal
      const handleCloseConfirmation = ()=>{
        setShow(false);
      }

      //Go to home page
    const handleButtonClick = () => {
      history.push('/home')
    }

    const togglePutTooltip = (index) => {
      const putTooltipOpen = [...puttooltipOpen];
      putTooltipOpen[index] = !putTooltipOpen[index];
      setPutToolTipOpen(putTooltipOpen);
    };

 


    return(
        <>

<div className='whole' style={{backgroundColor:"white"}}>
            <div class="splituom leftuom">
                <div class="centered">
                  <br/>
                  <br/>
                    <h3 className='ushe'>UOM MASTER</h3>
                    <br/>
                    <div style={{marginLeft:"12px",padding:"12px"}}>

                    <form onSubmit={Submit} >
            <div className='mb-5' >
            <lable className='uomlabel' >UOM Name</lable>
            <br/>
            <input  className="form-input" type='text' name='uom_name' placeholder='enter uom name' id='uomname'
             required
             value={uom_name}
             onChange={(e) => setUom_name(e.target.value)}
             />
            </div>
            <br/>
            <div className='mb-5'>
            <lable className='uomlabel'>UOM Description</lable>
            <input  className="form-input" type='text' name='uom_description' placeholder='enter uom description' id='uomdesc'
            required
             value={uom_description}
             onChange={(e) => setUom_description(e.target.value)}/>
            </div>
           
            <br/>
            <br/>
            {postloading ? (
                  <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) : <button className='btn btn-success' style={{float:"right",marginLeft:"5px"}}>Submit</button>} {' '}
                
            
            </form>
            
            {' '}<button className='btn btn-secondary' style={{float:"right"}}  onClick={handleToReset} >Reset</button> {' '}
                       
                        
                    </div>
                   
                </div>
            </div>

            <div class="splituom rightuom">
            <nav className="navbar" id='uomnav'>
            {/* <div style={{ display: "flex"}}> */}
                <h3 id='uomshop'>{ shopName }</h3>
                <button className="btn" onClick={handleButtonClick} id='uomgohome'>
                    <i class="bi bi-house"></i> Go to Home
                </button>
            {/* </div> */}
        </nav>
      <div id='tableuomdiv'>
      
      <div>
        
      <input class="form-control" type="Search" placeholder="Search" id="uomsearch"
       onChange={handleSearchInput}/>
      {/* <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder",textDecoration:"underline"}}>UOM's List</h3> */}
      <br/>
      <br/>
      <br/>
     <div className="table-containeru">
  <table class="table table-hover small-first-col" id='uomtable'>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
      <th scope="col">S.No</th>
      <th scope="col">UOM Name</th>
      <th scope="col">Description</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {filteredUoms.length > 0 ? (
   filteredUoms.map((data,index) =>(

    <tr>
      <th scope="row">{index+1}</th>
      <td style={{fontSize:"15px",textTransform:"uppercase"}}>{data.uom_name}</td>
      <td style={{fontSize:"15px",textTransform:"capitalize"}}>{data.uom_description}</td>
      <td>
      <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='right'
      >
          Edit UOM
        </Tooltip>
            
      <i class="bi bi-pencil-square" id={`putButton${index}`} onClick={() => {handleEditUOm(data); togglePutTooltip(index);}}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      {/* <i class="bi bi-trash" onClick={() => handleDeleteUOm(data.id, data.uom_name)}  style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i> */}
      </td>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="5" style={{ textAlign: "center" }}>
        No UOM Found
      </td>
    </tr>
   )}
 

   </tbody>

</table>
</div>

<br/>
<br/>


        </div>
      
      </div>
            </div>


            
<Modal
        show={isEdit}
        onHide={()=>setisedit(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update UOM </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleUpdateUOM}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>UOM Name</label>
              <input type="text" class="form-control" placeholder="enter uom name" id="input1"  name="shop_name"  value={updatedUOM.uom_name}  onChange={(e) => setUpdatdeUOM({...updatedUOM, uom_name:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>UOM Description</label>
              <input type="text" class="form-control" placeholder="enter uom description" id="input2" name="uom_description" value={updatedUOM.uom_description} onChange={(e) => setUpdatdeUOM({...updatedUOM, uom_description:e.target.value})} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          {updateloading ? (
                  <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) : <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>}
          </form>
          <button className="btn btn-danger" onClick={()=>setisedit(false)} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>


            <Modal
        show={show}
        onHide={handleCloseConfirmation}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px",backgroundColor:"white"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM!</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete UOM{" "}
          <span style={{color:"red",textTransform:"uppercase",fontSize:"22px"}}>{selectedUom.uom_name}</span> ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={handleCloseConfirmation}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDeleteUOM}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal> 
        </div>


{/* 
        <nav className="navbar navbar-expand navbar-light py-2 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none"}} >
          <h5 className="hefou" style={{textAlign:"center",color:"white",paddingLeft:"5px"}}><i class="bi bi-arrow-left-circle" onClick={handleHome} style={{fontSize:"21px"}}></i>{' '}</h5>
        </nav>
        <div id='dailybacku'>
          <br/>
          <br/>
          <div id='fiboxu'>
            <form onSubmit={Submit}>
            <div class="labelsu">
            <lable style={{color:"green",fontWeight:"bold",fontSize:"18px",letterSpacing:"2px"}}></lable>
            <input  className="form-inputu" type='text' name='uom_name'
             required
             value={uom_name}
             onChange={(e) => setUom_name(e.target.value)}/>
            </div>
            <br/>
            <div class="labelsu">
            <lable style={{color:"green",fontWeight:"bold",fontSize:"18px",letterSpacing:"2px"}}>UOM Description</lable>
            <input  className="form-input1u" type='text' name='uom_description'
            required
             value={uom_description}
             onChange={(e) => setUom_description(e.target.value)}/>
            </div>
            <br/>
            <br/>
            <button className='btn btn-success' id='dasubu'>Submit</button> {' '}
            
            </form>
            <button className='btn btn-danger' style={{borderRadius: "11px", marginLeft:"540px", marginTop:"-63px"}} onClick={handleToReset}> Reset</button>
          </div>
          <br/>
          <div id='taboxu'>
          <table class="table table-success table-striped table-hover">
            <caption className="hefou" style={{captionSide:"top",textAlign:"right",fontSize:"25px",color:"black",fontWeight:"bolder"}}>
            <input type='Search' name='search' className='listSearchu' placeholder='Search UOM' onChange={handleSearchInput}  value={searchQuery}/>
            </caption>
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">UOM Name</th>
      <th scope="col">Description</th>
      <th scope="col">Action</th>
      <th></th>
    </tr>
  </thead>
  {loading ? (
        <div className="loader-containeru">
      	  <div className="spinneru"></div>
        </div>
  ) : (
  <tbody >
   {filteredUoms.length > 0 ? (
   filteredUoms.map((data, index) => (
  
    <tr key={index}>
      <th scope="row">{index + 1}</th>
      <td style={{textTransform:"uppercase", fontSize:"15px"}}>{data.uom_name}</td>
      <td style={{textTransform:"capitalize",fontSize:"15px"}}>{data.uom_description}</td>
      <td>
      <i class="bi bi-pencil-square" onClick={() => handleEditUOm(data)}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      <i class="bi bi-trash" onClick={() => handleDeleteUOm(data.id)}  style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i>
      </td>
    </tr>
  
)) 
): (
    <tr>
      <td colSpan="5" style={{ textAlign: "center" }}>
        No Data Available
      </td>
    </tr>
   )
}

   </tbody>
  )}
</table>
          </div>
        </div> */}
        </>
    )
}

export default UomMaster;