import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from '../constant';
import "./subscription_plans.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function SubscriptionPlans(){
    const history = useHistory();
    const [categories, setcategories] = useState([]);
    const shopName= localStorage.getItem('shopName');
    const [selectedCurrency,setSelectedCurrency] = useState(null);
    const [selectedCurrencyDe,setSelectedCurrencyDe] = useState("");
    const [selectedPlan,setSelectedPlan] = useState("");
    const [actualPrice,setActualPrice] = useState(null);
    const [discount,setDiscount] = useState(0);
    const [duration,setDuration] = useState(null);
    const [defaultPrcie,setDefaultPrcie] = useState(null);
    const [final_price,setFinal_price] = useState(null);
    const [total_price,setTotal_price] = useState(null);
    const [plans, setPlans] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [ loading, setLoading ] = useState(false);
    const [targetData, setTargetData] = useState([]);
    const options = [
        { id: 1, name: 'Monthly' },
        { id: 2, name: 'Yearly' },
        { id: 2, name: 'Table Order' },
        { id: 2, name: 'SpecialPayment-Month' },
        { id: 2, name: 'SpecialPayment-Year' }
      
        // Add more options as needed
      ];
      const [isEdit , setIsEdit] = useState(false);
      const [updatedActualPrice, setUpdatedActualPrice] = useState("");
      const [updatedDiscount, setUpdatedDiscount] = useState("");
      const [updatedDuration, setUpdatedDuration] = useState("");
      const [updatedDefaultPrice, setUpdatedDefaultPrice] = useState("");
      const [updatedCurrency,setUpdatedCurrency] = useState("");
      const [updatedId,setUpdatedId] = useState(null);
      const [updatedPlan, setUpdatedPlan] = useState("");
      const [show, setShow] = useState(false);
      const [PlanD, setPlanD] = useState({});
      const [switches, setSwitches] = useState([]);
    

    //redirect to home page
    const handleHome = () =>{
      history.push('/AdminDashboard')
    }

  
    const fetchCate = async () => {
     try {
      const response = await axios.get(`${var_api}/api/currency-master/get-currency` );
      const use = response.data

    console.log("categories",use)
    setcategories(use);
    }catch (error) {
      console.log('Error fetching data:', error);
      
      }
    };

    const fetchPlans = async () => {
      setLoading(true);
        try {
         const response = await axios.get(`${var_api}/api/subscription-plan/get-plans` );
         const use = response.data
   
       console.log("categories",use)
       // Create an array to track the state of each switch based on location_status
    const initialSwitches = use.map((location) => location.is_active === 1);
    console.log("initialSwitches",initialSwitches)
    setSwitches(initialSwitches);
       setPlans(use);
       setLoading(false);
       }catch (error) {
         console.log('Error fetching data:', error);
         
         }
       };

    
    useEffect(() => {
        fetchCate();
        fetchPlans();
      }, []);
  
   
//get the selected currency
   const onChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    setSelectedCurrency(selectedOption.value)
    const optionId = selectedOption.id
    setSelectedCurrencyDe(optionId);
    console.log("se",selectedOption);
    console.log("se1",selectedIndex);
    }

    //get the selected plan
   const onChangeHandlerPlan = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    setSelectedPlan(selectedOption.value)
    }

    const handleDiscount = (e) => {
        const disc = e.target.value;
        setDiscount(disc)
        const final_price = actualPrice - (actualPrice * (disc / 100));
        setFinal_price(final_price);
    }


    const handleActual = (e) => {
      const actu = e.target.value;
      setActualPrice(actu);
      if(discount!=null){
      const final_price = actu - (actu * (discount / 100));
      setFinal_price(final_price);
      }
  }

    const handleDefault = (e) => {
        const dePrice = parseInt(e.target.value);
        setDefaultPrcie(dePrice)

        const total_price = parseInt(final_price) + dePrice;
        setTotal_price(total_price);
    }


    
   
   //post the form data
    const Submit = async (e) => {
      e.preventDefault();


    
      try {
        const response = await axios.post(`${var_api}/api/subscription-plan/post`, {
            subscription_plan_name: selectedPlan, 
            actual_price: actualPrice, 
            discount: discount, 
            plan_duration_days: duration,
            currency: selectedCurrency,
            default_price: defaultPrcie
        });
       
    
        if (response.status === 200) {
          Swal.fire({
            icon:"success",
            text:`${selectedPlan} plan has been created`,
            confirmButtonColor:"#007500"
          })
          setDefaultPrcie(" ");
          setActualPrice(" ");
          setDiscount(" ");
          setDuration(" ");
          setSelectedCurrency(" ");
          setSelectedCurrencyDe(" ");
          setSelectedPlan(" ");
          setTotal_price(" ");
          setFinal_price(" ");
          fetchPlans();

      }
      } catch (error) {
        console.error('Error:', error);
        // Handle error scenario as needed
        if (error.response && error.response.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Duplicate Entry",
            text: "This entry already exists. Please try again with a different one.",
            confirmButtonColor: "#ff0000"
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred. Please try again later.",
            confirmButtonColor: "#ff0000"
          });
        }
      }
    };

    //open modal
    const handleOpenUpdateModal = (data) => {
      setIsEdit(true);
      setUpdatedCurrency(data.currency_name);
      setUpdatedId(data.sub_id);
      setUpdatedPlan(data.subscription_plan_name);
      setUpdatedActualPrice(data.actual_price);
      setUpdatedDiscount(data.discount);
      setUpdatedDuration(data.plan_duration_days);
      setUpdatedDefaultPrice(data.default_price);
    }

    // onChange function for actual price
    const handleUpdateActual = (e) => {
      setUpdatedActualPrice(e.target.value);
      console.log("ee1",e.target.value);
    }


    // onChange function for actual price
    const handleUpdatedDefault = (e) => {
      setUpdatedDefaultPrice(e.target.value);
      console.log("ee2",e.target.value);
    }


    // onChange function for actual price
    const handleUpdatedDuration = (e) => {
      setUpdatedDuration(e.target.value);
      console.log("ee3",e.target.value);
    }


    // onChange function for actual price
    const handleUpdatedDiscount = (e) => {
      setUpdatedDiscount(e.target.value);
      console.log("ee4",e.target.value);
    }


    //update function
    const handleUpdateSubPlan = async(event) => {
      event.preventDefault(); // Prevent default form submission behavior


      const requestData = {
        actual_price: parseFloat(updatedActualPrice),
        discount: parseFloat(updatedDiscount),
        plan_duration_days: parseInt(updatedDuration),
        default_price: parseInt(updatedDefaultPrice),
      };
     
      // const fiPr = formData.actual_price - (formData.actual_price * (formData.discount / 100));

      // // Calculate total price by adding fiPr and default_price
      // const totalPrice = fiPr + formData.default_price;
      
      // console.log(totalPrice);
        
      console.log("re",requestData)
       try {
        // Make a PUT request to update the UOM
        const response = await axios.put(`${var_api}/api/subscription-plan/put/${updatedId}`, requestData);
        
        if (response.status === 200) {
          fetchPlans();
          setIsEdit(false);
        // Category updated successfully
          Swal.fire({
            icon: 'success',
            text: 'Plan Details updated successfully',
            confirmButtonColor: '#007500',
          });
        } else {
           Swal.fire({
            icon: 'error',
            text: 'Failed to update Plan Details',
            confirmButtonColor: '#007500',
          });
        }
      } catch (error) {
        console.error('Error updating Plan Details:', error);
        Swal.fire('Error', 'Failed to update Plan Details. Please try again.', 'error');
      }
        
    } 

    //delect method
    const handleDelete = () => {
      console.log("iiiii",PlanD)
      axios
      .delete(`${var_api}/api/subscription-plan/plan-delete/${PlanD.id}`)
      .then((response) => {
        if (response.status === 200) {
          fetchPlans();
          setShow(false);
          Swal.fire("success"," Plan Removed Successfully!","success")
          // Perform any other actions you need on success
        } else if (response.status === 404) {
          console.log('Plan not found');
          Swal.fire("error","Plan not found!","error")
        }
      })
      .catch((error) => {
        console.error('Error executing the request:', error);
        Swal.fire("error","Failed to Remove Plan!","error")
        // Handle the error as needed
      });
    }

     // open modal delete method for remove UOM
   const handleDeletePlan = (id,name) => {
    setShow(true);
    setPlanD({id:id,plan_name:name})
     console.log("iiiii",id,name,PlanD)
    }



     //toggle switch for is_active updation
     const handleToggle = async (index,id) => {
      const newSwitches = [...switches];
      console.log('newSwitches', newSwitches);
      console.log('newSwitches', !newSwitches[index]);
      newSwitches[index] = !newSwitches[index];
      console.log('newSwitches', newSwitches[index]);
     
      setSwitches(newSwitches);
      console.log('newSwitches', newSwitches);
      //  Extract the location's ID (you need to replace 'id' with the actual ID property in your data)
      const locationId = plans[index]?.id;
console.log('location Id', locationId);

// Make an API request to update the location_status
try {
  const response = await axios.put(`${var_api}/api/subscription-plan/update-active-status/${id}`, { is_active: newSwitches[index] ? 1 : 0 });
  if (response.status === 200) {
    console.log('Location status updated successfully');
    fetchPlans();
  }
} catch (error) {
  console.log('Error updating location status:', error);
  // Revert the switch state in case of an error
  newSwitches[index] = !newSwitches[index];
  setSwitches(newSwitches);
}
    };





    return(
        <>
        <nav className="navbar navbar-expand navbar-light py-2 sticky-top" style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none"}} >
          <h5 className="hefo" style={{textAlign:"center",color:"white"}}><i class="bi bi-arrow-left-circle" onClick={handleHome} style={{fontSize:"21px"}}></i>{' '}SUBSCRIPTION PLANS</h5>
        </nav>
        <div id='subbackd'>
          <br/>
          <br/>
          <div className='row'>
            <div className='col'>
            <div id='fiboxsubd'>
            <form onSubmit={Submit}>
                <h5 style={{textAlign:"center",margin:"5px",fontFamily:"serif"}}>Create Subscription Plan</h5>
                <div className='row' style={{margin:"20px 20px 0px 20px"}}>
                    <div className='col'>
                    <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Select Currency ({selectedCurrencyDe})</label>
                    <select class="form-selects" aria-label="Default select example" onChange={onChangeHandler} required>
                        <option value="" disabled selected>select Currency</option>
                        {categories.map((category) =>(
                        <option value={category.id} id={category.currency_description} key={category.id} >{category.currency_name}</option>
                        ))}
                    </select>
                    </div>
                    <div className='col'>
                    <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Select Plan</label>
                    <select class="form-selects" aria-label="Default select example" onChange={onChangeHandlerPlan} required>
                        <option value="" disabled selected>select Plan</option>
                        {options.map((category) =>(
                        <option value={category.name} id={category.id} key={category.id} >{category.name}</option>
                        ))}
                    </select>
                    </div>

                </div>

                <br/>

                <div className='row' style={{margin:"0px 20px 0px 20px"}}>
                    <div className='col'>
                    <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Actual Price</label>
                    <input  className="form-inputsud" type='number' name='quantity' pattern='[0-9]' 
                       required
                       value={actualPrice}
                       onChange={handleActual}
                       placeholder='Enter actual price'
                    />
                    </div>
                    <div className='col'>
                    <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Discount (%)</label>
                    <input  className="form-inputsud" type='number' name='quantity' pattern='[0-9]' 
                       required
                       value={discount}
                       onChange={handleDiscount}
                       placeholder='Enter discount'
                    />
                    </div>

                </div>

                <br/>
                <div className='row' style={{margin:"0px 20px 0px 20px"}}>
                    <div className='col'>
                        <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Duration Days Count</label>
                        <input  className="form-inputsud" type='number' name='quantity' pattern='[0-9]' 
                          required
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                          placeholder='Enter duration'
                        />
                    </div>
                    <div className='col'>
                        <label class="form-label fw-bold m-1" style={{fontFamily:"serif",color:"green"}}>Default Price</label>
                        <input  className="form-inputsud" type='number' name='quantity' pattern='[0-9]' 
                           required
                           value={defaultPrcie}
                           onChange={handleDefault}
                           placeholder='Enter default price'
                        />
                    </div>

                </div>
                <br/>
                <div className='row' style={{margin:"0px 20px 0px 20px"}}>
                    <div className='col'>
                       <h5 style={{fontFamily:"serif"}}>Final Price: <span style={{fontFamily:"monospace"}}>{final_price}</span></h5>
                    </div>
                    <div className='col'>
                    <h5 style={{fontFamily:"serif"}}>Total Price: <span style={{fontFamily:"monospace"}}>{total_price}</span></h5>
                    </div>
                    <div className='col'>
                    <button className='btn btn-outline-success' id='dasubscri'>Submit</button>
                    </div>

                </div>

             
         
           
            </form>
          </div>
            </div>

            <div className='col'></div>

            <div id='taboxsub'>
          <table class="table table-success table-striped table-hover" >
            {/* <caption className="hefo" style={{captionSide:"top",textAlign:"center",fontSize:"25px",color:"black",fontWeight:"bolder"}}>SUMMARY for
            {' '}
            <input type='date' name='date' value={selectedDate} onChange={handleDateChange} style={{border:"none",borderBottom:"2px solid green"}} placeholder='choose date' />
            </caption> */}
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Currency</th>
      <th scope="col">Plan Name</th>
      <th scope="col">Actual Price</th>
      <th scope="col">Discount (%)</th>
      <th scope="col">Default Price</th>
      <th scope="col">Final Price</th>
      <th scope="col">Duration Of Days</th>
      <th scope="col">Action</th>
      <th scope="col"></th>
    </tr>
  </thead>
  {loading ? (
        <div className="loader-containeru">
      	  <div className="spinner"></div>
        </div>
  ) : (
  <tbody>
   { Array.isArray(plans) && plans.map((plan, index) => (
 
    <tr key={plan.id}>
      <th scope="row">{index + 1}</th>
      <td>{plan.currency_name}</td>
      <td>{plan.subscription_plan_name}</td>
      <td>{plan.actual_price}</td>
      <td>{plan.discount}</td>
      <td>{plan.default_price}</td>
      <td style={{fontWeight:"bolder"}}>{plan.total_price}</td>
      <td>{plan.plan_duration_days}</td>
      <td>
      <i class="bi bi-pencil-square" onClick={()=>handleOpenUpdateModal(plan)}   style={{fontSize:"21px",color:"rgb(9, 97, 9)"}}></i>
      {/* <i class="bi bi-trash" onClick={()=>handleDeletePlan(plan.sub_id,plan.subscription_plan_name)} style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i> */}
      </td>
      <td>
        
      <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id={`flexSwitchCheckChecked_${index}`} checked={switches[index]}  onChange={() => handleToggle(index,plan.sub_id)}/>
</div>
      </td>
    </tr>
   ))}

   </tbody>
  )}
</table>
          </div>

          </div>
          <br/>
          <br/>
         


          <Modal
        show={isEdit}
        onHide={()=>setIsEdit(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Subscription Plan </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleUpdateSubPlan}>
          <div class="row">
            <div class="col">
               <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Currency</label>
              <input type="text" class="form-control"  id="input1"  name="currency"  value={updatedCurrency}  style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Subscription Plan</label>
              <input type="text" class="form-control"  id="input2" name="plan" value={updatedPlan} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input3" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Actual Price</label>
              <input type="number" class="form-control" placeholder="Enter actual price" id="input3"  name="actual_price"  value={updatedActualPrice}  onChange={handleUpdateActual} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
              <label for="input4" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Discount</label>
              <input type="number" class="form-control" placeholder="Enter discount" id="input4" name="discount" value={updatedDiscount} onChange={handleUpdatedDiscount} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col">
               <label for="input5" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Duration</label>
              <input type="number" class="form-control" placeholder="Enter duration" id="input5"  name="duration"  value={updatedDuration}  onChange={handleUpdatedDuration} style={{borderRadius:"12px"}} required/>
            </div>
            <div class="col">
            <label for="input6" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Default Price</label>
              <input type="number" class="form-control" placeholder="Enter default Price" id="6"  name="default_price"  value={updatedDefaultPrice}  onChange={handleUpdatedDefault} style={{borderRadius:"12px"}} required/>
            </div>
          </div>
         <br/>
          <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
          </form>
          <button className="btn btn-danger" onClick={()=>setIsEdit(false)} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
        
      </Modal>

      <Modal
        show={show}
        onHide={()=>setShow(false)}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM !</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete Plan Name 
          <span style={{color:"red",textTransform:"uppercase"}}> {PlanD.plan_name}</span> ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={()=>setShow(false)}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>
          
        </div>
        </>
    )
}
{/* <i class="fa fa-flag" aria-hidden="true"></i> */}

export default SubscriptionPlans;