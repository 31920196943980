import React, { useState, useEffect } from "react";
import axios from 'axios';
import { var_api } from '../constant';
import { useHistory } from 'react-router-dom';
import './Itemsummary.css';


const Itemsummary = () => {
    const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
    const [selectedDatepaymode, setSelectedDatepaymode] = useState(new Date().toISOString().split('T')[0]);
    const [tables, setTables] = useState([]);
    const [paymode, setPaymode] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false); // Add isLoading state
    const history = useHistory();

    useEffect(() => {
        fetchTables();
        fetchPaymode();
    }, [selectedDate,selectedDatepaymode]); // Add selectedDate to the dependency array

    const fetchTables = async () => {
        setIsLoading(true); // Set isLoading to true when fetching data
        try {
            const shopid = localStorage.getItem('shopId');
            const response = await axios.get(`${var_api}/api/sub_day_order_summary/api/sub/food-summary/${shopid}/${selectedDate}`);
            console.log("response", response);
            const user = response.data.result;
            setTables(user);
            setIsLoading(false); // Set isLoading to false after fetching data
        } catch (error) {
            console.log(error);
            setIsLoading(false); // Set isLoading to false if there's an error
        }
    };

    const fetchPaymode = async () => {
        setIsLoading1(true); // Set isLoading to true when fetching data
        try {
            const shopid = localStorage.getItem('shopId');
            const formattedDate = formatDate(selectedDatepaymode); 
            const response = await axios.get(`${var_api}/api/food/get-paymode-summary/${shopid}/${formattedDate}`);
            console.log("response", response);
            const paymodes = response.data;
            setPaymode(paymodes);
            setIsLoading1(false); // Set isLoading to false after fetching data
        } catch (error) {
            console.log(error);
            setIsLoading1(false); // Set isLoading to false if there's an error
        }
    };

    const totalAmount = paymode.reduce((total, item) => total + (item.total_Amount || 0), 0);

function getYesterdayDate() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const dd = String(yesterday.getDate()).padStart(2, '0');
    const mm = String(yesterday.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = yesterday.getFullYear();

    return `${dd}-${mm}-${yyyy}`;
}

// const handleDateChange = (e) => {
//     const selectedDate = e.target.value;
//     // Convert selectedDate to the desired format dd-mm-yyyy
//     const [yyyy, mm, dd] = selectedDate.split('-');
//     const formattedDate = `${dd}-${mm}-${yyyy}`;
//     setSelectedDate(formattedDate);
//     const today = new Date().toISOString().split('T')[0];
//     if (selectedDate === today) {
//         // Show toast message
//         setTables([]);
//         showToast("Only end-of-day summaries will be generated for tomorrow");
//     }
// };


const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    // Convert selectedDate to the desired format dd-mm-yyyy
    const [yyyy, mm, dd] = selectedDate.split('-');
    const formattedDate = `${dd}-${mm}-${yyyy}`;
    
    const today = new Date().toISOString().split('T')[0];
    console.log("date",formattedDate,today)
    if (selectedDate >= today) {
        // Show toast message
        // setTables([]);
        console.log("present date or future date");
        showToast("Only end-of-day summaries will be generated for tomorrow");
    } else {
        console.log("past date")
        // Set the selected date when it is not today's date
        setSelectedDate(formattedDate);
    }
};



const handleDateChangepaymode = (e) => {
    setSelectedDatepaymode(e.target.value);
};

const formatDate = (dateString) => {
    const [yyyy, mm, dd] = dateString.split('-');
    return `${dd}-${mm}-${yyyy}`;
};

const formatDateForInputField = (dateString) => {
    const [yyyy, mm, dd] = dateString.split('-');
    return `${dd}-${mm}-${yyyy}`; // Format as dd-mm-yyyy
};
const showToast = (message) => {
    // Implement toast message display logic here
    alert(message);
};
const handleButtonClickhome = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };

    return (
        <div>
            <div className="container-fluid" style={{ textAlign: "center" }}>
        <nav className="navbar" style={{ boxShadow: "0px 0px 1px 0px black", backgroundColor: "green", border: "none", padding: "5px", height: "50px", width: "100%", position: "relative"}}>
            <div style={{ display: "flex", margin:"auto"}}>
                <h3 style={{ fontFamily: "cursive", fontWeight: "bolder",color: "white" }}>Item & Paymode Summary</h3>
                <button className="btn" onClick={handleButtonClickhome} style={{  backgroundColor: "white", color: "green", marginLeft:"700px" }}>
                    <i class="bi bi-house"></i> Go to Home
                </button>
            </div>
           



        </nav>
    </div>
    
    <br/>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
<div style={{ margin: 'auto' }}>
{isLoading1 ? (
    <div className="loader-container">
        <div className="loader"></div>
    </div>
) : (
    <>
     <p className="card-text" style={{ marginRight: '10px', textAlign:'center', fontWeight:' bold', fontSize:'18px', color:"blue"}}>Paymode Summary</p>
        <div className="card" style={{ width: '300px'}}>
            <div className="card-body" style={{ display: 'flex', flexDirection: 'row', alignItems: 'right' }}>
                <p className="card-text" style={{ marginRight: '10px' }}>Date: </p>
                <i className="bi bi-search" style={{ marginRight: '5px', marginBottom: '10px'}}></i>
                <input 
                type="date" 
                id="yesterdayDateInput" 
                className="form-control" 
                style={{ marginRight: '5px', marginBottom: '15px', color:"blue"}}
                value={selectedDatepaymode}
                onChange={handleDateChangepaymode}
            />
            </div>
        </div>
        <p style={{textAlign:"end", marginRight:"150px", fontWeight:"bold"}}>Total Amount ({selectedDatepaymode}) :  <span style={{color:"blue", fontWeight:"bold"}}>{totalAmount}</span></p>
        <div className="card" style={{ width: '600px', height:"auto", border:"2px solid green"}}>
            <div className="card-body">
                <div style={{ borderBottom: "2px solid black", paddingBottom: "10px", marginBottom: "10px" }}>
                    <div className="card-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>S.No</strong></div>
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>Paymode</strong></div>
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>Amount</strong></div>
                    </div>
                </div>
                {paymode.length === 0 ? (
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>No Paymode Summary</div>
                ) : (
                    paymode.map((table, index) => (
                        <div className="content" key={index} style={{ borderBottom: "1px solid black", paddingBottom: "5px", marginBottom: "5px", display: 'flex', justifyContent: 'space-between', alignItems:"center" }}>
                            <div style={{ flex: '1', textAlign: 'left' }}>{index + 1}</div>
                            <div 
                            style={{ flex: '1', textAlign: 'left',color: table.payMode === "CREDIT ORDER" || table.payMode === "GROUP LOCATION CREDIT ORDER" ? "blue" : "black" }}>
                                {table.payMode}</div>
                            <div style={{ flex: '1', textAlign: 'left' ,color: table.payMode === "CREDIT ORDER" || table.payMode === "GROUP LOCATION CREDIT ORDER" ? "blue" : "black" }}>{table.total_Amount ? (table.total_Amount).toFixed(2) : 0}</div>
                        </div>
                    ))
                )}
            </div>
        </div>
    </>
)}
</div>

<div className="vertical-line"></div>

<div style={{ margin: 'auto'}}>
    {isLoading ? (
    <div className="loader-container">
        <div className="loader"></div>
    </div>
) : (
    <>
     <p className="card-text" style={{ marginRight: '10px', textAlign:'center', fontWeight:' bold', fontSize:'18px', color:"red"}}>Item Summary</p>
        <div className="card" style={{ width: '300px'}}>
            <div className="card-body" style={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
                <p className="card-text" style={{ marginRight: '10px' }}>Date: </p>
                <i className="bi bi-search" style={{ marginRight: '5px', marginBottom: '10px'}}></i>
                <input type="date" id="yesterdayDateInput" className="form-control" style={{ marginRight: '5px', marginBottom: '15px', color:"red"}}
                    value={formatDateForInputField(selectedDate)} onChange={handleDateChange}/>
            </div>
        </div>
        <div className="card" style={{ width: '600px', height:"600px", border:"2px solid green", overflow:"scroll"}}>
            <div className="card-body">
                <div style={{ borderBottom: "2px solid black", paddingBottom: "10px", marginBottom: "10px" }}>
                    <div className="card-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>S.No</strong></div>
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>Food Name</strong></div>
                        <div style={{ flex: '1', textAlign: 'left' }}><strong>Quantity</strong></div>
                    </div>
                </div>
                {tables.length === 0 ? (
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>No Item Summary</div>
                ) : (
                    tables.map((table, index) => (
                        <div className="content" key={index} style={{ borderBottom: "1px solid black", paddingBottom: "5px", marginBottom: "5px", display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ flex: '1', textAlign: 'left' }}>{index + 1}</div>
                            <div style={{ flex: '1', textAlign: 'left' }}>{table.food_name}</div>
                            <div style={{ flex: '1', textAlign: 'left' }}>{table.quantity}</div>
                        </div>
                    ))
                )}
            </div>
        </div>
    </>
)}
</div>
        </div>
        </div>
    );
};

export default Itemsummary;