import React, { useState, useEffect } from "react";
import './kitchen.css'
import pics from './kitImag/tray.png';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import ChildCard1 from "./childCard1";
import ChildCard2 from "./childCard2";
import ChildCard3 from "./childCard3";
import ChildCard4 from "./childCard4";
import { OrderProvider } from "./orderContext";
import cc1 from './kitImag/order.png';
import pro from './kitImag/processing.png';
import rea from './kitImag/reea.png';
import del from './kitImag/dele.png';
import { useHistory } from 'react-router-dom';
import { Table, Row, Col, Card} from 'react-bootstrap';
import { var_api } from "../constant";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';


function Kitchen(){
  const [allOrders, setAllOrders] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [progressOrders, setProgressOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [newOrdersCount, setNewOrdersCount] = useState(null);
  const [progressOrdersCount, setProgressOrdersCount] = useState(null);
  const [readyOrdersCount, setReadyOrdersCount] = useState(null);
  const [delOrdersCount, setDelOrdersCount] = useState(null);
  const [delOrders, setDelOrders] = useState([]);
  const [counts, setCounts] = useState({});
  const history = useHistory();
  const [pLoading, setPLoading] = useState([]);
  const [rLoading, setRLoading] = useState([]);
  const [cLoading, setCLoading] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deSummary, setDeSummary] = useState({});
  const [ordersCount, setOrdersCount] = useState({});
  const shopName= localStorage.getItem('shopName');
  const [newloading, setNewLoading] = useState(false);
  const [inprogressloading, setInprogressLoading] = useState(false);
  const [readyloading, setReadyLoading] = useState(false);
  const [deliveredloading, setDeliveredLoading] = useState(false);
  const role = localStorage.getItem("e_role");
  
  // Get today's date
  const today = new Date();
  const formattedStartDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
  const [ startDate, setStartDate ] = useState(formattedStartDate); // set today date for delivery date field
  const [ newStartDate, setNewStartDate ] = useState(formattedStartDate);
  const [ progressStartDate, setProgressStartDate ] = useState(formattedStartDate);
  const [ readyStartDate, setReadyStartDate ] = useState(formattedStartDate);
  const handleButtonClick = () => {
    // Navigate to a specific route using history.push
    history.push('/home');
  };
  const handleButtonTableClick = () => {
    // Navigate to a specific route using history.push
    history.push('/tableOrders');
  };
  

  const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // January is 0, so we add 1
    const year = currentDate.getFullYear();

    // Pad single digit day and month with leading zero if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Format the date as dd-mm-yyyy
    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

  const fetchData = async () => {
   const shop_id = localStorage.getItem('shopId');
    
    try {
      const response = await axios.get(`${var_api}/api/main-kitchen/get-orders/${shop_id}/${formattedDate}`); // Replace with your API endpoint
      setAllOrders(response.data.categorizedOrders); // Update the state with the fetched data
      setCounts(response.data.counts)
      console.log("data",response.data)
      console.log("data1",response.data.categorizedOrders)
      console.log("data2",response.data.categorizedOrders.new)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const fetchNewData = async (date) => {
    const shop_id = localStorage.getItem('shopId');
    setNewLoading(true);
     try {
       const response = await axios.get(`${var_api}/api/main-kitchen/get-new-orders/${shop_id}/${date}`); // Replace with your API endpoint
       setNewOrders(response.data.categorizedOrders); // Update the state with the fetched data
       setNewOrdersCount(response.data.counts)
       console.log("data",response.data)
       console.log("data1",response.data.categorizedOrders)
       console.log("data2",response.data.categorizedOrders.new)
       setNewLoading(false);
     } catch (error) {
       console.error('Error fetching data:', error);
       setNewLoading(false);
     }
   };


   const fetchProgressData = async (date) => {
    const shop_id = localStorage.getItem('shopId');
    setInprogressLoading(true); 
     try {
       const response = await axios.get(`${var_api}/api/main-kitchen/get-progress-orders/${shop_id}/${date}`); // Replace with your API endpoint
       setProgressOrders(response.data.categorizedOrders); // Update the state with the fetched data
       setProgressOrdersCount(response.data.counts)
       console.log("data",response.data)
       console.log("data1",response.data.categorizedOrders)
       console.log("data2",response.data.counts.progress)
       setInprogressLoading(false);
     } catch (error) {
       console.error('Error fetching data:', error);
       setInprogressLoading(false);
     }
   };


   const fetchReadyData = async (date) => {
    const shop_id = localStorage.getItem('shopId');
    setReadyLoading(true); 
     try {
       const response = await axios.get(`${var_api}/api/main-kitchen/get-ready-orders/${shop_id}/${date}`); // Replace with your API endpoint
       setReadyOrders(response.data.categorizedOrders); // Update the state with the fetched data
       setReadyOrdersCount(response.data.counts)
       console.log("data",response.data)
       console.log("data1",response.data.categorizedOrders)
       console.log("data2",response.data.categorizedOrders.new)
       setReadyLoading(false);
     } catch (error) {
       console.error('Error fetching data:', error);
       setReadyLoading(false);
     }
   };

  const fetchDeliveryData = async (startDate) => {
    const shop_id = localStorage.getItem('shopId');
    setDeliveredLoading(true)
     try {
       const response = await axios.get(`${var_api}/api/main-kitchen/get-delivered-orders/${shop_id}/${startDate}`); // Replace with your API endpoint
      const result = response.data;
      setDelOrders(result);
      setDeliveredLoading(false)
     } catch (error) {
       console.error('Error fetching data:', error);
       setDeliveredLoading(false)
     }
   };


   const fetchOrdersCount = async () => {
    const shop_id = localStorage.getItem('shopId');
     
     try {
       const response = await axios.get(`${var_api}/api/main-kitchen/get-orders-state-count/${shop_id}/${formattedDate}`); // Replace with your API endpoint
      const result = response.data;
      setOrdersCount(result);
     } catch (error) {
       console.error('Error fetching data:', error);
     }
   };


   const fetchDeliverySummary = async (startDate) => {
    const shop_id = localStorage.getItem('shopId');
     
     try {
       const response = await axios.get(`${var_api}/api/main-kitchen/get-kitchen-summary/${shop_id}/${startDate}`); // Replace with your API endpoint
      const result = response.data;
      setDeSummary(result);
     } catch (error) {
       console.error('Error fetching data:', error);
     }
   };

  useEffect(() => {
    // const fetchDataInterval = setInterval(() => {
      // fetchData();
      fetchDeliveryData(startDate);
      fetchDeliverySummary(startDate);
      // fetchOrdersCount();
      fetchNewData(newStartDate);
      fetchProgressData(progressStartDate);
      fetchReadyData(readyStartDate);
    // }, 1000); // Poll every second
    // Cleanup function to clear the interval when component unmounts
    // return () => clearInterval(fetchDataInterval);

    // Set up interval to call fetchNewData every 10 seconds
  // const intervalId = setInterval(fetchNewData(), 10000); // 10000 milliseconds = 10 seconds

  // Clean up function to clear interval when component unmounts or when dependencies change
  // return () => clearInterval(intervalId);
  }, [
    // startDate
  ]); // Add startDate as dependency to re-poll when it changes


  //update loading state for progeress
  const updateLoadingProgressState = (index, isLoading) => {
    setPLoading(prevStates => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = isLoading;
      return newLoadingStates;
    });
  };

    //update to progress
    const updateProgress = (id,kitchenOrderNo,index,table_status) => {
      const shop_id = localStorage.getItem('shopId');
      updateLoadingProgressState(index, true);
      axios.put(`${var_api}/api/main-kitchen/update-progress/${id}/${kitchenOrderNo}/${shop_id}/${table_status}`)
          .then(response => {
              console.log(response.data.message);
              fetchProgressData(progressStartDate);
              fetchNewData(newStartDate);
              updateLoadingProgressState(index, false);
              const audio = new Audio('https://spbiller.com/audio/to-ip-ready.mp3');
              audio.play();
          })
          .catch(error => {
              console.error('Error updating progress:', error);
              console.log('Error updating progress. Please try again.');
              updateLoadingProgressState(index, false);
              fetchProgressData(progressStartDate);
              fetchNewData(newStartDate);
          });
  };
 
//update loading state for ready
  const updateLoadingReadyState = (index, isLoading) => {
    setRLoading(prevStates => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = isLoading;
      return newLoadingStates;
    });
  };

  
   //update to ready
   const updateReady= (id,kitchenOrderNo, index,table_status) => {
    const shop_id = localStorage.getItem('shopId');
    updateLoadingReadyState(index, true);
    axios.put(`${var_api}/api/main-kitchen/update-ready/${id}/${kitchenOrderNo}/${shop_id}/${table_status}`)
        .then(response => {
            console.log(response.data.message);
            fetchProgressData(progressStartDate);
            fetchReadyData(readyStartDate);
            updateLoadingReadyState(index, false);
            const audio = new Audio('https://spbiller.com/audio/to-ip-ready.mp3');
              audio.play();
        })
        .catch(error => {
            console.error('Error updating progress:', error);
            console.log('Error updating progress. Please try again.');
            updateLoadingReadyState(index, false);
            fetchProgressData(progressStartDate);
            fetchReadyData(readyStartDate);
        });
        
        
};



const updateLoadingState = (index, isLoading) => {
  setCLoading(prevStates => {
    const newLoadingStates = [...prevStates];
    newLoadingStates[index] = isLoading;
    return newLoadingStates;
  });
};


 //update to deliver
 const updateComplete = (id,kitchenOrderNo,index,table_status) => {
  updateLoadingState(index, true);
  const shop_id = localStorage.getItem('shopId');
  axios.put(`${var_api}/api/main-kitchen/update-delivery/${id}/${kitchenOrderNo}/${shop_id}/${table_status}`)
      .then(response => {
          console.log(response.data.message);
          fetchDeliveryData(startDate);
          fetchDeliverySummary(startDate);
          fetchReadyData(readyStartDate);
          updateLoadingState(index, false);
          const audio = new Audio('https://spbiller.com/audio/to-deliver.mp3');
              audio.play();
      })
      .catch(error => {
          console.error('Error updating progress:', error);
          console.log('Error updating progress. Please try again.');
          updateLoadingState(index, false);
          fetchDeliveryData(startDate);
      fetchDeliverySummary(startDate);
      fetchReadyData(readyStartDate);
      });
      
      
};

const formatDate = (dateString) => {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
}


//open the summary modal
const handleOpenSummary = () => {
  setIsOpen(true);
}

//onchange function for delivery date text field
const handleToChangeDate = (event) => {
  const startDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = startDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the start date, e.g., store it in state
  setStartDate(formattedDate);
  fetchDeliverySummary(formattedDate);
  fetchDeliveryData(formattedDate);
  
}


//onchange function for new date text field
const handleToChangeNewDate = (event) => {
  const startDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = startDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the start date, e.g., store it in state
  setNewStartDate(formattedDate);
  fetchNewData(formattedDate);
}


//onchange function for progress date text field
const handleToChangeProgressDate = (event) => {
  const startDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = startDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the start date, e.g., store it in state
  setProgressStartDate(formattedDate);
  fetchProgressData(formattedDate);
}


//onchange function for ready date text field
const handleToChangeReadyDate = (event) => {
  const startDate = event.target.value;
  // Split the date string into year, month, and day
 const [year, month, day] = startDate.split('-');
 // Create a new date string with the desired format
 const formattedDate = `${day}-${month}-${year}`;
 // Do whatever you want with the formatted date
 console.log('Formatted date:', formattedDate);
  // Do something with the start date, e.g., store it in state
  setReadyStartDate(formattedDate);
  fetchReadyData(formattedDate);
}



function parseTime(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return {
    hours,
    minutes,
    seconds
  };
}

// Function to calculate duration in milliseconds
function calculateDuration(start, end) {
   // Extract hours, minutes, and seconds from start and end times
   const startParts = start.split(':').map(Number);
   const endParts = end.split(':').map(Number);
 
   // Calculate the duration components
   let hours = endParts[0] - startParts[0];
   let minutes = endParts[1] - startParts[1];
   let seconds = endParts[2] - startParts[2];
 
   // Adjust for negative values
   if (seconds < 0) {
     seconds += 60;
     minutes--;
   }
   if (minutes < 0) {
     minutes += 60;
     hours--;
   }
   if (hours < 0) {
     hours += 24;
   }
 
   // Format the duration
   const formattedHours = String(hours).padStart(2, '0');
   const formattedMinutes = String(minutes).padStart(2, '0');
   const formattedSeconds = String(seconds).padStart(2, '0');
 
   // Return the formatted duration
   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


return(

  // <OrderProvider>
<>
<div id="thalaikit">
<nav className="navbar  navbar-light py-1 sticky-top" id="navkit" >
<div className="container-fluid">
        <h5 id="kifoki">
        kitchen
        <img src={pics} alt="image" style={{width:"35px",height:"35px"}} />
        </h5>
        <h4 id="shopkit">{ shopName }</h4>
        <div className="d-flex flex-row" id="navbuttons">
      <button className="btn mr-2" style={{backgroundColor:"white",color:"green"}} onClick={handleButtonTableClick}>
        <i className="bi bi-house"></i> Go to Table Order
      </button>
      {
        !(role === "Chef" || role === "Captain" || role === "Waiter") &&
        <button className="btn" style={{backgroundColor:"white",color:"green"}} onClick={handleButtonClick}>
          <i className="bi bi-house"></i> Go to home
        </button>
      }
    </div>
    </div>
      </nav>
      <div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4" id="kitrow" >



          <div className="col mb-0 ftco-animate">
            <div className="card" id="kitone">
              <div class="card-header" style={{border:"none",color:"green",fontWeight:"bolder",fontSize:"18px",...(isOpen ? {} : { zIndex: "9999" })}}>New{" "}
              <span class="badge rounded-circle bg-dark" style={{color:"white"}}>{newOrdersCount && newOrdersCount.new}</span>
              <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto",float:"right"}}
              placeholder='choose date' value={formatDate(newStartDate)} onChange={handleToChangeNewDate} />
              </div>
              <div className="card-body" id="scrki">
                {/* <br/>
                <h6 style={{margin:"10px 10px 10px 5px"}}><span style={{float:"right"}}><i class="bi bi-calendar"></i>{" "}{formattedDate}</span></h6> */}
                <br/>
                {newloading ? (
                  <div class="text-center" >
                  <div class="spinner-border" role="status" >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) :
              <Row>
              { newOrders && newOrders.new && newOrders.new.length === 0 ? (
                <div className="text-center">
                  <h6 style={{fontWeight:"bolder",marginTop:"30px",color:"red"}}>No Date Available</h6>
                </div>
              ) : (
              newOrders && newOrders.new && newOrders.new.map((order,index) => (
           
           <Col key={index}>
            <Card  border="success" style={{borderRadius:"20px",width:"320px",marginBottom:"0px",marginTop:"12px",height:"auto"}}> 
          
           <Card.Body className="p-2">
            <div>
              <div className="Crowki">
                <div className="Ccolumnki">
                  <p style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>S.No: <span style={{fontWeight:"normal"}}>{order.order_count_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}} >Items: <span style={{fontWeight:"normal"}}>{order.ordered_by.length}</span></p>
                </div>
                <div className="Ccolumnki">
                  <p  style={{fontFamily:"serif",fontSize:"14px"}}>{order.is_table === 0 ? (
                    <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>Table Order</span>
                  ) : (
                    <span class="badge rounded-pill bg-info p-2" style={{color:"white"}}>Parcel Order</span>
                  )}</p>
                  {order.is_table === 0 && <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Table No: <span style={{fontWeight:"normal"}}>{order.table_no}</span></p>}
                </div>
                <div className="Ccolumnki">
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Kitchen Order: <span style={{fontWeight:"normal"}}>{order.kitchen_order_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Captain: <span style={{fontWeight:"normal"}}>{order.employee_name}</span></p>
                </div>
              </div>
            </div>
            <hr/>
          
                
               <Table striped="Ccolumnki" className="scrollable-table" borderless style={{marginTop:"-20px"}}>
                <thead style={{border:"none"}}>
                <th style={{border:"none"}}>S.No</th>
                <th style={{border:"none"}}>Food</th>
                <th style={{border:"none"}}>Items</th>
                <th style={{border:"none"}}>Qty</th>
              </thead>
              {order.ordered_by.map((item, index) => (
                    <tr key={index}>
                    <td>{index+1}</td>
                    <td style={{textAlign:"center"}}><img src = { item.image ? `${var_api}/private/${item.image}` : `${var_api}/private/food_default_image.jpg`} alt="food_img" width="80px" height="80px"/></td>
                    <td>{item.food_name}</td>
                    <td>{item.quantity}</td>
                    </tr>
              ))}
    </Table>
    <hr/>
           
                 <button type="button"  class="btn btn-outline-success" style={{float:"right"}} onClick={()=>updateProgress(order.id,order.kitchen_order_no,index,order.is_table)}>
                 {pLoading[index] ?  (
                    <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  ) : (
       <>
       <i className="bi bi-arrow-right"></i>
       {" "}to IP
     </>) }
                 </button>
               
               {/* <button type="button" onClick={() => moveOrderToInProgress(order.id)} class="btn btn-outline-success" style={{marginTop:"10px"}}>New</button> */}
              </Card.Body>
          
            </Card>
          </Col>
         )
        ))}
      </Row>
}
              </div>
            </div>
          </div>




          <div className="col mb-0 ftco-animate">
            <div className="card" id="kittwo" >
              <div class="card-header" style={{border:"none",color:"green",fontWeight:"bolder",fontSize:"18px",...(isOpen ? {} : { zIndex: "9999" }) }}>Inprogress {" "}
              <span class="badge rounded-circle bg-dark" style={{color:"white"}}>{progressOrdersCount && progressOrdersCount.progress}</span>
              <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto",float:"right"}}
              placeholder='choose date' value={formatDate(progressStartDate)} onChange={handleToChangeProgressDate} />
              </div>
              <div className="card-body" id="scr" style={{marginTop:"-40px"}}>
              {/* <br/>
                <h6 style={{margin:"10px 10px 10px 5px"}}><span style={{float:"right"}}><i class="bi bi-calendar"></i>{" "}{formattedDate}</span></h6> */}
                <br/>
                {inprogressloading ? (
                  <div class="text-center" >
                  <div class="spinner-border" role="status" >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) :
              <Row>
              {progressOrders && progressOrders.progress && progressOrders.progress.length === 0 ? (
                    <div className="text-center">
                      <h6 style={{fontWeight:"bolder",marginTop:"30px",color:"red"}}>No Date Available</h6>
                    </div>
                  ) : (
              progressOrders && progressOrders.progress && progressOrders.progress.map((order,index) => (
           
           <Col key={index}>
            <Card  border="success" style={{borderRadius:"20px",width:"320px",marginBottom:"0px",marginTop:"12px",height:"auto"}}> 
          
           <Card.Body className="p-2">
            <div>
              <div className="Crowki">
                <div className="Ccolumnki">
                  <p style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>S.No: <span style={{fontWeight:"normal"}}>{order.order_count_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}} >Items: <span style={{fontWeight:"normal"}}>{order.ordered_by.length}</span></p>
                </div>
                <div className="Ccolumnki">
                  <p  style={{fontFamily:"serif",fontSize:"14px"}}>{order.is_table === 0 ? (
                    <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>Table Order</span>
                  ) : (
                    <span class="badge rounded-pill bg-info p-2" style={{color:"white"}}>Parcel Order</span>
                  )}</p>
                  {order.is_table === 0 && <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Table No: <span style={{fontWeight:"normal"}}>{order.table_no}</span></p>}
                </div>
                <div className="Ccolumnki">
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Kitchen Order: <span style={{fontWeight:"normal"}}>{order.kitchen_order_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Captain: <span style={{fontWeight:"normal"}}>{order.employee_name}</span></p>
                </div>
              </div>
            </div>
            <hr/>
          
                
               <Table striped="columns" className="scrollable-table" borderless style={{marginTop:"-20px"}}>
                <thead style={{border:"none"}}>
                <th style={{border:"none"}}>S.No</th>
                <th style={{border:"none"}}>Food</th>
                <th style={{border:"none"}}>Items</th>
                <th style={{border:"none"}}>Qty</th>
              </thead>
              {order.ordered_by.map((item, index) => (
                    <tr key={index}>
                    <td>{index+1}</td>
                    <td style={{textAlign:"center"}}><img src = {item.image ? `${var_api}/private/${item.image}` : `${var_api}/private/food_default_image.jpg`} alt="food_img" width="80px" height="80px"/></td>
                    <td>{item.food_name}</td>
                    <td>{item.quantity}</td>
                    </tr>
              ))}
    </Table>
    <hr/>
           
                 <button type="button"  class="btn btn-outline-success" style={{float:"right"}} onClick={()=>updateReady(order.id,order.kitchen_order_no, index, order.is_table)}>
                 {rLoading[index] ?  (
                    <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  ) : (
       <>
       <i className="bi bi-arrow-right"></i>
       {" "}to Ready
     </>) }
                 </button>
               
               {/* <button type="button" onClick={() => moveOrderToInProgress(order.id)} class="btn btn-outline-success" style={{marginTop:"10px"}}>New</button> */}
              </Card.Body>
          
            </Card>
          </Col>
         ))
        )}
      </Row>
}
              </div>
            </div>
          </div>




          <div className="col mb-0 ftco-animate">
            <div className="card" id="kitthree" >
              <div class="card-header" style={{border:"none",color:"green",fontWeight:"bolder",fontSize:"18px",...(isOpen ? {} : { zIndex: "9999" })}}>Ready {" "}
              <span class="badge rounded-circle bg-dark" style={{color:"white"}}>{readyOrdersCount && readyOrdersCount.ready}</span>
              <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto",float:"right"}}
              placeholder='choose date' value={formatDate(readyStartDate)} onChange={handleToChangeReadyDate} />
              </div>
              <div className="card-body" id="scr" style={{marginTop:"-40px"}}>
              {/* <br/>
                <h6 style={{margin:"10px 10px 10px 5px"}}><span style={{float:"right"}}><i class="bi bi-calendar"></i>{" "}{formattedDate}</span></h6> */}
                <br/>
                {readyloading ? (
                  <div class="text-center" >
                  <div class="spinner-border" role="status" >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) :
              <Row>
               { readyOrders && readyOrders.ready && readyOrders.ready.length === 0 ? (
                <div className="text-center">
                  <h6 style={{fontWeight:"bolder",marginTop:"30px",color:"red"}}>No Date Available</h6>
                </div>
              ) : (
              readyOrders && readyOrders.ready && readyOrders.ready.map((order,index) => (
           
           <Col key={index}>
            <Card  border="success" style={{borderRadius:"20px",width:"320px",marginBottom:"0px",marginTop:"12px",height:"auto"}}> 
          
           <Card.Body className="p-2">
            <div>
              <div className="Crowki">
                <div className="Ccolumnki">
                  <p style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>S.No: <span style={{fontWeight:"normal"}}>{order.order_count_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}} >Items: <span style={{fontWeight:"normal"}}>{order.ordered_by.length}</span></p>
                </div>
                <div className="Ccolumnki">
                  <p  style={{fontFamily:"serif",fontSize:"14px"}}>{order.is_table === 0 ? (
                    <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>Table Order</span>
                  ) : (
                    <span class="badge rounded-pill bg-info p-2" style={{color:"white"}}>Parcel Order</span>
                  )}</p>
                  {order.is_table === 0 && <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Table No: <span style={{fontWeight:"normal"}}>{order.table_no}</span></p>}
                </div>
                <div className="Ccolumnki">
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Kitchen Order: <span style={{fontWeight:"normal"}}>{order.kitchen_order_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Captain: <span style={{fontWeight:"normal"}}>{order.employee_name}</span></p>
                </div>
              </div>
            </div>
            <hr/>
          
                
               <Table striped="columns" className="scrollable-table" borderless style={{marginTop:"-20px"}}>
                <thead style={{border:"none"}}>
                <th style={{border:"none"}}>S.No</th>
                <th style={{border:"none"}}>Food</th>
                <th style={{border:"none"}}>Items</th>
                <th style={{border:"none"}}>Qty</th>
              </thead>
              {order.ordered_by.map((item, itemIndex) => (
                    <tr key={itemIndex}>
                    <td>{itemIndex+1}</td>
                    <td style={{textAlign:"center"}}><img src = {item.image ? `${var_api}/private/${item.image}` : `${var_api}/private/food_default_image.jpg`} alt="food_img" width="80px" height="80px"/></td>
                    <td>{item.food_name}</td>
                    <td>{item.quantity}</td>
                    </tr>
              ))}
    </Table>
    <hr/>
           
                 <button type="button"  class="btn btn-outline-success" style={{float:"right"}} onClick={()=>updateComplete(order.id,order.kitchen_order_no,index,order.is_table)}>
                  {cLoading[index] ?  (
                    <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  ) : (
       <>
       <i className="bi bi-arrow-right"></i>
       {" "}to Complete
     </>) }
                  </button>
               
               {/* <button type="button" onClick={() => moveOrderToInProgress(order.id)} class="btn btn-outline-success" style={{marginTop:"10px"}}>New</button> */}
              </Card.Body>
          
            </Card>
          </Col>
         ))
        )}
      </Row>
}
              </div>
           </div>
          </div>



          <div className="col mb-0 ftco-animate">
            <div className="card" id="kitfour" >
              <div class="card-header" style={{border:"none",color:"green",fontWeight:"bolder",fontSize:"18px",...(isOpen ? {} : { zIndex: "9999" })}}>Delivered{" "}
              <span class="badge rounded-circle bg-dark" style={{color:"white"}}>{delOrders && delOrders.length}</span>
              <input type='date' name='date'  style={{border:"2px ridge green",borderRadius:"15px",height:"auto",padding:"2px", width:"auto",float:"right"}}
              placeholder='choose date' value={formatDate(startDate)} onChange={handleToChangeDate} />
              </div>
              <div className="card-body" id="scr" style={{marginTop:"-40px"}}>
              <br/>
              <div className="row">
              <div className="col">
              
                                </div>
                <div className="col">
                <button className="btn btn-outline-success" style={{borderRadius:"50px",padding:"0px 5px",marginTop:"10px",float:"right"}} onClick={handleOpenSummary}><i class="bi bi-eye-fill text-success"></i>{" "}summary</button>
                </div>
                
              </div>
              {deliveredloading ? (
                  <div class="text-center" >
                  <div class="spinner-border" role="status" >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                ) :
              <Row>
                { delOrders  && delOrders.length === 0 ? (
                <div className="text-center">
                  <h6 style={{fontWeight:"bolder",marginTop:"30px",color:"red"}}>No Date Available</h6>
                </div>
              ) : (
              delOrders  && delOrders.map((order,index) => (
           
           <Col key={index}>
            <Card  border="success" style={{borderRadius:"20px",width:"320px",marginBottom:"0px",marginTop:"12px",height:"auto"}}> 
          
           <Card.Body className="p-2">
            <div>
              <div className="Crowki">
                <div className="Ccolumnki">
                  <p style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>S.No: <span style={{fontWeight:"normal"}}>{order.order_count_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}} >Items: <span style={{fontWeight:"normal"}}>{order.ordered_by.length}</span></p>
                </div>
                <div className="Ccolumnki">
                  <p  style={{fontFamily:"serif",fontSize:"14px"}}>{order.is_table === 0 ? (
                    <span class="badge rounded-pill bg-primary p-2" style={{color:"white"}}>Table Order</span>
                  ) : (
                    <span class="badge rounded-pill bg-info p-2" style={{color:"white"}}>Parcel Order</span>
                  )}</p>
                  {order.is_table === 0 && <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Table No: <span style={{fontWeight:"normal"}}>{order.table_no}</span></p>}
                </div>
                <div className="Ccolumnki">
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Kitchen Order: <span style={{fontWeight:"normal"}}>{order.kitchen_order_no}</span></p>
                  <p  style={{fontFamily:"serif",fontSize:"14px",fontWeight:"bold"}}>Captain: <span style={{fontWeight:"normal"}}>{order.employee_name}</span></p>
                </div>
              </div>
            </div>
            <hr/>
          
                
               <Table striped="columns" className="scrollable-table" borderless style={{marginTop:"-20px"}}>
                <thead style={{border:"none"}}>
                <th style={{border:"none"}}>S.No</th>
                <th style={{border:"none"}}>Food</th>
                <th style={{border:"none"}}>Items</th>
                <th style={{border:"none"}}>Qty</th>
              </thead>
              {order.ordered_by.map((item, index) => (
                    <tr key={index}>
                    <td>{index+1}</td>
                    <td style={{textAlign:"center"}}><img src = {item.image ? `${var_api}/private/${item.image}` : `${var_api}/private/food_default_image.jpg`} alt="food_img" width="80px" height="80px"/></td>
                    <td>{item.food_name}</td>
                    <td>{item.quantity}</td>
                    </tr>
              ))}
    </Table>
    <hr/>
           
    <h6 style={{fontFamily:"serif",fontWeight:"bold",textAlign:"left"}}>
  Preparation Time: {calculateDuration(order.start_time,order.end_time)}
</h6>


               {/* <button type="button" onClick={() => moveOrderToInProgress(order.id)} class="btn btn-outline-success" style={{marginTop:"10px"}}>New</button> */}
              </Card.Body>
          
            </Card>
          </Col>
         ))
        )}
      </Row>
}
      
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </div>
    <Modal
  show={isOpen}
  backdrop="static"
  keyboard={false}
  onHide={()=>setIsOpen(false)}
  size="md"
>
  <Modal.Header closeButton  style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"12px"}}>
    <Modal.Title style={{fontSize:"19px", fontWeight:"bold"}}>Kitchen Summary ({startDate})</Modal.Title>
  </Modal.Header>

  <Modal.Body>
      <h6 style={{fontFamily:"serif", fontWeight:"bolder"}}>Total Table-Split Orders: <span style={{fontFamily:"monospace", fontWeight:"normal"}}>{deSummary.table_split_orders}</span></h6>
      <hr/>
      <h6 className="text-primary" style={{fontFamily:"serif", fontWeight:"bolder"}}>Total Table Orders: {" "}<span className="text-dark"style={{fontFamily:"monospace", fontWeight:"normal"}}>{deSummary.table_orders}</span></h6>
      <h6 className="text-primary" style={{fontFamily:"serif", fontWeight:"bolder"}}>Total Parcel Orders: {" "}<span className="text-dark" style={{fontFamily:"monospace", fontWeight:"normal"}}>{deSummary.parcel_orders}</span></h6>
      <h6 className="text-danger" style={{fontFamily:"serif", fontWeight:"bolder"}}>Total Orders: {" "}<span className="text-dark"style={{fontFamily:"monospace", fontWeight:"normal"}}>{deSummary.total_orders}</span></h6>
  </Modal.Body>
</Modal>
      

    </>

  // </OrderProvider>
      
    )
}

export default Kitchen;